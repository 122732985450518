import { useContext, useRef } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";

const ManageRunnerPagination = () => {
	const {
		LiveDataFiltered,
		debouncedSearch,
		pagination,
		page,
		nmbPerPage,
		handleChangePage,
		maxPage
	} = useContext(ManageRunnerContext);
	const selectRef = useRef<HTMLSelectElement>(null);

	return !!LiveDataFiltered.result.length ? (
		<>
			<div className="relative ml-auto flex items-center justify-center gap-3 md:mx-auto">
				{!pagination.includes(1) && (
					<>
						<p
							className="hidden cursor-pointer md:block"
							onClick={() => handleChangePage(1)}
						>
							1
						</p>
					</>
				)}

				{pagination.map((item) => {
					if (item == page) {
						return (
							<div className="relative" key={item}>
								<select
									ref={selectRef}
									value={page}
									onChange={(e) => {
										handleChangePage(parseInt(e.target.value));
									}}
									className="relative z-10 h-[56px] w-16 appearance-none border-t-0 border-b-0 bg-transparent bg-none p-0 text-center md:w-10"
									key={item}
								>
									{Array.from(
										{
											length: Math.ceil(LiveDataFiltered.count / nmbPerPage)
										},
										(_, i) => i + 1
									).map((item) => (
										<option value={item} key={item}>
											{item}
										</option>
									))}
								</select>
								<label className="absolute right-1 top-1/2 block -translate-y-1/2 select-none md:hidden">
									<AiOutlineDown />
								</label>
							</div>
						);
					}

					return (
						<p
							className={`${
								item == page ? "font-bold" : ""
							} hidden cursor-pointer md:block`}
							onClick={() => handleChangePage(item)}
							key={item}
						>
							{item}
						</p>
					);
				})}

				{!pagination.includes(maxPage) && (
					<p
						className="hidden cursor-pointer md:block"
						onClick={() => handleChangePage(maxPage)}
						key={maxPage}
					>
						{maxPage}
					</p>
				)}
			</div>
		</>
	) : (
		<></>
	);
};

export default ManageRunnerPagination;
