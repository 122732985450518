import { useContext, useEffect, useRef, useState } from "react";
import { FaFemale, FaMale } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { useParams } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";
import trad from "../../lang/traduction";

// Define currentRankConfig type
type RANK_CONFIG = {
	config: any;
};

const ManageRunnerFilters = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const {
		run,
		runSlug,
		LiveConfig,
		LiveData,
		customRankings,
		currentFilters,
		setCurrentFilters,
        currentRankConfig,
        setCurrentRankConfig,
        currentRaceCustomFields,
        setCurrentRaceCustomFields
	} = useContext(ManageRunnerContext);

    // Can be Lanières, Lanières SHN, Caserne de pompiers, etc... 
	const [customRankName, setCustomRankName] = useState("");
	const [genderFilter, setGenderFilter] = useState("");
	const [ts, setTs] = useState(0);
	const intervalRef = useRef<any>();

	const [selectValues, setSelectValues] = useState<any>({});

	const updateTs = () => {
		setTs(Math.floor(Date.now() / 1000));
	};

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			updateTs();
		}, 1000);

		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);

	useEffect(() => {
		if (LiveData && LiveConfig && runSlug && run) {
			// Get race object from LiveConfig
			const raceObj = LiveConfig.calendrier_child.find((item: any) => {
				return item.slug === runSlug;
			});
			// Liste
			const liste = raceObj.liste;
			setCurrentRaceCustomFields(liste);
		}
	}, [LiveData, LiveConfig, runSlug, run]);

	const getHorizontalSeparator = () => (
		<>
			<IoIosArrowForward
				className={`hidden md:ml-2 md:block ${
					ts % 2 === 0 ? "fill-gray-400" : "fill-gray-300"
				}`}
			/>
			<IoIosArrowForward
				className={`mr-2 hidden md:-ml-3 md:block ${
					ts % 2 === 1 ? "fill-gray-400" : "fill-gray-300"
				}`}
			/>
		</>
	);

	const getChoiceLabel = function (choiceId: number) {
		if (currentRaceCustomFields) {
			for (let list of currentRaceCustomFields) {
				for (let choice of list.choix) {
					if (choice.id === choiceId) return choice.libelle;
				}
			}
		}
		return "";
	};

	useEffect(() => {
		resetFilters();
	}, [runSlug, customRankings, currentRankConfig]);

	const handleChangeRanking = function (customRankName: string) {
		setCustomRankName(customRankName);
	};

	const resetFilters = function () {
		setGenderFilter("");
		// Loop all
		const fields: any = {};
		for (let field of Object.keys(currentRankConfig?.config)) {
			fields[field] = JSON.parse(
				JSON.stringify(currentRankConfig?.config[field])
			);
		}
		setCurrentFilters(fields);
		setSelectValues({});
	};

	useEffect(() => {
		if (customRankName && customRankings) {
			// Get custom rank config for current rank name
			let currentRankConfig = customRankings.find((item: any) => {
				return item.name === customRankName;
			});
			if (currentRankConfig) currentRankConfig = { ...currentRankConfig };

			// Get all custom fields configured for current race
			const raceObj = LiveConfig.calendrier_child.find((item: any) => {
				return item.slug === runSlug;
			});
			const raceListe = raceObj.liste;

			// Replace digit keys with liste's libelle
			// {123: [1, 2]} => {couleur: [1, 2]}
			for (let key in currentRankConfig.config) {
				if (!isNaN(parseInt(key))) {
					const listeObj = raceListe.find((item: any) => {
						return item.id == key;
					});
					currentRankConfig.config[listeObj?.libelle || key] =
						currentRankConfig.config[key];
					delete currentRankConfig.config[key];
				}
			}
			setCurrentRankConfig(currentRankConfig);
		}
	}, [customRankName]);

	useEffect(() => {
		const genderFields =
			genderFilter === "" || !genderFilter
				? [1, 2, 3]
				: [parseInt(genderFilter)];
		setCurrentFilters({ ...currentFilters, gender: genderFields });
	}, [genderFilter]);

	const handleChangeCustomField = function (
		rankConfig: any,
		listName: string,
		customField: string
	) {
		const fields = customField === "" ? rankConfig[listName] : [customField];
		setCurrentFilters((prevValues: any) => ({
			...prevValues,
			[listName]: fields
		}));
		setSelectValues((prevValues: any) => ({
			...prevValues,
			[listName]: customField
		}));
	};

	const onToggleGender = function (genderId: number) {
        if (currentRankConfig?.config?.gender && currentRankConfig?.config?.gender.length === 1)
            return ;
		if (!genderFilter || genderFilter !== genderId.toString())
			setGenderFilter(genderId.toString());
		else setGenderFilter("");
	};

	return (
		<>
            {run > 0 && (
                <div className="mb-2 flex w-full flex-row items-center justify-center gap-[2px] p-2 text-sm shadow-md lg:w-auto">
                    <div>
                        <label className="mr-2 text-xs font-semibold">Classement</label>
                    </div>

                    <select
                        value={customRankName}
                        onChange={(e) => {
                            handleChangeRanking(e.target.value);
                        }}
                        className="h-10 rounded-md border border-gray-300 pl-2 text-xs focus:outline-none"
                    >
                        {customRankings?.map((item: any) => (
                            <option value={item.name} key={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>

                    {currentRankConfig.config.gender && (
                        <>
                            {getHorizontalSeparator()}

                            {/* GENDER SELECT */}
                            <div className="mt-2 flex flex-row items-center sm:mt-0 md:w-auto">
                                <div className="flex w-1/2 flex-row items-center md:-ml-1 md:w-auto">
                                {currentRankConfig.config.gender.includes(1) && (
                                    <button onClick={() => onToggleGender(1)}>
                                        <FaMale
                                            className={`h-6 w-6 md:h-7 md:w-7 ${
                                                genderFilter === "1" || !genderFilter
                                                    ? "fill-primary"
                                                    : "fill-gray-400"
                                            }`}
                                        />
                                    </button>
                                    )}
                                    {currentRankConfig.config.gender.includes(2) && (
                                    <button onClick={() => onToggleGender(2)}>
                                        <FaFemale
                                            className={`-mr-1 h-6 w-6 md:-ml-1 md:h-7 md:w-7 ${
                                                genderFilter === "2" || !genderFilter
                                                    ? "fill-pink-400"
                                                    : "fill-gray-400"
                                            }`}
                                        />
                                    </button>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {currentRankConfig?.config && (
                        <>
                            {Object.keys(currentRankConfig.config)
                                .filter(
                                    (item) =>
                                        item !== "gender" && currentRankConfig.config[item]?.length
                                )
                                .map((item) => (
                                    <div key={item} className="flex flex-row items-center">
                                        {getHorizontalSeparator()}
                                        <div className="gap-x-2 flex flex-row items-center">
                                            <div>
                                                <label className="text-xs font-semibold">{item}</label>
                                            </div>
                                            <select
                                                value={selectValues[item] || ""}
                                                disabled={currentRankConfig.config[item]?.length === 1}
                                                onChange={(e) => {
                                                    handleChangeCustomField(
                                                        currentRankConfig.config,
                                                        item,
                                                        e.target.value
                                                    );
                                                }}
                                                className="h-10 rounded-md border border-gray-300 pl-2 text-xs focus:outline-none"
                                            >
                                                {currentRankConfig.config[item]?.length > 1 && (
                                                    <option value="">{trad[lang].ranking_all_choices}</option>
                                                )}
                                                {currentRankConfig.config[item]?.map((choice: any) => (
                                                    <option value={choice} key={choice}>
                                                        {getChoiceLabel(choice)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                </div>
            )}
		</>
	);
};

export default ManageRunnerFilters;
