import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEvent, getEventLists, getEventRunsConfig } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import ListItem from "../components/event_configuration/ListItem";
import ListPopup from "../components/event_configuration/ListPopup";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

const Lists = () => {
	const { slug, slug_race } = useParams();
	const { lang } = useContext(AppContext);
	const [openedList, setOpenedList] = useState({ id: -1, name: "" });

	const closeList = () => setOpenedList({ id: -1, name: "" });

	const { data, isLoading } = useQuery({
		queryKey: [slug],
		queryFn: () => getEvent(slug),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const { data: RunsConfig = [], isLoading: isRunsLoading } = useQuery({
		queryKey: ["run_config", slug],
		queryFn: () => getEventRunsConfig(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const {
		data: Lists = [],
		isLoading: isListLoading,
		refetch
	} = useQuery({
		queryKey: [slug, slug_race],
		queryFn: () => getEventLists(slug as string, slug_race as string),
		refetchOnWindowFocus: false,
		enabled: !!slug && !!slug_race
	});

	const run = RunsConfig.find((item: any) => item.slug == slug_race);

	if (isLoading || isListLoading || isRunsLoading) {
		return <PageLoader menu_key="configuration" />;
	}

	return (
		<Layout
			active_key="configuration"
			bottomBar={<BottomBarNavigation back_to={`/${slug}/event-details/`} />}
		>
			<BreadCrumb
				items={[
					{
						key: "event",
						text: data.name,
						to: `/${slug}/event-details/`,
						active: false
					},
					{ key: "lists", to: "#", active: true }
				]}
			/>

			{/* Title */}
			<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:text-3xl">
				{trad[lang].lists}
			</h1>

			<h2 className="mb-6 text-lg font-bold text-gloom">{run?.name}</h2>

			{Lists.map((item: any) => (
				<ListItem list={item} key={item.id} refetch={refetch} />
			))}

			<div className="w-full">
				<button
					type="button"
					className="rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-white transition-colors hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300"
					onClick={() => setOpenedList({ id: 0, name: "" })}
				>
					{trad[lang].add_list}
				</button>
			</div>

			{openedList.id >= 0 && (
				<ListPopup
					lists={Lists}
					opened={openedList}
					close={closeList}
					refetch={refetch}
				/>
			)}
		</Layout>
	);
};

export default Lists;
