import { API } from "./APIClient";

export const getActivityPositionsBySubscription = async (
	idInscription: number,
	idActivity: string
) => {
	try {
		const { data } = await API.get(
			`/app/subscription/${idInscription}/activity/${idActivity}`
		);

		return data;
	} catch (error) {
		console.warn("[getActivityPositionsBySubscription error]", error);
		throw error;
	}
};

export const getAppCheckpoints = async (idCalendrier: number) => {
	try {
		const { data } = await API.get(`/app/${idCalendrier}/checkpoints`);

		return data;
	} catch (error) {
		console.warn("[getActivityPositionsBySubscription error]", error);
		throw error;
	}
};
