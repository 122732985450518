import { Tooltip, Typography } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useContext } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { saveRankingConfig } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { IList, IRanking } from "../../types/IRankingConfig";
import Toast from "../../utils/Toasts";
import ListConfiguration from "./ListConfiguration";

const RankingComponent = ({
	lists,
	openedList,
	setOpenedList,
	refetch
}: {
	lists: IList[];
	openedList: IRanking;
	setOpenedList: Dispatch<SetStateAction<IRanking>>;
	refetch: any;
}) => {
	const { slug, slug_race } = useParams();
	const { lang } = useContext(AppContext);

	const saveList = async () => {
		try {
			if (!slug || !slug_race) return;

			if (
				openedList.name.length < 2 ||
				!Object.values(openedList.config).flat().length
			) {
				Toast.error(trad[lang].toast_empty_ranking_config);
				return;
			}

			await saveRankingConfig(slug, slug_race, openedList);

			Toast.success(trad[lang].toast_success_save_ranking_config);
			setOpenedList({ id: -1, name: "", config: {} });
			refetch();
		} catch (error) {
			Toast.error(trad[lang].toast_error_save_ranking_config);
			throw error;
		}
	};

	return (
		<>
			<div className="mb-4">
				<h3 className="mb-1">{trad[lang].ranking_name}</h3>

				<input
					type="string"
					value={openedList.name}
					onChange={(e) =>
						setOpenedList((old) => ({ ...old, name: e.target.value }))
					}
					className="h-12 w-full rounded-md border border-gray-300 px-2 focus:outline-none md:w-1/2"
				/>
			</div>

			<h3 className="mb-1 flex items-center gap-2">
				{trad[lang].available_filters}

				<Tooltip
					className="text-md z-50 text-center"
					content={
						<Typography>{trad[lang].ranking_filters_explanation}</Typography>
					}
				>
					<p>
						<AiOutlineInfoCircle />
					</p>
				</Tooltip>
			</h3>

			<ListConfiguration
				lists={lists}
				openedList={openedList}
				setOpenedList={setOpenedList}
			/>

			<button
				className="mt-2 rounded-md bg-primary px-5 py-2.5 text-center text-sm font-medium text-white transition-colors hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300 md:absolute md:top-2 md:right-2 md:mt-0"
				onClick={saveList}
			>
				{trad[lang].save}
			</button>
		</>
	);
};

export default RankingComponent;
