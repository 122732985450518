import { useContext, useRef, useState } from "react";
import {
    AiFillPlayCircle,
    AiOutlineDownload,
    AiOutlineLoading3Quarters
} from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { deleteOneSub } from "../../api/admin";
import {
    callExportExcel,
    exportPdf,
    getEventSubscriptions,
    getExportElogicaResults
} from "../../api/event";
import { updateRunnerStatus } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";
import Modal from "../common/Modal";
import InsertDetection from "../detections/InsertDetection";
import CreateRunnerModal from "./CreateRunnerModal";

const rankingExportOptions = [
	{ id: "classement", title: "Général" },
	{ id: "classementSex", title: "Sexe" },
	{ id: "classementCategorie", title: "Catégorie" }
];

const ManageRunnerMenu = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const {
		selectedRunner,
		run,
		debouncedSearch,
		order,
		source,
		handleChangeRun,
		LiveConfig,
		handleChangeSource,
		Points,
		search,
		finishedRace,
		statutCourse,
		setFinishedRace,
		handleChangeSearch,
		setOpenedDetections,
		setOpenedSubscription,
		setOpenedReset,
		handleChangeStatus,
		LiveDataRefetch,
		LiveData,
		LiveDataFiltered,
		currentRankConfig
	} = useContext(ManageRunnerContext);
	const [openedMenu, setOpenedMenu] = useState(false);
	const [openedExportMenu, setOpenedExportMenu] = useState(false);
	const [selectedExportOption, setSelectedExportOption] =
		useState("classement");
	const [selectedExportType, setSelectedExportType] = useState("pdf");
	const [openedExportOptions, setOpenedExportOptions] = useState(false);
	const [adminOption, setAdminOption] = useState(false);
	const [loading, setLoading] = useState(false);
	const [newStatus, setNewStatus] = useState(-1);
	const [openedInsert, setOpenedInsert] = useState(false);
	const [sexPageBreaker, setSexPageBreaker] = useState(false);
	const [allChecked, setAllChecked] = useState(true);
	const [openDelete, setOpenDetele] = useState(false);
	const [checkedState, setCheckedState] = useState(
		new Array(LiveConfig?.calendrier_child?.length).fill(false)
	);
	const [raceIds, setRaceIds] = useState([]);
	const [raceName, setRaceName] = useState<string>(slug as string);
	const [openedSubscriptionWithoutId, setOpenedSubscriptionWithoutId] =
		useState(false);
	const ref = useRef(null);
	const [isFFAEvent, setIsFFAEvent] = useState(false);
	const [isLoadingFFAExport, setIsLoadingFFAExport] = useState(false);
	useOnClickOutside(ref, () => setOpenedMenu(false));

	const exportPDF = async () => {
		try {
			setLoading(true);
			await exportPdf(
				slug as string,
				selectedExportOption,
				1,
				adminOption,
				sexPageBreaker,
				raceIds as [],
				raceName
			);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	function convertDateToDDMMYYYY(dateString: string): string {
		// Regular expression to match US date format YYYY-MM-DD
		const usDateFormat = /^\d{4}-\d{2}-\d{2}$/;

		if (usDateFormat.test(dateString)) {
			const [year, month, day] = dateString.split("-");
			return `${day}-${month}-${year}`;
		}
		return dateString;
	}

	const exportExcel = async () => {
		try {
			setLoading(true);

			const subscritpions = await getEventSubscriptions(
				slug,
				"",
				Number.MAX_SAFE_INTEGER.toString(),
				1,
				{ bib: "", run: "", contact: "", name: "", status: "", date: "" },
				{
					bib: null,
					run: null,
					contact: null,
					name: null,
					status: null,
					date: -1
				},
				false
			);
			const columnsToExport = [
				{ param: "a", label: "Classement" },
				{ param: "d", label: "Nom" },
				{ person: true, param: "city", label: "Ville" },
				{ param: "e", label: "Dossard" },
				{ param: "f", label: "Temps" },
				{ person: true, param: "birthdate", label: "Date de naissance" },
				{ param: "j", label: "Catégorie" },
				{ param: "c", label: "Classement par catégorie" },
				{ param: "h", label: "Sexe" },
				{ param: "b", label: "Classement par sexe" },
				{ param: "y", label: "Course" }
			];

			// Get one sub with one or more custom fields
			let subWithCustomFields: any = null;
			for (const sub of subscritpions?.result)
				if (
					sub.inscription_choix?.length &&
					sub.inscription_choix?.length >
						(subWithCustomFields?.inscription_choix?.length || 0)
				)
					subWithCustomFields = sub;

			if (LiveDataFiltered?.result?.length) {
				const data = LiveDataFiltered.result.map((runner: any) => {
					const row: any = {};
					const sub = subscritpions?.result?.find(
						(sub: any) => sub.id == runner.k
					);
					columnsToExport.forEach((column) => {
						if (column.person !== true) {
							if (column.param === "h")
								row[column.label] =
									runner[column.param] === "2" ? "Femme" : "Homme";
							else row[column.label] = runner[column.param];
						} else if (column.person === true) {
							if (column.param === "birthdate") {
								row[column.label] = convertDateToDDMMYYYY(
									sub?.person[column.param]
								);
							} else row[column.label] = sub?.person[column.param] || "";
						}
					});
					if (subWithCustomFields?.inscription_choix?.length) {
						const list = subWithCustomFields.inscription_choix;
						for (const customField of list) {
							row[customField.libelleList] =
								sub?.inscription_choix?.find(
									(item: any) => item.idList == customField.idList
								)?.libelle || "";
						}
					}

					return row;
				});
				await callExportExcel(
					slug as string,
					raceName,
					currentRankConfig.name,
					data
				);
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	const openSubscription = (idInscription: number) => {
		setOpenedSubscription(idInscription);
		setOpenedMenu(false);
	};

	const openSubscriptionWithoutId = () => {
		setOpenedSubscriptionWithoutId(true);
		setOpenedMenu(false);
	};

	const openDetections = (dossard: number) => {
		setOpenedDetections(dossard);
		setOpenedMenu(false);
	};

	const openReset = (dossard: number) => {
		setOpenedReset(dossard);
		setOpenedMenu(false);
	};

	const modalMessage = {
		0: trad[lang].updateRunnerStatusStarting,
		1: trad[lang].updateRunnerStatusNotStarting,
		2: trad[lang].updateRunnerStatusSurrended,
		3: trad[lang].updateRunnerStatusDisqualified
	};

	const handleUpdateStatus = async () => {
		try {
			if (!slug) return;

			await updateRunnerStatus(slug, selectedRunner.k, newStatus);

			setNewStatus(-1);
		} catch (error) {
			console.error(error);
		}
	};

	const handleCheckboxChange = (position: any) => {
		const updatedCheckedState = checkedState.map((item, index) =>
			index === position ? !item : item
		);

		const newRaceIds = LiveConfig?.calendrier_child
			?.filter((_: any, index: any) => updatedCheckedState[index])
			.map((item: any) => item.id);
		const newRaceName = LiveConfig?.calendrier_child
			?.filter((_: any, index: any) => updatedCheckedState[index])
			.map((item: any) => item.nom);

		setCheckedState(updatedCheckedState);
		setRaceIds(newRaceIds);
		setRaceName(
			newRaceIds.length == 1
				? newRaceName[0]
				: newRaceIds.length == 0
				? (slug as string)
				: (slug as string)
		);
		setAllChecked(updatedCheckedState.every((state) => !state));
	};
	const handleAllCheckboxChange = () => {
		const newAllChecked = !allChecked;
		setAllChecked(newAllChecked);
		const newCheckedState = new Array(
			LiveConfig?.calendrier_child?.length
		).fill(!newAllChecked);
		setCheckedState(newCheckedState);
		setRaceIds(
			newAllChecked
				? []
				: LiveConfig?.calendrier_child?.map((item: any) => item.id)
		);
		setRaceName(slug as string);
	};

	const handleClick = (divId: any) => {
		if (divId == "0") {
			setFinishedRace("true");
			handleChangeStatus("");
			return;
		}
		handleChangeStatus(divId);
		setFinishedRace("false");
	};

	const totalSubscriber = LiveData?.subs;

	const handleExportLogica = async () => {
		try {
			setIsLoadingFFAExport(true);
			const { data, headers } = await getExportElogicaResults(slug as string);

			const blob = new Blob([data], {
				type: "application/octet-stream"
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = `${
				LiveConfig.nom
					? LiveConfig.nom + "_Resultats_FFA_e-logica"
					: "Resultats e-logica"
			}.gz`;
			document.body.appendChild(link);
			link.click();

			URL.revokeObjectURL(url);
		} catch (error) {
		} finally {
			setIsLoadingFFAExport(false);
		}
	};

	const deleteSubscription = async (inscriptionId: any) => {
		try {
			await deleteOneSub(inscriptionId);
			await LiveDataRefetch();
			setOpenDetele(false);
			Toast.success(trad[lang].deletionSuccess);
		} catch {
			Toast.error(trad[lang].deletionError);
		}
	};

	return (
		<>
			<div className="sticky top-0 z-10">
				<div className="flex w-full flex-row flex-wrap items-center gap-4 bg-white p-5 lg:flex-nowrap">
					<div ref={ref}>
						<button
							className="h-10 rounded bg-primary px-2 text-white shadow-md transition-colors hover:bg-primarymedium"
							onClick={() => setOpenedMenu(!openedMenu)}
						>
							Action
						</button>
						{openedMenu && selectedRunner?.e && (
							<div className="absolute top-16 left-5 flex flex-col rounded bg-white py-2 shadow-md">
								<h3 className="px-2 pt-2 text-sm text-gray-600">{`${trad[lang].bib} ${selectedRunner.e} - ${selectedRunner.d}`}</h3>

								<div className="flex w-full flex-col border-b border-gray-400">
									<button
										className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
										onClick={() => openSubscription(selectedRunner.k)}
									>
										{trad[lang].editRunner}
									</button>
									<button
										className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
										onClick={() => openSubscriptionWithoutId()}
									>
										{trad[lang].addRegistration}
									</button>
									<button
										className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
										onClick={() => openDetections(selectedRunner.e)}
									>
										{trad[lang].manageDetections}
									</button>
									<button
										className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
										onClick={() => openReset(selectedRunner.e)}
									>
										{trad[lang].resetOnRunner}
									</button>
									<button
										className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
										onClick={() => {
											setOpenDetele(true);
										}}
									>
										{trad[lang].deleteSub}
									</button>
								</div>
								<div className="flex w-full flex-col">
									{selectedRunner.x != 0 && (
										<button
											className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
											onClick={() => setNewStatus(0)}
										>
											{trad[lang].setRunnerStarting}
										</button>
									)}
									{selectedRunner.x != 1 && (
										<button
											className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
											onClick={() => setNewStatus(1)}
										>
											{trad[lang].setRunnerNotStarting}
										</button>
									)}
									{selectedRunner.x != 2 && (
										<button
											className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
											onClick={() => setNewStatus(2)}
										>
											{trad[lang].setRunnerSurrended}
										</button>
									)}
									{selectedRunner.x != 3 && (
										<button
											className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
											onClick={() => setNewStatus(3)}
										>
											{trad[lang].setRunnerDisqualified}
										</button>
									)}
								</div>
							</div>
						)}
					</div>
					<button
						className="flex h-10 w-10 items-center justify-center rounded-md border bg-primary px-2 text-white shadow-md transition-colors hover:bg-primarymedium focus:outline-none"
						onClick={() => {
							setOpenedExportMenu(!openedExportMenu);
							!openedExportMenu && setOpenedExportOptions(false);
						}}
					>
						<AiOutlineDownload size={25} />
					</button>
					{isFFAEvent && (
						<button
							className={`flex h-10 items-center justify-center rounded-md border ${
								isLoadingFFAExport
									? "bg-orange-400 hover:bg-orange-500"
									: "bg-primary hover:bg-primarymedium"
							} px-2 text-white shadow-md transition-colors focus:outline-none`}
							onClick={() => {
								handleExportLogica();
							}}
						>
							{isLoadingFFAExport ? (
								<>
									<AiOutlineLoading3Quarters
										size={20}
										className="mr-1 animate-spin"
									/>
									FFA
								</>
							) : (
								<>
									<AiOutlineDownload size={25} />
									FFA
								</>
							)}
						</button>
					)}
					<InsertDetection
						openedInsert={openedInsert}
						setOpenedInsert={setOpenedInsert}
						refetch={LiveDataRefetch}
						credential={LiveConfig?.credential}
					/>

					<Link
						to={`/${slug}/live-positions`}
						className="mr-auto flex h-10 cursor-pointer items-center justify-center gap-2 rounded border border-primary bg-white py-4 px-2 text-primary shadow-md transition-transform hover:scale-105"
					>
						<AiFillPlayCircle size={20} className="text-primary" />
						<p className="hidden lg:block">Live</p>
					</Link>

					<div className="sicky top-[500px] flex gap-4">
						<select
							className="h-10 w-full rounded-md border border-gray-300 pl-2 text-xs focus:outline-none md:hidden"
							value={
								statutCourse == "" && finishedRace == "true"
									? "0"
									: statutCourse
							}
							onChange={(e) => handleClick(e.target.value)}
						>
							<option value={""}>{`${totalSubscriber || 0} - ${
								trad[lang].SUB
							}`}</option>
							<option value={"0"}>{`${LiveData?.ranked || 0} - ${
								trad[lang].ARR
							}`}</option>
							<option value={"1"}>{`${LiveData?.nostarter || 0} - ${
								trad[lang].DNS
							}`}</option>
							<option value={"2"}>{`${LiveData?.surrended || 0} - ${
								trad[lang].DNF
							}`}</option>
							<option value={"3"}>{`${LiveData?.disqualified || 0} - ${
								trad[lang].DQ
							}`}</option>
						</select>

						<div
							className={`hidden cursor-pointer flex-col items-center justify-center gap-1 rounded p-2 text-[0.5rem] hover:bg-blue-100 md:flex lg:text-xs ${
								statutCourse === "" && finishedRace === "false"
									? "bg-blue-100"
									: ""
							}`}
							onClick={() => handleClick("")}
						>
							<span className="text-[0.5rem] lg:text-xs">
								<strong>{totalSubscriber || 0}</strong>
							</span>{" "}
							<span>{trad[lang].SUB}</span>
						</div>
						<div
							className={`hidden cursor-pointer flex-col items-center justify-center gap-1 rounded p-2 text-[0.5rem] hover:bg-green-300 md:flex lg:text-xs ${
								statutCourse === "" && finishedRace === "true"
									? "bg-green-300"
									: ""
							}`}
							onClick={() => handleClick("0")}
						>
							<span className="text-[0.5rem] lg:text-xs">
								<strong>{LiveData?.ranked || 0}</strong>
							</span>{" "}
							<span>{trad[lang].ARR}</span>
						</div>
						<div
							className={`hidden cursor-pointer flex-col items-center justify-center gap-1 rounded p-2 text-[0.5rem] hover:bg-gray-300 md:flex lg:text-xs ${
								statutCourse === "1" ? "bg-gray-300" : ""
							}`}
							onClick={() => handleClick("1")}
						>
							<span className="text-[0.5rem] lg:text-xs">
								<strong>{LiveData?.nostarter || 0}</strong>
							</span>{" "}
							<span>{trad[lang].DNS}</span>
						</div>
						<div
							className={`hidden cursor-pointer flex-col items-center justify-center gap-1 rounded p-2 text-[0.5rem] hover:bg-yellow-200 md:flex lg:text-xs ${
								statutCourse === "2" ? "bg-yellow-200" : ""
							}`}
							onClick={() => handleClick("2")}
						>
							<span className="text-[0.5rem] lg:text-xs">
								<strong>{LiveData?.surrended || 0}</strong>
							</span>{" "}
							<span>{trad[lang].DNF}</span>
						</div>
						<div
							className={`hidden cursor-pointer flex-col items-center justify-center gap-1 rounded p-2 text-[0.5rem] hover:bg-red-300 md:flex lg:text-xs ${
								statutCourse === "3" ? "bg-red-300" : ""
							}`}
							onClick={() => handleClick("3")}
						>
							<span className="text-[0.5rem] lg:text-xs">
								<strong>{LiveData?.disqualified || 0}</strong>
							</span>{" "}
							<span>{trad[lang].DQ}</span>
						</div>
					</div>

					{openedExportMenu && (
						<div>
							<Modal
								closeModal={() => setOpenedExportMenu(!openedExportMenu)}
								button={loading ? false : true}
								titleButton="Générer"
								onClickButton={() => {
									if (selectedExportType === "pdf") exportPDF();
									else exportExcel();
								}}
								cancelBtn={loading ? false : true}
								rounded
								titleCancelButton="Annuler"
								onClickCancelButton={() =>
									setOpenedExportMenu(!openedExportMenu)
								}
								style={{ width: "auto", height: "auto", minWidth: "600px" }}
								children={
									loading ? (
										<AiOutlineLoading3Quarters
											size={45}
											className="ml-2 animate-spin"
										/>
									) : (
										<div className="flex flex-col items-start gap-8 p-10">
											<div className="flex items-center gap-4">
												<strong>Format :</strong>
												<div className="flex items-center gap-1">
													<strong>PDF</strong>
													<input
														type="radio"
														name="type"
														checked={selectedExportType === "pdf"}
														onChange={(e: any) =>
															setSelectedExportType(e.target.value)
														}
														value={"pdf"}
													/>
												</div>
												<div className="flex items-center gap-1">
													<strong>EXCEL</strong>
													<input
														type="radio"
														name="type"
														checked={selectedExportType === "excel"}
														onChange={(e: any) =>
															setSelectedExportType(e.target.value)
														}
														value={"excel"}
													/>
												</div>
												<div className="flex items-center gap-1"></div>
											</div>
											{selectedExportType === "excel" && (
												<div>
													<p className="mb-4">
														{trad[lang].ranking_export_info1}
													</p>
													<p>{trad[lang].ranking_export_info2}</p>
												</div>
											)}
											{selectedExportType === "pdf" && (
												<>
													<div className="flex items-center justify-center gap-4">
														<strong>{trad[lang].ranking_title} :</strong>
														<select
															className="h-10 rounded-md border border-gray-300 pl-2 focus:outline-none"
															value={selectedExportOption}
															onChange={(e) =>
																setSelectedExportOption(e.target.value)
															}
														>
															{rankingExportOptions.map((item: any) => {
																return (
																	<option value={item.id} key={item.id}>
																		{item.title}
																	</option>
																);
															})}
														</select>
													</div>

													{selectedExportOption === "classementCategorie" ? (
														<div>
															<strong>
																{trad[lang].ranking_export_pdf_newline}{" "}
															</strong>
															<input
																type="checkbox"
																checked={sexPageBreaker}
																onChange={() => {
																	setSexPageBreaker(!sexPageBreaker);
																}}
															/>
														</div>
													) : (
														""
													)}

													<div>
														<strong>
															{trad[lang].ranking_export_pdf_unranked}
														</strong>
														<input
															type="checkbox"
															checked={adminOption}
															onChange={() => setAdminOption(!adminOption)}
														/>
													</div>

													<div className="flex max-w-[400px] flex-row flex-wrap gap-4">
														<div className="flex items-center gap-2">
															<input
																type="checkbox"
																checked={allChecked}
																onChange={handleAllCheckboxChange}
															/>
															<span>{trad[lang].ranking_all_races}</span>
														</div>
														{LiveConfig?.calendrier_child
															?.sort((a: any, b: any) =>
																a.nom.localeCompare(b.nom)
															)
															.map((item: any, index: any) => (
																<div
																	key={item.id}
																	className="flex items-center gap-2"
																>
																	<input
																		type="checkbox"
																		value={item.id}
																		checked={checkedState[index]}
																		onChange={() => handleCheckboxChange(index)}
																	/>
																	<span>{item.nom}</span>
																</div>
															))}
													</div>
												</>
											)}
										</div>
									)
								}
							/>
						</div>
					)}

					<div className="flex w-full flex-row gap-4 lg:w-auto">
						<div className="flex w-full flex-row items-center gap-2 text-sm lg:w-auto">
							<select
								value={run}
								onChange={(e) => {
									handleChangeRun(parseInt(e.target.value));
								}}
								className="h-10 w-full rounded-md border border-gray-300 pl-2 text-xs focus:outline-none"
							>
								<option value={0}>{trad[lang].ranking_all_races}</option>
								{LiveConfig?.calendrier_child?.map((item: any) => (
									<option value={item.id} key={item.id}>
										{item.nom}
									</option>
								))}
							</select>

							{run > 0 && (
								<>
									<p>{trad[lang].between}</p>
									<select
										value={source[0]}
										onChange={(e) =>
											handleChangeSource(0, parseInt(e.target.value))
										}
										className="h-10 rounded-md border border-gray-300 pl-2 text-xs focus:outline-none"
									>
										<option value={0}>{trad[lang].none}</option>
										{Points.filter((item: any) => item.type != 3).map(
											(item: any) => (
												<option value={item.id} key={item.id}>
													{`${item.ligne} - ${item.name}`}
												</option>
											)
										)}
									</select>

									<p>{trad[lang].and}</p>

									<select
										value={source[1]}
										onChange={(e) =>
											handleChangeSource(1, parseInt(e.target.value))
										}
										className="h-10 rounded-md border border-gray-300 pl-2 text-xs focus:outline-none"
									>
										<option value={0}>{trad[lang].none}</option>
										{Points.filter((item: any) => item.type != 1).map(
											(item: any) => (
												<option value={item.id} key={item.id}>
													{`${item.ligne} - ${item.name}`}
												</option>
											)
										)}
									</select>
								</>
							)}
						</div>

						<input
							value={search}
							onChange={(e) => handleChangeSearch(e.target.value)}
							placeholder={trad[lang].search}
							className="h-10 w-full rounded-md border border-gray-300 pl-2 text-xs focus:outline-none lg:w-auto"
						/>
					</div>
				</div>

				{newStatus > -1 && (
					<Modal
						style={{
							width: "50%",
							height: "30%"
						}}
						closeModal={() => setNewStatus(-1)}
						titleButton={trad[lang].confirm}
						cancelBtn
						deleteBtn
						onClickCancelButton={() => setNewStatus(-1)}
						titleCancelButton={trad[lang].cancel}
						onClickButton={handleUpdateStatus}
						children={
							<h2>{modalMessage[newStatus as keyof typeof modalMessage]}</h2>
						}
						rounded
					/>
				)}

				{openedSubscriptionWithoutId && (
					<CreateRunnerModal
						onClose={() => setOpenedSubscriptionWithoutId(false)}
					/>
				)}

				{openDelete && (
					<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
						<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
							<div className="flex items-start justify-between rounded-t border-b p-4">
								<h2 className="text-center text-2xl">{trad[lang].deleteSub}</h2>
							</div>

							<div className="grid grid-cols-2 gap-5 p-5">
								<div className="col-span-2 flex flex-row gap-2 text-center">
									<p className="font-normal">{trad[lang].confirmDeleteSub}</p>
									<p className="font-semibold">{selectedRunner.d} ?</p>
								</div>
							</div>

							<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
								<button
									className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
									onClick={() => setOpenDetele(false)}
									disabled={loading}
								>
									{trad[lang].close}
								</button>
								<button
									className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
									onClick={() => deleteSubscription(selectedRunner.k)}
									disabled={loading}
								>
									{trad[lang].delete}
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default ManageRunnerMenu;
