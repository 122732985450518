import { useContext, useState } from "react";
import Layout from "../../components/navigation/Layout";
import { AppContext } from "../../contexts/AppContext";
import useScript from "../../hooks/useScript";
import trad from "../../lang/traduction";

const tabs: {
	id: number;
	key: "shop_licence" | "shop_material" | "shop_chip";
	container: string | undefined;
}[] = [
	{
		id: 1,
		key: "shop_licence",
		container: process.env.REACT_APP_SHOP_IDPRODUIT_LICENCE
	},
	{
		id: 2,
		key: "shop_material",
		container: process.env.REACT_APP_SHOP_IDPRODUIT_SPORT
	},
	{
		id: 3,
		key: "shop_chip",
		container: process.env.REACT_APP_SHOP_IDPRODUIT_PUCE
	}
];

const Shop = () => {
	const { lang } = useContext(AppContext);
	const [shopTab, setShopTab] = useState(1);

	useScript(
		(process.env &&
			process.env?.REACT_APP_SHOP_JETCODES_URL?.replace("${lang}", lang)) ||
			"",
		[lang]
	);

	return (
		<Layout active_key="shop">
			{/* TITLE */}
			<h1 className="mb-6 mt-6 text-center text-2xl font-bold text-gloom md:text-3xl">
				{trad[lang].shop}
			</h1>
			{/* BASKET */}
			<div
				className="basket-jetcode"
				data-basket-id="0"
				css-jetcode-href="https://content.dag-system.com/chronometrage/shop_basket.css"
			></div>
			{/* CONTENT */}
			<div className="w h-screen">
				{/* PRODUCTS */}

				<div className="mx-5 flex flex-row divide-x divide-gray-300 overflow-hidden rounded-md border border-gray-300">
					{tabs.map((item) => (
						<button
							className={`h-12 flex-1 ${
								item.id == shopTab
									? "bg-primary text-white transition-colors"
									: "bg-white text-black transition-colors hover:bg-slate-100"
							}`}
							onClick={() => setShopTab(item.id)}
							key={item.id}
						>
							{trad[lang][item.key]}
						</button>
					))}
				</div>

				{tabs.map((item) => (
					<div
						className={`container-jetcode ${
							item.id == shopTab ? "block" : "hidden"
						}`}
						data-container-id={item.container}
						css-jetcode-href="https://content.dag-system.com/chronometrage/product.css"
						jetcode-options="displayProductImage,options=display:true"
						key={item.id}
					></div>
				))}

				<div
					className="productinfo-jetcode-def"
					css-jetcode-href="https://content.dag-system.com/chronometrage/productinfo.css"
				></div>

				<div
					className="shippinginfo-jetcode-def"
					css-jetcode-href="https://content.dag-system.com/chronometrage/shippinginfo.css"
				></div>
			</div>
		</Layout>
	);
};

export default Shop;
