import { Dispatch, SetStateAction, useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { IChoice, IList, IRanking } from "../../types/IRankingConfig";

const ListConfiguration = ({
	lists,
	openedList,
	setOpenedList
}: {
	lists: IList[];
	openedList: IRanking;
	setOpenedList: Dispatch<SetStateAction<IRanking>>;
}) => {
	const { lang } = useContext(AppContext);

	const handleSelectList = (list: IList) => {
		const newConfig = { ...openedList.config };

		if (!newConfig[list.id]) {
			newConfig[list.id] = [];
		}

		newConfig[list.id] = list.choices.map((item) => item.id);

		setOpenedList((old) => ({ ...old, config: newConfig }));
	};

	const handleDeselectList = (list: IList) => {
		const newConfig = { ...openedList.config };

		newConfig[list.id] = [];

		setOpenedList((old) => ({ ...old, config: newConfig }));
	};

	const handleClickChoice = (list: IList, choice: IChoice) => {
		const newConfig = { ...openedList.config };

		if (!newConfig[list.id]) {
			newConfig[list.id] = [];
		}

		if (newConfig[list.id].includes(choice.id)) {
			newConfig[list.id] = newConfig[list.id].filter(
				(item) => item !== choice.id
			);
		} else {
			newConfig[list.id].push(choice.id);
		}

		setOpenedList((old) => ({ ...old, config: newConfig }));
	};

	return (
		<div className="grid-cols grid gap-4 lg:grid-cols-3">
			{lists
				.sort((a: any, b: any) => {
					if (a.choices.length > 4) return -1;
					if (b.choices.length > 4) return 1;
					return 0;
				})
				.map((item) => (
					<div
						className={`flex flex-col rounded-sm bg-primary p-1 ${
							item.choices.length > 4 ? "lg:col-span-3" : ""
						}`}
						key={item.id}
					>
						<div className="flex flex-wrap justify-between px-3 py-2">
							<h4 className="select-none text-white">{item.name}</h4>

							<div className="ml-auto flex items-center gap-1 text-sm text-white">
								<p
									className="cursor-pointer select-none"
									onClick={() => handleSelectList(item)}
								>
									{trad[lang].select}
								</p>
								<p className="select-none">/</p>
								<p
									className="cursor-pointer select-none"
									onClick={() => handleDeselectList(item)}
								>
									{trad[lang].deselect}
								</p>
							</div>
						</div>

						<div className="flex flex-1 flex-row flex-wrap justify-start gap-3 rounded-sm bg-white p-3">
							{item.choices.map((choice) => (
								<div
									className={`flex h-fit cursor-pointer select-none flex-col gap-1 rounded-sm ${
										openedList.config[item.id]?.includes(choice.id)
											? "border-green-500 bg-green-500 text-white"
											: "border-gray-300 bg-gray-200 opacity-70"
									} border py-2 px-4 transition-colors`}
									onClick={() => handleClickChoice(item, choice)}
									key={choice.id}
								>
									<p>{choice.name}</p>
								</div>
							))}
						</div>
					</div>
				))}
		</div>
	);
};

export default ListConfiguration;
