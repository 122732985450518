import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

const ListDeleteConfirm = ({
	submit,
	name,
	close
}: {
	submit: any;
	name: string;
	close: any;
}) => {
	const { lang } = useContext(AppContext);

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
			<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h2 className="text-center text-2xl">{trad[lang].delete_list}</h2>
				</div>

				<div className="flex flex-col gap-5 p-5">
					<div className="flex flex-col gap-2">
						<p className="font-bold">{`${trad[lang].sure_delete_list} "${name}" ?`}</p>
					</div>
				</div>

				<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
						onClick={close}
					>
						{trad[lang].close}
					</button>

					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={submit}
					>
						{trad[lang].validate}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ListDeleteConfirm;
