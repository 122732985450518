import { useContext, useMemo } from "react";
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements
} from "react-router-dom";
import GlobalError from "../components/errors_boundaries/GlobalError";
import AccessGuard, {
	AccessKeys,
	AccessType
} from "../components/navigation/AccessGuard";
import LiveProvider from "../contexts/LiveContext";
import ManageRunnerProvider from "../contexts/ManageRunnerContext";
import { UserContext } from "../contexts/UserContext";
import Admin from "../pages/Admin/Admin";
import Clients from "../pages/Admin/Clients";
import Finances from "../pages/Admin/Finances";
import FinancesResume from "../pages/Admin/FinancesResume";
import GlobalAdminSearch from "../pages/Admin/GlobalAdminSearch";
import Licences from "../pages/Admin/Licences";
import Commissions from "../pages/Commissions";
import Communication from "../pages/Communication/Communication";
import Dashboard from "../pages/Dashboard";
import DetectionsDashboard from "../pages/DetectionsDashboard";
import Donations from "../pages/Donations";
import Error404 from "../pages/Error404";
import EventConfiguration from "../pages/EventConfiguration";
import EventDetails from "../pages/EventDetails";
import SportEventContacts from "../pages/EventForms/Sport/SportEventContacts";
import SportEventIdentity from "../pages/EventForms/Sport/SportEventIdentity";
import SportEventOptions from "../pages/EventForms/Sport/SportEventOptions";
import SportEventRib from "../pages/EventForms/Sport/SportEventRib";
import SportEventRuns from "../pages/EventForms/Sport/SportEventRuns";
import ForgottenPassowrd from "../pages/FogottenPassword";
import Invitation from "../pages/Invitation";
import IsNotOrga from "../pages/IsNotOrga";
import Jetcodes from "../pages/Jetcodes";
import Lists from "../pages/Lists";
import LiveDetails from "../pages/LiveDetails";
import LiveEvent from "../pages/LiveEvent";
import LiveParcoursConfig from "../pages/LiveParcoursConfig";
import LivePositions from "../pages/LivePositions";
import LiveRoutesConfig from "../pages/LiveRoutesConfig";
import LiveRunConfig from "../pages/LiveRunConfig";
import LiveSegmentsConfig from "../pages/LiveSegmentsConfig";
import Login from "../pages/Login";
import ManageDetections from "../pages/ManageDetections";
import ManageRunners from "../pages/ManageRunners";
import Permissions from "../pages/Permissions";
import RankingConfiguration from "../pages/RankingConfiguration";
import RegistrationOrder from "../pages/RegistrationOrder";
import RegistrationRefund from "../pages/RegistrationRefund";
import RegistrationsDetails from "../pages/RegistrationsDetails";
import ResetPassword from "../pages/ResetPassword";
import ResultsEvent from "../pages/ResultsEvent";
import Shop from "../pages/Shop/Shop";
import Signup from "../pages/Signup";
import Statistics from "../pages/Statistics";
import Version from "../pages/Version";

function GeneralRoutes() {
	const { user } = useContext(UserContext);

	const userHasAccess = useMemo(() => {
		if (!user) return false;

		if (
			user?.roles?.includes("ROLE_ORGANISATEUR") ||
			user?.roles?.includes("ROLE_ADMIN")
		)
			return true;

		return false;
	}, [user]);

	const isNotOrga = useMemo(() => {
		if (!user) return false;

		if (
			!(
				user?.roles?.includes("ROLE_ORGANISATEUR") ||
				user?.roles?.includes("ROLE_ADMIN")
			)
		)
			return true;

		return false;
	}, [user]);

	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route errorElement={<GlobalError />}>
				{/* ACCEPT INVITATION */}
				<Route
					path="/invitation/:invitationKey/:invitationSlug"
					element={<Invitation />}
				/>

				{/* CONNECTED BUT ISN'T ORGA OR ADMIN */}
				{isNotOrga && (
					<>
						<Route path="/" element={<IsNotOrga />} />
					</>
				)}

				{/* --- NOT CONNECTED ROUTES --- */}
				{!userHasAccess && !isNotOrga && (
					<>
						<Route path="/" element={<Login />} />

						<Route path="*" element={<Login />} />

						<Route path="/forgotten-password" element={<ForgottenPassowrd />} />
						<Route path="/reset-password/:token" element={<ResetPassword />} />

						<Route path="/signup" element={<Signup />} />
					</>
				)}

				{/* --- PROTECTED ROUTES --- */}
				{userHasAccess && (
					<>
						{/* DASHBOARD */}
						<Route path="/" element={<Dashboard />} />
						<Route
							path="/:slug/event-details"
							element={
								<AccessGuard
									permissionKey={AccessKeys.EVENT}
									type={AccessType.ROUTE}
								>
									<EventDetails />
								</AccessGuard>
							}
						/>
						<Route
							path="event-configuration"
							element={<EventConfiguration />}
						/>
						<Route
							path="event-configuration/sport"
							element={<SportEventIdentity />}
						/>
						<Route
							path="/:slug/event-configuration/sport"
							element={<SportEventIdentity />}
						/>
						<Route
							path="/:slug/event-configuration/sport/runs"
							element={
								<AccessGuard
									permissionKey={AccessKeys.EVENT_RUNS}
									type={AccessType.ROUTE}
								>
									<SportEventRuns />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/event-configuration/sport/options"
							element={
								<AccessGuard
									permissionKey={AccessKeys.EVENT}
									type={AccessType.ROUTE}
								>
									<SportEventOptions />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/event-configuration/sport/options/:slug_race"
							element={
								<AccessGuard
									permissionKey={AccessKeys.EVENT}
									type={AccessType.ROUTE}
								>
									<SportEventOptions />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/event-configuration/sport/contacts"
							element={
								<AccessGuard
									permissionKey={AccessKeys.EVENT}
									type={AccessType.ROUTE}
								>
									<SportEventContacts />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/event-configuration/sport/ribs"
							element={
								<AccessGuard
									permissionKey={AccessKeys.EVENT_RIB}
									type={AccessType.ROUTE}
								>
									<SportEventRib />
								</AccessGuard>
							}
						/>
						<Route path="/:slug/commissions" element={<Commissions />} />
						<Route path="/:slug/donations" element={<Donations />} />

						{/* REGISTRATIONS */}
						<Route
							path="/:slug/registrations-details"
							element={
								<AccessGuard
									permissionKey={AccessKeys.SUBSCRIPTIONS}
									type={AccessType.ROUTE}
								>
									<LiveProvider>
										<RegistrationsDetails />
									</LiveProvider>
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/registration-refund/:idInscription"
							element={
								<AccessGuard
									permissionKey={AccessKeys.SUBSCRIPTIONS}
									type={AccessType.ROUTE}
								>
									<RegistrationRefund />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/registration-order/:idInscription"
							element={
								<AccessGuard
									permissionKey={AccessKeys.SUBSCRIPTIONS}
									type={AccessType.ROUTE}
								>
									<RegistrationOrder />
								</AccessGuard>
							}
						/>

						{/* LIVE RESULTS */}
						<Route
							path="/:slug/live"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<LiveEvent />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/live/:slug_race/rankings"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<RankingConfiguration />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/results"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<ResultsEvent />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/live-details"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<LiveDetails />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/live-details/:idcalendrier"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<LiveRunConfig />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/manage-runners"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<ManageRunnerProvider>
										<ManageRunners />
									</ManageRunnerProvider>
								</AccessGuard>
							}
						/>

						<Route
							path="/:slug/live-positions"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<LivePositions />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/detections"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<DetectionsDashboard />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/manage-detections"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<ManageDetections />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/manage-routes/:route"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<LiveRoutesConfig />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/manage-routes/:route/:idetape"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<LiveParcoursConfig />
								</AccessGuard>
							}
						/>
						<Route
							path="/:slug/manage-routes/:route/:idetape/:idparcours"
							element={
								<AccessGuard
									permissionKey={AccessKeys.LIVE}
									type={AccessType.ROUTE}
								>
									<LiveSegmentsConfig />
								</AccessGuard>
							}
						/>

						{/* SHOP */}
						<Route path="/shop" element={<Shop />} />

						{/* Gestion des listes */}
						<Route
							path="/:slug/event-configuration/:slug_race/lists"
							element={<Lists />}
						/>

						{/* PERMISSIONS */}
						<Route
							path="/:slug/permissions"
							element={
								<AccessGuard
									permissionKey={AccessKeys.PERMISSIONS}
									type={AccessType.ROUTE}
								>
									<Permissions />
								</AccessGuard>
							}
						/>

						{/* MARKETING / COMMUNICATIONS */}
						<Route
							path="/:slug/marketing-communications"
							element={<Communication />}
						/>

						{/* LIVE RESULTS */}
						<Route
							path="/:slug/statistics"
							element={
								<AccessGuard
									permissionKey={AccessKeys.STATS}
									type={AccessType.ROUTE}
								>
									<Statistics />
								</AccessGuard>
							}
						/>

						{/* JETCODE */}
						<Route
							path="/:slug/jetcodes"
							element={
								<AccessGuard
									permissionKey={AccessKeys.JETCODE}
									type={AccessType.ROUTE}
								>
									<Jetcodes />
								</AccessGuard>
							}
						/>

						{/* Note de version */}
						<Route path="/version" element={<Version />} />

						{/* ADMINISTRATION */}
						{user?.roles?.includes("ROLE_ADMIN") && (
							<>
								<Route path="/admin" element={<Admin />} />
								<Route path="/admin/finances" element={<Finances />} />
								<Route
									path="/admin/finances/resume"
									element={<FinancesResume />}
								/>
								<Route path="/admin/licences" element={<Licences />} />
								<Route path="/admin/clients" element={<Clients />} />
								<Route
									path="/admin/global-search"
									element={<GlobalAdminSearch />}
								/>
							</>
						)}
					</>
				)}

				{/* --- PUBLIC ROUTES --- */}
				<Route path="*" element={<Error404 />} />
			</Route>
		)
	);

	return <RouterProvider router={router} />;
}

export default GeneralRoutes;
