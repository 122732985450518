import { IRunnerResponse } from "../types/ILive";
import { IRanking } from "../types/IRankingConfig";
import { API } from "./APIClient";

/*
 *  LIVE CONFIG
 */
export const getLiveConfig = async (slug: string) => {
	try {
		const { data } = await API.get(`/live/liveConfig/${slug}`);

		return data;
	} catch (error) {
		console.warn("[getLiveConfig error]", error);
		throw error;
	}
};

export const getEventLines = async (slug: string) => {
	try {
		const { data } = await API.get(`/live/liveConfig/${slug}/lines`);

		return data;
	} catch (error) {
		console.warn("[getEventLines error]", error);
		throw error;
	}
};

export const getLiveConfigCalendar = async (
	slug: string,
	idcalendrier: string
) => {
	try {
		const { data } = await API.get(`/live/liveConfig/${slug}/${idcalendrier}`);

		return data;
	} catch (error) {
		console.warn("[getLiveConfigCalendar error]", error);
		throw error;
	}
};

export const updateLiveConfigCalendar = async (
	slug: string,
	idcalendrier: string,
	body: { type: number; distance: number; laps: number; date: string }
) => {
	try {
		const { data } = await API.put(
			`/live/liveConfig/${slug}/${idcalendrier}`,
			body
		);

		return data;
	} catch (error) {
		console.warn("[updateLiveConfigCalendar error]", error);
		throw error;
	}
};

export const postLiveConfigPoint = async (
	slug: string,
	idcalendrier: string,
	body: any
) => {
	try {
		const { data } = await API.post(
			`/live/liveConfig/${slug}/${idcalendrier}/point`,
			body
		);

		return data;
	} catch (error) {
		console.warn("[postLiveConfigPoint error]", error);
		throw error;
	}
};

export const generateSegments = async function (idcalendrier: string) {
	try {
		await API.get(`/live/generateSegments/${idcalendrier}`);
	} catch (error) {
		console.warn("[generateSegments error]", error);
		throw error;
	}
};

export const getLiveConfigPoints = async (
	slug: string,
	idcalendrier: string
) => {
	try {
		const { data } = await API.get(
			`/live/liveConfig/${slug}/${idcalendrier}/points`
		);

		return data;
	} catch (error) {
		console.warn("[getLiveConfigPoints error]", error);
		throw error;
	}
};

export const getLiveConfigSegments = async (
	slug: string,
	idcalendrier: string
) => {
	try {
		const { data } = await API.get(
			`/live/liveConfig/${slug}/${idcalendrier}/segments`
		);

		return data;
	} catch (error) {
		console.warn("[getLiveConfigPoints error]", error);
		throw error;
	}
};

export const deleteLiveConfigPoint = async (
	slug: string,
	idcalendrier: string,
	idpointage: number
) => {
	try {
		const { data } = await API.delete(
			`/live/liveConfig/${slug}/${idcalendrier}/points/${idpointage}`
		);

		return data;
	} catch (error) {
		console.warn("[deleteLiveConfigPoint error]", error);
		throw error;
	}
};

export const resetPoint = async (
	slug: string,
	idcalendrier: string,
	idpointage: number,
	form: { bib: number; time: string; idCalendrier: number }
) => {
	try {
		const { data } = await API.post(
			`/live/liveConfig/${slug}/${idcalendrier}/points/${idpointage}/reset`,
			form
		);

		return data;
	} catch (error) {
		console.warn("[deleteLiveConfigPoint error]", error);
		throw error;
	}
};

export const updateLiveCredential = async (
	slug: string,
	credential: string
) => {
	try {
		const { data } = await API.put(`/live/liveConfig/${slug}/credential`, {
			credential
		});

		return data;
	} catch (error) {
		console.warn("[updateLiveCredential error]", error);
		throw error;
	}
};

/*
 *  RUNNERS
 */
export const getRunnersEvent = async (
	slug: string,
	search: string,
	nmbPerPage: string,
	page: number,
	filters: any,
	sort: any
) => {
	try {
		const query = new URLSearchParams({
			search,
			nmbPerPage: nmbPerPage,
			page: page.toString(),
			filters: JSON.stringify(filters),
			sort: JSON.stringify(sort)
		});

		const { data } = await API.get(`/live/runners/${slug}?${query}`);

		return data;
	} catch (error) {
		console.warn("[updateLiveCredential error]", error);
		throw error;
	}
};

export const getRunner = async (slug: string, idInscription: number) => {
	try {
		const { data } = await API.get(`/live/runners/${slug}/${idInscription}`);

		return data;
	} catch (error) {
		console.warn("[updateLiveCredential error]", error);
		throw error;
	}
};

export const updateRunnerInfos = async (
	slug: string,
	idInscription: number,
	body: any
) => {
	try {
		const { data } = await API.put(
			`/live/runners/${slug}/${idInscription}/infos`,
			body
		);

		return data;
	} catch (error) {
		console.warn("[updateRunnerInfos error]", error);
		throw error;
	}
};

export const checkDuplicatesBib = async (
	slug: string,
	idInscription: number,
	bib: number
) => {
	try {
		const { data } = await API.get(
			`/live/runners/${slug}/${idInscription}/checkDuplicateBib/${bib}`
		);

		return data;
	} catch (error) {
		console.warn("[checkDuplicatesBib error]", error);
		throw error;
	}
};

export const updateRunnerStatus = async (
	slug: string,
	idInscription: number,
	status: number
) => {
	try {
		const { data } = await API.put(
			`/live/runners/${slug}/${idInscription}/status`,
			{ status }
		);

		return data;
	} catch (error) {
		console.warn("[updateRunnerStatus error]", error);
		throw error;
	}
};

export const getRunnerActivity = async (
	slug: string,
	idInscription: number
) => {
	try {
		const { data } = await API.get(
			`/live/runners/${slug}/${idInscription}/activity`
		);

		return data;
	} catch (error) {
		console.warn("[updateRunnerStatus error]", error);
		throw error;
	}
};

export const createActivityTime = async (
	slug: string,
	idInscription: number,
	idActivity: string,
	time: string
) => {
	try {
		const { data } = await API.post(
			`/live/runners/${slug}/${idInscription}/activity/${idActivity}/time`,
			{ time }
		);

		return data;
	} catch (error) {
		console.warn("[updateRunnerStatus error]", error);
		throw error;
	}
};

export const verifyCategory = async (slug: string) => {
	try {
		const { data } = await API.get(`/live/runners/${slug}/verifyCategory`);

		return data;
	} catch (error) {
		console.warn("[verifyCategory error]", error);
		throw error;
	}
};

/*
 *  Detections
 */
export const getDetections = async (
	slug: string,
	search: string,
	nmbPerPage = "0",
	page = 1,
	detector: number,
	calendar: number,
	all: boolean
) => {
	try {
		const query = new URLSearchParams({
			search,
			nmbPerPage,
			page: page.toString(),
			detector: detector.toString(),
			calendar: calendar.toString(),
			all: `${all}`
		});

		const { data } = await API.get(`/live/detections/${slug}?${query}`);

		return data;
	} catch (error) {
		console.warn("[verifyCategory error]", error);
		throw error;
	}
};

export const getDetectionsForBib = async (
	slug: string,
	dossard: number,
	idCalendrier: number
) => {
	try {
		const { data } = await API.get(
			`/live/detections/${slug}/${dossard}/${idCalendrier}`
		);

		return data;
	} catch (error) {
		console.warn("[verifyCategory error]", error);
		throw error;
	}
};

/*
 *  Classement
 */
export const getRunnersRanking = async (
	slug: string,
	run: number,
	search: string,
	column: string,
	order: number,
	sources: number[],
	download = false,
	admin?: string,
	statutCourse?: string,
	finishedRace?: string,
	onlyIfInter?: boolean
): Promise<IRunnerResponse> => {
	try {
		const queries = new URLSearchParams({
			run: run.toString(),
			search,
			column,
			order: order.toString(),
			sourceDepart: sources[0].toString(),
			sourceArrivee: sources[1].toString(),
			download: `${download}`,
			admin: "1",
			statutCourse: statutCourse || "",
			finishedRace: finishedRace || "false"
		});

		if (download) {
			const result = await API.get(
				`/live/classement/${slug}/runners?${queries}`,
				{
					responseType: "blob"
				}
			);
			return result as any;
		}
		const result = await API.get(
			`/live/classement/${slug}/runners?${queries}&displayOnlyIfInter=${
				onlyIfInter ? "true" : "false"
			}`
		);
		return result.data;
	} catch (error) {
		console.warn("[getRunnersRanking error]", error);
		throw error;
	}
};

export const getRunnersInfos = async (slug: string, idInscription: number) => {
	try {
		const result = await API.get(
			`/live/classement/${slug}/runners/${idInscription}`
		);

		return result.data;
	} catch (error) {
		console.warn("[getRunnersRanking error]", error);
		throw error;
	}
};

export const deleteDetections = async (
	slug: string,
	detector = 0,
	races = ["0"],
	all = false,
	search = ""
) => {
	try {
		const params = new URLSearchParams({
			detector: detector?.toString(),
			races: races?.toString(),
			all: all ? "1" : "0",
			search
		});
		const { data } = await API.delete(`/live/detections/${slug}?${params}`);
		return data;
	} catch (error) {
		console.warn("[deleteDetections error]", error);
		throw error;
	}
};

export const exportRanking = async (
	slug: string,
	order: string,
	direction: number,
	showSub: boolean,
	idCalendrier: number[] = []
) => {
	try {
		const { data } = await API.post(`/live/export`, {
			slug,
			order,
			direction,
			showSub,
			idCalendrier
		});

		return data;
	} catch (error) {
		console.warn("[exportRanking error]", error);
		throw error;
	}
};

export const updateSegment = async (
	slug: string,
	idCalendrier: string,
	body: {
		idSegment: number;
		libelle: string;
		idPointageDebut: string;
		idPointageFin: string;
		NumeroPassageDebut: string;
		NumeroPassageFin: string;
		ordre: string;
	}
) => {
	try {
		const formatedData = {
			idSegment: body.idSegment,
			libelle: body.libelle,
			idPointageDebut: parseInt(body.idPointageDebut),
			idPointageFin: parseInt(body.idPointageFin),
			NumeroPassageDebut: parseInt(body.NumeroPassageDebut),
			NumeroPassageFin: parseInt(body.NumeroPassageFin),
			ordre: parseInt(body.ordre || "0")
		};

		const { data } = await API.post(
			`/live/liveConfig/${slug}/${idCalendrier}/segments`,
			formatedData
		);

		return data;
	} catch (error) {
		console.warn("[updateSegment error]", error);
		throw error;
	}
};

export const deleteSegment = async (
	slug: string,
	idCalendrier: string,
	idSegment: number
) => {
	try {
		const { data } = await API.delete(
			`/live/liveConfig/${slug}/${idCalendrier}/segments/${idSegment}`
		);

		return data;
	} catch (error) {
		console.warn("[deleteSegment error]", error);
		throw error;
	}
};

export const getRankingLists = async (slug: string, slug_race: string) => {
	try {
		const { data } = await API.get(
			`/live/liveConfig/${slug}/${slug_race}/lists`
		);

		return data;
	} catch (error) {
		console.warn("[getRankingLists error]", error);
		throw error;
	}
};

export const getRankingConfig = async (slug: string, slug_race: string) => {
	try {
		const { data } = await API.get(
			`/live/liveConfig/${slug}/${slug_race}/rankings`
		);

		return data;
	} catch (error) {
		console.warn("[getRankingConfig error]", error);
		throw error;
	}
};

export const saveRankingConfig = async (
	slug: string,
	slug_race: string,
	config: IRanking
) => {
	try {
		const { data } = await API.post(
			`/live/liveConfig/${slug}/${slug_race}/rankings`,
			config
		);

		return data;
	} catch (error) {
		console.warn("[deleteSegment error]", error);
		throw error;
	}
};
export const deleteRankingConfig = async (
	slug: string,
	slug_race: string,
	idRanking: number
) => {
	try {
		const { data } = await API.delete(
			`/live/liveConfig/${slug}/${slug_race}/rankings/${idRanking}`
		);

		return data;
	} catch (error) {
		console.warn("[deleteSegment error]", error);
		throw error;
	}
};
