import { memo, useContext, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
	getLiveConfigSegments,
	getRunnerActivity,
	getRunnersInfos
} from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { formatDateString } from "../../utils/DateFormater";
import AppMap from "../map/AppMap";
import ManageRunnerAddTime from "./ManageRunnerAddTime";
import ManageRunnerTimes from "./ManageRunnerTimes";

const tabs: {
	id: number;
	key: "times" | "detections" | "activities";
	needActivities?: boolean;
}[] = [
	{ id: 0, key: "times" },
	{ id: 1, key: "detections" },
	{ id: 2, key: "activities", needActivities: true }
];

const ManageRunnerResum = ({
	idCalendrier,
	detectionsData,
	data,
	closeModal
}: {
	idCalendrier: number;
	detectionsData: any;
	data: any;
	closeModal: () => void;
}) => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const [tab, setTab] = useState(0);
	const [displayedActivity, setDisplayedActivity] = useState("");
	const [modalAddTimeOpened, setModalAddTimeOpened] = useState("");
	const mapRef = useRef(null);

	const { data: runnerData = [] } = useQuery({
		queryKey: ["runner_data", slug, data.idInscription],
		queryFn: () => getRunnersInfos(slug as string, data.idInscription),
		enabled: !!slug && !!data.idInscription,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchInterval: false,
		refetchOnWindowFocus: false
	});

	const { data: liveSegment = [] } = useQuery({
		queryKey: ["live_segment", slug, data.idCalendrier],
		queryFn: () => getLiveConfigSegments(slug as string, data.idCalendrier),
		enabled: !!slug && !!data.idCalendrier,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchInterval: false,
		refetchOnWindowFocus: false
	});

	const { data: Activities = {} } = useQuery({
		queryKey: ["activity", slug, data.idInscription],
		queryFn: () => getRunnerActivity(slug as string, data.idInscription),
		enabled: !!slug && !!data.idInscription,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchInterval: false
	});

	const handleClickAddTime = (e: any | undefined, activity: any) => {
		e.stopPropagation();
		setModalAddTimeOpened(activity);
	};

	return (
		<>
			<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
				<div className="flex max-h-[90vh] w-11/12 min-w-[300px] max-w-screen-lg flex-col rounded-md border bg-white p-5 md:w-4/5 lg:w-3/5">
					<div className="flex flex-col items-start justify-between">
						<div className="flex gap-2">
							<p className={`text-xl font-semibold`}>
								{`${data.name} N°${data.dossard} - `}
							</p>
							<p className="text-lg font-semibold">{data.courseNom}</p>
						</div>
					</div>

					<div className="my-2 flex flex-row justify-between gap-4">
						<div>
							<h3 className="text-sm text-gray-400">
								{trad[lang].rankingHeaderRank}
							</h3>
							<p>{data.classGen}</p>
						</div>
						<div>
							<h3 className="text-sm text-gray-400">
								{trad[lang].rankingHeaderCategoryRank}
							</h3>
							<p>
								{data.classCat} {data.categorieNom}
							</p>
						</div>
						<div>
							<h3 className="text-sm text-gray-400">
								{trad[lang].rankingHeaderSexRank}
							</h3>
							<p>
								{data.classSx} {data.sexe === "Homme" ? "Homme" : "Femme"}
							</p>
						</div>
						<div>
							<h3 className="text-sm text-gray-400">{trad[lang].time}</h3>
							<p>{data.temps ? data.temps : "N/A"}</p>
						</div>

						{data.ecart && (
							<div>
								<h3 className="text-sm text-gray-400">{trad[lang].gap}</h3>
								<p>{data.ecart}</p>
							</div>
						)}

						{data.vitesse && (
							<div>
								<h3 className="text-sm text-gray-400">{trad[lang].speed}</h3>
								<p>{data.vitesse} km/h</p>
							</div>
						)}

						{data?.equipe && data.equipe.length > 0 && (
							<div>
								<h3 className="text-sm text-gray-400">
									{trad[lang].rankingHeaderTeam}
								</h3>
								<p>{data.equipe}</p>
							</div>
						)}
					</div>

					<div className="overflow-y-auto">
						{Object.keys(Activities).length > 0 && (
							<>
								<div className="h-96 w-full flex-shrink-0">
									<AppMap
										id={idCalendrier}
										mapRef={mapRef}
										polylines={
											displayedActivity
												? [
														Activities[displayedActivity]
															.filter(
																(item: any, index: number) =>
																	index == 0 ||
																	index ==
																		Activities[displayedActivity].length - 1 ||
																	index %
																		Math.ceil(
																			Activities[displayedActivity].length / 500
																		) ==
																		0
															)
															.map((item: any) => [
																item.latitude,
																item.longitude,
																item.date
															])
												  ]
												: []
										}
										displayPolylinesPoints={true}
									/>
								</div>

								{Activities[displayedActivity]?.length > 500 && (
									<p className="text-sm">
										{trad[lang].not_all_position_display}
									</p>
								)}
							</>
						)}

						<div className="mt-2 flex w-full flex-shrink-0 items-center justify-center divide-x divide-slate-400 overflow-hidden rounded-md">
							{tabs
								.filter((item) =>
									item.needActivities
										? Object.keys(Activities).length > 0
										: true
								)
								.map((item) => (
									<button
										className={`flex h-12 w-1/2 items-center justify-center transition-colors ${
											item.id === tab
												? "bg-primary text-white"
												: "bg-slate-100 hover:bg-slate-200"
										}`}
										key={item.key}
										disabled={item.id === tab}
										onClick={() => setTab(item.id)}
									>
										{trad[lang][item.key]}
									</button>
								))}
						</div>

						{tab === 0 && (
							<>
								{runnerData.length > 0 ? (
									<ManageRunnerTimes data={runnerData} segment={liveSegment} />
								) : (
									<p className="my-2 text-center">{trad[lang].no_times}</p>
								)}
							</>
						)}

						{tab === 1 && (
							<>
								{detectionsData && detectionsData.length > 0 ? (
									<div className="my-2 flex h-auto w-full flex-col divide-y">
										<div className="flex h-10 w-full flex-row items-center justify-between">
											<p className="w-3/12 px-1 font-mono">
												{trad[lang].activities}
											</p>

											<p className="w-1/12 px-1 font-mono">
												{trad[lang].detectionLigne}
											</p>

											<p className="w-3/12 px-1 font-mono">
												{trad[lang].passage_points}
											</p>

											<p className="flex w-4/12 flex-row px-1 font-mono">
												{trad[lang].date}
											</p>
										</div>
										{detectionsData.map((detection: any, index: any) => (
											<div
												key={index}
												className="flex w-full flex-row items-center justify-between py-2"
											>
												<p className="w-3/12 px-1 font-mono">
													{detection.uuid_gps}
												</p>

												<p className="w-1/12 px-1 font-mono">
													{detection.ligne}
												</p>

												<p className="w-3/12 px-1 font-mono">
													{detection.nomPointage}
												</p>

												<p className="flex w-4/12 flex-row px-1 font-mono">
													{formatDateString(detection.date)}
												</p>
											</div>
										))}
									</div>
								) : (
									<p>{trad[lang].no_detections}</p>
								)}
							</>
						)}

						{tab === 2 && (
							<>
								<div className="my-2 flex h-auto w-full flex-col divide-y overflow-y-auto">
									<div className="flex h-10 w-full flex-row items-center justify-between">
										<p className="w-3/12 px-1 font-mono">ID</p>

										<p className="w-3/12 px-1 font-mono">{trad[lang].date}</p>

										<p className="w-3/12 px-1 font-mono">Nombre de positions</p>

										<p className="w-3/12 px-1 font-mono"></p>
									</div>

									{Object.entries(Activities).map(([key, gps]: any) => (
										<div
											key={gps[0].id}
											className={`flex w-full cursor-pointer select-none flex-row items-center justify-between py-2 transition-colors hover:bg-gray-200 ${
												key == displayedActivity ? "bg-cyan-50" : ""
											}`}
											onClick={() => setDisplayedActivity(key)}
										>
											<p className="w-4/12 px-1 font-mono">{gps[0].uuid}</p>

											<p className="flex w-4/12 flex-row px-1 font-mono">
												{formatDateString(gps[0].date)}
											</p>

											<p className="flex w-4/12 flex-row px-1 font-mono">
												{gps.length}
											</p>

											<p className="flex w-4/12 flex-row px-1 py-1 font-mono">
												<button
													type="button"
													className="flex w-full items-center justify-center rounded-md bg-primary py-1 text-white transition-colors hover:bg-primarymedium"
													onClick={(e) => handleClickAddTime(e, key)}
												>
													Ajouter un temps
												</button>
											</p>
										</div>
									))}
								</div>
							</>
						)}
					</div>

					<div className="flex w-full justify-center">
						<button
							type="button"
							className={`rounded-lg border border-primary bg-white px-5 py-2.5 text-center text-sm font-medium text-primary focus:outline-none`}
							onClick={closeModal}
						>
							{trad[lang].close}
						</button>
					</div>
				</div>
			</div>

			{modalAddTimeOpened != "" && (
				<ManageRunnerAddTime
					data={data}
					modalAddTimeOpened={modalAddTimeOpened}
					setModalAddTimeOpened={setModalAddTimeOpened}
				/>
			)}
		</>
	);
};

export default memo(ManageRunnerResum, (prevProps, nextProps) => {
	const sameDataSize = prevProps.data.length === nextProps.data.length;
	const sameDetectionsSize =
		prevProps.detectionsData.length === nextProps.detectionsData.length;
	const sameInscription =
		prevProps.data.idInscription === nextProps.data.idInscription;

	return sameDataSize && sameDetectionsSize && sameInscription;
});
