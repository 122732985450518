import { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEvent } from "../api/event";
import {
	deleteRankingConfig,
	getRankingConfig,
	getRankingLists
} from "../api/live";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import Layout from "../components/navigation/Layout";
import RankingComponent from "../components/rankingConfiguration/RankingComponent";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";
import { IList, IRanking } from "../types/IRankingConfig";
import Toast from "../utils/Toasts";

const preconfiguredLists: IList[] = [
	{
		id: "gender",
		name: "Sexe",
		choices: [
			{ id: 1, name: "Homme" },
			{ id: 2, name: "Femme" }
		]
	}
];

const RankingConfiguration = () => {
	const { slug, slug_race } = useParams();
	const { lang } = useContext(AppContext);
	const [openedList, setOpenedList] = useState<IRanking>({
		id: -1,
		name: "",
		config: {}
	});
	const [openedDeleteListModal, setOpenedDeleteListModal] =
		useState<null | IRanking>(null);

	const { data, isLoading } = useQuery({
		queryKey: [slug],
		queryFn: () => getEvent(slug),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const {
		data: RankingConfig,
		isLoading: isLoadingConfig,
		refetch
	} = useQuery({
		queryKey: ["ranking", slug, slug_race],
		queryFn: () => getRankingConfig(slug as string, slug_race as string),
		refetchOnWindowFocus: false,
		enabled: !!slug && !!slug_race
	});

	const { data: RankingLists, isLoading: isLoadingLists } = useQuery({
		queryKey: ["ranking_lists", slug, slug_race],
		queryFn: () => getRankingLists(slug as string, slug_race as string),
		refetchOnWindowFocus: false,
		enabled: !!slug && !!slug_race
	});

	const deleteList = async () => {
		try {
			if (!slug || !slug_race || !openedDeleteListModal) return;

			await deleteRankingConfig(slug, slug_race, openedDeleteListModal.id);

			setOpenedDeleteListModal(null);
			Toast.success(trad[lang].success_delete_ranking_list);
			await refetch();
		} catch (error) {
			Toast.success(trad[lang].error_delete_ranking_list);
			throw error;
		}
	};

	if (isLoading || isLoadingConfig || isLoadingLists) {
		return <PageLoader menu_key="live" />;
	}

	return (
		<Layout
			active_key="live"
			bottomBar={<BottomBarNavigation back_to={`/${slug}/live/`} />}
		>
			<BreadCrumb
				items={[
					{
						key: "event",
						text: data?.name,
						to: `/${slug}/event-details`,
						active: false
					},
					{ key: "live_results", to: `/${slug}/live`, active: false },
					{ key: "live_ranking", to: "#", active: true }
				]}
			/>

			{/* Title */}
			<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:text-3xl">
				{trad[lang].live_ranking}
			</h1>

			{RankingConfig?.map((item: any) => (
				<div
					className="relative mb-5 w-full rounded-md border border-primary p-3"
					key={item.id}
				>
					{item.id == openedList.id ? (
						<RankingComponent
							lists={[...RankingLists, ...preconfiguredLists]}
							openedList={openedList}
							setOpenedList={setOpenedList}
							refetch={refetch}
						/>
					) : (
						<>
							<div className="flex flex-col items-center justify-between gap-2 md:flex-row">
								<h3>{item.name}</h3>

								<div className="flex items-center gap-2">
									<button
										className="rounded-md bg-primary px-5 py-2.5 text-center text-sm font-medium text-white transition-colors hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300"
										onClick={() => setOpenedList(item)}
									>
										{trad[lang].edit}
									</button>

									<button
										className="rounded-md bg-red-600 px-5 py-2.5 text-center text-sm font-medium text-white transition-colors hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300"
										onClick={() => setOpenedDeleteListModal(item)}
									>
										{trad[lang].delete}
									</button>
								</div>
							</div>
						</>
					)}
				</div>
			))}

			{openedList.id === 0 && (
				<div className="relative mb-5 w-full rounded-md border border-primary p-3">
					<RankingComponent
						lists={[...RankingLists, ...preconfiguredLists]}
						openedList={openedList}
						setOpenedList={setOpenedList}
						refetch={refetch}
					/>
				</div>
			)}

			<div className="w-full">
				<button
					type="button"
					className="rounded-md bg-primary px-5 py-2.5 text-center text-sm font-medium text-white transition-colors hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300"
					onClick={() => setOpenedList({ id: 0, name: "", config: {} })}
				>
					{trad[lang].add_ranking}
				</button>
			</div>

			{openedDeleteListModal != null && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-50">
					<div className="max-h-[80vh] w-1/3 max-w-[95%] rounded-md border bg-white">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h3 className="text-xl font-semibold text-gray-900">
								{openedDeleteListModal.name}
							</h3>
							<button
								type="button"
								className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
								onClick={() => setOpenedDeleteListModal(null)}
							>
								<AiOutlineClose size={16} />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="w-full p-6">
							<h3 className="text-xl font-semibold text-gray-900">
								{trad[lang].sure_delete_ranking_list(
									openedDeleteListModal.name
								)}
							</h3>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								type="button"
								className={`rounded-lg border border-primary px-5 py-2.5 text-center text-sm font-medium text-primary
								focus:outline-none`}
								onClick={() => setOpenedDeleteListModal(null)}
							>
								{trad[lang].cancel}
							</button>

							<button
								type="submit"
								className={`rounded-lg bg-red-600 px-5 py-2.5 text-center text-sm font-medium text-white
								focus:outline-none`}
								onClick={deleteList}
							>
								{trad[lang].delete}
							</button>
						</div>
					</div>
				</div>
			)}
		</Layout>
	);
};

export default RankingConfiguration;
