import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { createEventList } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";

const ListPopup = ({
	lists,
	opened,
	close,
	refetch
}: {
	lists: any;
	opened: { id: number; name: string };
	close: () => void;
	refetch: () => Promise<any>;
}) => {
	const { slug, slug_race } = useParams();
	const { lang } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [listName, setListName] = useState(opened.name);

	const handleSubmit = async () => {
		try {
			if (!slug_race || !slug) return;

			if (
				listName.length < 2 ||
				lists.find((list: any) => list.libelle == listName)
			) {
				Toast.error(trad[lang].list_name_required);
				return;
			}

			setLoading(true);

			await createEventList(slug, slug_race, { name: listName });

			await refetch();
			close();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
			<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h2 className="text-center text-2xl">
						{opened.id == 0 ? trad[lang].add_list : trad[lang].edit_list}
					</h2>
				</div>

				<div className="flex flex-col gap-5 p-5">
					<div className="flex flex-col gap-2">
						<p className="font-bold">{trad[lang].name}</p>

						<input
							type="string"
							value={listName}
							onChange={(e) => setListName(e.target.value)}
							className="h-12 rounded-md border border-gray-300 px-2 focus:outline-none"
						/>
					</div>
				</div>

				<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
						onClick={close}
						disabled={loading}
					>
						{trad[lang].close}
					</button>

					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={handleSubmit}
						disabled={loading}
					>
						{trad[lang].validate}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ListPopup;
