import {
	Dispatch,
	SetStateAction,
	createContext,
	useEffect,
	useMemo,
	useState
} from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import {
	getLiveConfig,
	getLiveConfigPoints,
	getRankingConfig,
	getRunnersRanking
} from "../api/live";
import { IRunnerResponse } from "../types/ILive";
import { IRanking } from "../types/IRankingConfig";

interface IManageRunnerContext {
	// Gestion du menu
	selectedRunnerIndex: number;
	setSelectedRunnerIndex: Dispatch<SetStateAction<number>>;
	source: [number, number];
	order: { column: string; order: number };
	search: string;
	debouncedSearch: string;

	// Gestion du tableau
	nmbPerPage: number;
	page: number;
	run: number;
	runSlug: string;
	pagination: number[];
	maxPage: number;

	// Gestion des modifications
	openedSubscription: number;
	setOpenedSubscription: Dispatch<SetStateAction<number>>;
	openedDetections: number;
	setOpenedDetections: Dispatch<SetStateAction<number>>;
	openedReset: number;
	setOpenedReset: Dispatch<SetStateAction<number>>;
	openedSubscriptionWithoutId: boolean;
	setOpenedSubscriptionWithoutId: Dispatch<SetStateAction<boolean>>;
	statutCourse: string;
	setStatutCourse: Dispatch<SetStateAction<string>>;
	admin: string;
	setAdmin: Dispatch<SetStateAction<string>>;
	finishedRace: string;
	setFinishedRace: Dispatch<SetStateAction<string>>;

	// Results Filters
	currentFilters: any;
	setCurrentFilters: Dispatch<SetStateAction<any>>;

	// Ranking conf
	currentRankConfig: IRanking;
	setCurrentRankConfig: Dispatch<SetStateAction<IRanking>>;

	// All custom fields configured for race
	currentRaceCustomFields: any[];
	setCurrentRaceCustomFields: Dispatch<SetStateAction<any[]>>;

	LiveConfig: any;
	LiveConfigLoading: boolean;
	Points: any;
	LiveData: IRunnerResponse;
	LiveDataFiltered: IRunnerResponse;
	LiveDataLoading: boolean;
	LiveDataError: any;
	LiveDataRefetch: any;
	selectedRunner: any;
	customRankings: any;
	handleChangeNmbPerPage: (nmbPerPage: number) => void;
	handleChangePage: (page: number) => void;
	handleChangeRun: (run: number) => void;
	handleChangeSearch: (search: string) => void;
	handleChangeOrder: (key: string) => void;
	handleChangeSource: (index: number, source: number) => void;
	handleChangeStatus: (statutCourse: string) => void;
	onlyIfInter: boolean;
	setOnlyIfInter: Dispatch<SetStateAction<boolean>>;
}

export const ManageRunnerContext = createContext<IManageRunnerContext>(
	{} as IManageRunnerContext
);

const ManageRunnerProvider = ({ children }: { children: React.ReactNode }) => {
	const { slug } = useParams();

	// Gestion du menu
	const [selectedRunnerIndex, setSelectedRunnerIndex] = useState(0);
	const [source, setSource] = useState<[number, number]>([0, 0]);
	const [run, setRun] = useState(0);
	const [runSlug, setRunSlug] = useState("");
	const [statutCourse, setStatutCourse] = useState("");
	const [admin, setAdmin] = useState("1");
	const [finishedRace, setFinishedRace] = useState("false");
	const [search, setSearch] = useState("");
	const [customRankings, setCustomRankings] = useState([]);
	const debouncedSearch = useDebounce(search, 500);
	const NB_PER_PAGE: number = 100;

	// Gestion du tableau
	const [order, setOrder] = useState({ column: "a", order: 1 });
	const [nmbPerPage, setNmbPerPage] = useState(NB_PER_PAGE);
	const [page, setPage] = useState(1);

	// Gestion des modifications
	const [openedSubscription, setOpenedSubscription] = useState(0);
	const [openedDetections, setOpenedDetections] = useState(0);
	const [openedReset, setOpenedReset] = useState(0);
	const [openedSubscriptionWithoutId, setOpenedSubscriptionWithoutId] =
		useState(false);
	const [LiveDataFiltered, setLiveDataFiltered] = useState<IRunnerResponse>({
		count: 0,
		result: []
	});

	// Results Filters
	const [currentFilters, setCurrentFilters] = useState({ gender: "" });
	const [currentRaceCustomFields, setCurrentRaceCustomFields] = useState<any[]>(
		[]
	);
	const [onlyIfInter, setOnlyIfInter] = useState(false);

	// Current ranking configuration for current race
	// Example: {"id":133,"name":"Lanières","config":{"Lanières":[1323,1333,1343,1353,1363,1373,1383],"SHN":[1403],"A.S":[]}}
	const [currentRankConfig, setCurrentRankConfig] = useState<IRanking>({
		id: 0,
		name: "Scratch",
		config: { gender: [1, 2] }
	});

	// Récupération de la config Live
	const { data: LiveConfig, isLoading: LiveConfigLoading } = useQuery({
		queryKey: ["live_config", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	// Récupération des Points de detection
	const { data: Points = [] } = useQuery({
		queryKey: ["points", slug, run, statutCourse, finishedRace],
		queryFn: () => getLiveConfigPoints(slug as string, run.toString()),
		enabled: !!slug && !!run
	});

	// Automatiquent set comme source finale l'arrivée s'il n'y a pas déjà une source enregistrée en local storage
	useEffect(() => {
		if (Points && run && source && source[1] === 0) {
			const arrival = Points.find((item: any) => item.type === 3);
			if (arrival && arrival.id) handleChangeSource(1, arrival.id);
		}
	}, [Points, run, source]);

	const {
		data: LiveData = { count: 0, result: [] },
		isLoading: LiveDataLoading,
		error: LiveDataError,
		refetch: LiveDataRefetch
	} = useQuery({
		queryKey: [
			"runners_ranking",
			slug,
			run,
			debouncedSearch,
			// order,
			1,
			source,
			"1",
			statutCourse,
			finishedRace,
			onlyIfInter
		],
		queryFn: () =>
			getRunnersRanking(
				slug as string,
				run,
				debouncedSearch,
				// order.column,
				"a",
				1,
				// order.order,
				source,
				false,
				"1",
				statutCourse,
				finishedRace,
				onlyIfInter
			),
		refetchOnWindowFocus: false,
		refetchInterval: 5000,
		refetchOnReconnect: false,
		refetchOnMount: true,
		enabled: !!slug
	});

	const filterLiveData = function (
		filteredLiveData: IRunnerResponse,
		filters: any
	) {
		const filterKeys = Object.keys(filters);
		for (let filterName of filterKeys) {
			// Filter by Gender
			if (filterName === "gender") {
				filteredLiveData.result = filteredLiveData.result.filter(
					(item: any) => {
						if (!filters.gender || filters.gender.length === 0) return true;
						if ((filters.gender as any).includes(parseInt(item.h))) return true;
						return false;
					}
				);
			}
			// Filter by Custom fields
			else {
				filteredLiveData.result = filteredLiveData.result.filter(
					(item: any) => {
						const params = JSON.parse(item.params || "[]");
						if (
							!(filters as any)[filterName] ||
							(filters as any)[filterName].length == 0
						)
							return true;
						for (let filterValue of (filters as any)[filterName]) {
							if (params.includes(parseInt(filterValue))) return true;
						}
						return false;
					}
				);
			}
		}

		filteredLiveData.count = filteredLiveData.result?.length || 0;
	};

	const getChoiceLabel = function (choiceId: number) {
		if (currentRaceCustomFields) {
			for (let list of currentRaceCustomFields) {
				for (let choice of list.choix) {
					if (choice.id === choiceId) return choice.libelle;
				}
			}
		}
		return "";
	};

	// Apply filters (gender, custom fields, ...)
	useEffect(() => {
		if (
			LiveDataLoading === false &&
			LiveData &&
			currentFilters &&
			currentRankConfig
		) {
			//
			// First filtering (used to compute new ranking positions)
			//

			const filteredLiveData = { ...LiveData };
			// Get current custom ranking config filters
			const rankingFilters: any = {};
			for (let filterName of Object.keys(currentRankConfig.config)) {
				rankingFilters[filterName] = currentRankConfig.config[filterName];
			}
			filterLiveData(filteredLiveData, rankingFilters);

			//
			// Compute new ranking positions (scratch, by gender, by category) based on ranking config filters
			//

			const r = filteredLiveData.result;
			let currentRank: number = 1;
			const rankByCategory: any = {};
			const rankByGender: any = {};

			if (r && r.length && currentRankConfig.id != 0) {
				for (const runner of r) {
					if (runner.a) {
						runner.a = currentRank;
						currentRank += 1;
					}
					if (runner.b && runner.h) {
						runner.b = rankByCategory[runner.h] || 1;
						rankByCategory[runner.h] = (rankByCategory[runner.h] || 1) + 1;
					}
					if (runner.c && runner.j) {
						runner.c = rankByGender[runner.j] || 1;
						rankByGender[runner.j] = (rankByGender[runner.j] || 1) + 1;
					}
				}
			}

			//
			// Last filtering (apply client filters, like a specific gender, specific "Lanières" or other custom field
			//

			filterLiveData(filteredLiveData, currentFilters);

			// Add p1Label, p1Value, p2Label, p2Value, ... to each runner (custom fields)
			// In order to have them in column names
			const customFields = currentRankConfig.config;

			if (filteredLiveData.result && customFields) {
				for (let runner of filteredLiveData.result) {
					// runner.customFields example:  [ {label: "Lanières", value: "A"}, {label: "SHN", value: "Oui"} ]
					runner.customFields = [];
					const runnerParams = JSON.parse(runner.params || "[]");
					for (let customField of Object.keys(customFields)) {
						const customFieldChoices = customFields[customField];
						for (let runnerChoice of runnerParams) {
							for (let choiceId of customFieldChoices) {
								if (runnerChoice == choiceId) {
									runner.customFields.push({
										label: customField,
										value: getChoiceLabel(choiceId)
									});
								}
							}
						}
					}
				}
			}

			// Sort in function of order object (column, order)
			if (order.column && order.order) {
				filteredLiveData.result = filteredLiveData.result.sort(
					(a: any, b: any) => {
						let columnName = order.column;
						// Cannot order by time when values are string like "28:38".78
						// Use fx that is a float
						if (order.column === "f") columnName = "fx";
						if (a[columnName] > b[columnName]) return order.order;
						if (a[columnName] < b[columnName]) return -order.order;
						return 0;
					}
				);
			}

			// setLiveData({ ...filteredLiveData });
			setLiveDataFiltered({ ...filteredLiveData });
		}
	}, [LiveData, LiveDataLoading, currentRankConfig, currentFilters, order]);

	const loadCustomRankings = async () => {
		if (slug && runSlug) {
			const data = await getRankingConfig(slug as string, runSlug as string);
			data.unshift({ id: 0, name: "Scratch", config: { gender: [1, 2] } });
			setCustomRankings(data);
		}
	};

	useEffect(() => {
		if (slug && runSlug) {
			loadCustomRankings();
		}
	}, [slug, runSlug]);

	const selectedRunner =
		LiveDataFiltered.result.length >= selectedRunnerIndex
			? LiveDataFiltered.result[selectedRunnerIndex]
			: {};

	useEffect(() => {
		console.log("Num coureurs", LiveData?.result?.length);
	}, [LiveData]);

	// Gestion de la pagination
	const pagination = useMemo(() => {
		if (!LiveDataFiltered.count) return [];

		const pages = [page - 2, page - 1, page, page + 1, page + 2];

		return Array.from(
			new Set(
				pages.filter(
					(item) =>
						item > 0 && item <= Math.ceil(LiveDataFiltered.count / nmbPerPage)
				)
			)
		).sort((a, b) => a - b);
	}, [LiveDataFiltered, page]);

	const maxPage = Math.ceil(LiveDataFiltered.count / nmbPerPage);

	// Gestion du clavier
	const handleKey = (e: KeyboardEvent) => {
		if (
			!["ArrowDown", "ArrowUp", "Enter", "ArrowRight", "ArrowLeft"].includes(
				e.key
			)
		) {
			return;
		}

		e.preventDefault();

		if (e.key === "ArrowRight") {
			setPage((old) =>
				Math.min(Math.ceil(LiveDataFiltered.count / nmbPerPage), old + 1)
			);
		} else if (e.key === "ArrowLeft") {
			setPage((old) => Math.max(1, old - 1));
		} else if (e.key === "ArrowDown") {
			setSelectedRunnerIndex((old) => {
				if (old + 1 > LiveDataFiltered.result.length) {
					setPage((old) =>
						Math.min(Math.ceil(LiveDataFiltered.count / nmbPerPage), old + 1)
					);

					return 0;
				} else {
					return old + 1;
				}
			});
		} else if (e.key === "ArrowUp") {
			setSelectedRunnerIndex((old) => {
				if (old - 1 < 1) {
					setPage((old) => Math.max(1, old - 1));

					return 0;
				} else {
					return old - 1;
				}
			});
		}
	};

	useEffect(() => {
		if (
			!openedSubscription &&
			!openedDetections &&
			!openedReset &&
			!openedSubscriptionWithoutId
		) {
			window.addEventListener("keydown", handleKey);
		}

		return () => {
			window.removeEventListener("keydown", handleKey);
		};
	}, [
		LiveDataFiltered,
		openedSubscription,
		openedDetections,
		openedReset,
		openedSubscriptionWithoutId
	]);

	useEffect(() => {
		setSelectedRunnerIndex(0);
	}, [LiveDataFiltered]);

	const handleChangeNmbPerPage = (nmb: number) => {
		setPage(1);
		setNmbPerPage(nmb);
		localStorage.setItem("manageRunnersNmbPerPage", nmb.toString());
	};

	const handleChangePage = (nmb: number) => {
		setPage(nmb);
		localStorage.setItem("manageRunnersPage", nmb.toString());
	};

	useEffect(() => {
		// Find raceSlug in LiveConfig
		if (run && LiveConfig?.calendrier_child) {
			const raceSlug = LiveConfig.calendrier_child.find(
				(item: any) => item.id === run
			);
			if (raceSlug && raceSlug.slug) {
				setRunSlug(raceSlug.slug);
			}
		}
	}, [run, LiveConfig]);

	const handleChangeRun = (run: number) => {
		setPage(1);
		setRun(run);
		setSource([0, 0]);
		localStorage.setItem("manageRunnersRun", run.toString());
		localStorage.setItem("manageRunnersPage", "1");
		localStorage.setItem("manageRunnersSource", JSON.stringify([0, 0]));
	};

	const handleChangeSearch = (search: string) => {
		setSearch(search);
		localStorage.setItem("manageRunnersSearch", search);
	};

	const handleChangeStatus = (statutCourse: string) => {
		setStatutCourse(statutCourse);
		localStorage.setItem("manageRegistrantsFilter", statutCourse);
	};

	const handleChangeOrder = (key: string) => {
		setOrder((old) => {
			if (old.column == key) {
				localStorage.setItem(
					"manageRunnersOrder",
					JSON.stringify({ column: key, order: old.order * -1 })
				);
				return {
					column: key,
					order: old.order * -1
				};
			}

			localStorage.setItem(
				"manageRunnersOrder",
				JSON.stringify({ column: key, order: 1 })
			);
			return {
				column: key,
				order: 1
			};
		});
	};

	const handleChangeSource = (index: number, source: number) => {
		setPage(1);

		setSource((old) => {
			const new_source = [...old];

			new_source[index] = source || 0;

			localStorage.setItem("manageRunnersSource", JSON.stringify(new_source));
			return new_source as [number, number];
		});
	};

	useEffect(() => {
		const localNmbPerPage = localStorage.getItem("manageRunnersNmbPerPage");
		const localPage = localStorage.getItem("manageRunnersPage");
		const localRun = localStorage.getItem("manageRunnersRun");
		const localSearch = localStorage.getItem("manageRunnersSearch");
		const localOrder = localStorage.getItem("manageRunnersOrder");
		const localSource = localStorage.getItem("manageRunnersSource");
		const localRegistrantsFilter = localStorage.getItem(
			"manageRegistrantsFilter"
		);

		if (localNmbPerPage) {
			setNmbPerPage(parseInt(localNmbPerPage));
		}
		if (localPage) {
			setPage(parseInt(localPage));
		}
		if (localRun) {
			setRun(parseInt(localRun));
		}
		if (localSearch) {
			setSearch(localSearch);
		}
		if (localOrder) {
			setOrder(JSON.parse(localOrder));
		}
		if (localSource && localSource != "undefined") {
			setSource(JSON.parse(localSource));
		}
		if (localRegistrantsFilter) {
			setStatutCourse(localRegistrantsFilter);
		}
	}, []);

	useEffect(() => {
		if (!slug) {
			return;
		}

		const localSlug = localStorage.getItem("manageRunnersSlug");

		if (localSlug != slug) {
			localStorage.setItem("manageRunnersSlug", slug);

			localStorage.removeItem("manageRunnersNmbPerPage");
			localStorage.removeItem("manageRunnersPage");
			localStorage.removeItem("manageRunnersRun");
			localStorage.removeItem("manageRunnersSearch");
			localStorage.removeItem("manageRunnersOrder");
			localStorage.removeItem("manageRunnersSource");
			localStorage.removeItem("manageRegistrantsFilter");

			setNmbPerPage(NB_PER_PAGE);
			setPage(1);
			setRun(0);
			setSearch("");
			setOrder({ column: "a", order: 1 });
			setSource([0, 0]);
			setStatutCourse("");
		}
	}, [slug]);

	useEffect(() => {
		setPage(1);
	}, [debouncedSearch, statutCourse, finishedRace]);

	return (
		<ManageRunnerContext.Provider
			value={{
				selectedRunnerIndex,
				setSelectedRunnerIndex,
				finishedRace,
				setFinishedRace,
				source,
				order,
				LiveConfig,
				LiveConfigLoading,
				nmbPerPage,
				page,
				Points,
				run,
				runSlug,

				LiveData,
				LiveDataFiltered,
				LiveDataLoading,
				LiveDataError,
				LiveDataRefetch,

				customRankings,
				search,
				debouncedSearch,
				pagination,
				maxPage,
				selectedRunner,
				statutCourse,
				setStatutCourse,
				admin,
				setAdmin,
				openedSubscription,
				setOpenedSubscription,
				openedDetections,
				setOpenedDetections,
				openedReset,
				setOpenedSubscriptionWithoutId,
				openedSubscriptionWithoutId,
				setOpenedReset,
				handleChangeNmbPerPage,
				handleChangePage,
				handleChangeRun,
				handleChangeSearch,
				handleChangeOrder,
				handleChangeSource,
				handleChangeStatus,

				// Results Filters
				currentFilters,
				setCurrentFilters,

				// Ranking configuration
				currentRankConfig,
				setCurrentRankConfig,

				// All custom fields for current race
				currentRaceCustomFields,
				setCurrentRaceCustomFields,
				onlyIfInter,
				setOnlyIfInter
			}}
		>
			{children}
		</ManageRunnerContext.Provider>
	);
};

export default ManageRunnerProvider;
