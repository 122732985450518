import { useContext, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { deleteEventChoice } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import ChoiceDeleteConfirm from "./ChoiceDeleteConfirm";
import ListChoiceImport from "./ListChoiceImport";

const ListDetail = ({ list, refetch }: { list: any; refetch: any }) => {
	const { slug, slug_race } = useParams();
	const { lang } = useContext(AppContext);
	const [openedChoice, setOpenedChoice] = useState(false);
	const [openedConfirm, setOpenedConfirm] = useState(-1);

	const deleteChoice = async (id: number) => {
		try {
			if (!slug || !slug_race) return;

			await deleteEventChoice(slug, slug_race, list.id, id);
			setOpenedConfirm(-1);
			refetch();
		} catch (error) {
			throw error;
		}
	};

	return (
		<>
			<div className="mx-2 mb-2 rounded-sm bg-white p-4">
				{list.choix.length > 0 ? (
					<>
						<h3 className="mb-2 flex flex-row items-center gap-2">
							{trad[lang].list_available_choices}
						</h3>

						<div className="flex flex-row flex-wrap items-center gap-5">
							{list.choix.map((item: any) => (
								<p
									className="flex flex-row items-center gap-2 rounded-full border border-slate-300 bg-slate-100 py-2 pr-3 pl-5 text-black"
									key={item.id}
								>
									{item.libelle}
									<div
										className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-white text-red-500"
										onClick={() => setOpenedConfirm(item.id)}
									>
										<AiOutlinePlus size={12} className="rotate-45" />
									</div>
								</p>
							))}
						</div>

						<button
							type="button"
							className="mt-4 h-10 rounded-lg border border-primary bg-white px-5 text-center text-sm font-medium text-primary transition-colors hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
							onClick={() => setOpenedChoice(true)}
						>
							{trad[lang].add_list_choice}
						</button>
					</>
				) : (
					<ListChoiceImport id={list.id} refetch={refetch} />
				)}
			</div>

			{openedChoice && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
					<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h2 className="text-center text-2xl">
								{trad[lang].add_list_choice}
							</h2>
						</div>

						<div className="flex flex-col gap-5 p-5">
							<ListChoiceImport
								id={list.id}
								refetch={refetch}
								close={() => setOpenedChoice(false)}
							/>
						</div>
					</div>
				</div>
			)}

			{openedConfirm > 0 && (
				<ChoiceDeleteConfirm
					close={() => setOpenedConfirm(-1)}
					refetch={refetch}
					name={
						list.choix.find((item: any) => item.id == openedConfirm).libelle
					}
					submit={() => deleteChoice(openedConfirm)}
				/>
			)}
		</>
	);
};

export default ListDetail;
