import { Tooltip, Typography } from "@material-tailwind/react";
import dayjs from "dayjs";
import { useCallback, useContext, useRef, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useQuery } from "react-query";
import {
	unstable_useBlocker,
	useBeforeUnload,
	useParams
} from "react-router-dom";
import {
	createRun,
	editRun,
	getEventSubtypes,
	getFFTRIOptions,
	updateSubscriptionVisibility
} from "../../../api/event";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import { IIdentityForm } from "../../../types/EventForms/ISportIdentity";
import { classNames } from "../../../utils/Classes";
import { clampISODate } from "../../../utils/DateFormater";
import Toast from "../../../utils/Toasts";
import DateTimePickerTZ from "../../common/DateTimePickerTZ";

export const SportEventRunModal = ({
	eventInfos,
	eventType,
	run,
	setRun,
	refetch,
	timezone
}: {
	eventInfos: IIdentityForm;
	eventType: number;
	run: any;
	setRun: any;
	refetch: any;
	timezone: string;
}) => {
	const { slug } = useParams();
	const { lang, unsavedChangesRef } = useContext(AppContext);
	const [localForm, setLocalForm] = useState({
		...run,
		startDate: run.startDate || eventInfos.startDate,
		endDate: run.endDate || eventInfos.endDate,
		allowTransfer: run.allowTransfer !== undefined ? run.allowTransfer : true,
		displayGpx: run.displayGpx !== undefined ? run.displayGpx : true,
		event_type: run.event_type || eventType
	});
	const [errors, setErrors] = useState<string[]>([]);

	const { data: FFTRIOptions = [] } = useQuery({
		queryKey: "fftri_options",
		queryFn: getFFTRIOptions,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false
	});

	// Récupération des types de course
	const { data: subtypes = [], isLoading: TypesLoading } = useQuery({
		queryKey: "subtypes_sport",
		refetchOnWindowFocus: false,
		queryFn: () => getEventSubtypes("sport")
	});

	// Unsaved changes ? (Display prompt if true)
	const isPromptConsumedRef = useRef(false);

	unstable_useBlocker((args) => {
		if (unsavedChangesRef.current && isPromptConsumedRef.current === false) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			return !userDiscarded;
		}
		return false;
	});

	useBeforeUnload(
		useCallback(
			(e) => {
				if (unsavedChangesRef.current) {
					e.preventDefault();
					return (e.returnValue = "");
				}
			},
			[unsavedChangesRef.current]
		)
	);

	const closeModal = () => {
		if (unsavedChangesRef.current === true) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			if (userDiscarded === false) return;
		}
		setRun(null);
	};

	const handleChange = (key: string, value: any) => {
		unsavedChangesRef.current = true;
		setLocalForm((old: any) => ({ ...old, [key]: value }));

		setErrors((old) => {
			const old_errors = [...old];

			const index = old_errors.findIndex((item) => item == key);

			if (index >= 0) {
				old_errors.splice(index, 1);
			}

			return old_errors;
		});
	};

	const checkErrors = () => {
		const newErrors = [];

		if (!localForm.name || localForm.name.length < 2) newErrors.push("name");

		if (!localForm.startDate) newErrors.push("startDate");

		if (
			!localForm.endDate ||
			new Date(localForm.endDate).getTime() <
				new Date(localForm.startDate).getTime()
		)
			newErrors.push("endDate");

		if (
			localForm.availablePlaces &&
			localForm.teamNumber > 1 &&
			localForm.availablePlaces < localForm.teamNumber
		)
			newErrors.push("availablePlaces");
		else if (
			localForm.availablePlaces === 0 ||
			localForm.availablePlaces === "0"
		)
			newErrors.push("availablePlaces");

		if (localForm.fftriID && !localForm.distanceType)
			newErrors.push("distanceType");

		if (newErrors.length) {
			setErrors(newErrors);
			throw new Error("Invalid form");
		}
	};

	const submit = async () => {
		try {
			checkErrors();

			if (localForm.id) {
				await editRun(localForm);
			} else {
				await createRun(slug as string, localForm);
			}

			unsavedChangesRef.current = false;
			refetch();
			closeModal();
			Toast.success(trad[lang].sport_runs_success);
		} catch (error) {
			Toast.error(trad[lang].sport_runs_error);
		}
	};

	const RequiredComponent = () => (
		<span className="text-sm italic text-red-500">
			{" "}
			({trad[lang].required})
		</span>
	);
	const [isVisible, setIsVisible] = useState(run?.inscription_visible);
	const updateSubscriptionVisible = async () => {
		try {
			setIsVisible((prevIsVisible: any) => {
				const newVisibility = !prevIsVisible;
				updateSubscriptionVisibility(run.id, newVisibility);
				return newVisibility;
			});
			refetch();
			Toast.success("La visibilité a été mise à jour.");
		} catch (error) {
			Toast.error(
				"Une erreur est survenue lors de la mise à jour de la visibilité."
			);
		}
	};

	console.log(subtypes);

	return (
		<>
			<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
				<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white p-3 xl:w-3/5 2xl:w-2/5">
					<h2 className="mb-3 px-3 text-center text-2xl font-bold">
						{trad[lang].edit_run_title} - {eventInfos.timezone}
					</h2>

					<div className="flex max-h-[60vh] flex-col gap-3 overflow-y-auto px-3 md:grid md:grid-cols-2">
						{/* Nom de la course */}
						<div className={`col-span-1 flex flex-col`}>
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_run_name"
							>
								{trad[lang].event_run_name_label}
								<RequiredComponent />
								<Tooltip
									className="text-md z-50 text-center"
									content={<Typography>{trad[lang].event_name}</Typography>}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<input
								type="text"
								id="event_run_name"
								className={classNames(
									"rounded-md",
									errors.includes("name")
										? "border-transparent outline outline-2 outline-red-500"
										: ""
								)}
								onChange={(e) => handleChange("name", e.target.value)}
								value={localForm.name || ""}
							/>
						</div>
						{/* Nombre de place */}
						<div className={`col-span-1 flex flex-col`}>
							<label
								className={classNames(
									"mb-1 flex flex-row items-center gap-1 text-lg",
									errors.includes("availablePlaces") ? "text-red-500" : ""
								)}
								htmlFor="event_run_places"
							>
								{trad[lang].event_run_places_label}{" "}
								{errors.includes("availablePlaces")
									? trad[lang].error_too_small
									: ""}
								<Tooltip
									className="text-md z-50 text-center"
									content={<Typography>{trad[lang].event_places}</Typography>}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<input
								placeholder={trad[lang].unlimited}
								type="number"
								min={0}
								id="event_run_places"
								className={classNames(
									"rounded-md",
									errors.includes("availablePlaces")
										? "border-transparent outline outline-2 outline-red-500"
										: ""
								)}
								onChange={(e) =>
									handleChange("availablePlaces", e.target.value)
								}
								value={localForm.availablePlaces || ""}
							/>
						</div>
						{/* Date de début de la course */}
						<div className={`col-span-1 flex flex-col`}>
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_run_start_date"
							>
								{trad[lang].event_run_start_date_label}
								<RequiredComponent />
								<Tooltip
									className="text-md z-50 text-center"
									content={
										<Typography>{trad[lang].event_start_date}</Typography>
									}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<DateTimePickerTZ
								handleChange={(e) => {
									if (e) {
										handleChange("startDate", e);
										if (dayjs(localForm.endDate).isBefore(dayjs(e)))
											handleChange("endDate", e);
									}
								}}
								minDateTime={
									eventInfos.startDate
										? dayjs(eventInfos.startDate).tz(timezone)
										: undefined
								}
								maxDateTime={
									eventInfos.endDate
										? dayjs(eventInfos.endDate).tz(timezone)
										: undefined
								}
								value={dayjs(localForm.startDate)}
								timezone={timezone}
							/>
						</div>
						{/* Date de fin de la course */}
						<div className={`col-span-1 flex flex-col`}>
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_run_end_date"
							>
								{trad[lang].event_run_end_date_label}
								<RequiredComponent />
								<Tooltip
									className="text-md z-50 text-center"
									content={<Typography>{trad[lang].event_end_date}</Typography>}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<DateTimePickerTZ
								handleChange={(e) => {
									if (e) {
										handleChange("endDate", e);
									}
								}}
								minDateTime={dayjs(localForm.startDate)}
								maxDateTime={
									eventInfos.endDate ? dayjs(eventInfos.endDate) : undefined
								}
								value={dayjs(
									clampISODate(
										localForm.endDate,
										localForm.startDate,
										localForm.endDate
									)
								)}
								timezone={timezone}
							/>
						</div>
						{/* Nombre d'équipier */}
						<div className={`col-span-1 flex flex-col`}>
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_run_places"
							>
								{trad[lang].event_run_member_label}
								<Tooltip
									className="text-md z-50 text-center"
									content={
										<Typography>{trad[lang].event_team_number}</Typography>
									}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<input
								type="number"
								min={2}
								max={6}
								id="event_run_member"
								className={classNames(
									"rounded-md",
									errors.includes("teamNumber")
										? "border-transparent outline outline-2 outline-red-500"
										: "",
									!!localForm.id ? "opacity-50" : ""
								)}
								onChange={(e) => handleChange("teamNumber", e.target.value)}
								value={localForm.teamNumber || ""}
								disabled={!!localForm.id}
							/>
						</div>

						<div className={`col-span-1 flex flex-col`}>
							<label className="mb-1 text-lg" htmlFor="event_type">
								{/* @ts-ignore */}
								Type de la course
							</label>

							<select
								className={"rounded-md"}
								id="event_type"
								onChange={(e) => handleChange("event_type", e.target.value)}
								value={localForm.event_type}
							>
								{subtypes.map((item: any) => (
									<option value={item.id}>{item.title}</option>
								))}
							</select>
						</div>

						<div
							className={`col-span-2 mt-2 flex flex-row place-items-center gap-2`}
						>
							<label className="mb-1 flex flex-row items-center gap-1 text-lg">
								{" "}
								{trad[lang].event_run_make_it_visible}
								<Tooltip
									className="text-md z-50 text-center"
									content={
										<Typography>
											{trad[lang].event_run_make_it_visible_info}
										</Typography>
									}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>
							<label className="inline-flex cursor-pointer items-center space-x-4 dark:text-gray-800">
								<span className="font-bold">non</span>
								<span className="relative">
									<input
										type="checkbox"
										className="peer hidden"
										checked={isVisible === true}
										onChange={() => updateSubscriptionVisible()}
									/>
									<div className="h-6 w-10 rounded-full bg-gray-300 peer-checked:dark:bg-primary"></div>
									<div className="absolute inset-y-0 left-0 m-1 h-4 w-4 rounded-full bg-white shadow peer-checked:right-0 peer-checked:left-auto dark:bg-gray-100"></div>
								</span>
								<span className="font-bold">oui</span>
							</label>{" "}
						</div>
						{/* Code FFTRI */}
						{eventType == 63 && (
							<>
								<div className={`col-span-1 flex flex-col`}>
									<label
										className="mb-1 flex flex-row items-center gap-1 text-lg"
										htmlFor="event_run_fftri_code"
									>
										{trad[lang].event_run_fftri_code}
										<Tooltip
											className="text-md z-50 text-center"
											content={
												<Typography>
													{trad[lang].event_run_fftri_code}
												</Typography>
											}
										>
											<p>
												<AiOutlineInfoCircle />
											</p>
										</Tooltip>
									</label>

									<input
										type="text"
										id="event_run_fftri_code"
										className="rounded-md"
										onChange={(e) => handleChange("fftriID", e.target.value)}
										value={localForm.fftriID || ""}
									/>
								</div>

								{/* Type distance */}
								<div className={`col-span-2 flex flex-col`}>
									<label
										className="mb-1 flex flex-row items-center gap-1 text-lg"
										htmlFor="event_run_fftri_distance"
									>
										{trad[lang].event_run_fftri_distance}

										{localForm.fftriID && <RequiredComponent />}
										<Tooltip
											className="text-md z-50 text-center"
											content={
												<Typography>
													{trad[lang].event_run_fftri_distance}
												</Typography>
											}
										>
											<p>
												<AiOutlineInfoCircle />
											</p>
										</Tooltip>
									</label>

									<select
										className={classNames(
											"rounded-md",
											errors.includes("distanceType")
												? "border-transparent outline outline-2 outline-red-500"
												: ""
										)}
										id="event_run_fftri_distance"
										onChange={(e) =>
											handleChange("distanceType", e.target.value)
										}
										value={localForm.distanceType}
									>
										<option value={0}>
											{trad[lang].event_fftri_default_distance}
										</option>

										{FFTRIOptions.map((item: any) => (
											<option value={item.tarif.id} key={item.tarif.id}>
												{item.tarif.DesignationInternet}
											</option>
										))}
									</select>
								</div>
							</>
						)}

						{eventInfos.siffa && (
							<div className="col-span-1 flex flex-col">
								<label
									className="mb-1 flex flex-row items-center gap-1 text-lg"
									htmlFor="event_run_fftri_distance"
								>
									{trad[lang].event_run_licence_accepted}

									{localForm.fftriID && <RequiredComponent />}
									<Tooltip
										className="text-md z-50 text-center"
										content={
											<Typography>
												{trad[lang].event_run_licence_accepted_tooltip}
											</Typography>
										}
									>
										<p>
											<AiOutlineInfoCircle />
										</p>
									</Tooltip>
								</label>

								<select
									className={classNames(
										"rounded-md",
										errors.includes("typeLicence")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									id="event_run_fftri_distance"
									onChange={(e) => handleChange("typeLicence", e.target.value)}
									value={localForm.typeLicence}
								>
									<option value={""}>
										{trad[lang].event_run_licence_accepted_all}
									</option>

									<option value={"COMP"}>
										{trad[lang].event_run_licence_accepted_comp}
									</option>
								</select>
							</div>
						)}

						<div className={`col-span-2 mt-2 flex flex-row place-items-center`}>
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_run_places"
							>
								{trad[lang].event_run_transfer_label}
								<Tooltip
									className="text-md z-50 text-center"
									content={<Typography>{trad[lang].event_transfer}</Typography>}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<input
								type="checkbox"
								id="event_transfer"
								className="ml-4 mt-0.5 h-6 w-6 rounded-md"
								onChange={(e) =>
									handleChange("allowTransfer", e.target.checked)
								}
								defaultChecked={
									localForm.allowTransfer !== undefined
										? localForm.allowTransfer
										: true
								}
								value={localForm.allowTransfer}
							/>
						</div>

						<div className={`col-span-2 mt-2 flex flex-row place-items-center`}>
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_run_places"
							>
								{trad[lang].display_gpx}
							</label>

							<input
								type="checkbox"
								id="event_transfer"
								className="ml-4 mt-0.5 h-6 w-6 rounded-md"
								onChange={(e) => handleChange("displayGpx", e.target.checked)}
								defaultChecked={
									localForm.displayGpx !== undefined
										? localForm.displayGpx
										: true
								}
								value={localForm.displayGpx}
							/>
						</div>

						{/* Detail spécifique */}
						<div className={`col-span-2 flex flex-col`}>
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_run_places"
							>
								{trad[lang].event_run_details_label}
								<Tooltip
									className="text-md z-50 text-center"
									content={
										<Typography>{trad[lang].event_run_details}</Typography>
									}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<textarea
								id="event_run_details"
								className={classNames(
									"rounded-md",
									errors.includes("runDetails")
										? "border-transparent outline outline-2 outline-red-500"
										: ""
								)}
								onChange={(e) => handleChange("runDetails", e.target.value)}
								value={localForm.runDetails || ""}
							/>
						</div>
					</div>

					<div className="mt-3 flex flex-row gap-3 px-3">
						<button
							className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
							onClick={() => closeModal()}
						>
							{trad[lang].close}
						</button>
						<button
							className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
							onClick={submit}
						>
							{trad[lang].validate}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default SportEventRunModal;
