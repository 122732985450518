import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getActivityPositionsBySubscription } from "../../api/app";
import { getEventRunList } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

interface IManageRunnerTimesProps {
	data: any;
	segment: any;
}

const ManageRunnerTimes = ({ data, segment }: IManageRunnerTimesProps) => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const [selectedRun, setSelectedRun] = useState(
		data && data.length > 0 ? data[0].w : null
	);

	const isApp = data && data.length > 0 && !!data[0].w;
	const idInscription = data && data.length > 0 ? data[0].k : null;

	/* Gestion de la différence entre les données de l'application et les données du segment */
	const usedData = isApp ? data : data[0];
	const segmentKeys = isApp
		? []
		: Object.keys(usedData)
				.filter((key) => key.startsWith("a_"))
				.map((key) => key.replace("a_", ""));
	const times = isApp
		? usedData.map((item: any) => ({
				a: item.a,
				b: item.b,
				c: item.c,
				f: item.f,
				o: item.o,
				y: item.y,
				w: item.w
		  }))
		: segmentKeys.map((item) => ({
				a: usedData[`a_${item}`],
				b: usedData[`b_${item}`],
				c: usedData[`c_${item}`],
				f: usedData[`f_${item}`],
				o: usedData[`o_${item}`],
				y: segment.find((seg: any) => seg.idSegment == item)?.libelle
		  }));

	/* Récupération des données du GPX */
	const { data: GpxInfos = { positions: [] } } = useQuery({
		queryKey: ["GpxInfos", selectedRun, idInscription],
		queryFn: () =>
			getActivityPositionsBySubscription(idInscription, selectedRun),
		enabled: !!selectedRun && !!idInscription
	});

	const { data: EventInfos } = useQuery({
		queryKey: ["event_routes", slug],
		queryFn: () => getEventRunList(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	return (
		<div className="my-2 flex w-full flex-1 flex-col divide-y">
			<div className="flex h-10 w-full flex-row items-center justify-between">
				{!isApp && <p className="w-2/12 px-2 font-mono">{trad[lang].step}</p>}
				<p className="w-2/12 px-2 font-mono">{trad[lang].general_ranking}</p>
				<p className="w-2/12 px-2 font-mono">{trad[lang].sex_ranking}</p>
				<p className="w-2/12 px-2 font-mono">{trad[lang].cat_ranking}</p>

				<p className="w-2/12 px-2 font-mono">{trad[lang].times}</p>
				<p className="w-2/12 px-2 font-mono">{trad[lang].gap}</p>
			</div>

			{times.map((item: any, index: any) => (
				<div
					key={index}
					className={`flex w-full flex-row items-center justify-between py-2 transition-colors ${
						selectedRun == item.w
							? "bg-slate-200"
							: isApp && "cursor-pointer hover:bg-slate-100"
					}`}
					onClick={() => {
						if (!isApp) return;
						setSelectedRun(item.w);
					}}
				>
					{!isApp && <p className="w-2/12 px-2 font-mono">{item.y}</p>}
					<p className="w-2/12 px-2 font-mono">{item.a}</p>
					<p className="w-2/12 px-2 font-mono">{item.b}</p>
					<p className="w-2/12 px-2 font-mono">{item.c}</p>

					<p className="w-2/12 px-2 font-mono">{item.f}</p>
					<p className="w-2/12 px-2 font-mono">{item.o}</p>
				</div>
			))}
		</div>
	);
};

export default ManageRunnerTimes;
