const trad = {
	/* ↓↓↓--FRENCH VERSION--↓↓↓ */
	fr: {
		delete_old_detections:
			"Souhaitez-vous conserver les détections de la dernière prestation ?",
		select_race: "Veuillez choisir une course",
		select_club: "Choix du club",
		select_team: "Choix de l'équipe",
		select_noClub: "Aucun club disponible",
		select_noTeam: "Aucune équipe disponible",
		select_gender: "Veuillez choisir le genre",
		select_bib: "Veuillez choisir un numéro de dossard",
		select_country: "Veuillez choisir un pays",
		select_countrySearch: "Rechercher...",
		select_countryNoResult: "Aucun résultat",
		published: "Publié",
		unpublished: "Non publié",
		edit: "Modifier",
		registered: "inscrit(s)",
		toValidate: "à valider",
		ofTurnover: "d'inscription",
		publishEvent: "Publier l'événement",
		unpublishEvent: "Dépublier l'événement",
		publishEventWarning:
			"Attention, vous êtes sur le point de publier cet événement, cela signifie qu'il sera visible par tous les utilisateurs de Chronometrage.com. Êtes-vous sûr de vouloir continuer ?",
		unpublishEventWarning:
			"Attention, vous êtes sur le point de dépublier cet événement, cela signifie qu'il ne sera plus visible par les utilisateurs de Chronometrage.com. Êtes-vous sûr de vouloir continuer ?",
		editEvent: "Modifier l'événement",
		editRunner: "Modifier le coureur",
		handleDetection: "Gestion des détections",
		eventName: "Nom de l'événement",
		category: "Catégorie",
		date: "Date",
		description: "Description",
		poster: "Affiche",
		ffaFFTRI: "FFA / FFTRI",
		ffa: "FFA",
		fftri: "FFTRI",
		location: "Localisation",
		rulesPDF: "Règlement PDF",
		website: "Site web",
		file: "Fichier",
		rib: "RIB",
		addRunner: "Ajouter un coureur",
		addRegistration: "Ajouter une inscription",
		sendEmailTitle: "Rédaction du mail à destination des inscrits",
		sendOneEmailTitle: "Rédaction du mail à destination de l'inscrit",
		insight: "Apercu du mail à envoyer",
		config_title: "Gestion",
		runs_title: "Courses",
		update_presentation: "Présentation",
		update_runs: "Courses",
		update_options: "Options",
		update_contacts: "Contacts",
		update_sponsors: "Sponsors",
		update_ribs: "RIBs",
		configure_steps: "Étapes",
		configure_live: "Suivi des coureurs",
		configure_results: "Publiez vos résultats",
		sync_folomi_error: "Il y a davantage d'inscrits que de coureurs.",
		sync_folomi_action: "Cliquer pour synchroniser",
		sync_folomi_button: "Synchroniser les coureurs",
		sync_folomi_race_subscribed: "inscrits mais seulement",
		sync_folomi_race_detected: "coureurs détectés",
		ask_validation: "Demander la publication",
		unpublish: "Dépublier l'évènement",
		validation_asked: "Publication demandée",
		preview: "Aperçu",
		preview_invoice: "Aperçu de la facture",
		edit_invoice: "Edition de la facture",
		commissions: "Information de commissions",
		donations: "Dons",
		invoice_title: "Facturation",
		option: "Option",
		configure_options: "Configurer les options",
		configure_lists: "Champs personnalisés",
		lists: "Configuration des champs",
		add_list: "Ajouter un champ",
		edit_list: "Modifier un champ",
		delete_list: "Supprimer une liste",
		sure_delete_list: "Etes vous sûr de vouloir supprimer",
		lists_desc: "Ces champs seront disponible dans le formulaire d'inscription",
		lists_text_desc:
			"Vous pouvez coller un texte séparé par des points-virgules ( ; ) ou sauts de ligne",
		list_name_too_short: "Le nom du champ est trop court",
		list_available_choices: "Choix disponibles",
		import_list: "Importer cette liste",
		or: "ou",
		add_list_choice: "Ajouter des choix",
		empty_list_text: "Vous ne pouvez pas importer une liste vide",
		list_choice_name_too_short: "Le nom du choix est trop court",
		add_global_option: "Ajouter une option globale",
		add_price: "Ajouter un tarif",
		add_option: "Ajouter une option",
		add_product: "Ajouter un produit",
		min_quantity: "Quantité minimale",
		max_quantity: "Quantité maximale",
		designation: "Designation",
		option_type: "TYPE DE CHAMPS SOUHAITÉS",
		is_required: "SOUHAITEZ-VOUS RENDRE L'OPTION OBLIGATOIRE ?",
		product: "Produit",
		price: "Tarif",
		type_dropdown_description:
			"Liste de différents produits (Exemple: Taille T-shirt, S, M, L)",
		type_check_description: "Case à cocher (Exemple: Bus, Repas)",
		type_quantity_description:
			"Demande de quantité pour un produit (Exemple: Nombre de repas coureur)",
		delete_title: "Suppression",
		delete_message: "Êtes-vous sûr de vouloir supprimer",
		price_start_date: "DATE ET HEURE DE DÉBUT DU TARIF",
		price_end_date: "DATE ET HEURE DE FIN DU TARIF",
		price_start_date_donation: "DATE ET HEURE DE DÉBUT DE LA CAMPAGNE DE DON",
		price_end_date_donation: "DATE ET HEURE DE FIN DE LA CAMPAGNE DE DON",
		warning_no_price:
			"Au moins un tarif (même gratuit) doit exister pour cette course pour ajouter une option.",
		warning_delete: "(L'opération sera effective après enregistrement)",
		error_qty_min_0: "Les quantités doivent être supérieures à 0",
		error_qty_min_max:
			"La quantité minimale doit être inférieure à la quantité maximale",
		error_too_small: "(Trop petit)",
		default: "Par défaut",
		prestation: "Lancer la prestation",
		loading: "Chargement",
		importResults: "Importer les résultats",
		set: "Paramétré",
		notSet: "Non paramétré",
		filesToBeChecked: "fichiers à vérifier",
		completedAndValidatedFiles: "fichiers complétés et validés",
		viaOptions: "via les options",
		confirm: "Confirmer",
		cancel: "Annuler",
		interrupt: "Interrompre",
		previous: "Précédent",
		next: "Suivant",
		save: "Enregistrer",
		search: "Rechercher",
		download: "Télécharger",
		allM: "Tous",
		allF: "Toutes",
		delete: "Supprimer",
		inTeam: " - En équipe",
		send: "Envoyer",
		create_event: "Créer un événement",
		required: "Obligatoire",
		discard_changes: "Abandonner les changements ?",
		preview_off: "Fermer l'aperçu",
		error_boundary_title: "Une erreur est survenue.",
		error_boundary_support:
			"Si le problème persiste, contacter le support en fournissant les informations suivantes :",
		back: "Retour",
		sendMailAllSubscribers: "Envoyer un mail collectif",
		sendMail: "Envoyer un mail",
		sendMailError: "Le mail n'a pas pu être envoyé",
		resetExpired: "Demande expirée. Retournez sur 'mot de passe oublié'",
		eventSearch: "Rechercher un événement",
		number_bib: "Numéro de dossard",
		number_line: "Numéro de ligne",
		time_detection: "Heure de passage",
		number_line_short: "Ligne",
		whatIsJetcode: "Qu'est-ce qu'un jetcode ?",
		jetcodeExplanation:
			"C’est un outil qui permet d’intégrer directement, sur votre sur votre site web, le contenu d’une autre page (formulaires d’inscriptions & paiement en ligne, compteurs de dossards restants, compte à rebours, logo chronometrage.com)",
		howIntegrateJetcodeToMyWebsite:
			"Comment intégrer un jetcode à mon site web ?",
		jetcodeIntegrationExplanation:
			"Il suffit de copier les codes fournis des jetcodes souhaités (inclure mes inscriptions et logo chronometrage.com) Puis de le coller sur une page de votre site web.",
		integrateSubscriptionsForm: "Intégrer le formulaire d'inscriptions",
		integrateSubscirbersList: "Intégrer la liste des inscrits",
		integrateOnlineSubscriptionLogo: 'Intégrer le logo "inscription en ligne"',
		engineCodeLabel:
			"Code du moteur à copier 1 fois dans le Header de votre site",
		basketCodeLabel:
			"Code du panier à copier 1 fois sur chaque page de votre site ",
		runCodeLabel: "Code spécifique aux courses",
		french: "Français",
		english: "Anglais",
		copy: "Copier",
		copyDone: "Copié !",
		insertionCodeLabel:
			"Code d’insertion du panier, ce code est à insérer une seule fois par page",
		language: "Langue",
		runSelectLabel: "Course souhaitée pour le jetcode",
		priceJetcodeCodeLabel:
			"Paramétrage du jetcode Tarif à copier dans votre site",
		theme: "Thème",
		setup: "Configuration",
		seeTimer: "Voir le timer",
		seeSeconds: "Voir les secondes",
		seeBibs: "Voir les dossards",
		seeBibsLeft: "Voir les dossards restants",
		codeToCopyToYourWebsite: "Code à copier sur votre site web",
		downloadProcessLabel:
			"Télécharger la procédure au format PDF afin de la transmettre à votre webmaster",
		listSetup: "Configuration de la liste",
		selectARun: "Sélectionnez une course",
		generalSettings: "Paramètres généraux",
		dagLiveCredentials: "Credential DAG Live",
		sensorName: "Libellé",
		distance: "Distance (m)",
		passageMin: "Nbre passage min",
		passageMax: "Nbre passage max",
		sensor: "Détecteur",
		type: "Type",
		start: "Démarrer",
		stop: "Arrêter la prestation",
		deleteDetections: "Supprimer définitivement les détections",
		deleteDetectionsSuccess: "Suppression réussie",
		deleteDetectionsError: "Une erreur s'est produit pendant la suppression",
		prestationError: "ERREUR",
		sensorSettings: "Paramètres détecteur",
		addGpx: "Ajouter GPX",
		chooseSensorType: "Choisissez le type de détecteur",
		startLine: "Ligne de départ",
		finishLine: "Ligne d'arrivée",
		checkpoint: "Point de contrôle",
		addSensor: "Ajouter un détecteur",
		removeThisSensor: "Supprimer ce détecteur",
		handleRunners: "Coureurs",
		handleDetections: "Détections",
		dashboardDetections: "Résumé",
		global_configuration: "Configuration",
		route_configuration: "Parcours",
		delete_step: "Supprimer cette étape",
		edit_step: "Modifier cette étape",
		delete_parcours: "Supprimer ce parcours",
		edit_parcours: "Modifier ce parcours",
		delete_segment: "Supprimer ce segment",
		edit_segment: "Modifier ce segment",
		step: "Etape",
		parcours: "Parcours",
		segment: "Segment",
		configure_routes: "Parametrer les parcours",
		configure_segments: "Parametrer les segments",
		intermediary: "Intermédiaire",
		start_line: "Départ",
		end_line: "Arrivée",
		typeLine1: "Départ",
		typeLine2: "Intermédiaire",
		typeLine3: "Arrivée",
		grid: "Mise en grille",
		categoryReassignement: "Ré-assignation des catégories",
		handleResults: "Résultats",
		timezone: "Quelle timezone souhaitez-vous conserver ?",
		no_run_selected: "Aucune course selectionnée",
		placeholder_select_run: "Selectionner une course",
		placeholder_select_segment: "Selectionner un segment",
		log_title: "Connectez-vous à votre outil d'administration",
		forgotten_title: "Mot de passe oublié ?",
		reset_password: "Réinitialiser le mot de passe",
		back_to_home: "Revenir à l'accueil",
		passwords_not_match: "Les mots de passe ne correspondent pas",
		passsword_reset_success: "Votre mot de passe a été réinitialisé",
		password_too_short: "Votre mot de passe doit faire 8 caractères minimum",
		send_forgotten_mail_success: "Un email vous a été envoyé",
		email: "Adresse email",
		password: "Mot de passe",
		new_password: "Nouveau mot de passe",
		new_password_confirm: "Confirmez votre mot de passe",
		confirmPassword: "Confirmez votre mot de passe",
		submit: "Se connecter",
		social_reason: "Raison sociale",
		adresse: "Adresse",
		postal_code: "Code postal",
		city: "Ville",
		country: "Pays",
		noAccount: "Pas encore de compte ?",
		rememberMe: "Se souvenir de moi",
		signup_button: "Créer un compte",
		signup: "Je n'ai pas encore de compte",
		signup_title: "S'incrire à l'outil d'administration",
		forgotten_password: "Mot de passe oublié ?",
		already_account: "J'ai déjà un compte",
		lastname: "Nom",
		firstname: "Prénom",
		birthdate: "Date de naissance",
		sex: "Genre",
		acceptCGV: "J'accepte les conditions d'utilisation de la plateforme",
		signup_error_firstname: "Votre prénom doit faire plus de 1 lettre.",
		signup_error_lastname: "Votre nom doit faire plus de 1 lettre.",
		signup_error_birthdate:
			"Votre date de naissance doit être valide et vous devez avoir entre 13 ans et 100 ans.",
		signup_error_email: "Votre email n'est pas valide.",
		signup_error_password:
			"Votre mot de passe doit faire 6 charactère minimum.",
		signup_error_passwordConfirm: "Vos mots de passe ne sont pas identiques.",
		signup_error_CGU: "Vous devez accepter les CGU.",
		orga_title: "Devenir organisateur",
		runDelete: "Supprimer la course",
		runDeleteWarning:
			"Attention, vous êtes sur le point de supprimer cette course, cette action est irréversible. Êtes-vous sûr de vouloir continuer ?",
		runEdit: "Modifier la course",
		subEdit: "Modification de l'inscription",
		subscription: "Inscription",
		subOptions: "Options",
		runName: "Nom de la course",
		teamName: "Nom de l'équipe",
		runStartDate: "Date de départ",
		noClub: "Aucun club",
		runEndDate: "Date de fin",
		runMaxNbOfSlots: "Nombre maximum de places",
		runnerChart: "Charte des coureurs",
		yourParentalAuthorizationModel: "Votre modèle d'autorisation parentale",
		addBban: "Ajouter un RIB",
		editBban: "Modifier le RIB",
		bbanType: "Type de RIB",
		bbanName: "Nom du RIB",
		bankName: "Nom de la banque",
		iban: "IBAN",
		bicSwift: "BIC/SWIFT",
		areYouThePersonInCharge: "Êtes-vous le responsable légal ?",
		personInChargeInformations: "Informations du responsable légal",
		gender: "Genre",
		male: "Homme",
		female: "Femme",
		other: "Autre",
		legalEntity: "Raison sociale",
		address: "Adresse",
		team: "Équipe",
		race: "Course",
		bib: "Dossard",
		bibAvailable: "Dossard disponible",
		bibUnavailable: "Dossard déjà utilisé",
		invalid: "Invalide",
		bibInvalid: "Dossard invalide",
		runner: "Coureur",
		zipCode: "Code postal",
		birthDate: "Date de naissance",
		genderH: "H",
		genderF: "F",
		genderX: "X",
		editGeneral: "Général",
		editRace: "En course",
		nationality: "Nationalité",
		countryPlaceholder: "Sélectionnez un pays",
		firstPhone: "Téléphone 1",
		secondPhone: "Téléphone 2",
		marketer: "Commercialisateur",
		organizer: "Organisateur",
		yes: "Oui",
		no: "Non",
		bbanDelete: "Supprimer le RIB",
		bbanDeleteWarning:
			"Attention, vous êtes sur le point de supprimer ce RIB, cette action est irréversible. Êtes-vous sûr de vouloir continuer ?",
		addTeam: "Ajouter une équipe",
		club: "Club",
		duplicateEmail: "Cette adresse mail est déjà utilisée",
		alreadyInUse: "Déjà utilisé",
		editTimes: "Temps",
		object: "Objet",
		mailBody: "Corps du mail",
		swapBibs: "Échanger les dossards",
		swapFrom: "Avant",
		swapTo: "Après",
		nbDetections: "Détections",
		stageDepartureHour: "Heure de départ officielle",
		handicap: "Handicap",
		miniTime: "Temps minimum sur l'étape",
		error404: "Erreur 404",
		pageDoesNotExist: "Cette page n'existe pas",
		iThinkYouAreLost: "Je pense que vous êtes perdu",
		goBackHome: "Retourner à l'accueil",
		dashboard: "Tableau de bord",
		registrations: "Inscriptions",
		event: "Evenement",
		liveResults: "Chronométrage",
		menuResults: "Résultats",
		liveConfiguration: "Configuration du Live",
		statistics: "Statistiques",
		admin: "Administration",
		jetcodes: "Jetcodes",
		toCome: "À venir",
		today: "Aujourd'hui",
		past: "Passés",
		myAccount: "Mon compte",
		logout: "Déconnexion",
		manageRunners: "Gestion des coureurs",
		detections_dashboard: "Tableau de bord des détections",
		manage_dashboard: "Gestion des détections",
		registration_refund: "Remboursement d'inscription",
		registration_order: "Commande de l'inscription",
		cgu: "Conditions d'utilisation",
		cgv: "Conditions générales de vente",
		manage_results: "Gestion des résultats",
		startOn: "Débute le",
		registeredContestants: "Coureurs inscrits",
		displayRegistrations: "Afficher les inscriptions",
		completeSubscriptionsList: "Liste complète des inscriptions",
		bibs: "Dossard",
		bibsSearch: "Rechercher un dossard",
		firstnameSearch: "Rechercher un prénom",
		lastnameSearch: "Rechercher un nom",
		statusSearch: "Rechercher un état",
		loadingRegisterForm: "Chargement du formulaire d'inscription",
		thanksToWait: "Merci de patienter",
		noRace: "Aucune course configurée",
		teamOf: "ÉQUIPE de",
		invididual: "SOLO",
		importError: "Erreur lors de l'importation",
		preimportWarning: "ATTENTION",
		importWarning: "ATTENTION",
		preimportError: "ERREUR : CET INSCRIT NE SERA PAS IMPORTÉ...",
		preimportErrors: "ERREUR : CES INSCRITS NE SERONT PAS IMPORTÉ(S)...",
		exportTitle: "Exporter des inscriptions au format",
		raceChoice: "Courses",
		sexeChoice: "Sexe",
		statusChoice: "Statut",
		subscriptionType: "Type d'inscription",
		importTitle: "Importer des inscriptions",
		toImport: "Pour importer une liste d'inscriptions, utilisez",
		useThisModel: "ce modèle de fichier EXCEL",
		seeComments: "Voir les commentaires",
		dragHere: "Glissez le fichier EXCEL (format .xlsx) ici...",
		dragHereOr:
			"Glissez le fichier EXCEL (format .xlsx) ici, ou cliquer pour le sélectionner",
		fileFormatNotSupported: "Format de fichier non supporté",
		existingSubs: "inscrits déjà importés précédemment.",
		replace: "Supprimer tout et remplacer",
		donotupdate: "Ajouter uniquement les nouveaux dossards",
		continue: "Continuer l'importation",
		entries: "ajouts sur",
		import: "Importer",
		nbImportedRunners: "coureur(s) importé(s)",
		pre: "Pré-",
		importing: "Importation...",
		existing: "déjà existant(s)",
		adds: "ajout(s) sur ",
		add: "ajout(s)",
		close: "Fermer",
		whattodo: "Que voulez-vous faire ?",
		line: "Ligne",
		invalidRace: "Course invalide",
		bibUsed: "Dossard déjà utilisé",
		missingRace: "Course non renseigné(s)",
		missingBib: "Dossard non renseigné(s)",
		yourEvents: "Vos événements",
		yourRuns: "Vos courses",
		bibsAllocation: "Attribution des dossards",
		discountVouchers: "Bons de réduction",
		form: "Formulaire",
		refunds: "Remboursements",
		accessRights: "Droits d'accès",
		bankBBAN: "RIB",
		manageBibsAllocation: "Gérer l'attribution des dossards",
		manageEventDiscountVouchers: "Gérer les bons de réduction de l'événement",
		manageEventForm: "Gérer le formulaire de l'événement",
		manageRefunds: "Gérer les remboursements",
		manageAccessRights: "Gérer les droits d'accès",
		manageYourBBAN: "Gérer votre RIB",
		manageFFAFFTRILicenses: "Gérer vos licence FFA/FFTRI",
		ribListLinkToThisEvent: "Liste des RIB liés à cet événement",
		addBBAN: "Ajouter un RIB",
		name: "Nom",
		bank: "Banque",
		ibanBban: "IBAN / BBAN",
		thereAreCurrently: "Il y a actuellement",
		registrantsForThe: "inscrits pour la course",
		doYouReallyWantToReimburseThe: "Voulez-vous vraiment rembourser les",
		registrants: "inscrits",
		chargeAdditionalFeesPerRegistrations:
			"Frais supplémentaires par inscription (en €)",
		invoicingOnRunnerOrOrganizer:
			"Facturation sur le coureur ou l'organisateur",
		ifRegistrationFeeIncluded:
			"Si le prix d'inscription est inclus, prise en charge par",
		reimbursementCostsCoveredBy:
			"Les frais de remboursement sont pris en charge par",
		makeTheRefund: "Effectuer le remboursement",
		reason: "Motif",
		choose: "Choisir...",
		bibPlan: "Plan de numérotation",
		bibPlanAffectation: "Manière d'affecter les dossards",
		minBib: "Dossard Minimum",
		maxBib: "Dossard Maximum",
		bibExclusion: "Dossards à exclure",
		bibExlusionPlaceholder:
			"Exemple : \rListe = 1;2;8;10 (les dossards 1,2,8 et 10 ne seront pas pris en compte) \rTranche = 5:10 (les dossards de 5 à 10 inclus (5,6,7,8,9 et 10) ne seront pas pris en compte) \rCombinaison 1 = 2:4;7:8 (les dossards 2,3,4,7 et 8 ne seront pas pris en compte) \rCombinaison 2 = 2;4:6;8 (les dossards 2,4,5,6, et 8 ne seront pas pris en compte)",
		reaffectAllbibs:
			"Réaffecter tous les dossards de la course en validant la sélection",
		keepBibs: "Garder les dossards déjà attribué",
		applyToPending: "Appliquer aux inscriptions en attente",
		orderAlphabetic: "Réaffecter par ordre alphabétique des coureurs",
		list: "Par liste de dossards",
		range: "Par plage de dossards",
		filterByRun: "Filtrer par course",
		resolution: "Résolution",
		days: "Jours",
		weeks: "Semaines",
		months: "Mois",
		years: "Années",
		speed: "Allure",
		minimmAge: "L'âge maximum requis est de",
		maximumAge: "L'âge maximum requis est de",
		gap: "Écart",
		runnerAge: "ans",
		passageTime: "HEURE DE PASSAGES",
		downloadInPdf: "Télécharger en PDF",
		nbOfSubscribers: "Nombre d'inscrits",
		maxInfMin:
			"Veuillez choisir une valeur maximale supérieure à la valeur minimale",
		errorBib: "Une erreur est survenie lors de la mise à jour des dossards",
		updatedBib: "Les numéros de dossard ont été mis à jour",
		loginSuccess: "Connexion réussie",
		loginError: "Erreur de connexion",
		logoutSuccess: "Déconnexion réussie",
		logoutError: "Erreur de déconnexion",
		updateError: "La mise à jour a rencontrée un problème",
		updateSuccess: "La mise à jour a été effectuée",
		updateCredentialSuccess: "La mise à jour a été effectuée",
		updateCredentialError: "La mise à jour a rencontrée un problème",
		startPrestation: "La prestation est lancée",
		liveTimezone:
			"Il a une une inconhérence sur la configuration de la Timezone de votre évènement.",
		stopPrestation: "La prestation est arrêtée",
		prestationStartError: "Erreur lors du lancement de la prestation",
		prestationStartErrorCredentiel:
			"Veuillez configurer votre credentiel avant de lancer la prestation",
		configureFromAnotherRace: "Configurer à partir d'une autre course",
		prestationStopError: "Erreur lors de l'arrêt de la prestation",
		updateCheckpointSuccess: "L'enregistrement des détecteurs a été effectué",
		updateCheckpointError: "Erreur lors de l'enregistrement des détecteurs",
		deletionSuccess: "Suppression réussie",
		deletionError: "Une erreur est survenue pendant la suppression",
		insertSuccess: "Création réussie",
		insertError: "Erreur pendant la création",
		insertErrorDetection:
			"La détection n'a pas pu être transmise. Vérifiez que vous avez une prestation en cours.",
		ppsError: "Numéro de Licence ou PPS non validé par la FFA",
		requiredRace: "Veuillez choisir une course",
		requiredBib: "Veuillez choisir un numéro de dossard",
		requiredBirthdate: "Veuillez renseigner la date de naissance",
		requiredGender: "Veuillez renseigner le genre",
		requiredTeamName: "Veuillez renseigner le nom de l'équipe",
		requiredTeam:
			"Veuillez choisir une équipe dans la liste ou en créer une nouvelle",
		requiredOneOrAllRunner: "Veuillez renseigner un ou tous les coureurs",
		emailSendedSuccess: "L'envoie des emails s'est effectué avec succès.",
		emailSendedError: "Une erreur s'est produite lors de l'envoie des emails.",
		login_expired: "Votre session a expiré",
		newRaceUpdateSuccess: "Le changement d'épreuve s'est effectué avec succès",
		newRaceUpdateError: "Une erreur est survenue lors du changement d'épreuve",
		bibChangeRaceChoice: "Veuillez faire un choix concernant les dossards",
		refundSuccess: "Remboursement effectué avec succès",
		refundError: "Le remboursement a rencontré une erreur",
		sport_identity_success: "Evénement enregistré avec succès",
		sport_runs_success: "Courses configurées avec succès",
		sport_options_success: "Options configurées avec succès",
		sport_identity_error:
			"Une erreur est survenue pendant la création de l'événement",
		sport_runs_error:
			"Une erreur est survenue pendant la configuration des courses",
		sport_options_error:
			"Impossible d'enregistrer les options. Veuillez vérifier que vous ne modifiez pas un tarif d'une option déjà souscrite",
		file_error: "Une erreur est survenue pendant la lecture du fichier",
		file_exist: "Ce fichier est déjà uploader",
		event_form_required: "Vous n'avez pas remplit tous les champs obligatoires",
		event_form_donation_required: "Le choix du don est obligatoire",
		event_invalid_dates:
			"Attention la date de fin est la même que celle du début",
		error_creation_segment:
			"Une erreur est survenue pendant l'enregistrement du segment",
		success_creation_segment: "Segment enregistré avec succès",
		success_deletion_segment: "Segment supprimé",
		error_deletion_segment:
			"Une erreur est survenue pendant la suppression du segment",
		error_creation_parcours:
			"Une erreur est survenue pendant l'enregistrement du parcours",
		success_creation_parcours: "Parcours enregistré avec succès",
		success_deletion_parcours: "Parcours supprimé",
		error_deletion_parcours:
			"Une erreur est survenue pendant la suppression du parcours",
		error_creation_etape:
			"Une erreur est survenue pendant l'enregistrement de l'étape",
		success_creation_etape: "Etape enregistrée avec succès",
		success_deletion_etape: "Etape supprimée",
		error_deletion_etape:
			"Une erreur est survenue pendant la suppression de l'étape",
		success_creating_price: "Tarif enregistré avec succès",
		error_creating_price:
			"Une erreur est survenue pendant la création du tarif",
		success_deleting_run: "Course supprimée avec succès",
		error_deleting_run:
			"Une erreur est survenue pendant la suppression de la course",
		error_deleting_run_subs:
			"Impossible de supprimer une course où sont encore présents des inscrits",
		success_deleting_price: "Tarif supprimé avec succès",
		error_deleting_price:
			"Une erreur est survenue pendant la suppression du tarif",
		success_updating_fields: "Formulaire d'inscription mis à jour",
		error_updating_fields:
			"Une erreur est survenue pendant la mise à jour du formulaire",
		event_does_not_exist:
			"L'évènement que vous souhaitez configurer n'existe pas",
		success_updating_contact: "Contact enregistré",
		error_updating_contact:
			"Une erreur est survenue pendant l'enregistrement du contact",
		success_updating_rib: "RIB enregistré",
		error_updating_rib:
			"Une erreur est survenue pendant l'enregistrement du RIB. Le responsable légal possède peut être déjà un compte.",
		success_deleting_contact: "Contact supprimé",
		success_deleting_rib: "RIB supprimé",
		error_deleting_contact:
			"Une erreur est survenue pendant la suppression du contact",
		error_deleting_rib: "Une erreur est survenue pendant la suppression du RIB",
		error_signup:
			"Une erreur est survenue. Cet email est peut être déjà utilisé.",
		success_signup: "Votre compte a bien été créé",
		success_change_orga: "Vous êtes désormais organisateur, reconnecter vous.",
		error_change_orga: "Une erreur est survenue.",
		error_contact_empty:
			"Veuillez renseigner la raison sociale, le nom et prénom du contact.",
		success_ask_validation: "Un email a été envoyé a l'administrateur",
		error_ask_validation:
			"Une erreur est survenue pendant la demande de validation",
		import_error: "Une erreur est survenue pendant l'import",
		success_publish_event: "Evènement publié",
		success_unpublish_event: "Evènement dépublié",
		error_unpublish_event: "Une erreur est survenue",
		cant_delete_stage:
			"Veuillez supprimer les détections liées à cette étape avant de la supprimer",
		reassignCategorySuccess:
			"La ré-assignation des catégories s'est effectué avec succès",
		reassignCategoryError:
			"Une erreur est survenue lors de la ré-assignation des catégories",
		orgaSuccess: "Vous êtes désormais organisateur",
		orgaError: "Une erreur est survenue",
		toast_raceChoice: "Veuillez choisir au moins une course dans la liste",
		genderChoice: "Veuillez choisir au moins un genre dans la liste",
		toast_statusChoice:
			"Veuillez choisir au moins un statut d'inscription dans la liste",
		typeChoice: "Veuillez choisir au moins un type d'inscription dans la liste",
		changeTimezoneError:
			"Une erreur est survenue lors du changement de la timezone.",
		saveCredentialError:
			"Une erreur est survenue pendant l'enregistrement de votre identifiant.",
		saveConfigRunSuccess: "Votre configuration a été enregistrée.",
		saveConfigRunError:
			"Une erreur est survenue pendant la configuration de votre course.",
		saveConfigPointSuccess: "Votre point de détection à été enregistré.",
		saveConfigPointError:
			"Une erreur est survenue pendant l'enregistrement du point.",
		deleteConfigPointSuccess: "Votre point de détection à été supprimé.",
		deleteConfigPointError:
			"Une erreur est survenue pendant la suppression du point.",
		deleteConfigPointError_1451:
			"Veuillez d'abord supprimer toutes les étapes contenant ce point de détection.",
		seeAllDetections: "Voir toutes les détections",
		saveCredentialSuccess: "Votre identifiant a été enregistré",
		delete_detections_choice: "Veuillez faire un choix.",
		no_delete: "Aucune détection n'a été supprimé",
		no_detections: "Aucune detection",
		no_times: "Aucun temps",
		export_error: "Une erreur est survenue pendant l'export",
		accept_document_success: "Le document a été accepté",
		accept_document_error: "Une erreur est survenue.",
		refuse_document_success:
			"Le document a été refusé et un mail a été transmis au coureur.",
		refuse_document_error: "Une erreur est survenue.",
		category_or_team: "Cat / Équipe",
		run: "Course",
		status: "Statut",
		options: "Options",
		runners: "Coureurs",
		starter: "Partant",
		surrended: "Abandon",
		disqualified: "Disqualifié",
		filter: "Filtrer",
		man: "Homme",
		woman: "Femme",
		other_gender: "Autre",
		your_runners: "Vos coureurs",
		refused_reason: "Raison du refus",
		accept_document: "Accepter",
		refuse_document: "Refuser",
		refuse_document_and_notice: "Refuser et Envoyer email",
		your_subscriptions: "Vos inscriptons",
		contact: "Coordonnées",
		edit_subscription: "Modifier l'inscription",
		add_subscription: "Ajouter une inscription",
		message: "Envoyer un message",
		document: "Gérer les documents",
		actions: "Action",
		waiting: "En attente",
		valid: "Valide",
		reimbursed: "Remboursement",
		documents_sent: "Document(s) envoyé(s)",
		close_documents: "Fermer",
		manage_documents: "Gestion des documents",
		personnal_informations: "Informations personnelles :",
		age: "Age",
		phone: "Téléphone",
		emergency_phone: "Téléphone d'urgence",
		mail: "Email",
		run_informations: "Informations de la course",
		bib_info: "Dossard",
		licence: "Licence",
		validated: "validé",
		by: "par",
		la: "la",
		certificat: "Certificat Médical",
		autorisation: "Auto. Parentale",
		validation_date: "Date de validation :",
		sub_team: "Team / Association / Entreprise",
		sub_options: "Options de l'inscrit :",
		reimburse: "Rembourser",
		validate_subscription: "Valider l'inscription",
		refuse_subscription: "Refuser l'inscription",
		refuse_reason: "Quelle est la raison du refus ?",
		see_message_history: "Voir l'historique des mails",
		messages_history: "Historique des mails",
		close_history: "Fermer l'historique",
		close_comments: "Fermer les commentaires",
		"inscription.refus.title": "Inscription Refusée",
		no_messages: "Aucun message transmis à cet inscrit",
		changeRace: "Changer d'épreuve",
		changeRaceModalTitle: "Modification de l'inscription pour : ",
		currentlyRegistredFor: "Actuellement inscrit pour la course ",
		newRace: "Nouvelle épreuve",
		cannotChangeRace: "Changement d'épreuve impossible",
		teammatesWith: "Avec",
		bibMessageChangeRun:
			"1 - Souhaitez-vous conserver les numéros de dossard ?",
		automaticBibChangeRun:
			"2 - Souhaitez-vous qu'on attribue automatiquement les nouveaux dossards en fonction du plan de numérotation de la nouvelle course ?",
		bibAlreadyExistInNewRace:
			"a déjà le numéro de dossard dans la course choisie. Un nouveau dossard sera automatiquement affecté au coureur si un plan de numérotation a été défini pour la course choisie.",
		noBibInNewRace:
			"ATTENTION : Le dossard actuel sera libéré. Une affectation manuelle du dossard sera nécessaire.",
		noBibAssignmentInNewRace:
			"ATTENTION : Le dossard actuel sera libéré car la nouvelle course ne comporte pas de plan de numérotation.",
		see_order: "Voir la commande",
		no_document: "Aucun document",
		detectors: "Détecteurs",
		verification: "Vérification ok",
		percentage: "Pourcentage",
		finishers: "Finishers",
		number_runners: "Coureurs",
		non_starters: "Non partant",
		start_the: "Départ le :",
		abandon: "Abandons",
		official_starters: "Partants officiels",
		manage_detections_title: "Détections",
		hours: "Heure de passage",
		restore_detection: "Restaurer",
		delete_detections: "Supprimer les détections",
		deleteSub: "Supprimer l'inscription",
		confirmDeleteSub: "Êtes vous sur de vouloir supprimer l'inscription de",
		choose_detectors: "Toutes les lignes",
		add_detection: "Ajouter une détection",
		edit_detection: "Modifier cette détection",
		date_format: "Format Date",
		chrono_format: "Format chrono",
		effective_start: "Départ effectif",
		races: "Toutes les courses",
		deleteAllDetections: "Voulez-vous supprimer toutes les détections ?",
		confirmStartSaveCredential:
			"Souhaitez vous enregistrer votre credential avant de lancer la prestation ?",
		deleteTitle: "Suppression des détections de la course",
		all: "Suppression de toutes les détections de l'évènement",
		upload_dag: "Importer un fichier DAG",
		insertion: "Insertion",
		estimation: "Estimation",
		current_time: "Durée",
		seconds: "secondes",
		detection_detail: "Detail des détections",
		already_exist: "Déjà existantes",
		inserted: "Insérées",
		runner_created: "Dont coureur créées",
		checkpoint_missing: "Checkpoint manquant",
		interpassage_ignored: "Ignorées dû au temps interpassage",
		search_runner: "Rechercher un coureur",
		search_event: "Rechercher un évènement",
		search_bib: "Rechercher un dossard",
		search_subscription: "Rechercher une inscription",
		autorisationParentale: "Autorisation Parentale",
		licence_already_sent: "Licence déjà envoyé",
		certificat_already_sent: "Certificat déjà envoyé",
		autorisation_already_sent: "Autorisation déjà envoyé",
		send_documents: "Envoyer les nouveaux documents",
		see_document: "Voir le document",
		download_document: "Télécharger",
		product_name: "Nom du produit",
		refund_price: "Prix TTC",
		refund_amount: "Montant a rembourser",
		subscription_fees: "Frais d'inscriptions",
		refund_reason: "Raison de votre remboursement",
		refund: "Rembourser",
		orga_sub_fees: "Frais d'inscription à votre charge ?",
		orga_refund_fees: "Frais de remboursement (1€ TTC) à votre charge ?",
		contact_doubt:
			"Merci de contacter le support en cas de doute sur vos manipulations",
		refund_summary: "Récapitulatif du remboursement",
		refund_close_summary: "Fermer le récapitulatif",
		refund_summary_start_text: "Vous allez rembourser ",
		refund_summary_end_text: " à ce coureur, souhaitez vous confirmer ?",
		confirm_refund: "Confirmer le remboursement",
		refund_paid: "Le coureur a payé",
		amount_to_refund: "Vous allez lui rembourser",
		refund_fees: "Frais de remboursement",
		refunded_amount: "Remboursement TTC",
		already_refunded: (amount: string, date: string) =>
			`Vous ne pouvez plus rembourser cette inscription car un remboursement de ${amount} € à déjà eu lieu le ${date}.`,
		refund_product: "Remboursement",
		choose_type: "Je veux organiser un événement",
		select: "Sélectionner",
		type_details: "Détails",
		sport_event_identity: "Présentation de l'évènement",
		sport_event_runs: "Configuration des courses",
		sport_event_options: "Configuration des options",
		go_back: "Retour",
		save_and_go_run: "Enregistrer et configurer les courses",
		save_and_go_options: "Configurer les options",
		save_and_finish: "Finir la configuration",
		event_name_label: "Nom de l'évènement",
		event_sport_label: "Sport",
		event_sport_option: "Selectionner un sport",
		event_start_date_label: "Date de début",
		event_end_date_label: "Date de fin",
		event_poster_label: "Image",
		event_banner_label: "Bannière",
		event_short_description_label: "Description courte",
		event_long_description_label: "Description longue",
		event_address_label: "Adresse",
		event_postal_code_label: "Code postal",
		event_city_label: "Ville",
		event_country_label: "Pays",
		event_name_error:
			"Le nom de votre évènement doit faire plus de 3 caractères",
		event_sport_error: "Vous devez choisir un sport",
		event_ffa_siffa_error:
			"Autorisation FFA manquante. Avez-vous bien lié votre évènement à DAG System sur CALORG ?",
		event_start_date_error:
			"La date ne peut précéder la date du jour ET doit correspondre avec la date saisie du SIFFA (si Code FFA))",
		event_end_date_error:
			"Vous devez choisir une date ultérieur à la date du jour",
		event_short_description_error:
			"La description courte doit être comprise entre 3 et 190 caractères",
		event_address_error: "Votre adresse doit faire plus de 3 caractères",
		event_postal_code_error:
			"Votre code postal doit faire plus de 3 caractères",
		event_city_error: "Votre ville doit faire plus de 3 caractères",
		event_country_error: "Vous devez choisir un pays",
		event_country_option: "Selectionner un pays",
		event_timezone_label: "Fuseau horaire",
		event_places_label: "Nombre de places disponibles",
		event_timezone_option: "Selectionner une fuseau horaire",
		event_rules_label: "Règlement de l'événement",
		event_run_name_label: "Nom de l'épreuve",
		event_run_date_label: "Date de l'épreuve",
		event_run_member_label: "Si par équipe, nombre d'équipiers",
		event_run_transfer_label: "Permettre le transfert d'inscription ?",
		event_run_details_label: "Informations supplémentaire",
		event_run_details:
			"Permet de donner des informations supplémentaire concernant la course (Retrait de dossard, etc.e)",
		event_run_number_members_label: "Nombre d'équipiers",
		event_run_places_label: "Nombre de places disponibles",
		event_run_start_date_label: "Date de début",
		event_run_end_date_label: "Date de fin",
		event_run_same_bib_label: "Même dossard pour l'équipe",
		event_run_fftri_code: "Code compétition FFTRI",
		event_run_fftri_distance: "Type de distance",
		event_run_licence_accepted: "Licences acceptées",
		event_run_licence_accepted_tooltip:
			"Type de licence accepté pour cette course",
		event_run_licence_accepted_all: "Toutes les licences",
		event_run_licence_accepted_comp: "Licences de compétition",
		event_run_same_bib_no: "Non",
		event_run_same_bib_yes: "Oui",
		event_run_make_it_visible:
			"Rendre visible la liste des inscrits sur chronometrage.com ?",
		event_run_make_it_visible_info:
			"Si vous cochez cette case, tous les utilisateurs pourront voir la liste des inscrits sur chronometrage.com",
		event_add_run: "Ajouter une course",
		event_delete_run: "Supprimer une course",
		event_undo_delete_run: "Ne pas supprimer la course",
		event_prices: "Tarifs",
		event_run: "Epreuve",
		event_run_designation_label: "Désignation",
		edit_run_title: "Edition d'une course",
		edit_tarif_title: "Edition d'un tarif",
		edit_contact_title: "Edition d'un contact",
		edit_rib_title: "Edition d'un RIB",
		event_run_age_control_label: "Type de limite d'age",
		event_run_age_control_option: "Pas de limite d'age",
		event_run_age_min_label: "Age minimum",
		event_run_age_max_label: "Age maximum",
		event_run_add_price: "Ajouter un tarif",
		event_add_contact: "Ajouter un contact",
		event_add_rib: "Ajouter un RIB",
		validate: "Valider",
		fees_inclued: "inclusif",
		fees_exclued: "exclusif",
		event_fees_inclued: "Frais inclusifs",
		event_fees_exclued: "Frais exclusifs",
		event_starting_date: "Début :",
		event_ending_date: "Fin :",
		event_age_disponibility: (min: number, max: number) =>
			`Disponible de ${min} ans à ${max} ans`,
		event_price_fee_inclued: (fees: number) =>
			`dont ${fees.toFixed(2)}€ de frais`,
		event_price_fee_exclued: (fees: number) => `+ ${fees.toFixed(2)}€ de frais`,
		event_run_price: "Prix",
		event_run_fees_type_label: "Type de frais",
		event_run_type_control_event: "Selon la date d'évènement",
		event_run_type_control_subscription: "Selon la date d'inscription",
		event_run_type_control_year_event: "Selon l'année d'évènement",
		event_run_free_run: "Course gratuite ?",
		event_no_free_run: "Non",
		event_free_run: "Oui",
		event_free_run_text: "Course gratuite",
		event_ffa_siffa_label: "Code compétition FFA",
		event_configure_sub_form: "Configurer le formulaire",
		event_edit_run: "Editer la course",
		event_run_form_title: "Configuration du formulaire d'inscription",
		event_tooltip_form: "Un formulaire est configuré pour ce tarif",
		event_field_nom_label: "Nom",
		event_field_prenom_label: "Prénom",
		event_field_sexe_label: "Genre",
		event_field_dateNaissance_label: "Date de naissance",
		event_field_mail_label: "Email",
		event_field_ville_label: "Ville",
		event_field_codePostal_label: "Code postal",
		event_field_pays_label: "Pays",
		event_field_nationalite_label: "Nationalité",
		event_field_telephone_label: "Téléphone",
		event_field_telephoneUrgence_label: "Téléphone d'urgence",
		event_field_numLicence_label: "N° de licence",
		infos_ffa_numlicence_optional_message:
			"Le coureur pourra renseigner son numéro N° de licence FFA",
		infos_ffa_numlicence_required_message:
			"Le coureur devra renseigner son numéro N° de licence FFA",
		infos_ffa_ppsandlicence_optional_message:
			"Le coureur pourra renseigner son N° PPS ou son N° de licence FFA",
		infos_ffa_ppsandlicence_required_message:
			"Le coureur devra renseigner son N° PPS ou son N° de licence FFA",
		addGpxFile: "Ajouter un gpx",
		gpxFile: "Fichier gpx",
		event_field_licence_label: "Licence",
		event_field_certificat_label: "Certificat médical",
		event_field_pps_label: "N° PPS OU N° de licence",
		event_field_adresse1_label: "Adresse",
		event_field_adresse2_label: "Complément d'adresse",
		event_field_club_label: "Club",
		event_field_team_label: "Team",
		event_field_ffa_label: "CNIL FFA",
		event_field_autorisationParentale_label: "Autorisation parentale",
		event_field_tiers_label: "Tiers",
		event_field_dechargeResponsabilite_label: "Décharge de responsabilité",
		group_fields_nominative: "Champs nominatifs",
		group_fields_FFA: "Validation FFA",
		group_fields_others: "Autres champs",
		enter_text: "Entrez votre description",
		enter_mail_content: "Écrivez le contenu de votre mail...",
		enter_mail_object: "Écrivez l'objet de votre mail...",
		sport_event_contacts: "Gestion des contacts",
		sport_event_ribs: "Gestion des RIBs",
		event_raisonSocial_label: "Raison sociale",
		event_nom_label: "Nom",
		event_prenom_label: "Prenom",
		event_adresse1_label: "Adresse",
		event_adresse2_label: "Complément d'adresse",
		event_ville_label: "Ville",
		event_codePostal_label: "Code postal",
		event_pays_label: "Pays",
		event_telephone1_label: "Téléphone",
		event_telephone2_label: "Téléphone secondaire",
		event_mail_label: "Email",
		event_siteWeb_label: "Site web",
		event_facebook_label: "Facebook",
		event_twitter_label: "Twitter",
		event_instagram_label: "Instagram",
		delete_tarif_title: "Voulez-vous vraiment supprimer le tarif",
		delete_run_title: "Voulez-vous vraiment supprimer la course",
		restore_deletes: "Restaurer les éléments supprimer",
		restore: "Annuler les modifications",
		delete_contact_title: "Voulez-vous vraiment supprimer le contact",
		create_step: "Ajouter une étape",
		create_route: "Ajouter une étape",
		step_name_label: "Nom de l'étape",
		event_distance_label: "Distance (en mètre)",
		sport_event_steps: "Configuration des étapes",
		sport_event_parcours: "Configuration des parcours",
		sport_event_segments: "Configuration des segments",
		parcours_name_label: "Nom du parcours",
		segment_name_label: "Nom du segment",
		parcours_file_label: "Fichier GPX",
		create_parcours: "Ajouter un parcours",
		update_parcours: "Modifier un parcours",
		create_segment: "Ajouter un segment",
		update_segment: "Modifier un segment",
		delete_step_title: "Voulez-vous vraiment supprimer l'étape",
		delete_segment_title: "Voulez-vous vraiment supprimer le segment",
		delete_parcours_title: "Voulez-vous vraiment supprimer le parcours",
		checkpoint_debut_label: "Checkpoint de début",
		checkpoint_fin_label: "Checkpoint de fin",
		parcours_active_label: "Parcours actif",
		active: "actif",
		event_fees: "Frais",
		dag_fees: "Part DAG TTC",
		subscription_income: "Votre revenu",
		subscription_fees_sup: "Votre part TTC",
		subscription_fees_total: "Frais d'inscription TTC",
		event_sport_price: "Prix de la course",
		event_fftri_default_distance: "Choisissez une distance",
		designation_label: "Désignation",
		unlimited: "Illimité",
		nomBanque_label: "Nom Banque",
		iban_label: "IBAN",
		bic_label: "BIC",
		inCharge_label: "Je suis le représentant légal",
		lastname_label: "Nom",
		firstname_label: "Prénom",
		birthdate_label: "Date de naissance",
		email_label: "Email",
		address_label: "Adresse",
		city_label: "Ville",
		postalCode_label: "Code postal",
		country_label: "Pays",
		phone_label: "Téléphone",
		sex_label: "Genre",
		genderHfull: "Homme",
		genderFfull: "Femme",
		genderXfull: "Autre",
		select_type_rib: "Selectioner un rôle",
		see_contract: "Voir contrat",
		add_general_option: "Ajouter une option globale",
		delete_stage_cannot_start_live:
			"ATTENTION : A défault de créer une autre étape, une fois supprimée vous ne pourrez plus faire de LIVE.",
		event_configuration: "Je veux organiser un évènement",
		event_identity: "Identité de l'évènement",
		event_runs: "Course",
		event_options: "Options",
		event_subscription: "Configuration des inscriptions",
		event_contacts: "Contacts",
		event_rib: "RIBs",
		subscriptions: "Inscriptions",
		live_results: "Live / Résultats",
		live_positions: "Positions des coureurs",
		manage_detections: "Gestion des détections",
		detection_dashboard: "Résumé des détections",
		manage_runners: "Gestion des coureurs",
		manage_live: "Gestion du live",
		manage_steps: "Configurations des étapes",
		manage_parcours: "Configurations des parcours",
		manage_segments: "Configurations des segments",
		bib_plan_exemple_1:
			"Liste = 1;2;8;10 (les dossards 1,2,8 et 10 ne seront pas pris en compte)",
		bib_plan_exemple_2:
			"Tranche = 5:10 (les dossards de 5 à 10 inclus (5,6,7,8,9 et 10) ne seront pas pris en compte)",
		bib_plan_exemple_3:
			"Combinaison 1 = 2:4;7:8 (les dossards 2,3,4,7 et 8 ne seront pas pris en compte)",
		bib_plan_exemple_4:
			"Combinaison 2 = 2;4:6;8 (les dossards 2,4,5,6, et 8 ne seront pas pris en compte)",
		event_name: "Permet d'identifier l'activité lors de l'inscription.",
		event_places:
			"Permet de limiter les inscriptions à un nombre défini de places (Ne rien entrer pour un nombre de places illimité).",
		event_start_date: "Permet de définir la date de début de l'activité.",
		event_end_date: "Permet de définir la date de fin de l'activité.",
		event_team_number:
			"Permet de définir combien d'inscrits composent une équipe (Ne rien entrer si l'activité n'est pas en équipe).",
		event_transfer:
			"Permet de définir si le transfert d'inscription est autorisé d'un inscrit vers un nouveau coureur (vous aurez la main et pourrez accepter/refuser les demandes individuellement).",
		event_price_name:
			"Permet de décrire le prix de la course (Exemple : Pré-vente, Enfant, Adulte).",
		event_age: "Permet de définir un âge limite d'inscription.",
		event_min_age: "Définit l'âge minimum requis afin de pouvoir s'inscrire.",
		event_max_age: "Définit l'âge maximum requis afin de pouvoir s'inscrire.",
		event_price_start_date:
			"Permet de définir la date de début de disponibilité du tarif. Les tarifs ne seront pas visibles avant cette date.",
		event_price_end_date:
			"Permet de définir la date de fin de disponibilité du tarif. Les tarifs ne seront plus visibles après cette date.",
		event_price_free:
			"Permet de définir une gratuité pour le tarif. Le participant ne paiera pas les frais, mais ils vous seront facturés.",
		event_price_price:
			"Définit le prix de l'inscription par coureur (des frais peuvent s'ajouter).",
		event_price_fees_type:
			"Permet de définir si les frais sont exclusifs (non compris dans le prix de la course) ou inclusifs (compris dans le prix de la course).",
		event_price_fees:
			"Frais prélevés par la plateforme. Ces frais sont au minimum égaux aux frais supplémentaires ajoutés par le commercialisateur.",
		event_price_fees_sup:
			"Frais supplémentaires appliqués par le commercialisateur.",
		event_price_fees_calc:
			"Frais de la plateforme + Frais supplémentaires du commercialisateur.",
		event_timezone: "A bien choisir en cas de live pour le classement",
		event_defined_tz: "Timezone définie pour l'évènement",
		default_race:
			"Permet de classer un dossard inconnu détecté lors du live dans une course par défault si pas de plan de numérotation défini",
		finances: "Flux financiers",
		finances_desc: "Visualiser les flux financiers d'un évènement",
		finances_resume: "Gestion des virements",
		finances_resume_desc:
			"Visualiser l'état des virements pour les évènements de la plateforme",
		placeholder_select: "Selectionner un évènement",
		placeholder_select_client: "Selectionner un évènement",
		placeholder_search: "Rechercher un évènement",
		search_client: "Rechercher un client",
		select_no_options: "Aucun évènement",
		report: "Rapport",
		time: "Temps",
		section: "Segment",
		filters: "Filtres",
		placeholder_select_gender: "Selectionner un sexe",
		placeholder_select_category: "Selectionner une catégorie",
		H: "Homme",
		F: "Femme",
		A: "Autre",
		export: "Exporter",
		export_format: "Format d'export",
		placeholder_select_format: "Selectionner un format",
		optional: "(facultatif)",
		warning_passage: (line: number, min: number, done: number) =>
			`Ligne ${line} : ${min} passage min, ${done} passage effectués`,
		inviteNewUser: "Nouvelle invitation",
		error403: "Erreur 403",
		contactOrga: "Contactez l'organisateur si vous avez besoin de cet accès",
		restrictedPage: "Cette page est restreinte",
		permissions: "Droits d'accès",
		roles: "Droits",
		search_permissions: "Rechercher un nom",
		invoice: "Facture",
		dag_share_ht: "Part Dag HT",
		orga_share_ht: "Part Orga HT",
		total_ht: "Total HT",
		total_ttc: "Total TTC",
		com_ht: "Commission HT",
		bill_com: "Virement Com",
		bill_orga: "Virement Orga",
		title: "Titre",
		quantity: "Quantité",
		unit_prix_ht: "P.U HT",
		unit_prix_ttc: "P.U TTC",
		nbSubs: "Inscrits",
		StartPeriode: "Debut de la période",
		EndPeriode: "Fin de la période",
		paymentDone: "Virement effectué",
		validatePayment: "Valider le paiement",
		toastPaymentSuccess: "Virement validé",
		toastPaymentError:
			"Une erreur est survenue pendant la validation du virement",
		downloadContractAskValidation: "Télécharger et demande la publication",
		contract_explanation:
			"Afin de finaliser la publication de votre événement, il est essentiel d'établir un contrat. En confirmant votre demande de publication, nous vous enverrons ce contrat par email, qui devra ensuite être complété et renvoyé à notre équipe : ",
		licences: "Licences",
		licences_desc: "Gérer les licences d'utilisation des clients DAG",
		clients: "Clients",
		clients_desc: "Gestion des clients de DAG",
		toast_invoice_error:
			"Une erreur est survenue. Vérifier que l'evenement est bien clôturé et le rib présent.",
		documents: "Documents",
		total_subs: "Nombre d'inscrits total",
		total_subs_online: "Nombre d'inscrits en ligne",
		total_subs_manual: "Nombre d'inscrits manuel",
		total_subs_imported: "Nombre d'inscrits importé",
		total_subs_in_place: "Nombre d'inscrits sur place",
		docMissing: "Documents manquants",
		manualCancel: "Annulation",
		signup_error_country: "Le pays est invalide",
		signup_error_city: "La ville est invalide",
		signup_error_postal_code: "Le code postal est invalide",
		signup_error_adresse: "L'adresse est invalide",
		sub_validation_message: "La raison est manquante",
		loginMissing: "Identifiants manquants",
		createNewEdition: "Créer une nouvelle édition",
		disableSubscriptions: "Désactivez les inscriptions",
		activateSubscriptions: "Activez les inscriptions",
		inputs_edition_not_complete: "Veuillez compléter tous les champs",
		error_new_edition:
			"Une erreur est survenue pendant la tentative de création d'un nouvelle edition",
		country_home: "Pays de résidence",
		mailUsed: "Ce mail est déjà utilisé",
		mailUsedTooltip:
			"Le mail que vous essayez d'utiliser est déjà utilisé par quelqu'un d'autre. Veuillez saisir un email différent.",
		succes_stopsubscription_event: "Les inscriptions ont été déssactivés",
		error_stopsubscription_event:
			"Une erreur est survenue pendant la désactivations des inscriptions",
		results: "resultat",
		displayOrder: "Ordre d'affichage",
		moveAbove: "Remonter",
		moveBelow: "Descendre",
		moveTop: "Déplacer tout en haut",
		moveBottom: "Déplacer tout en bas",
		cannotDeleteRunWithSubscription:
			"Vous ne pouvez pas supprimer une course avec des inscrits",
		ShouldBeNotSameDate: "Les dates ne doivent pas être identiques",
		toastSuccessAddingRib: "Le rib a été ajouté",
		toastErrorAddingRib: "Une erreur est survenue pendant l'ajout du rib",
		placeholder_select_rib: "Selectionner un rib",
		processed: "Traité(s)",
		earnings: "Collecte",
		chartTitleSubs: "Inscrits / Course",
		chartTitleGenders: "Genres",
		chartTitleCases: "Dossiers",
		noOptions: "Aucune option disponible",
		noAdditionalInformation: "Aucune information supplémentaire",
		saveOk: "Sauvegarde effectuée",
		saveError: "Une erreur est survenue pendant la sauvegarde",
		shareLiveTrackingUrl: "Partagez le lien du suivi",
		cantPreview:
			"Le document est trop volumineux pour être prévisualisé dans un navigateur. Téléchargez-le pour le consulter",
		liveTypeOfRun: "Quel est le type de votre course ?",
		liveDateOfRun: "Quelle est la date de départ de la course ?",
		bibNotFound:
			"Ce dossard ne fait pas partie de votre évènement. Cliquer pour ajouter aux inscrits",
		between: "entre",
		and: "et",
		none: "Aucun",
		bySubscriber: " /coureur",

		chooseCalendarRunType: "Choisissez un type de course",
		chooseCalendarRunTypeSimple: "Course simple (Départ - Inter. - Arrivée)",
		chooseCalendarRunTypeLaps: "Course à tour",
		chooseCalendarRunTypeSimpleVirtual:
			"Course simple virtuel (Application sportive)",
		chooseCalendarRunTypeLapsVirtual:
			"Course à tour virtuel (Application sportive)",
		chooseCalendarRunTypeTriathlon: "Triathlon",
		calendarRunDistance: "Quelle distance fait la course ? (en mètres)",
		calendarRunLaps: "Combien de tours fait votre course ?",
		calendarRunDetectionPoints: "Vos points de détection",
		calendarRunSegments: "Vos étapes",
		calendarRunDetectionAddPoint: "Ajouter un point de détection",
		calendarRunAddSegment: "Ajouter une étape",
		calendarRunDetectionConfigurePoint: "Configurez votre point de détection",
		calendarRunConfigureSegment: "Configurez votre étape",
		calendarRunDetectionType: "Quel est le type de votre point ?",
		calendarRunDetectionChooseType: "Choisissez un type de point",
		finish: "Arrivée",
		arrived: "Arrivé",
		advancedParams: "Configuration avancée",
		interpassTime: "Temps interpassage",
		maxNumberPass: "Nombre de passages maximum",
		minNumberPass: "Nombre de passages minimum",
		startNumberPass: "Numéro de passage de début",
		endNumberPass: "Numéro de passage de fin",
		numberPass: "Numéro de passage",
		startDetectionPoint: "Entrée",
		endDetectionPoint: "Sortie",
		latitude: "Latitude",
		longitude: "Longitude",
		order: "Ordre",
		radius: "Radius",
		altitude: "Altitude",
		runFormTypeError: "Vous devez choisir un type de course",
		runFormDistanceError:
			"La distance de votre course doit être supérieure à 0",
		runFormLapsError: "Le nombre de tour doit être supérieur à 0",
		runFormPointNameError: "Vous devez donner un nom à votre point",
		runFormPointDistanceError: "La distance de votre point doit être définit",
		runFormPointLineError: "Une ligne doit être définie",
		runFormPointTypeError: "Vous devez choisir un type de point",
		runFormPointSameError: "Point de départ et de fin identiques",
		runFormSegmentNotComplete: "Informations de l'étape incompletes",
		runFormSegmentErrorHappened:
			"Une erreur est survenue lors de l'enregistrement de l'étape",
		calendarRunDeleteSegment: "Supprimer l'étape",
		runFormDeleteSegmentConfirmation:
			"Etes vous sûr de vouloir supprimer cette étape ?",
		runFormDeleteDetectionConfirmation:
			"Etes vous sûr de vouloir supprimer ce point de détection ?",
		runFormDeleteSegmentSuccess: "Étape supprimée avec succès",
		runFormUpdateSegmentSuccess: "Étape mise à jour avec succès",
		runFormDistanceType: "Choisissez la distance",
		whichRunConfigure: "Quelle course souhaitez-vous configurer ?",
		credentialDagLive: "Votre identifiant DAG Live",
		rankingHeaderRank: "Cl.",
		rankingHeaderName: "Nom",
		rankingHeaderBib: "Doss.",
		rankingHeaderLastPassage: "Dernier passage",
		rankingHeaderDetections: "Détections",
		rankingHeaderTime: "Temps",
		rankingHeaderCategoryRank: "Cl. Cat.",
		rankingHeaderSexRank: "Cl. Sexe",
		rankingHeaderRun: "Course",
		rankingHeaderTeam: "Equipe",
		rankingHeaderActions: "Actions",
		editRunnerTooltip:
			"Attention, mettre à jour le coureur affectera toutes ses autres inscriptions",
		editRunnerRunTooltip:
			"La mise à jour des informations de course affectera le classement",
		selectRunPlaceholder: "Selectionner une course",
		chooseTeam: "Choisissez une équipe",
		createTeam: "Créez une équipe",
		resetOnRunner: "Recaler sur coureur",
		bibTime: "Temps du dossard",
		manageDetections: "Gérer les détections",
		totalPriceOfRun: "Prix total de la course (hors frais) :",
		invalidateSub: "Rendre l'inscription invalide ?",
		errorVerifyBibAlreadyUse:
			"Une erreur est survenue. Vérifier que le dossard n'est pas déjà utilisé.",
		addDetection: "Ajouter une détection",
		setRunnerSurrended: "Passer le coureur en abandon",
		setRunnerDisqualified: "Passer le coureur en disqualifié",
		setRunnerStarting: "Passer le coureur en partant",
		setRunnerNotStarting: "Passer le coureur en non partant",
		updateRunnerStatusSurrended:
			"Mettre à jour le statut du coureur en abandon ?",
		updateRunnerStatusDisqualified:
			"Mettre à jour le statut du coureur en disqualifié ?",
		updateRunnerStatusStarting:
			"Mettre à jour le statut du coureur en partant ?",
		updateRunnerStatusNotStarting:
			"Mettre à jour le statut du coureur en non partant ?",
		notStarting: "Non partant",
		ranked: "Classé",

		// PatchNotes
		patchNotes: "Notes de mise à jour",
		patchAddNotes: "Ajout des notes de mise à jour",
		patchAddJetcodes: "Mise à disposition des Jetcodes pour un événement",
		patchAddRoles: "Gestion des droits d'accès des utilisateurs",
		patchCorrectDocuments: "Correction de la visualisation des documents",
		patchFixInvoiceErrorMessage:
			"Nouveau message d'erreur concernant les factures",
		patchChangeDashboardLinks:
			"Changement des liens pour les cartes évènements dans le Tableau de bord",
		patchUpdateSendOneMail: "Mise a jour de l'envoie du mail a un coureur",
		patchMailHistory:
			"Visualisation de l'historique des mails envoyé à un coureur",
		patchResponsive: "Responsive et ergonomie améliorer sur mobile",
		patchHoursPrices:
			"Correction de la mise à jour de l'heure quand on souhaite mettre à jour un tarif",
		patchRedirectionToValidate:
			"Mise a jour de la redirection au clique sur X à valider",
		patchTraduction: "Traductions mise à jour",
		patchEventImages: "Amélioration de l'affichage des images",
		patchCreateEdition: "Ajout de la création d'édition",
		patchPictoDocs: "Ajout de pictogramme pour les documents",
		patchForgetPassword: "Ajout d'une fonctionnalités d'oublie de mot de passe",
		patchAddPdfExport: "Ajout de la possibilité d'exporter en PDF les inscrits",
		patchAddFilterDocuments:
			"Ajout d'un filter pour les inscriptions avec documents en attente",
		onlyWithDocStart: "Afficher seulement les inscriptions avec",
		onlyWithDocStrong: " documents en attente",
		patchReasignBibsAndKeepThem:
			"Mise à jour du comportement de réasignation des dossards avec possibilité de garder les dossards déjà attribué",
		patchAddRegistration: "Amélioration de l'ajout d'inscription",
		patchGenderX: "Affichage du genre X",
		patchRegistrations: "Amélioration de la gestion des inscriptions",
		patchSwitchSubscriptionStatus:
			"Il est désormais possible d'activer ou désactivés les inscriptions a un évènement",
		patchUpdateAdminStatistics:
			"Mise a jour de l'interface d'administration concernant les tableaux de données",
		patchAddRibSelector:
			"Ajout d'une fonctionnalité d'ajout rapide de RIB à partir d'une liste des RIB déjà utilisé par le passé",
		patchAddResults: "Ajout de la fonctionnalité d'import de résultats",
		patchDocumentPreview: "Améliore la prévisualisation des documents",
		patchEditOption: "Permet de configurer les options des inscrits",
		patchUX: "Amélioration de l'expérience utilisateur",
		patchPDFExportRaces:
			"Ajout de la séléction des courses lors de l'export PDF",

		deleteGpxFileConfirmation:
			"Etes vous sûr de vouloir supprimer ce fichier ?",
		deletedFile: "Fichier supprimé !",
		selectRunMail: "Selectionnez une ou plusieurs courses",
		detectionPointName: "Nom du point de détection",
		detectionLigne: "Ligne",
		verifyPrestation:
			"Vérifiez que vous avez une prestation en cours et que votre configuration DAG live est correcte.",
		verifyCredential:
			"Une erreur est survenue. Vérifiez votre identifiant DAG Live.",
		noPrestationStarted:
			"Démarrez une première fois la prestation pour afficher le classement.",
		missing: "manquant",
		ffa_error_generic: "Licence non validée",
		ffa_error_001: "Erreur sur le format de la date.(1)",
		ffa_error_002: "Informations fournies inssufisantes.(2)",
		ffa_error_003: "Numéro de licence introuvable.(3)",
		ffa_error_004: "Numéro de TP introuvable.(4)",
		ffa_error_005: "Numéro de CF introuvable.(5)",
		ffa_error_006:
			"La relation (Licence/TP/PPS) ne sera plus valide au moment de la compétition.(6)",
		ffa_error_007:
			"Identité différente: mal orthographié ou couple (Relation / Identité) faux.(7)",
		ffa_error_008: "Identité introuvable.(8)",
		ffa_error_009: "Réponse impossible en raison d'homonymie.(9)",
		ffa_error_010:
			"Service FFA invalide, contactez directement l'organisateur.(10)",
		ffa_error_011: "Vous n'êtes pas authorisé à utiliser ce service.(11)",
		ffa_error_012: "Le service est bloqué, contactez la FFA(12)",
		ffa_error_013: "Erreur sur le format de la date de la compétition.(13)",
		ffa_error_014:
			"La date de la compétition ne correspond pas à la date saisie dans le SIFFA.(14)",
		ffa_error_015: "Numéro de PPS introuvable.(15)",
		ffa_error_016: "Le nom ne correspond pas",
		ffa_error_017: "Le prénom ne correspond pas",
		ffa_error_018: "Le genre ne correspond pas",
		ffa_error_019: "La date de naissance ne correspond pas",
		SUB: "INS",
		ARR: "ARR",
		DNS: "NP",
		DNF: "AB",
		DQ: "DQ",
		sport_identity_error_name_taken: "Le nom de l'évènement est déjà pris",
		add_licence: "Ajouter une licence",
		udc_licence_remaining: "Licence UDC restante",
		total_key_credit: "Total crédits encodage",
		donation_required: "Saisie du don obligatoire",
		donation_minimum_amount: "Montant minimum",
		additional_information: "Informations complémentaires",
		shop_licence: "Licences",
		shop_material: "Equipements",
		shop_chip: "Puces",
		global_search: "Recherche",
		global_search_desc:
			"Recherchez des personnes, des clients, des commandes...",
		quickstart: "Démarrage rapide",
		features: "Fonctionnalités",
		shop: "Boutique",
		reset: "Reinitialiser",
		persons: "Personnes",
		commands: "Commandes",
		search_results: "Résultats de la recherche",
		account_type: "Type de compte",
		registration: "Inscription",
		ttc_price: "Prix TTC",
		command_date: "Date de la commande",
		payer: "Payeur",
		donation_list: "Liste des dons reçus",
		no_run_with_donation:
			"Aucune course n'est configurée avec un DON pour une association",
		donation_value: "Valeur du don",
		no_donation_for_association: "Aucun don trouvé pour cette association",
		donation_campain: "Campagne de dons",
		campain_email: "EMAIL DE L'ASSOCIATION",
		campain_objective: "OBJECTIF DE LA CAMPAGNE DE DONS",
		campain_minimum: "VALEUR DU MONTANT MINIMUM",
		campain_default: "VALEUR DU MONTANT PAR DEFAUT",
		pps_number: "Numéro Prévention Parcours Santé FFA",
		all_runs: "Toutes les courses",
		all_runners: "Tous les coureurs",
		all_valid_subsriptions: "Tous les dossiers valides",
		all_invalid_subsriptions: "Tous les dossiers invalides",
		all_incomplete_subsriptions: "Tous les dossiers incomplets",
		active_users: "Utilisateurs actifs",
		tracking_url: "URL de suivi",
		document_title: "Titre du document",
		ranking: "Classement",
		subscription_history_by_run: "Historique des inscriptions par course",

		rights_add_subscription: "Ajout d'inscription",
		rights_add_subscription_infos: "Permet d'ajouter un inscrit sur place",
		rights_import_subscriptions: "Import d'inscriptions",
		rights_import_subscriptions_infos: "Au format Excel",
		rights_export_subscriptions: "Export d'inscriptions",
		rights_export_subscriptions_infos: "Au format Excel,CSV,PDF ou GmDAG",
		rights_validate_documents: "Validation des documents",
		rights_validate_documents_infos:
			"Validation des certificats médicaux et autorisations parentales",
		rights_send_mails: "Communication avec les inscrits (mails)",

		rights_subscription_live: "Droits d'inscriptions et gestion du live",
		rights_run_config: "Configuration des courses",
		rights_rib_config: "Modification du RIB",
		rights_contact_config: "Modification du Contact",

		rights_runner_management: "Gestion des coureurs",
		rights_runner_management_infos:
			"Accès à la liste, aux temps de passages etc...",
		rights_dossard_assign: "Assignation des dossards",
		rights_dossard_assign_infos: "Permet de changer le dossard d'un coureur",
		live_chrono: "Live/Chronometrage",
		passage_points: "Points de passage",
		times: "Temps",
		detections: "Detections",
		general_ranking: "Cl. Gen.",
		sex_ranking: "Cl. Sex.",
		cat_ranking: "Cl. Cat.",
		minAgeRequired: (minAge: number) =>
			`L'âge minimum requis est de ${minAge} ans.`,
		maxAgeRequired: (maxAge: number) =>
			`L'âge maximum requis est de ${maxAge} ans.`,
		yearBetweenRequired: (minAge: number, maxAge: number) =>
			`L'année de naissance doit être comprise entre ${minAge} et ${maxAge} inclus.`,
		ageBetweenRequired: (minAge: number, maxAge: number) =>
			`Vous devez avoir au moins ${minAge} ans ou au plus ${maxAge} ans au moment de l'inscription.`,
		activities: "Activités",
		not_all_position_display:
			"Le nombre de positions a été ajusté, seulement 500 positions seront affichées",
		success_time_saved: "Le temps a été enregistré",
		error_time_saved:
			"Une erreur est survenue pendant l'enregistrement du temps",
		display_gpx: "Rendre visible le tracés GPX ?",
		error_create_licence: "Erreur lors de la création de la licence",
		success_create_licence: "La licence a été créée avec succès",
		error_create_encode_key: "Erreur lors de la création de la clé de codage",
		success_create_encode_key: "La clé de codage a été créée avec succès",
		live_ranking: "Configuration des classements personnalisés",
		add_ranking: "Ajouter un classement",
		deselect: "Désélectionner",
		ranking_name: "Nom du classement",
		available_filters: "Filtres disponibles",
		ranking_filters_explanation:
			"Permet de définir les différents filtres disponibles pour le classement",
		toast_error_save_ranking_config:
			"Erreur lors de la sauvegarde du classement",
		toast_success_save_ranking_config: "Classement sauvegardé",
		toast_empty_ranking_config:
			"Le nom du classement et les filtres ne peuvent pas être vides",
		sure_delete_ranking_list: (name: string) =>
			`Êtes-vous sûr de vouloir supprimer "${name}" ?`,
		success_delete_ranking_list: "Classement supprimé",
		error_delete_ranking_list: "Erreur lors de la suppression du classement",
		custom_rankings: "Classement personnalisés",
		care_choice_unavaible:
			"Attention, ces choix ne seront plus disponible pour le filtres des classements.",
		list_name_required:
			"Le nom de la liste est obligatoire et doit être unique",
		ranking_title: "Classement",
		ranking_all_choices: "Tous/Toutes",
		ranking_all_races: "Toutes les courses",
		ranking_export_experimental: "(expérimental)",
		ranking_export_info1:
			"Exporte les résultats tels qu'ils apparaissent à votre écran au format Excel.",
		ranking_export_info2:
			"Les colonnes sont les mêmes que celles affichées sur la page de gestion des coureurs.",
		ranking_export_pdf_newline: "Saut de page au changement de sexe :",
		ranking_export_pdf_unranked: "Afficher les non classés :",
		ranking_export_pdf_all_races: "Toutes les courses",
		price_dates_archived: "Dates archivées"
	},

	/* ↓↓↓--ENGLISH VERSION--↓↓↓ */
	en: {
		delete_old_detections:
			"Do you want to keep the detections from the last performance?",
		select_race: "Please select a race",
		select_club: "Club selection",
		select_team: "Team selection",
		select_noClub: "No club available",
		select_noTeam: "No team available",
		select_gender: "Please choose the gender",
		select_bib: "Please choose a bib number",
		select_country: "Please choose a country",
		select_countrySearch: "Search...",
		select_countryNoResult: "No results",
		published: "Published",
		unpublished: "Not published",
		edit: "Edit",
		registered: "registered",
		toValidate: "to validate",
		ofTurnover: "of turnover",
		publishEvent: "Publish Event",
		unpublishEvent: "Unpublish Event",
		publishEventWarning:
			"Warning, you are about to publish this event, which means it will be visible to all Chronometrage.com users. Are you sure you want to continue?",
		unpublishEventWarning:
			"Warning, you are about to unpublish this event, which means it will no longer be visible to Chronometrage.com users. Are you sure you want to continue?",
		editEvent: "Edit Event",
		editRunner: "Edit Runner",
		handleDetection: "Manage detections",
		eventName: "Event Name",
		category: "Category",
		date: "Date",
		description: "Description",
		poster: "Poster",
		ffaFFTRI: "FFA / FFTRI",
		ffa: "FFA",
		fftri: "FFTRI",
		location: "Location",
		rulesPDF: "Rules PDF",
		website: "Website",
		file: "File",
		rib: "RIB",
		addRunner: "Add Runner",
		addRegistration: "Add Registration",
		sendEmailTitle: "Compose the email for registered participants",
		sendOneEmailTitle: "Compose the email for the participant",
		insight: "Email preview",
		config_title: "Management",
		runs_title: "Races",
		update_presentation: "Presentation",
		update_runs: "Races",
		update_options: "Options",
		update_contacts: "Contacts",
		update_sponsors: "Sponsors",
		update_ribs: "RIBs",
		configure_steps: "Steps",
		configure_live: "Live tracking",
		configure_results: "Send results",
		sync_folomi_error: "There are more registrations than runners.",
		sync_folomi_action: "Click to synchronize",
		sync_folomi_button: "Synchronize runners",
		sync_folomi_race_subscribed: "registered but only",
		sync_folomi_race_detected: "detected runners",
		ask_validation: "Request publication",
		unpublish: "Unpublish event",
		validation_asked: "Publication requested",
		preview: "Preview",
		preview_invoice: "Invoice preview",
		edit_invoice: "Invoice editing",
		commissions: "Commission Information",
		donations: "Donations",
		invoice_title: "Billing",
		option: "Option",
		configure_options: "Configure options",
		configure_lists: "Custom fields",
		lists: "Fields configuration",
		add_list: "Add a field",
		edit_list: "Edit a field",
		delete_list: "Delete a list",
		sure_delete_list: "Are you sure you want to delete",
		add_list_choice: "Add choices",
		empty_list_text: "You can't import an empty list",
		list_choice_name_too_short: "The choice name is too short",
		add_global_option: "Add a global option",
		lists_desc: "These fields will be available in the registration form",
		lists_text_desc:
			"You can paste a text separated by semicolons ( ; ) or line breaks",
		list_name_too_short: "The field name is too short",
		list_available_choices: "Available choices",
		import_list: "Import this list",
		or: "or",
		add_price: "Add a price",
		add_option: "Add an option",
		add_product: "Add a product",
		min_quantity: "Minimum quantity",
		max_quantity: "Maximum quantity",
		designation: "Designation",
		option_type: "DESIRED FIELD TYPE",
		is_required: "DO YOU WANT TO MAKE THE OPTION MANDATORY?",
		product: "Product",
		price: "Price",
		type_dropdown_description:
			"List of different products (Example: T-shirt size, S, M, L)",
		type_check_description: "Checkbox (Example: Bus, Meal)",
		type_quantity_description:
			"Quantity request for a product (Example: Number of runner meals)",
		delete_title: "Deletion",
		delete_message: "Are you sure you want to delete",
		price_start_date: "START DATE AND TIME OF PRICE",
		price_end_date: "END DATE AND TIME OF PRICE",
		price_start_date_donation: "START DATE AND TIME OF DONATION",
		price_end_date_donation: "END DATE AND TIME OF DONATION",
		warning_no_price:
			"At least one price (even free) must exist for this race to add an option.",
		warning_delete: "(The operation will take effect after saving)",
		error_qty_min_0: "Quantities must be greater than 0",
		error_qty_min_max:
			"The minimum quantity must be less than the maximum quantity",
		error_too_small: "(Too small)",
		default: "Default",
		prestation: "Launch the service",
		loading: "Loading",
		importResults: "Import results",
		set: "Set",
		notSet: "Not set",
		filesToBeChecked: "files to be checked",
		completedAndValidatedFiles: "completed and validated files",
		viaOptions: "via options",
		confirm: "Confirm",
		cancel: "Cancel",
		interrupt: "Interrupt",
		previous: "Previous",
		next: "Next",
		save: "Save",
		search: "Search",
		download: "Download",
		allM: "All",
		allF: "All",
		delete: "Delete",
		inTeam: " - In team",
		send: "Send",
		create_event: "Create an event",
		required: "Required",
		discard_changes: "Discard changes?",
		preview_off: "Close preview",
		error_boundary_title: "An error has occurred.",
		error_boundary_support:
			"If the problem persists, contact support and provide the following information:",
		back: "Back",
		sendMailAllSubscribers: "Send collective email",
		sendMail: "Send email",
		sendMailError: "An error occurred while sending the email",
		resetExpired: "Reset expired. Ask for a new reset",
		eventSearch: "Search for an event",
		number_bib: "Bib number",
		number_line: "Line number",
		time_detection: "Passing time",
		number_line_short: "Line",
		whatIsJetcode: "What is a jetcode?",
		jetcodeExplanation:
			"It is a tool that allows you to integrate the content of another page (registration forms & online payment, remaining bib counters, countdown, chronometrage.com logo) directly on your website.",
		howIntegrateJetcodeToMyWebsite:
			"How to integrate a jetcode into my website?",
		jetcodeIntegrationExplanation:
			"Simply copy the provided codes of the desired jetcodes (include my registrations and chronometrage.com logo) and paste them on a page of your website.",
		integrateSubscriptionsForm: "Integrate the registration form",
		integrateSubscirbersList: "Integrate the list of registrants",
		integrateOnlineSubscriptionLogo: "Integrate the 'online registration' logo",
		engineCodeLabel: "Engine code to be copied once in the header of your site",
		basketCodeLabel: "Basket code to be copied once on each page of your site",
		runCodeLabel: "Run specific code",
		french: "French",
		english: "English",
		copy: "Copy",
		copyDone: "Copied!",
		insertionCodeLabel:
			"Basket insertion code, this code is to be inserted once per page",
		language: "Language",
		runSelectLabel: "Desired race for the jetcode",
		priceJetcodeCodeLabel: "Jetcode Pricing setup to copy into your site",
		theme: "Theme",
		setup: "Setup",
		seeTimer: "See timer",
		seeSeconds: "See seconds",
		seeBibs: "See bibs",
		seeBibsLeft: "See remaining bibs",
		codeToCopyToYourWebsite: "Code to copy to your website",
		downloadProcessLabel:
			"Download the procedure in PDF format to provide to your webmaster",
		listSetup: "List setup",
		selectARun: "Select a race",
		generalSettings: "General settings",
		dagLiveCredentials: "DAG Live Credentials",
		sensorName: "Label",
		distance: "Distance (m)",
		passageMin: "Min passages",
		passageMax: "Max passages",
		sensor: "Sensor",
		type: "Type",
		start: "Start",
		stop: "Stop performance",
		deleteDetections: "Delete definitively detections",
		deleteDetectionsSuccess: "Deletion completed",
		deleteDetectionsError: "Deletion failed",
		prestationError: "ERROR",
		sensorSettings: "Sensor settings",
		addGpx: "Add GPX",
		chooseSensorType: "Choose sensor type",
		startLine: "Start line",
		finishLine: "Finish line",
		checkpoint: "Checkpoint",
		addSensor: "Add a sensor",
		removeThisSensor: "Remove this sensor",
		handleRunners: "Runners",
		handleDetections: "Detections",
		dashboardDetections: "Summary",
		global_configuration: "Configuration",
		route_configuration: "Routes",
		delete_step: "Delete this step",
		edit_step: "Edit this step",
		delete_parcours: "Delete this route",
		edit_parcours: "Edit this route",
		delete_segment: "Delete this segment",
		edit_segment: "Edit this segment",
		step: "Step",
		parcours: "Route",
		segment: "Segment",
		configure_routes: "Configure routes",
		configure_segments: "Configure segments",
		intermediary: "Intermediary",
		start_line: "Start",
		end_line: "Finish",
		typeLine1: "Start",
		typeLine2: "Intermediary",
		typeLine3: "Finish",
		grid: "Grid layout",
		categoryReassignement: "Category reassignment",
		handleResults: "Results",
		timezone: "Which timezone do you want to keep?",
		no_run_selected: "No race selected",
		placeholder_select_run: "Select a race",
		placeholder_select_segment: "Select a segment",
		log_title: "Log in to your admin tool",
		forgotten_title: "Forgotten password ?",
		reset_password: "Reset password",
		back_to_home: "Back to homepage",
		passwords_not_match: "Passwords do not match",
		passsword_reset_success: "Your password has been reset",
		password_too_short: "Your password must be at least 6 characters long",
		send_forgotten_mail_success: "An email has been sent to you",
		email: "Email address",
		new_password: "New Password",
		new_password_confirm: "Confirm new Password",
		password: "Password",
		confirmPassword: "Confirm your password",
		submit: "Log In",
		social_reason: "Social reason",
		adresse: "Address",
		postal_code: "Postal code",
		city: "City",
		country: "Country",
		noAccount: "Don't have an account yet?",
		rememberMe: "Remember me",
		signup_button: "Create an account",
		signup: "I don't have an account yet",
		signup_title: "Sign up for the admin tool",
		already_account: "I already have an account",
		forgotten_password: "Forgotten password ?",
		lastname: "Last Name",
		firstname: "First Name",
		birthdate: "Date of Birth",
		sex: "Gender",
		acceptCGV: "I accept the conditions of use of the platform",
		signup_error_firstname: "Your first name must be more than 1 letter.",
		signup_error_lastname: "Your name must be more than 1 letter.",
		signup_error_birthdate:
			"Your date of birth must be valid and you must be between 13 and 100 years old.",
		signup_error_email: "Your email is invalid.",
		signup_error_password: "Your password must be at least 6 characters long.",
		signup_error_passwordConfirm: "Your passwords are not identical.",
		signup_error_CGU: "You must accept the T&Cs.",
		male: "Male",
		female: "Female",
		other: "Other",
		legalEntity: "Legal Entity",
		address: "Address",
		team: "Team",
		race: "Race",
		bib: "Bib",
		bibAvailable: "Available Bib",
		bibUnavailable: "Bib already used",
		invalid: "Invalid",
		bibInvalid: "Invalid Bib",
		runner: "Runner",
		zipCode: "Zip Code",
		birthDate: "Birth Date",
		genderH: "M",
		genderF: "F",
		genderX: "X",
		editGeneral: "General",
		editRace: "In race",
		nationality: "Nationality",
		countryPlaceholder: "Select a country",
		firstPhone: "Phone 1",
		secondPhone: "Phone 2",
		marketer: "Marketer",
		organizer: "Organizer",
		yes: "Yes",
		no: "No",
		bbanDelete: "Delete BBAN",
		bbanDeleteWarning:
			"Warning, you are about to delete this BBAN, this action is irreversible. Are you sure you want to continue?",
		addTeam: "Add a team",
		club: "Club",
		duplicateEmail: "This email address is already in use",
		alreadyInUse: "Already in use",
		editTimes: "Times",
		object: "Subject",
		mailBody: "Mail body",
		swapBibs: "Swap bibs",
		swapFrom: "From",
		swapTo: "To",
		nbDetections: "Detections",
		stageDepartureHour: "Official departure time",
		handicap: "Handicap",
		miniTime: "Minimum time on the stage",
		error404: "Error 404",
		pageDoesNotExist: "This page does not exist",
		iThinkYouAreLost: "I think you are lost",
		goBackHome: "Go back to homepage",
		dashboard: "Dashboard",
		registrations: "Registrations",
		event: "Event",
		liveResults: "Timing",
		menuResults: "Results",
		liveConfiguration: "Configuration du Live",
		statistics: "Statistics",
		admin: "Administration",
		jetcodes: "Jetcodes",
		toCome: "To come",
		today: "Today",
		past: "Past",
		myAccount: "My account",
		logout: "Logout",
		manageRunners: "Manage runners",
		detections_dashboard: "Detections dashboard",
		manage_dashboard: "Manage detections",
		registration_refund: "Registration refund",
		registration_order: "Registration order",
		cgu: "Terms of use",
		cgv: "General conditions of sale",
		manage_results: "Manage results",
		startOn: "Starts on",
		registeredContestants: "Registered runners",
		displayRegistrations: "Display registrations",
		completeSubscriptionsList: "Complete list of registrations",
		bibs: "Bibs",
		bibsSearch: "Search for a bib",
		firstnameSearch: "Search for a first name",
		lastnameSearch: "Search for a last name",
		statusSearch: "Search for a status",
		loadingRegisterForm: "Loading registration form",
		thanksToWait: "Thank you for waiting",
		noRace: "No race configured",
		teamOf: "TEAM of",
		invididual: "SOLO",
		importError: "Error during import",
		preimportWarning: "WARNING",
		importWarning: "WARNING",
		preimportError: "ERROR: THIS REGISTRATION WILL NOT BE IMPORTED...",
		preimportErrors: "ERROR: THESE REGISTRATIONS WILL NOT BE IMPORTED...",
		exportTitle: "Export registrations in",
		raceChoice: "Races",
		sexeChoice: "Gender",
		statusChoice: "Status",
		subscriptionType: "Subscription type",
		importTitle: "Import registrations",
		toImport: "To import a list of registrations, use",
		configureFromAnotherRace: "Configure from another race",
		maximumAge: "Maximum age required is",
		useThisModel: "this EXCEL file template",
		dragHere: "Drag the EXCEL file (.xlsx format) here...",
		dragHereOr:
			"Drag the EXCEL file (.xlsx format) here, or click to select it",
		fileFormatNotSupported: "File format not supported",
		existingSubs: "previously imported registrants.",
		replace: "Remove all and replace",
		donotupdate: "Add only new bibs",
		continue: "Continue import",
		entries: "additions on",
		import: "Import",
		nbImportedRunners: "imported runner(s)",
		pre: "Pre-",
		importing: "Importing...",
		existing: "already existing",
		adds: "additions on",
		add: "additions",
		close: "Close",
		whattodo: "What do you want to do?",
		line: "Line",
		invalidRace: "Invalid race",
		bibUsed: "Bib already used",
		missingRace: "Race not provided",
		missingBib: "Bib not provided",
		yourEvents: "Your events",
		yourRuns: "Your runs",
		bibsAllocation: "Bibs allocation",
		discountVouchers: "Discount vouchers",
		form: "Form",
		refunds: "Refunds",
		accessRights: "Access rights",
		bankBBAN: "BBAN",
		manageBibsAllocation: "Manage bibs allocation",
		manageEventDiscountVouchers: "Manage event discount vouchers",
		manageEventForm: "Manage event form",
		manageRefunds: "Manage refunds",
		manageAccessRights: "Manage access rights",
		manageYourBBAN: "Manage your BBAN",
		manageFFAFFTRILicenses: "Manage your FFA/FFTRI licenses",
		ribListLinkToThisEvent: "List of RIBs linked to this event",
		addBBAN: "Add a BBAN",
		name: "Name",
		bank: "Bank",
		ibanBban: "IBAN / BBAN",
		thereAreCurrently: "There are currently",
		registrantsForThe: "registrants for the race",
		doYouReallyWantToReimburseThe: "Do you really want to reimburse the",
		registrants: "registrants",
		chargeAdditionalFeesPerRegistrations:
			"Additional fees per registration (in €)",
		invoicingOnRunnerOrOrganizer: "Invoicing on the runner or the organizer",
		ifRegistrationFeeIncluded:
			"If the registration fee is included, covered by",
		reimbursementCostsCoveredBy: "Reimbursement costs covered by",
		makeTheRefund: "Make the refund",
		reason: "Reason",
		choose: "Choose...",
		bibPlan: "Bib numbering plan",
		bibPlanAffectation: "Way to assign bibs",
		minBib: "Minimum Bib",
		maxBib: "Maximum Bib",
		bibExclusion: "Excluded Bib",
		bibExlusionPlaceholder:
			"Example: \rList = 1;2;8;10 (bibs 1, 2, 8, and 10 will not be considered) \rRange = 5:10 (bibs from 5 to 10 inclusive (5, 6, 7, 8, 9, and 10) will not be considered) \rCombination 1 = 2:4;7:8 (bibs 2, 3, 4, 7, and 8 will not be considered) \rCombination 2 = 2;4:6;8 (bibs 2, 4, 5, 6, and 8 will not be considered)",
		reaffectAllbibs:
			"Reassign all bibs for the race by validating the selection",
		keepBibs: "Keep already assigned bibs",
		applyToPending: "Apply to Pending Registrations",
		orderAlphabetic: "Reassign bibs in alphabetical order of runners",
		list: "By bib list",
		range: "By range of bibs",
		filterByRun: "Filter by race",
		resolution: "Resolution",
		days: "Days",
		weeks: "Weeks",
		months: "Months",
		years: "Years",
		runnerAge: "ans",
		seeComments: "See comments",
		downloadInPdf: "Download in PDF",
		nbOfSubscribers: "Number of subscribers",
		maxInfMin: "Please choose a maximum value greater than the minimum value",
		errorBib: "An error occurred while updating the bibs",
		updatedBib: "Bib numbers have been updated",
		loginSuccess: "Login successful",
		loginError: "Login error",
		logoutSuccess: "Logout successful",
		logoutError: "Logout error",
		updateError: "Update encountered an issue",
		updateSuccess: "Update was successful",
		updateCredentialSuccess: "Update of credentials was successful",
		updateCredentialError: "Update of credentials encountered an issue",
		startPrestation: "The service has started",
		liveTimezone:
			"There is an inconsistency in the timezone configuration of your event.",
		stopPrestation: "The service has stopped",
		prestationStartError: "Error when starting the service",
		prestationStartErrorCredentiel:
			"Please configure your credential before starting the service",
		prestationStopError: "Error when stopping the service",
		updateCheckpointSuccess: "The detector recording was successful",
		updateCheckpointError: "Error during detector recording",
		deletionSuccess: "Deletion successful",
		deletionError: "An error occurred during deletion",
		insertSuccess: "Creation successful",
		insertError: "Error during creation",
		insertErrorDetection:
			"The detection could not be transmitted. Please check that you have a race in progress.",
		ppsError: "Invalid Licence or PPS (not validated by French Federation)",
		requiredRace: "Please choose a race",
		requiredBib: "Please choose a bib number",
		requiredBirthdate: "Please provide the date of birth",
		requiredGender: "Please provide the gender",
		requiredTeamName: "Please provide the team name",
		requiredTeam: "Please choose a team from the list or create a new one",
		requiredOneOrAllRunner: "Please provide one or all runners",
		emailSendedSuccess: "Emails sent successfully.",
		emailSendedError: "An error occurred while sending emails.",
		login_expired: "Your session has expired",
		newRaceUpdateSuccess: "Race change was successful",
		newRaceUpdateError: "An error occurred during race change",
		bibChangeRaceChoice: "Please make a choice regarding the bibs",
		refundSuccess: "Refund was successful",
		refundError: "Refund encountered an error",
		sport_identity_success: "Event registration successful",
		sport_runs_success: "Runs configured successfully",
		sport_options_success: "Options configured successfully",
		sport_identity_error: "An error occurred during event creation",
		sport_runs_error: "An error occurred during runs configuration",
		sport_options_error:
			"Unable to save the options. Please verify that you are not modifying a rate for an already subscribed option",
		file_error: "An error occurred while reading the file",
		file_exist: "This file is already uploaded",
		event_form_required: "You have not filled in all required fields",
		event_invalid_dates: "Warning: The end date is the same as the start date",
		error_creation_segment: "An error occurred during stage saved",
		success_creation_segment: "Stage saved successfully",
		success_deletion_segment: "Stage deleted",
		error_deletion_segment: "An error occurred during stage deletion",
		error_creation_parcours: "An error occurred during parcours recording",
		success_creation_parcours: "Parcours recorded successfully",
		success_deletion_parcours: "Parcours deleted",
		error_deletion_parcours: "An error occurred during parcours deletion",
		error_creation_etape: "An error occurred during étape recording",
		success_creation_etape: "Étape recorded successfully",
		success_deletion_etape: "Étape deleted",
		error_deletion_etape: "An error occurred during étape deletion",
		success_creating_price: "Price recorded successfully",
		error_creating_price: "An error occurred during price creation",
		success_deleting_run: "Race deleted successfully",
		error_deleting_run_subs: "You can't delete a run with subscriptions",
		error_deleting_run: "An error occurred during race deletion",
		success_deleting_price: "Price deleted successfully",
		error_deleting_price: "An error occurred during the deletion of the price",
		success_updating_fields: "Registration form updated",
		error_updating_fields: "An error occurred while updating the form",
		event_does_not_exist: "The event you wish to configure does not exist",
		success_updating_contact: "Contact registered",
		error_updating_contact: "An error occurred while registering the contact",
		success_updating_rib: "Bank account (RIB) registered",
		error_updating_rib:
			"An error occurred while registering the bank account (RIB). The legal representative may already have an account.",
		success_deleting_contact: "Contact deleted",
		success_deleting_rib: "Bank account (RIB) deleted",
		error_deleting_contact: "An error occurred while deleting the contact",
		error_deleting_rib:
			"An error occurred while deleting the bank account (RIB)",
		error_signup: "An error occurred. This email may already be in use.",
		success_signup: "Your account has been successfully created",
		success_change_orga: "You are now an organizer, please log in again",
		error_change_orga: "An error occurred.",
		error_contact_empty:
			"Please provide the company name, first name, and last name of the contact.",
		success_ask_validation: "An email has been sent to the administrator",
		error_ask_validation: "An error occurred while asking validation",
		import_error: "An error occurred during import",
		success_publish_event: "Event published",
		success_unpublish_event: "Event unpublished",
		error_unpublish_event: "An error occurred",
		cant_delete_stage:
			"Please delete the detections associated with this stage before deleting it",
		reassignCategorySuccess: "Categories successfully reassigned",
		reassignCategoryError: "An error occurred while reassigning categories",
		orgaSuccess: "You are now an organizer",
		orgaError: "An error occurred",
		toast_raceChoice: "Please select at least one race from the list",
		genderChoice: "Please select at least one gender from the list",
		toast_statusChoice:
			"Please select at least one registration status from the list",
		typeChoice: "Please select at least one registration type from the list",
		changeTimezoneError: "An error occurred during timezone change",
		delete_detections_choice: "Please make a choice",
		no_delete: "No detections have been deleted",
		no_detections: "No detections",
		no_times: "No times",
		export_error: "An error occurred during export",
		accept_document_success: "The document has been accepted",
		accept_document_error: "An error occurred",
		refuse_document_success:
			"The document has been refused, and an email has been sent to the runner",
		refuse_document_error: "An error occurred",
		category_or_team: "Category / Team",
		run: "Race",
		status: "Status",
		options: "Options",
		runners: "Runners",
		starter: "Starter",
		surrendered: "Surrendered",
		disqualified: "Disqualified",
		filter: "Filter",
		gender: "Gender",
		man: "Male",
		woman: "Female",
		speed: "Speed",
		gap: "Gap",
		passageTime: "PASSAGE TIME",
		minimmAge: "Minimum age required is",
		other_gender: "Other",
		your_runners: "Your runners",
		refused_reason: "Reason for refusal",
		accept_document: "Accept",
		refuse_document: "Refuse",
		refuse_document_and_notice: "Refuse et Send mail",
		your_subscriptions: "Your subscriptions",
		contact: "Contact",
		edit_subscription: "Edit subscription",
		add_subscription: "Add subscription",
		message: "Send a message",
		document: "Manage documents",
		actions: "Action",
		waiting: "Waiting",
		valid: "Valid",
		reimbursed: "Reimbursed",
		documents_sent: "Document(s) sent",
		close_documents: "Close",
		manage_documents: "Document management",
		personnal_informations: "Personal information:",
		confirmDeleteSub: "Are you sure you want to delete the registration from",
		age: "Age",
		phone: "Phone",
		emergency_phone: "Emergency Phone",
		mail: "Email",
		run_informations: "Race Information",
		bib_info: "Bib Number",
		licence: "License",
		validated: "validated",
		by: "by",
		la: "the",
		validated_by_fede: "Validated by ",
		certificat: "Medical certificate",
		autorisation: "Parental Authorization",
		validation_date: "Validation Date:",
		sub_team: "Team / Association / Company",
		sub_options: "Subscriber Options:",
		reimburse: "Reimburse",
		validate_subscription: "Validate Subscription",
		refuse_subscription: "Refuse Subscription",
		refuse_reason: "What is the reason for refusal?",
		see_message_history: "See Mails history",
		messages_history: "Mails history",
		close_history: "Close History",
		close_comments: "Close comments",
		"inscription.refus.title": "Subscription Refused",
		no_messages: "No messages sent to this subscriber",
		changeRace: "Change Race",
		changeRaceModalTitle: "Subscription Modification for:",
		currentlyRegistredFor: "Currently registered for the race",
		newRace: "New Race",
		cannotChangeRace: "Race change not possible",
		teammatesWith: "With",
		bibMessageChangeRun: "1 - Do you wish to keep the bib numbers?",
		automaticBibChangeRun:
			"2 - Do you want to automatically assign new bib numbers based on the numbering plan of the new race?",
		bibAlreadyExistInNewRace:
			"already has the bib number in the selected race. A new bib number will be automatically assigned to the runner if a numbering plan has been defined for the chosen race.",
		noBibInNewRace:
			"ATTENTION: The current bib number will be released. Manual assignment of the bib number will be required.",
		noBibAssignmentInNewRace:
			"ATTENTION: The current bib number will be released as the new race does not have a numbering plan.",
		see_order: "View Order",
		no_document: "No Document",
		detectors: "Detectors",
		verification: "Verification OK",
		percentage: "Percentage",
		finishers: "Finishers",
		number_runners: "Runners",
		non_starters: "Non-Starters",
		start_the: "Starts on:",
		abandon: "Abandonments",
		official_starters: "Official Starters",
		manage_detections_title: "Detections",
		hours: "Passing Time",
		restore_detection: "Restore",
		delete_detections: "Delete Detections",
		choose_detectors: "Choose a line",
		add_detection: "Add Detection",
		edit_detection: "Edit This Detection",
		runFormDeleteDetectionConfirmation: "Do you want to delete this detection?",
		date_format: "Date Format",
		chrono_format: "Chrono Format",
		effective_start: "Effective Start",
		races: "All Races",
		deleteAllDetections: "Do you want to delete all detections?",
		confirmStartSaveCredential:
			"Do you want to save your credential before starting the live?",
		deleteTitle: "Deleting Race Detections",
		all: "Delete all event detections",
		upload_dag: "Import DAG File",
		insertion: "Insertion",
		estimation: "Estimation",
		current_time: "Duration",
		seconds: "seconds",
		detection_detail: "Detection Details",
		already_exist: "Already Exist",
		inserted: "Inserted",
		runner_created: "Including Runner Created",
		checkpoint_missing: "Missing Checkpoint",
		interpassage_ignored: "Ignored Due to Interpassage Time",
		search_runner: "Search for a Runner",
		search_event: "Search for an Event",
		search_bib: "Search for a Bib Number",
		search_subscription: "Search for a Subscription",
		autorisationParentale: "Parental Authorization",
		licence_already_sent: "License Already Sent",
		certificat_already_sent: "Certificate Already Sent",
		autorisation_already_sent: "Authorization Already Sent",
		send_documents: "Send New Documents",
		see_document: "View Document",
		download_document: "Download",
		product_name: "Product Name",
		refund_price: "Price (TTC)",
		refund_amount: "Refund Amount",
		subscription_fees: "Subscription Fees",
		refund_reason: "Reason for Your Refund (Required)",
		refund: "Refund",
		deleteSub: "Delete subscription",
		orga_sub_fees: "Subscription Fees at Your Charge?",
		orga_refund_fees: "Refund Fees (1€ TTC) at Your Charge?",
		contact_doubt:
			"Please contact support if you have any doubts about your actions",
		refund_summary: "Refund Summary",
		refund_close_summary: "Close Summary",
		refund_summary_start_text: "You are about to refund ",
		refund_summary_end_text: " to this runner, do you wish to confirm?",
		confirm_refund: "Confirm Refund",
		refund_paid: "The runner has paid",
		amount_to_refund: "You are going to refund",
		refund_fees: "Refund Fees",
		refunded_amount: "Total Refund Amount",
		already_refunded: (amount: string, date: string) =>
			`You can no longer refund this subscription because a refund of ${amount} € already took place on ${date}.`,
		refund_product: "Refund",
		choose_type: "I want to organize an event",
		select: "Select",
		type_details: "Details",
		sport_event_identity: "Event Presentation",
		sport_event_runs: "Race Configuration",
		sport_event_options: "Option Configuration",
		go_back: "Back",
		save_and_go_run: "Save and Configure Races",
		save_and_go_options: "Configure Options",
		save_and_finish: "Finish Configuration",
		event_name_label: "Event Name",
		event_sport_label: "Sport",
		event_sport_option: "Select a sport",
		event_start_date_label: "Start Date",
		event_end_date_label: "End Date",
		event_poster_label: "Image",
		event_banner_label: "Banner",
		event_short_description_label: "Short Description",
		event_long_description_label: "Long Description",
		event_address_label: "Address",
		event_postal_code_label: "Postal Code",
		event_city_label: "City",
		event_country_label: "Country",
		event_name_error: "The name of your event must be longer than 3 characters",
		event_sport_error: "You must choose a sport",
		event_start_date_error:
			"The date cannot be before today's date (or must match the date entered in the SIFFA if FFA Code)",
		event_ffa_siffa_error:
			"Missing FFA authorization. Have you linked your event to DAG System on CALORG?",
		event_end_date_error: "You must choose a date later than today's date",
		event_short_description_error:
			"The short description must be between 3 and 190 characters",
		event_address_error: "Your address must be longer than 3 characters",
		event_postal_code_error:
			"Your postal code must be longer than 3 characters",
		event_city_error: "Your city must be more than 3 characters",
		event_country_error: "You must choose a country",
		event_country_option: "Select a country",
		event_timezone_label: "Timezone",
		event_places_label: "Available Spots",
		event_timezone_option: "Select a timezone",
		event_rules_label: "Event Rules",
		event_run_name_label: "Race Name",
		event_run_date_label: "Race Date",
		event_run_member_label: "If by team, number of teammates",
		event_run_transfer_label: "Allows transfer of registrations ?",
		event_run_details_label: "Additional informations",
		event_run_details: "If you want to give more informations about the run",
		event_run_number_members_label: "Number of Teammates",
		event_run_places_label: "Available Spots",
		event_run_start_date_label: "Start Date",
		event_run_end_date_label: "End Date",
		event_run_same_bib_label: "Same bib for the team",
		event_run_fftri_code: "FFTRI Competition Code",
		event_run_fftri_distance: "Distance Type",
		event_run_licence_accepted: "Accepted licenses",
		event_run_licence_accepted_tooltip: "License type accepted for this run",
		event_run_licence_accepted_all: "All licenses",
		event_run_licence_accepted_comp: "Competition licenses",
		event_run_same_bib_no: "No",
		event_run_same_bib_yes: "Yes",
		event_run_make_it_visible:
			"Make the list of runners visible on chronometrage.com",
		event_run_make_it_visible_info:
			"By checking this box, you authorize the publication of the list of registered runners on the site chronometrage.com",
		event_add_run: "Add Race",
		event_delete_run: "Delete Race",
		event_undo_delete_run: "Do Not Delete Race",
		event_prices: "Prices",
		event_run: "Race",
		event_run_designation_label: "Designation",
		edit_run_title: "Edit Race",
		edit_tarif_title: "Edit Price",
		edit_contact_title: "Edit Contact",
		edit_rib_title: "Edit Bank Account",
		deleteConfigPointError_1451:
			"Please delete all segments containing this detection point first.",
		event_run_age_control_label: "Age Limit Type",
		event_run_age_control_option: "No Age Limit",
		event_run_age_min_label: "Minimum Age",
		event_run_age_max_label: "Maximum Age",
		event_run_add_price: "Add Price",
		event_add_contact: "Add Contact",
		event_add_rib: "Add Bank Account",
		validate: "Validate",
		fees_inclued: "inclusive",
		fees_exclued: "exclusive",
		event_fees_inclued: "Inclusive Fees",
		event_fees_exclued: "Exclusive Fees",
		event_starting_date: "Start:",
		event_ending_date: "End:",
		event_age_disponibility: (min: number, max: number) =>
			`Available from ${min} years old to ${max} years old`,
		event_price_fee_inclued: (fees: number) =>
			`including ${fees.toFixed(2)}€ fees`,
		event_price_fee_exclued: (fees: number) => `+ ${fees.toFixed(2)}€ fees`,
		event_run_price: "Price",
		event_run_fees_type_label: "Fee Type",
		event_run_type_control_event: "According to event date",
		event_run_type_control_subscription: "According to registration date",
		event_run_type_control_year_event: "According to event year",
		event_run_free_run: "Free Race?",
		event_no_free_run: "No",
		event_free_run: "Yes",
		event_free_run_text: "Free Race",
		event_ffa_siffa_label: "FFA Competition Code",
		event_configure_sub_form: "Configure the form",
		event_edit_run: "Edit the race",
		event_run_form_title: "Registration Form Configuration",
		event_tooltip_form: "A form is configured for this rate",
		event_field_nom_label: "Last Name",
		event_field_prenom_label: "First Name",
		event_field_sexe_label: "Gender",
		event_field_dateNaissance_label: "Date of Birth",
		event_field_mail_label: "Email",
		event_field_ville_label: "City",
		event_field_codePostal_label: "Postal Code",
		event_field_pays_label: "Country",
		event_field_nationalite_label: "Nationality",
		event_field_telephone_label: "Telephone",
		event_field_telephoneUrgence_label: "Emergency Telephone",
		event_field_numLicence_label: "License Number",
		event_field_licence_label: "License",
		event_field_certificat_label: "Medical Certificate",
		event_field_pps_label: "PPS Number (FFA)",
		event_field_adresse1_label: "Address",
		event_field_adresse2_label: "Address Line 2",
		event_field_club_label: "Club",
		event_field_team_label: "Team",
		event_field_ffa_label: "FFA CNIL",
		event_field_autorisationParentale_label: "Parental Authorization",
		event_field_tiers_label: "Third Party",
		event_field_dechargeResponsabilite_label: "Disclaimer",
		group_fields_nominative: "Nominative fields",
		group_fields_FFA: "FFA fields",
		group_fields_others: "Other fields",
		infos_ffa_numlicence_optional_message:
			"The runner can enter his FFA license number",
		infos_ffa_numlicence_required_message:
			"The runner must enter his FFA license number",
		infos_ffa_ppsandlicence_optional_message:
			"The runner can enter his PPS or FFA license number",
		infos_ffa_ppsandlicence_required_message:
			"The runner must enter his PPS or FFA license number",
		enter_text: "Enter your description",
		enter_mail_content: "Write your email content...",
		enter_mail_object: "Write your email subject...",
		sport_event_contacts: "Contact Management",
		sport_event_ribs: "Bank Account Management",
		event_raisonSocial_label: "Legal Name",
		event_nom_label: "Last Name",
		event_prenom_label: "First Name",
		event_adresse1_label: "Address",
		event_adresse2_label: "Address Line 2",
		event_ville_label: "City",
		event_codePostal_label: "Postal Code",
		event_pays_label: "Country",
		country_home: "Country",
		event_telephone1_label: "Telephone",
		event_telephone2_label: "Secondary Telephone",
		event_mail_label: "Email",
		event_siteWeb_label: "Website",
		event_facebook_label: "Facebook",
		event_twitter_label: "Twitter",
		event_instagram_label: "Instagram",
		delete_tarif_title: "Do you really want to delete the rate",
		delete_run_title: "Do you really want to delete the race",
		restore_deletes: "Restore Deleted Items",
		restore: "Undo Changes",
		delete_contact_title: "Do you really want to delete the contact",
		create_step: "Add a Step",
		create_route: "Add a Step",
		step_name_label: "Step Name",
		event_distance_label: "Distance (in meters)",
		sport_event_steps: "Step Configuration",
		sport_event_parcours: "Route Configuration",
		sport_event_segments: "Stage Configuration",
		parcours_name_label: "Route Name",
		segment_name_label: "Stage Name",
		parcours_file_label: "GPX File",
		create_parcours: "Add a Route",
		update_parcours: "Edit a Route",
		create_segment: "Add a Stage",
		update_segment: "Edit a Stage",
		delete_step_title: "Do you really want to delete the step",
		delete_segment_title: "Do you really want to delete the stage",
		delete_parcours_title: "Do you really want to delete the route",
		checkpoint_debut_label: "Start Checkpoint",
		checkpoint_fin_label: "End Checkpoint",
		parcours_active_label: "Active Route",
		active: "active",
		event_fees: "Fees",
		dag_fees: "DAG Fee (VAT included)",
		subscription_income: "Your Income",
		subscription_fees_sup: "Your VAT included share",
		subscription_fees_total: "Total Registration Fees (VAT included)",
		event_sport_price: "Race Price",
		event_fftri_default_distance: "Choose a distance",
		designation_label: "Designation",
		unlimited: "Unlimited",
		nomBanque_label: "Bank Name",
		iban_label: "IBAN",
		bic_label: "BIC",
		inCharge_label: "I am the legal representative",
		lastname_label: "Last Name",
		firstname_label: "First Name",
		birthdate_label: "Date of Birth",
		email_label: "Email",
		address_label: "Address",
		city_label: "City",
		postalCode_label: "Postal Code",
		country_label: "Country",
		phone_label: "Phone",
		sex_label: "Gender",
		genderHfull: "Male",
		genderFfull: "Female",
		genderXfull: "Other",
		select_type_rib: "Select a role",
		see_contract: "See contract",
		add_general_option: "Add a global option",
		delete_stage_cannot_start_live:
			"WARNING: If you delete this stage without creating another one, you won't be able to go live.",
		event_configuration: "I want to organize an event",
		event_identity: "Event Identity",
		event_runs: "Races",
		event_options: "Options",
		event_subscription: "Registration Configuration",
		event_contacts: "Contacts",
		event_rib: "Bank Accounts",
		subscriptions: "Subscriptions",
		live_results: "Live / Results",
		live_positions: "Runners position",
		manage_detections: "Detection Management",
		detection_dashboard: "Detection Summary",
		manage_runners: "Runner Management",
		manage_live: "Live Management",
		manage_steps: "Step Configuration",
		manage_parcours: "Route Configuration",
		manage_segments: "Stage Configuration",
		bib_plan_exemple_1:
			"List = 1;2;8;10 (bib numbers 1, 2, 8, and 10 will not be considered)",
		bib_plan_exemple_2:
			"Range = 5:10 (bib numbers from 5 to 10 inclusive (5, 6, 7, 8, 9, and 10) will not be considered)",
		bib_plan_exemple_3:
			"Combination 1 = 2:4;7:8 (bib numbers 2, 3, 4, 7, and 8 will not be considered)",
		bib_plan_exemple_4:
			"Combination 2 = 2;4:6;8 (bib numbers 2, 4, 5, 6, and 8 will not be considered)",
		event_name: "Used to identify the activity during registration.",
		event_places:
			"Limits registrations to a defined number of spots (leave empty for unlimited spots).",
		event_start_date: "Defines the start date of the activity.",
		event_end_date: "Defines the end date of the activity.",
		event_team_number:
			"Defines how many registrants make up a team (leave empty if the activity is not team-based).",
		event_transfer:
			"Allows registrants to transfer their registration to another user.",
		event_price_name:
			"Describes the race price (e.g., Early Bird, Child, Adult).",
		event_age: "Defines an age limit for registration.",
		event_min_age: "Sets the minimum age required for registration.",
		event_max_age: "Sets the maximum age required for registration.",
		event_price_start_date:
			"Defines the start date when the rate becomes available. Rates won't be visible before this date.",
		event_price_end_date:
			"Defines the end date when the rate is no longer available. Rates won't be visible after this date.",
		event_price_free:
			"Indicates whether the rate is free. Participants won't pay fees, but they will be charged to you.",
		event_price_price: "Defines the race price (additional fees may apply).",
		event_price_fees_type:
			"Indicates whether fees are exclusive (not included in the race price) or inclusive (included in the race price).",
		event_price_fees:
			"Fees charged by the platform. These fees are at least equal to the additional fees added by the marketer.",
		event_price_fees_sup: "Additional fees applied by the marketer.",
		event_price_fees_calc: "Platform fees + Marketer's additional fees.",
		event_timezone: "Choose carefully for live ranking purposes.",
		event_defined_tz: "Timezone defined for the event",
		default_race:
			"Classify an unknown bib detected during live in a default race if no numbering plan is defined",
		finances: "Financial Flows",
		finances_desc: "View the financial flows of an event",
		finances_resume: "Transfers Management",
		finances_resume_desc: "View the status of transfers for platform events",
		placeholder_select: "Select an event",
		placeholder_search: "Search for an event",
		search_client: "Search for a client",
		select_no_options: "No events",
		report: "Report",
		time: "Time",
		section: "Stage",
		filters: "Filters",
		placeholder_select_gender: "Select a gender",
		placeholder_select_category: "Select a category",
		H: "Male",
		F: "Female",
		A: "Other",
		export: "Export",
		export_format: "Export Format",
		placeholder_select_format: "Select a format",
		optional: "(optional)",
		warning_passage: (line: number, min: number, done: number) =>
			`Line ${line}: ${min} min passage, ${done} passages completed`,
		inviteNewUser: "Invite new user",
		error403: "Error 403",
		contactOrga: "Contact the organizer if you need this access",
		event_form_donation_required: "The donation choise is required",
		restrictedPage: "Restricted page",
		permissions: "Permissions",
		roles: "Permissions",
		search_permissions: "Search for a name",
		invoice: "Invoice",
		dag_share_ht: "DAG Shares ex. tax",
		orga_share_ht: "Orga Shares ex. tax",
		total_ht: "Total ex. tax",
		total_ttc: "Total inc. tax",
		com_ht: "Commission ex. tax",
		bill_com: "Invoice State",
		bill_orga: "Bill Orga",
		title: "Title",
		quantity: "Quantity",
		unit_prix_ht: "P.U ex. tax",
		unit_prix_ttc: "P.U inc. tax",
		nbSubs: "Subscribers",
		StartPeriode: "Period start",
		EndPeriode: "Period end",
		paymentDone: "Payment done",
		validatePayment: "Validate payment",
		toastPaymentSuccess: "Transfer validated",
		toastPaymentError: "An error occurred during transfer validation",
		downloadContractAskValidation: "Download and ask validation",
		contract_explanation:
			"In order to finalize the publication of your event, it is essential to establish a contract. Upon confirming your request for publication, we will send you this contract via email, which must then be completed and returned to our team : ",
		licences: "Licences",
		licences_desc: "Manage DAG Clients licences",
		clients: "Clients",
		clients_desc: "DAG Client management",
		toast_invoice_error:
			"An error has occurred. Check that the event is closed and the rib is present.",
		documents: "Documents",
		total_subs: "Total number of subscriptions",
		total_subs_online: "Total number of online subscriptions",
		total_subs_manual: "Number of total manual subscriptions",
		total_subs_imported: "Number of subscriptions imported",
		total_subs_in_place: "Number of subscriptions on site",
		docMissing: "Documents missing",
		manualCancel: "Cancel",
		orga_title: "Become an organizer",
		signup_error_country: "Counrty is not valid",
		signup_error_city: "City is invalid",
		signup_error_postal_code: "Postal code is invalid",
		signup_error_adresse: "Adress is invalid",
		sub_validation_message: "Reason is missing",
		surrended: "Surrended",
		runEndDate: "End date",
		runMaxNbOfSlots: "Maximum number of places",
		runnerChart: "Runners' charter",
		yourParentalAuthorizationModel: "Your parental authorization model",
		addBban: "Add a RIB",
		editBban: "Edit a RIB",
		bbanType: "RIB type",
		bbanName: "RIB name",
		bankName: "Name of the bank",
		iban: "IBAN",
		bicSwift: "BIC/SWIFT",
		areYouThePersonInCharge: "Are you the legal guardian?",
		personInChargeInformations: "Information from the legal guardian",
		subOptions: "Options",
		subEdit: "Subscription edit",
		subscription: "Subscription",
		runName: "Name of the race",
		teamName: "Team name",
		runStartDate: "Date of departure",
		noClub: "No club",
		loginMissing: "Identifiants manquants",
		createNewEdition: "Create a new edition",
		disableSubscriptions: "Disable subscriptions",
		activateSubscriptions: "Activate subscriptions",
		inputs_edition_not_complete: "Please, fill all inputs",
		error_new_edition: "Error happened while trying to create new edition",
		mailUsed: "Email ignored. Already used.",
		mailUsedTooltip:
			"Check firstname and lastname. They are maybe spelled wrong",
		succes_stopsubscription_event: "Subscriptions disabled successfuly",
		error_stopsubscription_event:
			"An error has occured while disabling subscriptions",
		results: "result",
		displayOrder: "Display order",
		moveAbove: "Move up",
		moveBelow: "Move down",
		moveTop: "Move to top",
		moveBottom: "Move to bottom",
		cannotDeleteRunWithSubscription:
			"You cannot delete a run with subscriptions",
		addGpxFile: "Add GPX File",
		gpxFile: "GPX File",
		ShouldBeNotSameDate: "Dates should not be the same",
		toastSuccessAddingRib: "Rib added successfuly",
		toastErrorAddingRib: "An error has occured while adding rib",
		placeholder_select_rib: "Choose a rib",
		processed: "Processed",
		earnings: "Earnings",
		chartTitleSubs: "Subs / Race",
		chartTitleGenders: "Genders",
		chartTitleCases: "Cases",
		noOptions: "No option available",
		noAdditionalInformation: "No additional information",
		saveOk: "Save successful",
		saveError: "An error has occured while saving",
		shareLiveTrackingUrl: "Share the live tracking URL",
		cantPreview:
			"The preview is not available for this document (too big). Please download it.",
		liveTypeOfRun: "What type of run?",
		liveDateOfRun: "When is the start date of the race ?",
		bibNotFound:
			"This bib is not part of your event. Click to add to subscribers",
		between: "between",
		and: "and",
		none: "None",
		bySubscriber: " /runner",

		chooseCalendarRunType: "Choose a type of race",
		chooseCalendarRunTypeSimple: "Single race (Start - Inter. - Finish)",
		chooseCalendarRunTypeLaps: "Lap race",
		chooseCalendarRunTypeSimpleVirtual:
			"Simple virtual race (Sports application)",
		chooseCalendarRunTypeLapsVirtual: "Virtual lap race (Sports application)",
		chooseCalendarRunTypeTriathlon: "Triathlon",

		calendarRunDistance: "How far is the race? (in meter)",
		calendarRunLaps: "How many laps does your race take?",
		calendarRunDetectionPoints: "Your detection points",
		calendarRunSegments: "Your stages",
		calendarRunDetectionAddPoint: "Add a detection point",
		calendarRunAddSegment: "Add a stage",
		calendarRunDetectionConfigurePoint: "Configure your detection point",
		calendarRunConfigureSegment: "Configure your stage",
		calendarRunDetectionType: "What is the type of your point ?",
		calendarRunDetectionChooseType: "Choose a type",
		finish: "Finish",
		arrived: "Arrived",
		advancedParams: "Advanced settings",
		interpassTime: "Interpass time",
		maxNumberPass: "Maximum number of passages",
		minNumberPass: "Minimum number of passages",
		startNumberPass: "Start number pass",
		endNumberPass: "End number pass",
		numberPass: "Number pass",
		startDetectionPoint: "Entry",
		endDetectionPoint: "Exit",
		latitude: "Latitude",
		longitude: "Longitude",
		order: "Order",
		radius: "Radius",
		altitude: "Altitude",
		runFormTypeError: "You must choose a type of race",
		runFormDistanceError: "The distance of your race must be greater than 0",
		runFormLapsError: "The number of turns must be greater than 0",
		runFormPointNameError: "You must give a name to your point",
		runFormPointDistanceError: "The distance of your point must be defined",
		runFormPointLineError: "A line must be defined",
		runFormPointTypeError: "You must choose a stitch type",
		runFormPointSameError: "Start and end points are the same",
		runFormSegmentNotComplete: "Stage information incomplete",
		runFormSegmentErrorHappened: "An error happened while saving the stage",
		calendarRunDeleteSegment: "Delete stage",
		runFormDeleteSegmentConfirmation:
			"Are you sure you want to delete this stage ?",
		runFormDeleteSegmentSuccess: "Stage deleted successfully",
		runFormUpdateSegmentSuccess: "Stage updated successfully",
		runFormDistanceType: "Choose distance type",
		whichRunConfigure: "Which run do you want to configure?",
		credentialDagLive: "Your DAG Live credential",
		saveCredentialSuccess: "Your credential as been saved",
		saveCredentialError: "An error as occured while saving your credential.",
		saveConfigRunSuccess: "You configuration has been saved.",
		saveConfigRunError:
			"An error as occured while updating your configuration.",
		saveConfigPointSuccess: "You point has been saved.",
		saveConfigPointError: "An error as occured while saving you point.",
		deleteConfigPointSuccess: "You configuration has deleted.",
		deleteConfigPointError: "An error as occured while deleting your point.",
		seeAllDetections: "See all detections",
		rankingHeaderRank: "Rk",
		rankingHeaderName: "Name",
		rankingHeaderBib: "Bib",
		rankingHeaderLastPassage: "Last passage",
		rankingHeaderDetections: "Detections",
		rankingHeaderTime: "Time",
		rankingHeaderCategoryRank: "Cat. Rk.",
		rankingHeaderSexRank: "Sex Rk.",
		rankingHeaderRun: "Run",
		rankingHeaderTeam: "Team",
		rankingHeaderActions: "Actions",
		editRunnerTooltip:
			"Please, not that update runner will also change all other subscriptions",
		editRunnerRunTooltip: "Updating race information will affect rankings",
		selectRunPlaceholder: "Select a race",
		chooseTeam: "Choose a team",
		createTeam: "Create a team",
		resetOnRunner: "Reset on runner",
		bibTime: "Bib time",
		manageDetections: "Manage detections",
		totalPriceOfRun: "Total price of the trip (excluding fees)",
		invalidateSub: "Make registration invalid ?",
		errorVerifyBibAlreadyUse:
			"An error has occurred. Check that the bib is not already used.",
		addDetection: "Add a detection",
		setRunnerSurrended: "Pass runner in surrended",
		setRunnerDisqualified: "Pass runner in disqualified",
		setRunnerStarting: "Pass runner in starting",
		setRunnerNotStarting: "Pass runner in not starting",
		updateRunnerStatusSurrended: "Update runner status to surrended ?",
		updateRunnerStatusDisqualified: "Update runner status to disqualified ?",
		updateRunnerStatusStarting: "Update runner status to starting ?",
		updateRunnerStatusNotStarting: "Update runner status to not starting ?",
		notStarting: "Not starting",

		// PatchNotes
		patchNotes: "Update notes",
		patchAddNotes: "Adding update notes",
		patchAddJetcodes: "Availability of Jetcodes for an event",
		patchAddRoles: "User access rights management",
		patchCorrectDocuments: "Correction of document visualization",
		patchFixInvoiceErrorMessage: "Correction of Invoice error message",
		patchChangeDashboardLinks: "Changed links for event cards in the Dashboard",
		patchUpdateSendOneMail: "Updated sending mail to a runner",
		patchMailHistory:
			"Correction of the display of the history of emails sent to a runner",
		patchResponsive:
			"Correction of responsiveness to improve usability on mobile",
		patchHoursPrices:
			"Correction of updating the time when you want to update a price",
		patchRedirectionToValidate:
			"Update of the redirection when clicking on X to validate",
		patchTraduction: "Fix translations",
		patchEventImages: "Fix event images",
		patchCreateEdition: "Add create new edition",
		patchPictoDocs: "Add Picto for docs",
		patchForgetPassword: "Add forget password",
		patchAddPdfExport: "Add pdf export",
		patchAddFilterDocuments:
			"Add a filter for subscription with pending documents",
		onlyWithDocStart: "Display only subscriptions with",
		onlyWithDocStrong: " pending documents",
		patchReasignBibsAndKeepThem:
			"Updated bib reassignment behavior with the possibility of keeping bibs already assigned",
		patchAddRegistration: "Improve add of registration",
		patchGenderX: "Display X gender",
		patchRegistrations: "Improve registrations management",
		patchSwitchSubscriptionStatus: "You can now switch subscription status",
		patchUpdateAdminStatistics:
			"Update of the administration interface regarding data tables",
		patchAddRibSelector:
			"Addition of a functionality for quickly adding RIBs from a list of RIBs already used by the user",
		patchEditOption: "Allows to configure registrations' options",
		patchAddResults: "Allows to add results as PDF files",
		patchDocumentPreview: "Improve document preview",
		patchUX: "Improve user experience",
		patchPDFExportRaces: "Add race selection for PDF export",

		deleteGpxFileConfirmation: "Are you sure you want to delete this file?",
		deletedFile: "File deleted!",
		selectRunMail: "Select a race",
		detectionPointName: "Detection point name",
		detectionLigne: "Line",
		verifyPrestation:
			"Check that you have a live in progress and you DAG Live configuration",
		verifyCredential: "An error has occurred. Check your DAG Live credential.",
		noPrestationStarted:
			"Start the live for the first time to show the ranking.",
		ranked: "Ranked",
		missing: "missing",
		ffa_error_generic: "Licence not validated",
		ffa_error_001: "Error with the birthdate format",
		ffa_error_002: "Insufficient provided information",
		ffa_error_003: "Number not found",
		ffa_error_004: "TP number not found",
		ffa_error_005: "CF number not found",
		ffa_error_006:
			"The number will no longer be valid at the time of the competition",
		ffa_error_007:
			"The number does not match your name / first name / gender / birthdate",
		ffa_error_008:
			"Your information (name / first name / gender / birthdate) is not found",
		ffa_error_009: "Homonymy error, please contact the FFA directly",
		ffa_error_010: "FFA Service unavailable, contact the organizer (10)",
		ffa_error_011: "FFA Service unavailable, contact the organizer (11)",
		ffa_error_012: "FFA Service unavailable, contact the organizer (12)",
		ffa_error_013: "FFA Service unavailable, contact the organizer (13)",
		ffa_error_014: "FFA Service unavailable, contact the organizer (14)",
		ffa_error_015: "PPS number not found",
		ffa_error_016: "The name does not match",
		ffa_error_017: "The first name does not match",
		ffa_error_018: "Gender does not match",
		ffa_error_019: "Birthdate does not match",
		SUB: "SUB",
		ARR: "ARR",
		DNS: "DNS",
		DNF: "DNF",
		DQ: "DQ",
		sport_identity_error_name_taken: "The event name is already taken",
		add_licence: "Add a licence",
		udc_licence_remaining: "Remaining UDC license",
		total_key_credit: "Total encoding credits",
		donation_required: "Donation required",
		donation_minimum_amount: "Minimum donation amount",
		additional_information: "Additional information",
		shop_licence: "Licenses",
		shop_material: "Hardware",
		shop_chip: "Chips",
		global_search: "Search",
		global_search_desc: "Search for people, clients, commands...",
		quickstart: "Quickstart",
		features: "Features",
		shop: "Shop",
		reset: "Reset",
		persons: "Persons",
		commands: "Commands",
		search_results: "Search results",
		account_type: "Account type",
		registration: "Registration",
		ttc_price: "Price with tax",
		command_date: "Command date",
		payer: "Payer",
		donation_list: "Donation list",
		no_run_with_donation:
			"No run is configured with a donation for an association",
		donation_value: "Donation value",
		no_donation_for_association: "No donation for this association",
		donation_campain: "Donation campain",
		campain_email: "EMAIL OF THE ASSOCIATION",
		campain_objective: "CAMPAIGN OBJECTIVE",
		campain_minimum: "MINIMUM AMOUNT",
		campain_default: "DEFAULT AMOUNT",
		pps_number: "PPS Number",
		all_runs: "All runs",
		all_runners: "All runners",
		all_valid_subsriptions: "All valid subscriptions",
		all_invalid_subsriptions: "All invalid subscriptions",
		all_incomplete_subsriptions: "All incomplete subscriptions",
		active_users: "Active users",
		tracking_url: "Tracking URL",
		document_title: "Document title",
		ranking: "Ranking",
		subscription_history_by_run: "Subscription history by run",
		rights_add_subscription: "Registration",
		rights_add_subscription_infos: "Allows adding an on-site registrant",
		rights_import_subscriptions: "Import registrations",
		rights_import_subscriptions_infos: "In Excel format",
		rights_export_subscriptions: "Export registrations",
		rights_export_subscriptions_infos: "In Excel, CSV, PDF, or GmDAG format",
		rights_validate_documents: "Document validation",
		rights_validate_documents_infos:
			"Validation of medical certificates and parental authorizations",
		rights_send_mails: "Communication with registrants (emails)",
		rights_subscription_live: "Registration rights and live management",
		rights_run_config: "Race configuration",
		rights_rib_config: "Edit bank details (RIB)",
		rights_contact_config: "Edit contact details",
		rights_runner_management: "Runner management",
		rights_runner_management_infos: "Access to the list, passage times, etc.",
		rights_dossard_assign: "Bib assignment",
		rights_dossard_assign_infos: "Allows changing a runner's bib number",
		live_chrono: "Live/Timekeeping",
		passage_points: "Passage points",
		times: "Times",
		detections: "Detections",
		general_ranking: "Rk Gen.",
		sex_ranking: "Rk Sex.",
		cat_ranking: "Rk Cat.",
		minAgeRequired: (minAge: number) =>
			`Minimum age required is ${minAge} years.`,
		maxAgeRequired: (maxAge: number) =>
			`Maximum age required is ${maxAge} years.`,
		yearBetweenRequired: (minAge: number, maxAge: number) =>
			`The birth year must be between ${minAge} and ${maxAge} included.`,
		ageBetweenRequired: (minAge: number, maxAge: number) =>
			`You must be at least ${minAge} years or at most ${maxAge} years at the time of registration.`,
		activities: "Activities",
		not_all_position_display:
			"The number of positions has been adjusted, only 500 positions will be displayed",
		success_time_saved: "The time has been saved",
		error_time_saved: "An error occurred while saving the time",
		display_gpx: "Display GPX ?",
		error_create_licence: "Error creating licence",
		success_create_licence: "Licence created",
		error_create_encode_key: "Error creating encode key",
		success_create_encode_key: "Encode key created",
		live_ranking: "Live ranking",
		add_ranking: "Add ranking",
		deselect: "Deselect",
		ranking_name: "Ranking name",
		available_filters: "Available filters",
		ranking_filters_explanation:
			"These filters define the combinations available for classification",
		toast_error_save_ranking_config: "Error saving rankings",
		toast_success_save_ranking_config: "Ranking saved",
		toast_empty_ranking_config: "Ranking name and filters cannot be empty",
		sure_delete_ranking_list: (name: string) =>
			`Are you sure you want to delete  "${name}" ?`,
		success_delete_ranking_list: "Ranking list deleted",
		error_delete_ranking_list: "Error deleting ranking list",
		custom_rankings: "Custom ranking",

		care_choice_unavaible:
			"Please note, these choices will no longer be available for the ranking filter.",
		list_name_required: "The list name is mandatory and must be unique",
		ranking_title: "Ranking",
		ranking_all_choices: "All",
		ranking_all_races: "All races",
		ranking_export_experimental: "(experimental)",
		ranking_export_info1:
			"Exports the results as they appear on your screen in Excel format.",
		ranking_export_info2:
			"The columns are the same as those displayed on the runner management page.",
		ranking_export_pdf_newline: "Page break on gender change:",
		ranking_export_pdf_unranked: "Show unranked:",
		ranking_export_pdf_all_races: "All races",
		price_dates_archived: "Archived dates"
	},

	de: {
		delete_old_detections:
			"Möchten Sie die Erkennungen aus der letzten Aufführung behalten?",
		select_race: "Bitte wählen Sie ein Rennen aus",
		select_club: "Vereinsauswahl",
		select_team: "Teamauswahl",
		select_noClub: "Kein Verein verfügbar",
		select_noTeam: "Kein Team verfügbar",
		select_gender: "Bitte wählen Sie das Geschlecht aus",
		select_bib: "Bitte wählen Sie eine Startnummer aus",
		select_country: "Bitte wählen Sie ein Land aus",
		select_countrySearch: "Suchen...",
		select_countryNoResult: "Keine Ergebnisse",
		published: "Veröffentlicht",
		unpublished: "Nicht veröffentlicht",
		edit: "Bearbeiten",
		registered: "registriert",
		toValidate: "zu validieren",
		ofTurnover: "des Umsatzes",
		publishEvent: "Event veröffentlichen",
		unpublishEvent: "Event zurückziehen",
		publishEventWarning:
			"Warnung: Sie sind dabei, dieses Event zu veröffentlichen. Dadurch wird es für alle Chronometrage.com-Nutzer sichtbar. Sind Sie sicher, dass Sie fortfahren möchten?",
		unpublishEventWarning:
			"Warnung: Sie sind dabei, dieses Event zurückzuziehen. Dadurch wird es nicht mehr für Chronometrage.com-Nutzer sichtbar sein. Sind Sie sicher, dass Sie fortfahren möchten?",
		editEvent: "Event bearbeiten",
		editRunner: "Teilnehmer bearbeiten",
		handleDetection: "Erkennungen verwalten",
		eventName: "Event-Name",
		category: "Kategorie",
		date: "Datum",
		description: "Beschreibung",
		poster: "Poster",
		ffaFFTRI: "FFA / FFTRI",
		ffa: "FFA",
		fftri: "FFTRI",
		location: "Ort",
		rulesPDF: "Regeln (PDF)",
		website: "Webseite",
		file: "Datei",
		rib: "RIB",
		addRunner: "Teilnehmer hinzufügen",
		addRegistration: "Registrierung hinzufügen",
		sendEmailTitle: "E-Mail für registrierte Teilnehmer verfassen",
		sendOneEmailTitle: "E-Mail für den Teilnehmer verfassen",
		insight: "E-Mail-Vorschau",
		config_title: "Verwaltung",
		runs_title: "Rennen",
		update_presentation: "Präsentation",
		update_runs: "Rennen",
		update_options: "Optionen",
		update_contacts: "Kontakte",
		update_sponsors: "Sponsoren",
		update_ribs: "RIBs",
		configure_steps: "Schritte konfigurieren",
		configure_live: "Live-Tracking",
		configure_results: "Ergebnisse senden",
		sync_folomi_error: "Es gibt mehr Anmeldungen als Teilnehmer.",
		sync_folomi_action: "Zum Synchronisieren klicken",
		sync_folomi_button: "Teilnehmer synchronisieren",
		sync_folomi_race_subscribed: "registriert, aber nur",
		sync_folomi_race_detected: "erkannte Teilnehmer",
		ask_validation: "Veröffentlichung anfragen",
		unpublish: "Event zurückziehen",
		validation_asked: "Veröffentlichung angefragt",
		preview: "Vorschau",
		preview_invoice: "Rechnungsvorschau",
		edit_invoice: "Rechnung bearbeiten",
		commissions: "Provisionsinformationen",
		donations: "Spenden",
		invoice_title: "Rechnungsstellung",
		option: "Option",
		configure_options: "Optionen konfigurieren",
		configure_lists: "Benutzerdefinierte Felder",
		lists: "Feldkonfiguration",
		add_list: "Feld hinzufügen",
		edit_list: "Feld bearbeiten",
		delete_list: "Liste löschen",
		sure_delete_list: "Sind Sie sicher, dass Sie löschen möchten?",
		add_list_choice: "Auswahl hinzufügen",
		empty_list_text: "Sie können keine leere Liste importieren",
		list_choice_name_too_short: "Der Auswahlsname ist zu kurz",
		add_global_option: "Globale Option hinzufügen",
		lists_desc: "Diese Felder werden im Registrierungsformular verfügbar sein",
		lists_text_desc:
			"Sie können Text einfügen, getrennt durch Semikolons ( ; ) oder Zeilenumbrüche",
		list_name_too_short: "Der Feldname ist zu kurz",
		list_available_choices: "Verfügbare Auswahlmöglichkeiten",
		import_list: "Diese Liste importieren",
		or: "oder",
		add_price: "Preis hinzufügen",
		add_option: "Option hinzufügen",
		add_product: "Produkt hinzufügen",
		min_quantity: "Mindestmenge",
		max_quantity: "Höchstmenge",
		designation: "Bezeichnung",
		option_type: "GEWÜNSCHTER FELDTYP",
		is_required: "MUSS DIE OPTION VERPFLICHTEND SEIN?",
		product: "Produkt",
		price: "Preis",
		type_dropdown_description:
			"Liste verschiedener Produkte (z. B.: T-Shirt-Größen, S, M, L)",
		type_check_description: "Checkbox (z. B.: Bus, Mahlzeit)",
		type_quantity_description:
			"Anfrage zur Produktmenge (z. B.: Anzahl der Mahlzeiten für Teilnehmer)",
		delete_title: "Löschung",
		delete_message: "Sind Sie sicher, dass Sie löschen möchten?",
		price_start_date: "STARTDATUM UND -ZEIT DES PREISES",
		price_end_date: "ENDDATUM UND -ZEIT DES PREISES",
		price_start_date_donation: "STARTDATUM UND -ZEIT DER SPENDE",
		price_end_date_donation: "ENDDATUM UND -ZEIT DER SPENDE",
		warning_no_price:
			"Es muss mindestens ein Preis (auch kostenlos) für dieses Rennen existieren, um eine Option hinzuzufügen.",
		warning_delete: "(Die Operation wird nach dem Speichern wirksam)",
		error_qty_min_0: "Mengen müssen größer als 0 sein",
		error_qty_min_max: "Die Mindestmenge muss kleiner als die Höchstmenge sein",
		error_too_small: "(Zu klein)",
		default: "Standard",
		prestation: "Dienst starten",
		loading: "Lädt",
		importResults: "Ergebnisse importieren",
		set: "Festlegen",
		notSet: "Nicht festgelegt",
		filesToBeChecked: "zu prüfende Dateien",
		completedAndValidatedFiles: "abgeschlossene und validierte Dateien",
		viaOptions: "über Optionen",
		confirm: "Bestätigen",
		cancel: "Abbrechen",
		interrupt: "Unterbrechen",
		previous: "Zurück",
		next: "Weiter",
		save: "Speichern",
		search: "Suchen",
		download: "Herunterladen",
		allM: "Alle",
		allF: "Alle",
		delete: "Löschen",
		inTeam: " - Im Team",
		send: "Senden",
		create_event: "Event erstellen",
		required: "Erforderlich",
		discard_changes: "Änderungen verwerfen?",
		preview_off: "Vorschau schließen",
		error_boundary_title: "Ein Fehler ist aufgetreten.",
		error_boundary_support:
			"Wenn das Problem weiterhin besteht, wenden Sie sich an den Support und geben Sie folgende Informationen an:",
		back: "Zurück",
		sendMailAllSubscribers: "Sammel-E-Mail senden",
		sendMail: "E-Mail senden",
		sendMailError: "Beim Senden der E-Mail ist ein Fehler aufgetreten",
		resetExpired: "Zurücksetzen abgelaufen. Neuen Reset anfordern",
		eventSearch: "Event suchen",
		number_bib: "Startnummer",
		number_line: "Zeilennummer",
		time_detection: "Passierzeit",
		number_line_short: "Zeile",
		whatIsJetcode: "Was ist ein Jetcode?",
		jetcodeExplanation:
			"Es ist ein Tool, mit dem Sie den Inhalt einer anderen Seite (Anmeldeformulare & Online-Zahlung, verbleibende Startnummern, Countdown, Chronometrage.com-Logo) direkt in Ihre Website integrieren können.",
		howIntegrateJetcodeToMyWebsite:
			"Wie integriere ich einen Jetcode in meine Website?",
		jetcodeIntegrationExplanation:
			"Kopieren Sie einfach die bereitgestellten Codes der gewünschten Jetcodes (einschließlich meiner Anmeldungen und des Logos von chronometrage.com) und fügen Sie sie auf einer Seite Ihrer Website ein.",
		integrateSubscriptionsForm: "Anmeldeformular integrieren",
		integrateSubscirbersList: "Liste der registrierten Teilnehmer integrieren",
		integrateOnlineSubscriptionLogo: "'Online-Anmeldung'-Logo integrieren",
		engineCodeLabel:
			"Engine-Code, der einmal im Header Ihrer Website eingefügt werden muss",
		basketCodeLabel:
			"Warenkorb-Code, der auf jeder Seite Ihrer Website eingefügt werden muss",
		runCodeLabel: "Lauf-spezifischer Code",
		french: "Französisch",
		english: "Englisch",
		copy: "Kopieren",
		copyDone: "Kopiert!",
		insertionCodeLabel:
			"Warenkorb-Einfügecode, dieser Code muss einmal pro Seite eingefügt werden",
		language: "Sprache",
		runSelectLabel: "Gewünschtes Rennen für den Jetcode",
		priceJetcodeCodeLabel:
			"Jetcode-Preiseinstellung, die in Ihre Website kopiert werden muss",
		theme: "Thema",
		setup: "Einrichten",
		seeTimer: "Timer anzeigen",
		seeSeconds: "Sekunden anzeigen",
		seeBibs: "Startnummern anzeigen",
		seeBibsLeft: "Verbleibende Startnummern anzeigen",
		codeToCopyToYourWebsite: "Code zum Kopieren auf Ihre Website",
		downloadProcessLabel:
			"Laden Sie die Anleitung im PDF-Format herunter, um sie Ihrem Webmaster bereitzustellen",
		listSetup: "Liste einrichten",
		selectARun: "Wählen Sie ein Rennen aus",
		generalSettings: "Allgemeine Einstellungen",
		dagLiveCredentials: "DAG Live-Zugangsdaten",
		sensorName: "Bezeichnung",
		distance: "Distanz (m)",
		passageMin: "Minimale Durchgänge",
		passageMax: "Maximale Durchgänge",
		sensor: "Sensor",
		type: "Typ",
		start: "Start",
		stop: "Leistung stoppen",
		deleteDetections: "Erkennungen endgültig löschen",
		deleteDetectionsSuccess: "Löschen abgeschlossen",
		deleteDetectionsError: "Löschen fehlgeschlagen",
		prestationError: "FEHLER",
		sensorSettings: "Sensoreinstellungen",
		addGpx: "GPX hinzufügen",
		chooseSensorType: "Sensor-Typ auswählen",
		startLine: "Startlinie",
		finishLine: "Ziellinie",
		checkpoint: "Kontrollpunkt",
		addSensor: "Einen Sensor hinzufügen",
		removeThisSensor: "Diesen Sensor entfernen",
		handleRunners: "Teilnehmer verwalten",
		handleDetections: "Erkennungen verwalten",
		dashboardDetections: "Zusammenfassung",
		global_configuration: "Konfiguration",
		route_configuration: "Routen",
		delete_step: "Diesen Schritt löschen",
		edit_step: "Diesen Schritt bearbeiten",
		delete_parcours: "Diese Route löschen",
		edit_parcours: "Diese Route bearbeiten",
		delete_segment: "Dieses Segment löschen",
		edit_segment: "Dieses Segment bearbeiten",
		step: "Schritt",
		parcours: "Route",
		segment: "Segment",
		configure_routes: "Routen konfigurieren",
		configure_segments: "Segmente konfigurieren",
		intermediary: "Zwischenstation",
		start_line: "Start",
		end_line: "Ziel",
		typeLine1: "Start",
		typeLine2: "Zwischenstation",
		typeLine3: "Ziel",
		grid: "Rasterlayout",
		categoryReassignement: "Kategorie-Neuzuordnung",
		handleResults: "Ergebnisse verwalten",
		timezone: "Welche Zeitzone möchten Sie beibehalten?",
		no_run_selected: "Kein Rennen ausgewählt",
		placeholder_select_run: "Wählen Sie ein Rennen aus",
		placeholder_select_segment: "Wählen Sie ein Segment aus",
		log_title: "Melden Sie sich in Ihrem Admin-Tool an",
		forgotten_title: "Passwort vergessen?",
		reset_password: "Passwort zurücksetzen",
		back_to_home: "Zurück zur Startseite",
		passwords_not_match: "Die Passwörter stimmen nicht überein",
		passsword_reset_success: "Ihr Passwort wurde zurückgesetzt",
		password_too_short: "Ihr Passwort muss mindestens 6 Zeichen lang sein",
		send_forgotten_mail_success: "Eine E-Mail wurde an Sie gesendet",
		email: "E-Mail-Adresse",
		new_password: "Neues Passwort",
		new_password_confirm: "Neues Passwort bestätigen",
		password: "Passwort",
		confirmPassword: "Bestätigen Sie Ihr Passwort",
		submit: "Anmelden",
		social_reason: "Sozialgrund",
		adresse: "Adresse",
		postal_code: "Postleitzahl",
		city: "Stadt",
		country: "Land",
		noAccount: "Noch keinen Account?",
		rememberMe: "Angemeldet bleiben",
		signup_button: "Ein Konto erstellen",
		signup: "Ich habe noch kein Konto",
		signup_title: "Registrieren Sie sich für das Admin-Tool",
		already_account: "Ich habe bereits ein Konto",
		forgotten_password: "Passwort vergessen?",
		lastname: "Nachname",
		firstname: "Vorname",
		birthdate: "Geburtsdatum",
		sex: "Geschlecht",
		acceptCGV: "Ich akzeptiere die Nutzungsbedingungen der Plattform",
		signup_error_firstname: "Ihr Vorname muss mehr als 1 Buchstabe lang sein.",
		signup_error_lastname: "Ihr Nachname muss mehr als 1 Buchstabe lang sein.",
		signup_error_birthdate:
			"Ihr Geburtsdatum muss gültig sein und Sie müssen zwischen 13 und 100 Jahre alt sein.",
		signup_error_email: "Ihre E-Mail-Adresse ist ungültig.",
		signup_error_password: "Ihr Passwort muss mindestens 6 Zeichen lang sein.",
		signup_error_passwordConfirm: "Ihre Passwörter stimmen nicht überein.",
		signup_error_CGU: "Sie müssen die AGB akzeptieren.",
		male: "Männlich",
		female: "Weiblich",
		other: "Andere",
		legalEntity: "Juristische Person",
		address: "Adresse",
		team: "Team",
		race: "Rennen",
		bib: "Startnummer",
		bibAvailable: "Verfügbar Bib",
		bibUnavailable: "Bib bereits verwendet",
		invalid: "Ungültig",
		bibInvalid: "Ungültige Bib",
		runner: "Läufer",
		zipCode: "Postleitzahl",
		birthDate: "Geburtsdatum",
		genderH: "M",
		genderF: "W",
		genderX: "X",
		editGeneral: "Allgemein",
		editRace: "Im Rennen",
		nationality: "Nationalität",
		countryPlaceholder: "Land auswählen",
		firstPhone: "Telefon 1",
		secondPhone: "Telefon 2",
		marketer: "Vermarkter",
		organizer: "Organisator",
		yes: "Ja",
		no: "Nein",
		bbanDelete: "BBAN löschen",
		bbanDeleteWarning:
			"Achtung, Sie sind dabei, dieses BBAN zu löschen. Diese Aktion ist unwiderruflich. Sind Sie sicher, dass Sie fortfahren möchten?",
		addTeam: "Ein Team hinzufügen",
		club: "Verein",
		duplicateEmail: "Diese E-Mail-Adresse wird bereits verwendet",
		alreadyInUse: "Bereits in Gebrauch",
		editTimes: "Zeiten",
		object: "Betreff",
		mailBody: "E-Mail-Inhalt",
		swapBibs: "Bibs tauschen",
		swapFrom: "Von",
		swapTo: "Nach",
		nbDetections: "Erkennungen",
		stageDepartureHour: "Offizielle Abfahrtszeit",
		handicap: "Handicap",
		miniTime: "Mindestzeit für die Etappe",
		error404: "Fehler 404",
		pageDoesNotExist: "Diese Seite existiert nicht",
		iThinkYouAreLost: "Ich glaube, Sie haben sich verlaufen",
		goBackHome: "Zur Startseite zurückkehren",
		dashboard: "Dashboard",
		registrations: "Registrierungen",
		event: "Veranstaltung",
		liveResults: "Timing",
		menuResults: "Ergebnisse",
		liveConfiguration: "Live-Konfiguration",
		statistics: "Statistiken",
		admin: "Verwaltung",
		jetcodes: "Jetcodes",
		toCome: "Bevorstehend",
		today: "Heute",
		past: "Vergangen",
		myAccount: "Mein Konto",
		logout: "Abmelden",
		manageRunners: "Läufer verwalten",
		detections_dashboard: "Erkennungs-Dashboard",
		manage_dashboard: "Erkennungen verwalten",
		registration_refund: "Registrierung erstatten",
		registration_order: "Registrierungsauftrag",
		cgu: "Nutzungsbedingungen",
		cgv: "Allgemeine Verkaufsbedingungen",
		manage_results: "Ergebnisse verwalten",
		startOn: "Beginnt am",
		registeredContestants: "Registrierte Läufer",
		displayRegistrations: "Registrierungen anzeigen",
		completeSubscriptionsList: "Komplette Liste der Registrierungen",
		bibs: "Startnummern",
		bibsSearch: "Startnummer suchen",
		firstnameSearch: "Vorname suchen",
		lastnameSearch: "Nachname suchen",
		statusSearch: "Status suchen",
		loadingRegisterForm: "Registrierungsformular wird geladen",
		thanksToWait: "Vielen Dank für Ihre Geduld",
		noRace: "Kein Rennen konfiguriert",
		teamOf: "TEAM von",
		invididual: "EINZEL",
		importError: "Fehler beim Import",
		preimportWarning: "WARNUNG",
		importWarning: "WARNUNG",
		preimportError: "FEHLER: DIESE REGISTRIERUNG WIRD NICHT IMPORTIERT...",
		preimportErrors: "FEHLER: DIESE REGISTRIERUNGEN WERDEN NICHT IMPORTIERT...",
		exportTitle: "Registrierungen exportieren in",
		raceChoice: "Rennen",
		sexeChoice: "Geschlecht",
		statusChoice: "Status",
		subscriptionType: "Art der Anmeldung",
		importTitle: "Registrierungen importieren",
		toImport: "Zum Importieren einer Liste von Registrierungen verwenden Sie",
		configureFromAnotherRace: "Von einem anderen Rennen konfigurieren",
		maximumAge: "Maximales Alter ist",
		useThisModel: "diese Excel-Dateivorlage",
		dragHere: "Ziehen Sie die Excel-Datei (.xlsx-Format) hierher...",
		dragHereOr:
			"Ziehen Sie die Excel-Datei (.xlsx-Format) hierher oder klicken Sie, um sie auszuwählen",
		fileFormatNotSupported: "Dateiformat nicht unterstützt",
		existingSubs: "bereits importierte Registranten.",
		replace: "Alles entfernen und ersetzen",
		donotupdate: "Nur neue Nummern hinzufügen",
		continue: "Import fortsetzen",
		entries: "Ergänzungen auf",
		import: "Importieren",
		nbImportedRunners: "importierte Läufer",
		pre: "Vor-",
		importing: "Importiere...",
		existing: "bereits vorhanden",
		adds: "Ergänzungen auf",
		add: "Ergänzungen",
		close: "Schließen",
		whattodo: "Was möchten Sie tun?",
		line: "Zeile",
		invalidRace: "Ungültiges Rennen",
		bibUsed: "Startnummer bereits verwendet",
		missingRace: "Rennen nicht angegeben",
		missingBib: "Startnummer nicht angegeben",
		yourEvents: "Ihre Veranstaltungen",
		yourRuns: "Ihre Läufe",
		bibsAllocation: "Startnummernzuteilung",
		discountVouchers: "Rabattgutscheine",
		form: "Formular",
		refunds: "Rückerstattungen",
		accessRights: "Zugriffsrechte",
		bankBBAN: "BBAN",
		manageBibsAllocation: "Startnummernverwaltung",
		manageEventDiscountVouchers: "Rabattgutscheine verwalten",
		manageEventForm: "Veranstaltungsformular verwalten",
		manageRefunds: "Rückerstattungen verwalten",
		manageAccessRights: "Zugriffsrechte verwalten",
		manageYourBBAN: "Ihr BBAN verwalten",
		manageFFAFFTRILicenses: "Ihre FFA/FFTRI-Lizenzen verwalten",
		ribListLinkToThisEvent:
			"Liste der RIBs, die mit diesem Ereignis verknüpft sind",
		addBBAN: "BBAN hinzufügen",
		name: "Name",
		bank: "Bank",
		ibanBban: "IBAN / BBAN",
		thereAreCurrently: "Derzeit gibt es",
		registrantsForThe: "Registranten für das Rennen",
		doYouReallyWantToReimburseThe:
			"Möchten Sie wirklich die Rückerstattung für",
		registrants: "Registranten durchführen?",
		chargeAdditionalFeesPerRegistrations:
			"Zusätzliche Gebühren pro Anmeldung (in €)",
		invoicingOnRunnerOrOrganizer:
			"Rechnungsstellung an den Läufer oder den Veranstalter",
		ifRegistrationFeeIncluded:
			"Wenn die Anmeldegebühr enthalten ist, abgedeckt durch",
		reimbursementCostsCoveredBy: "Erstattungskosten abgedeckt durch",
		makeTheRefund: "Die Erstattung vornehmen",
		reason: "Grund",
		choose: "Wählen...",
		bibPlan: "Startnummernplan",
		bibPlanAffectation: "Zuweisungsmethode der Startnummern",
		minBib: "Minimale Startnummer",
		maxBib: "Maximale Startnummer",
		bibExclusion: "Ausgeschlossene Startnummern",
		bibExlusionPlaceholder:
			"Beispiel: \rListe = 1;2;8;10 (Startnummern 1, 2, 8 und 10 werden nicht berücksichtigt) \rBereich = 5:10 (Startnummern von 5 bis 10 einschließlich (5, 6, 7, 8, 9 und 10) werden nicht berücksichtigt) \rKombination 1 = 2:4;7:8 (Startnummern 2, 3, 4, 7 und 8 werden nicht berücksichtigt) \rKombination 2 = 2;4:6;8 (Startnummern 2, 4, 5, 6 und 8 werden nicht berücksichtigt)",
		reaffectAllbibs:
			"Alle Startnummern für das Rennen durch Bestätigung der Auswahl neu zuweisen",
		keepBibs: "Bereits zugewiesene Startnummern beibehalten",
		applyToPending: "Auf ausstehende Anmeldungen anwenden",
		orderAlphabetic:
			"Startnummern in alphabetischer Reihenfolge der Läufer neu zuweisen",
		list: "Nach Startnummernliste",
		range: "Nach Startnummernbereich",
		filterByRun: "Nach Rennen filtern",
		resolution: "Auflösung",
		days: "Tage",
		weeks: "Wochen",
		months: "Monate",
		years: "Jahre",
		runnerAge: "Jahre",
		seeComments: "Kommentare anzeigen",
		downloadInPdf: "Als PDF herunterladen",
		nbOfSubscribers: "Anzahl der Abonnenten",
		maxInfMin:
			"Bitte wählen Sie einen maximalen Wert größer als den minimalen Wert",
		errorBib: "Fehler bei der Aktualisierung der Startnummern",
		updatedBib: "Startnummern wurden aktualisiert",
		loginSuccess: "Login erfolgreich",
		loginError: "Login-Fehler",
		logoutSuccess: "Logout erfolgreich",
		logoutError: "Logout-Fehler",
		updateError: "Es trat ein Fehler bei der Aktualisierung auf",
		updateSuccess: "Die Aktualisierung war erfolgreich",
		updateCredentialSuccess:
			"Die Aktualisierung der Anmeldedaten war erfolgreich",
		updateCredentialError: "Fehler bei der Aktualisierung der Anmeldedaten",
		startPrestation: "Der Dienst wurde gestartet",
		liveTimezone:
			"Es gibt eine Inkonsistenz in der Zeitzonen-Konfiguration Ihres Events.",
		stopPrestation: "Der Dienst wurde gestoppt",
		prestationStartError: "Fehler beim Starten des Dienstes",
		prestationStartErrorCredentiel:
			"Bitte konfigurieren Sie Ihre Anmeldedaten, bevor Sie den Dienst starten",
		prestationStopError: "Fehler beim Stoppen des Dienstes",
		updateCheckpointSuccess: "Die Detektoraufzeichnung war erfolgreich",
		updateCheckpointError: "Fehler bei der Detektoraufzeichnung",
		deletionSuccess: "Löschung erfolgreich",
		deletionError: "Ein Fehler ist bei der Löschung aufgetreten",
		insertSuccess: "Erstellung erfolgreich",
		insertError: "Fehler bei der Erstellung",
		insertErrorDetection:
			"Die Erkennung konnte nicht übertragen werden. Bitte prüfen Sie, ob ein Rennen aktiv ist.",
		ppsError:
			"Ungültige Lizenz oder PPS (nicht von der Französischen Föderation validiert)",
		requiredRace: "Bitte wählen Sie ein Rennen",
		requiredBib: "Bitte wählen Sie eine Startnummer",
		requiredBirthdate: "Bitte geben Sie das Geburtsdatum an",
		requiredGender: "Bitte geben Sie das Geschlecht an",
		requiredTeamName: "Bitte geben Sie den Teamnamen an",
		requiredTeam:
			"Bitte wählen Sie ein Team aus der Liste oder erstellen Sie ein neues",
		requiredOneOrAllRunner: "Bitte geben Sie einen oder alle Läufer an",
		emailSendedSuccess: "E-Mails erfolgreich gesendet.",
		emailSendedError: "Fehler beim Senden der E-Mails.",
		login_expired: "Ihre Sitzung ist abgelaufen",
		newRaceUpdateSuccess: "Änderung des Rennens war erfolgreich",
		newRaceUpdateError: "Fehler bei der Änderung des Rennens",
		bibChangeRaceChoice:
			"Bitte treffen Sie eine Auswahl bezüglich der Startnummern",
		refundSuccess: "Erstattung war erfolgreich",
		refundError: "Fehler bei der Erstattung",
		sport_identity_success: "Event-Anmeldung war erfolgreich",
		sport_runs_success: "Läufe erfolgreich konfiguriert",
		sport_options_success: "Optionen erfolgreich konfiguriert",
		sport_identity_error: "Fehler bei der Erstellung des Events",
		sport_runs_error: "Fehler bei der Konfiguration der Läufe",
		sport_options_error:
			"Optionen konnten nicht gespeichert werden. Bitte prüfen Sie, ob Sie einen Preis für eine bereits abonnierte Option ändern.",
		file_error: "Fehler beim Lesen der Datei",
		file_exist: "Diese Datei wurde bereits hochgeladen",
		event_form_required:
			"Sie haben nicht alle erforderlichen Felder ausgefüllt",
		event_invalid_dates:
			"Warnung: Das Enddatum ist dasselbe wie das Startdatum",
		error_creation_segment: "Fehler bei der Speicherung der Etappe",
		success_creation_segment: "Etappe erfolgreich gespeichert",
		success_deletion_segment: "Etappe gelöscht",
		error_deletion_segment: "Fehler bei der Löschung der Etappe",
		error_creation_parcours: "Fehler bei der Speicherung des Parcours",
		success_creation_parcours: "Parcours erfolgreich gespeichert",
		success_deletion_parcours: "Parcours gelöscht",
		error_deletion_parcours: "Fehler bei der Löschung des Parcours",
		error_creation_etape: "Fehler bei der Speicherung der Etappe",
		success_creation_etape: "Etappe erfolgreich gespeichert",
		success_deletion_etape: "Etappe gelöscht",
		error_deletion_etape: "Fehler bei der Löschung der Etappe",
		success_creating_price: "Preis erfolgreich gespeichert",
		error_creating_price: "Fehler bei der Erstellung des Preises",
		success_deleting_run: "Lauf erfolgreich gelöscht",
		error_deleting_run_subs:
			"Sie können einen Lauf mit Abonnements nicht löschen",
		error_deleting_run: "Fehler bei der Löschung des Rennens",
		success_deleting_price: "Preis erfolgreich gelöscht",
		error_deleting_price: "Fehler bei der Löschung des Preises",
		success_updating_fields: "Anmeldeformular aktualisiert",
		error_updating_fields: "Fehler bei der Aktualisierung des Formulars",
		event_does_not_exist:
			"Das Event, das Sie konfigurieren möchten, existiert nicht",
		success_updating_contact: "Kontakt erfolgreich gespeichert",
		error_updating_contact: "Fehler bei der Speicherung des Kontakts",
		success_updating_rib: "Bankkonto (RIB) erfolgreich gespeichert",
		error_updating_rib:
			"Fehler bei der Speicherung des Bankkontos (RIB). Der gesetzliche Vertreter hat möglicherweise bereits ein Konto.",
		success_deleting_contact: "Kontakt gelöscht",
		success_deleting_rib: "Bankkonto (RIB) gelöscht",
		error_deleting_contact: "Fehler bei der Löschung des Kontakts",
		error_deleting_rib: "Fehler bei der Löschung des Bankkontos (RIB)",
		error_signup:
			"Ein Fehler ist aufgetreten. Diese E-Mail-Adresse wird möglicherweise bereits verwendet.",
		success_signup: "Ihr Konto wurde erfolgreich erstellt",
		success_change_orga:
			"Sie sind jetzt Veranstalter, bitte melden Sie sich erneut an",
		error_change_orga: "Fehler ist aufgetreten.",
		error_contact_empty:
			"Bitte geben Sie den Firmennamen, Vor- und Nachnamen des Kontakts an.",
		success_ask_validation: "Eine E-Mail wurde an den Administrator gesendet",
		error_ask_validation: "Fehler beim Anfordern der Validierung",
		import_error: "Fehler beim Importieren",
		success_publish_event: "Event veröffentlicht",
		success_unpublish_event: "Event nicht veröffentlicht",
		error_unpublish_event: "Fehler ist aufgetreten",
		cant_delete_stage:
			"Bitte löschen Sie die Erkennungen, die mit dieser Etappe verbunden sind, bevor Sie sie löschen",
		reassignCategorySuccess: "Kategorien erfolgreich neu zugewiesen",
		reassignCategoryError:
			"Beim Neuzuordnen der Kategorien ist ein Fehler aufgetreten",
		orgaSuccess: "Sie sind nun Organisator",
		orgaError: "Es ist ein Fehler aufgetreten",
		toast_raceChoice: "Bitte wählen Sie mindestens ein Rennen aus der Liste",
		genderChoice: "Bitte wählen Sie mindestens ein Geschlecht aus der Liste",
		toast_statusChoice:
			"Bitte wählen Sie mindestens einen Registrierungsstatus aus der Liste",
		typeChoice:
			"Bitte wählen Sie mindestens einen Registrierungstyp aus der Liste",
		changeTimezoneError: "Beim Ändern der Zeitzone ist ein Fehler aufgetreten",
		delete_detections_choice: "Bitte treffen Sie eine Auswahl",
		no_delete: "Es wurden keine Detektionen gelöscht",
		no_detections: "Keine Detektionen",
		no_times: "Keine Zeiten",
		export_error: "Beim Exportieren ist ein Fehler aufgetreten",
		accept_document_success: "Das Dokument wurde akzeptiert",
		accept_document_error: "Es ist ein Fehler aufgetreten",
		refuse_document_success:
			"Das Dokument wurde abgelehnt und eine E-Mail wurde an den Teilnehmer gesendet",
		refuse_document_error: "Es ist ein Fehler aufgetreten",
		category_or_team: "Kategorie / Team",
		run: "Rennen",
		status: "Status",
		options: "Optionen",
		runners: "Teilnehmer",
		starter: "Starter",
		surrendered: "Aufgegeben",
		disqualified: "Disqualifiziert",
		filter: "Filter",
		gender: "Geschlecht",
		man: "Männlich",
		woman: "Weiblich",
		speed: "Geschwindigkeit",
		gap: "Lücke",
		passageTime: "DURCHGANGSZEIT",
		minimmAge: "Das Mindestalter beträgt",
		other_gender: "Sonstiges",
		your_runners: "Ihre Teilnehmer",
		refused_reason: "Ablehnungsgrund",
		accept_document: "Akzeptieren",
		refuse_document: "Ablehnen",
		refuse_document_and_notice: "Ablehnen und E-Mail senden",
		your_subscriptions: "Ihre Anmeldungen",
		contact: "Kontakt",
		edit_subscription: "Anmeldung bearbeiten",
		add_subscription: "Anmeldung hinzufügen",
		message: "Nachricht senden",
		document: "Dokumente verwalten",
		actions: "Aktionen",
		waiting: "Warten",
		valid: "Gültig",
		reimbursed: "Erstattet",
		documents_sent: "Dokument(e) gesendet",
		close_documents: "Schließen",
		manage_documents: "Dokumentenverwaltung",
		personnal_informations: "Persönliche Informationen:",
		confirmDeleteSub: "Möchten Sie die Anmeldung von wirklich löschen?",
		age: "Alter",
		phone: "Telefon",
		emergency_phone: "Notfall-Telefon",
		mail: "E-Mail",
		run_informations: "Renninformationen",
		bib_info: "Startnummer",
		licence: "Lizenz",
		validated: "validiert",
		by: "von",
		la: "der",
		validated_by_fede: "Validiert durch ",
		certificat: "Ärztliches Attest",
		autorisation: "Elterliche Zustimmung",
		validation_date: "Validierungsdatum:",
		sub_team: "Team / Verein / Firma",
		sub_options: "Abonnentenoptionen:",
		reimburse: "Erstatten",
		validate_subscription: "Anmeldung bestätigen",
		refuse_subscription: "Anmeldung ablehnen",
		refuse_reason: "Was ist der Ablehnungsgrund?",
		see_message_history: "E-Mail-Verlauf ansehen",
		messages_history: "E-Mail-Verlauf",
		close_history: "Verlauf schließen",
		close_comments: "Kommentare schließen",
		"inscription.refus.title": "Anmeldung abgelehnt",
		no_messages: "Keine Nachrichten an diesen Abonnenten gesendet",
		changeRace: "Rennen ändern",
		changeRaceModalTitle: "Anmeldungsänderung für:",
		currentlyRegistredFor: "Derzeit für das Rennen angemeldet",
		newRace: "Neues Rennen",
		cannotChangeRace: "Rennenwechsel nicht möglich",
		teammatesWith: "Mit",
		bibMessageChangeRun: "1 - Möchten Sie die Startnummern beibehalten?",
		automaticBibChangeRun:
			"2 - Möchten Sie automatisch neue Startnummern basierend auf dem Nummerierungsplan des neuen Rennens zuweisen?",
		bibAlreadyExistInNewRace:
			"hat bereits die Startnummer im gewählten Rennen. Eine neue Startnummer wird automatisch zugewiesen, wenn ein Nummerierungsplan für das gewählte Rennen definiert wurde.",
		noBibInNewRace:
			"ACHTUNG: Die aktuelle Startnummer wird freigegeben. Eine manuelle Zuweisung der Startnummer ist erforderlich.",
		noBibAssignmentInNewRace:
			"ACHTUNG: Die aktuelle Startnummer wird freigegeben, da das neue Rennen keinen Nummerierungsplan hat.",
		see_order: "Bestellung anzeigen",
		no_document: "Kein Dokument",
		detectors: "Detektoren",
		verification: "Verifizierung OK",
		percentage: "Prozentsatz",
		finishers: "Finisher",
		number_runners: "Teilnehmer",
		non_starters: "Nicht-Teilnehmer",
		start_the: "Startet am:",
		abandon: "Abbrüche",
		official_starters: "Offizielle Starter",
		manage_detections_title: "Detektionen",
		hours: "Durchgangszeit",
		restore_detection: "Wiederherstellen",
		delete_detections: "Detektionen löschen",
		choose_detectors: "Wählen Sie eine Linie",
		add_detection: "Detektion hinzufügen",
		edit_detection: "Diese Detektion bearbeiten",
		runFormDeleteDetectionConfirmation: "Möchten Sie diese Detektion löschen?",
		date_format: "Datumsformat",
		chrono_format: "Chronoformat",
		effective_start: "Tatsächlicher Start",
		races: "Alle Rennen",
		deleteAllDetections: "Möchten Sie alle Detektionen löschen?",
		confirmStartSaveCredential:
			"Möchten Sie Ihre Anmeldedaten vor dem Start des Livestreams speichern?",
		deleteTitle: "Löschen von Renn-Detektionen",
		all: "Alle Event-Detektionen löschen",
		upload_dag: "DAG-Datei importieren",
		insertion: "Einfügen",
		estimation: "Schätzung",
		current_time: "Dauer",
		seconds: "Sekunden",
		detection_detail: "Detektionsdetails",
		already_exist: "Existiert bereits",
		inserted: "Eingefügt",
		runner_created: "Teilnehmer erstellt",
		checkpoint_missing: "Fehlender Checkpoint",
		interpassage_ignored: "Durchgang aufgrund der Zwischenzeit ignoriert",
		search_runner: "Nach Teilnehmer suchen",
		search_event: "Nach Event suchen",
		search_bib: "Nach Startnummer suchen",
		search_subscription: "Nach Anmeldung suchen",
		autorisationParentale: "Elterliche Zustimmung",
		licence_already_sent: "Lizenz bereits gesendet",
		certificat_already_sent: "Zertifikat bereits gesendet",
		autorisation_already_sent: "Genehmigung bereits gesendet",
		send_documents: "Neue Dokumente senden",
		see_document: "Dokument ansehen",
		download_document: "Herunterladen",
		product_name: "Produktname",
		refund_price: "Preis (inkl. MwSt.)",
		refund_amount: "Erstattungsbetrag",
		subscription_fees: "Anmeldegebühren",
		refund_reason: "Grund für Ihre Rückerstattung (Pflichtfeld)",
		refund: "Rückerstattung",
		deleteSub: "Anmeldung löschen",
		orga_sub_fees: "Anmeldegebühren zu Ihren Lasten?",
		orga_refund_fees:
			"Rückerstattungsgebühren (1€ inkl. MwSt.) zu Ihren Lasten?",
		contact_doubt:
			"Bitte kontaktieren Sie den Support, wenn Sie Zweifel an Ihren Aktionen haben",
		refund_summary: "Rückerstattungszusammenfassung",
		refund_close_summary: "Zusammenfassung schließen",
		refund_summary_start_text: "Sie sind dabei, eine Rückerstattung von ",
		refund_summary_end_text:
			" an diesen Teilnehmer vorzunehmen, möchten Sie bestätigen?",
		confirm_refund: "Rückerstattung bestätigen",
		refund_paid: "Der Teilnehmer hat bezahlt",
		amount_to_refund: "Sie werden folgendes zurückerstatten",
		refund_fees: "Rückerstattungsgebühren",
		refunded_amount: "Gesamter Erstattungsbetrag",
		already_refunded: (amount: string, date: string) =>
			`Sie können dieses Abonnement nicht mehr erstatten, da bereits eine Rückerstattung von ${amount} € am ${date} erfolgt ist.`,
		refund_product: "Rückerstattung",
		choose_type: "Ich möchte eine Veranstaltung organisieren",
		select: "Auswählen",
		type_details: "Details",
		sport_event_identity: "Veranstaltungspräsentation",
		sport_event_runs: "Rennkonfiguration",
		sport_event_options: "Optionskonfiguration",
		go_back: "Zurück",
		save_and_go_run: "Speichern und Rennen konfigurieren",
		save_and_go_options: "Optionen konfigurieren",
		save_and_finish: "Konfiguration abschließen",
		event_name_label: "Veranstaltungsname",
		event_sport_label: "Sportart",
		event_sport_option: "Sportart auswählen",
		event_start_date_label: "Startdatum",
		event_end_date_label: "Enddatum",
		event_poster_label: "Bild",
		event_banner_label: "Banner",
		event_short_description_label: "Kurze Beschreibung",
		event_long_description_label: "Lange Beschreibung",
		event_address_label: "Adresse",
		event_postal_code_label: "Postleitzahl",
		event_city_label: "Stadt",
		event_country_label: "Land",
		event_name_error:
			"Der Name Ihrer Veranstaltung muss länger als 3 Zeichen sein",
		event_sport_error: "Sie müssen eine Sportart wählen",
		event_start_date_error:
			"Das Datum kann nicht vor dem heutigen Datum liegen (oder muss mit dem im SIFFA eingegebenen Datum übereinstimmen, wenn es sich um einen FFA-Code handelt)",
		event_ffa_siffa_error:
			"Fehlende FFA-Autorisierung. Haben Sie Ihre Veranstaltung mit dem DAG-System auf CALORG verknüpft?",
		event_end_date_error:
			"Sie müssen ein Datum später als das heutige Datum wählen",
		event_short_description_error:
			"Die kurze Beschreibung muss zwischen 3 und 190 Zeichen liegen",
		event_address_error: "Ihre Adresse muss länger als 3 Zeichen sein",
		event_postal_code_error: "Ihre Postleitzahl muss länger als 3 Zeichen sein",
		event_city_error: "Ihre Stadt muss mehr als 3 Zeichen haben",
		event_country_error: "Sie müssen ein Land wählen",
		event_country_option: "Land auswählen",
		event_timezone_label: "Zeitzone",
		event_places_label: "Verfügbare Plätze",
		event_timezone_option: "Zeitzone auswählen",
		event_rules_label: "Veranstaltungsregeln",
		event_run_name_label: "Rennname",
		event_run_date_label: "Renndatum",
		event_run_member_label: "Wenn im Team, Anzahl der Mitspieler",
		event_run_transfer_label: "Erlaubt die Übertragung von Anmeldungen?",
		event_run_details_label: "Zusätzliche Informationen",
		event_run_details:
			"Falls Sie mehr Informationen über das Rennen geben möchten",
		event_run_number_members_label: "Anzahl der Mitspieler",
		event_run_places_label: "Verfügbare Plätze",
		event_run_start_date_label: "Startdatum",
		event_run_end_date_label: "Enddatum",
		event_run_same_bib_label: "Gleiche Startnummer für das Team",
		event_run_fftri_code: "FFTRI-Wettbewerbscode",
		event_run_fftri_distance: "Distanztyp",
		event_run_licence_accepted: "Akzeptierte Lizenzen",
		event_run_licence_accepted_tooltip:
			"Akzeptierte Lizenzarten für dieses Rennen",
		event_run_licence_accepted_all: "Alle Lizenzen",
		event_run_licence_accepted_comp: "Wettbewerbs-Lizenzen",
		event_run_same_bib_no: "Nein",
		event_run_same_bib_yes: "Ja",
		event_run_make_it_visible:
			"Liste der Läufer auf chronometrage.com sichtbar machen ?",
		event_run_make_it_visible_info:
			"Mit einem Häkchen in diesem Kästchen autorisieren Sie die Veröffentlichung der Liste der registrierten Läufer auf der Website chronometrage.com",
		event_add_run: "Rennen hinzufügen",
		event_delete_run: "Rennen löschen",
		event_undo_delete_run: "Rennen nicht löschen",
		event_prices: "Preise",
		event_run: "Rennen",
		event_run_designation_label: "Bezeichnung",
		edit_run_title: "Rennen bearbeiten",
		edit_tarif_title: "Preis bearbeiten",
		edit_contact_title: "Kontakt bearbeiten",
		edit_rib_title: "Bankkonto bearbeiten",
		deleteConfigPointError_1451:
			"Bitte löschen Sie zuerst alle Segmente, die diesen Erkennungspunkt enthalten.",
		event_run_age_control_label: "Altersbegrenzung",
		event_run_age_control_option: "Keine Altersbegrenzung",
		event_run_age_min_label: "Mindestalter",
		event_run_age_max_label: "Höchstalter",
		event_run_add_price: "Preis hinzufügen",
		event_add_contact: "Kontakt hinzufügen",
		event_add_rib: "Bankkonto hinzufügen",
		validate: "Bestätigen",
		fees_inclued: "inklusive",
		fees_exclued: "exklusive",
		event_fees_inclued: "Inklusive Gebühren",
		event_fees_exclued: "Exklusive Gebühren",
		event_starting_date: "Start:",
		event_ending_date: "Ende:",
		event_age_disponibility: (min: any, max: any) =>
			`Verfügbar von ${min} Jahren bis ${max} Jahren`,
		event_price_fee_inclued: (fees: number) =>
			`inklusive ${fees.toFixed(2)}€ Gebühren`,
		event_price_fee_exclued: (fees: number) => `+ ${fees.toFixed(2)}€ Gebühren`,
		event_run_price: "Preis",
		event_run_fees_type_label: "Gebührentyp",
		event_run_type_control_event: "Nach Veranstaltungsdatum",
		event_run_type_control_subscription: "Nach Anmeldedatum",
		event_run_type_control_year_event: "Nach Veranstaltungsjahr",
		event_run_free_run: "Kostenloses Rennen?",
		event_no_free_run: "Nein",
		event_free_run: "Ja",
		event_free_run_text: "Kostenloses Rennen",
		event_ffa_siffa_label: "FFA-Wettbewerbscode",
		event_configure_sub_form: "Formular konfigurieren",
		event_edit_run: "Rennen bearbeiten",
		event_run_form_title: "Anmeldeformular Konfiguration",
		event_tooltip_form: "Ein Formular ist für diesen Tarif konfiguriert",
		event_field_nom_label: "Nachname",
		event_field_prenom_label: "Vorname",
		event_field_sexe_label: "Geschlecht",
		event_field_dateNaissance_label: "Geburtsdatum",
		event_field_mail_label: "E-Mail",
		event_field_ville_label: "Stadt",
		event_field_codePostal_label: "Postleitzahl",
		event_field_pays_label: "Land",
		event_field_nationalite_label: "Nationalität",
		event_field_telephone_label: "Telefon",
		event_field_telephoneUrgence_label: "Notfalltelefon",
		event_field_numLicence_label: "Lizenznummer",
		event_field_licence_label: "Lizenz",
		event_field_certificat_label: "Ärztliches Attest",
		event_field_pps_label: "PPS-Nummer (FFA)",
		event_field_adresse1_label: "Adresse",
		event_field_adresse2_label: "Adresszeile 2",
		event_field_club_label: "Club",
		event_field_team_label: "Team",
		event_field_ffa_label: "FFA CNIL",
		event_field_autorisationParentale_label: "Elterliche Erlaubnis",
		event_field_tiers_label: "Dritte Partei",
		event_field_dechargeResponsabilite_label: "Haftungsausschluss",
		group_fields_nominative: "Nominative Felder",
		group_fields_FFA: "FFA Felder",
		group_fields_others: "Andere Felder",
		infos_ffa_numlicence_optional_message:
			"Der Läufer kann seine FFA-Lizenznummer eingeben",
		infos_ffa_numlicence_required_message:
			"Der Läufer muss seine FFA-Lizenznummer eingeben",
		infos_ffa_ppsandlicence_optional_message:
			"Der Läufer kann seine PPS oder FFA-Lizenznummer eingeben",
		infos_ffa_ppsandlicence_required_message:
			"Der Läufer muss seine PPS oder FFA-Lizenznummer eingeben",
		enter_text: "Geben Sie Ihre Beschreibung ein",
		enter_mail_content: "Schreiben Sie den Inhalt Ihrer E-Mail...",
		enter_mail_object: "Schreiben Sie den Betreff Ihrer E-Mail...",
		sport_event_contacts: "Kontaktverwaltung",
		sport_event_ribs: "Bankkontoverwaltung",
		event_raisonSocial_label: "Rechtlicher Name",
		event_nom_label: "Nachname",
		event_prenom_label: "Vorname",
		event_adresse1_label: "Adresse",
		event_adresse2_label: "Adresszeile 2",
		event_ville_label: "Stadt",
		event_codePostal_label: "Postleitzahl",
		event_pays_label: "Land",
		country_home: "Land",
		event_telephone1_label: "Telefon",
		event_telephone2_label: "Sekundärtelefon",
		event_mail_label: "E-Mail",
		event_siteWeb_label: "Website",
		event_facebook_label: "Facebook",
		event_twitter_label: "Twitter",
		event_instagram_label: "Instagram",
		delete_tarif_title: "Möchten Sie den Tarif wirklich löschen?",
		delete_run_title: "Möchten Sie das Rennen wirklich löschen?",
		restore_deletes: "Gelöschte Elemente wiederherstellen",
		restore: "Änderungen rückgängig machen",
		delete_contact_title: "Möchten Sie den Kontakt wirklich löschen?",
		create_step: "Schritt hinzufügen",
		create_route: "Strecke hinzufügen",
		step_name_label: "Schrittname",
		event_distance_label: "Entfernung (in Metern)",
		sport_event_steps: "Schritt Konfiguration",
		sport_event_parcours: "Strecken Konfiguration",
		sport_event_segments: "Etappen Konfiguration",
		parcours_name_label: "Streckenname",
		segment_name_label: "Etappenname",
		parcours_file_label: "GPX-Datei",
		create_parcours: "Strecke hinzufügen",
		update_parcours: "Strecke bearbeiten",
		create_segment: "Etappe hinzufügen",
		update_segment: "Etappe bearbeiten",
		delete_step_title: "Möchten Sie den Schritt wirklich löschen?",
		delete_segment_title: "Möchten Sie die Etappe wirklich löschen?",
		delete_parcours_title: "Möchten Sie die Strecke wirklich löschen?",
		checkpoint_debut_label: "Startpunkt",
		checkpoint_fin_label: "Endpunkt",
		parcours_active_label: "Aktive Strecke",
		active: "aktiv",
		event_fees: "Gebühren",
		dag_fees: "DAG Gebühr (inkl. MwSt.)",
		subscription_income: "Ihr Einkommen",
		subscription_fees_sup: "Ihre MwSt.-eingeschlossene Anteile",
		subscription_fees_total: "Gesamte Anmeldegebühren (inkl. MwSt.)",
		event_sport_price: "Rennpreis",
		event_fftri_default_distance: "Wählen Sie eine Strecke",
		designation_label: "Bezeichnung",
		unlimited: "Unbegrenzt",
		nomBanque_label: "Bankname",
		iban_label: "IBAN",
		bic_label: "BIC",
		inCharge_label: "Ich bin der gesetzliche Vertreter",
		lastname_label: "Nachname",
		firstname_label: "Vorname",
		birthdate_label: "Geburtsdatum",
		email_label: "E-Mail",
		address_label: "Adresse",
		city_label: "Stadt",
		postalCode_label: "Postleitzahl",
		country_label: "Land",
		phone_label: "Telefon",
		sex_label: "Geschlecht",
		genderHfull: "Männlich",
		genderFfull: "Weiblich",
		genderXfull: "Andere",
		select_type_rib: "Wählen Sie eine Rolle",
		see_contract: "Vertrag einsehen",
		add_general_option: "Fügen Sie eine globale Option hinzu",
		delete_stage_cannot_start_live:
			"WARNUNG: Wenn Sie diese Phase löschen, ohne eine andere zu erstellen, können Sie nicht live gehen.",
		event_configuration: "Ich möchte ein Event organisieren",
		event_identity: "Event-Identität",
		event_runs: "Rennen",
		event_options: "Optionen",
		event_subscription: "Anmeldekonto",
		event_contacts: "Kontakte",
		event_rib: "Bankverbindungen",
		subscriptions: "Abonnements",
		live_results: "Live / Ergebnisse",
		live_positions: "Läuferposition",
		manage_detections: "Erkennungsmanagement",
		detection_dashboard: "Erkennungsübersicht",
		manage_runners: "Läuferverwaltung",
		manage_live: "Live-Verwaltung",
		manage_steps: "Schrittkonfiguration",
		manage_parcours: "Streckenverwaltung",
		manage_segments: "Segmentverwaltung",
		bib_plan_exemple_1:
			"Liste = 1;2;8;10 (Startnummern 1, 2, 8 und 10 werden nicht berücksichtigt)",
		bib_plan_exemple_2:
			"Bereich = 5:10 (Startnummern von 5 bis 10, inkl. 5, 6, 7, 8, 9 und 10, werden nicht berücksichtigt)",
		bib_plan_exemple_3:
			"Kombination 1 = 2:4;7:8 (Startnummern 2, 3, 4, 7 und 8 werden nicht berücksichtigt)",
		bib_plan_exemple_4:
			"Kombination 2 = 2;4:6;8 (Startnummern 2, 4, 5, 6 und 8 werden nicht berücksichtigt)",
		event_name:
			"Wird zur Identifizierung der Aktivität während der Anmeldung verwendet.",
		event_places:
			"Begrenzt Anmeldungen auf eine definierte Anzahl von Plätzen (leer lassen für unbegrenzte Plätze).",
		event_start_date: "Definiert das Startdatum der Aktivität.",
		event_end_date: "Definiert das Enddatum der Aktivität.",
		event_team_number:
			"Definiert, wie viele Teilnehmer ein Team ausmachen (leer lassen, wenn die Aktivität nicht auf Teams basiert).",
		event_transfer:
			"Ermöglicht es den Teilnehmern, ihre Anmeldung auf einen anderen Benutzer zu übertragen.",
		event_price_name:
			"Beschreibt den Rennpreis (z.B. Frühbucher, Kind, Erwachsener).",
		event_age: "Definiert eine Altersgrenze für die Anmeldung.",
		event_min_age: "Legt das Mindestalter für die Anmeldung fest.",
		event_max_age: "Legt das Höchstalter für die Anmeldung fest.",
		event_price_start_date:
			"Definiert das Startdatum, an dem der Preis verfügbar wird. Preise sind vor diesem Datum nicht sichtbar.",
		event_price_end_date:
			"Definiert das Enddatum, an dem der Preis nicht mehr verfügbar ist. Preise sind nach diesem Datum nicht sichtbar.",
		event_price_free:
			"Gibt an, ob der Preis kostenlos ist. Teilnehmer müssen keine Gebühren zahlen, aber diese werden Ihnen berechnet.",
		event_price_price:
			"Definiert den Rennpreis (zusätzliche Gebühren können anfallen).",
		event_price_fees_type:
			"Gibt an, ob die Gebühren exklusiv (nicht im Rennpreis enthalten) oder inklusiv (im Rennpreis enthalten) sind.",
		event_price_fees:
			"Gebühren, die von der Plattform erhoben werden. Diese Gebühren sind mindestens gleich den zusätzlichen Gebühren des Marketers.",
		event_price_fees_sup:
			"Zusätzliche Gebühren, die vom Marketer angewendet werden.",
		event_price_fees_calc:
			"Plattformgebühren + zusätzliche Gebühren des Marketers.",
		event_timezone: "Wählen Sie sorgfältig für Live-Ranglistenzwecke.",
		event_defined_tz: "Zeitzone für das Event definiert",
		default_race:
			"Klassifiziert eine unbekannte Startnummer, die während des Live-Streams erkannt wird, in einem Standardrennen, wenn kein Nummerierungsplan definiert ist.",
		finances: "Finanzflüsse",
		finances_desc: "Zeigt die finanziellen Flüsse eines Events an",
		finances_resume: "Überweisungsmanagement",
		finances_resume_desc:
			"Zeigt den Status der Überweisungen für Plattform-Events an",
		placeholder_select: "Wählen Sie ein Event",
		placeholder_search: "Suchen Sie nach einem Event",
		search_client: "Suchen Sie nach einem Kunden",
		select_no_options: "Keine Events",
		report: "Bericht",
		time: "Zeit",
		section: "Phase",
		filters: "Filter",
		placeholder_select_gender: "Wählen Sie ein Geschlecht",
		placeholder_select_category: "Wählen Sie eine Kategorie",
		H: "Männlich",
		F: "Weiblich",
		A: "Andere",
		export: "Exportieren",
		export_format: "Exportformat",
		placeholder_select_format: "Wählen Sie ein Format",
		optional: "(optional)",
		warning_passage: (line: number, min: number, done: number) =>
			`Zeile ${line}: ${min} min Passage, ${done} Passagen abgeschlossen`,
		inviteNewUser: "Neuen Benutzer einladen",
		error403: "Fehler 403",
		contactOrga:
			"Kontaktieren Sie den Veranstalter, wenn Sie diesen Zugang benötigen",
		event_form_donation_required: "Die Auswahl einer Spende ist erforderlich",
		restrictedPage: "Eingeschränkte Seite",
		permissions: "Berechtigungen",
		roles: "Rollen",
		search_permissions: "Nach einem Namen suchen",
		invoice: "Rechnung",
		dag_share_ht: "DAG-Anteile ohne Steuer",
		orga_share_ht: "Orga-Anteile ohne Steuer",
		total_ht: "Gesamt ohne Steuer",
		total_ttc: "Gesamt inkl. Steuer",
		com_ht: "Provision ohne Steuer",
		bill_com: "Rechnungsstatus",
		bill_orga: "Rechnung Orga",
		title: "Titel",
		quantity: "Menge",
		unit_prix_ht: "P.U ohne Steuer",
		unit_prix_ttc: "P.U inkl. Steuer",
		nbSubs: "Abonnenten",
		StartPeriode: "Zeitraum Anfang",
		EndPeriode: "Zeitraum Ende",
		paymentDone: "Zahlung erfolgt",
		validatePayment: "Zahlung bestätigen",
		toastPaymentSuccess: "Überweisung bestätigt",
		toastPaymentError:
			"Ein Fehler ist bei der Überweisungsbestätigung aufgetreten",
		downloadContractAskValidation:
			"Vertrag herunterladen und um Bestätigung bitten",
		contract_explanation:
			"Um die Veröffentlichung Ihres Events abzuschließen, muss ein Vertrag erstellt werden. Nach Bestätigung Ihrer Anfrage zur Veröffentlichung senden wir Ihnen diesen Vertrag per E-Mail, der dann ausgefüllt und an unser Team zurückgesendet werden muss: ",
		licences: "Lizenzen",
		licences_desc: "Verwaltung von DAG-Kundenlizenzen",
		clients: "Kunden",
		clients_desc: "DAG-Kundenverwaltung",
		toast_invoice_error:
			"Ein Fehler ist aufgetreten. Überprüfen Sie, ob das Event geschlossen und das RIB vorhanden ist.",
		documents: "Dokumente",
		total_subs: "Gesamtanzahl der Abonnements",
		total_subs_online: "Gesamtanzahl der Online-Abonnements",
		total_subs_manual: "Anzahl der manuellen Abonnements",
		total_subs_imported: "Anzahl der importierten Abonnements",
		total_subs_in_place: "Anzahl der vor Ort vorgenommenen Abonnements",
		docMissing: "Fehlende Dokumente",
		manualCancel: "Abbrechen",
		orga_title: "Organisator werden",
		signup_error_country: "Land ist ungültig",
		signup_error_city: "Stadt ist ungültig",
		signup_error_postal_code: "Postleitzahl ist ungültig",
		signup_error_adresse: "Adresse ist ungültig",
		sub_validation_message: "Grund fehlt",
		surrended: "Abgegeben",
		runEndDate: "Enddatum",
		runMaxNbOfSlots: "Maximale Anzahl der Plätze",
		runnerChart: "Läufer-Charta",
		yourParentalAuthorizationModel: "Ihr Modell der Elterngenehmigung",
		addBban: "Fügen Sie ein RIB hinzu",
		editBban: "Bearbeiten Sie ein RIB",
		bbanType: "RIB-Typ",
		bbanName: "RIB-Name",
		bankName: "Name der Bank",
		iban: "IBAN",
		bicSwift: "BIC/SWIFT",
		areYouThePersonInCharge: "Sind Sie der gesetzliche Vertreter?",
		personInChargeInformations: "Informationen vom gesetzlichen Vertreter",
		subOptions: "Optionen",
		subEdit: "Abonnement bearbeiten",
		subscription: "Abonnement",
		runName: "Name des Rennens",
		teamName: "Teamname",
		runStartDate: "Startdatum",
		noClub: "Kein Club",
		loginMissing: "Anmeldedaten fehlen",
		createNewEdition: "Neue Ausgabe erstellen",
		disableSubscriptions: "Abonnements deaktivieren",
		activateSubscriptions: "Abonnements aktivieren",
		inputs_edition_not_complete: "Bitte füllen Sie alle Eingabefelder aus",
		error_new_edition: "Fehler beim Erstellen einer neuen Ausgabe",
		mailUsed: "E-Mail ignoriert. Bereits verwendet.",
		mailUsedTooltip:
			"Überprüfen Sie Vorname und Nachname. Diese sind möglicherweise falsch geschrieben.",
		succes_stopsubscription_event: "Abonnements erfolgreich deaktiviert",
		error_stopsubscription_event:
			"Ein Fehler ist beim Deaktivieren der Abonnements aufgetreten",
		results: "Ergebnisse",
		displayOrder: "Anzeige-Reihenfolge",
		moveAbove: "Nach oben verschieben",
		moveBelow: "Nach unten verschieben",
		moveTop: "Nach oben verschieben",
		moveBottom: "Nach unten verschieben",
		cannotDeleteRunWithSubscription:
			"Sie können ein Rennen mit Abonnements nicht löschen",
		addGpxFile: "GPX-Datei hinzufügen",
		gpxFile: "GPX-Datei",
		ShouldBeNotSameDate: "Daten sollten nicht gleich sein",
		toastSuccessAddingRib: "Rippe erfolgreich hinzugefügt",
		toastErrorAddingRib: "Ein Fehler ist beim Hinzufügen der Rippe aufgetreten",
		placeholder_select_rib: "Wählen Sie eine Rippe",
		processed: "Verarbeitet",
		earnings: "Einnahmen",
		chartTitleSubs: "Abonnenten / Rennen",
		chartTitleGenders: "Geschlechter",
		chartTitleCases: "Fälle",
		noOptions: "Keine Optionen verfügbar",
		noAdditionalInformation: "Keine zusätzlichen Informationen",
		saveOk: "Speichern erfolgreich",
		saveError: "Ein Fehler ist beim Speichern aufgetreten",
		shareLiveTrackingUrl: "Live-Tracking-URL teilen",
		cantPreview:
			"Die Vorschau ist für dieses Dokument nicht verfügbar (zu groß). Bitte laden Sie es herunter.",
		liveTypeOfRun: "Welcher Typ von Rennen?",
		liveDateOfRun: "Wann ist das Startdatum des Rennens?",
		bibNotFound:
			"Diese Startnummer gehört nicht zu Ihrem Event. Klicken Sie, um sie zu den Abonnenten hinzuzufügen",
		between: "zwischen",
		and: "und",
		none: "Keine",
		bySubscriber: " /Läufer",
		chooseCalendarRunType: "Wählen Sie einen Rennenstyp",
		chooseCalendarRunTypeSimple: "Einzelrennen (Start - Zwischenstopp - Ziel)",
		chooseCalendarRunTypeLaps: "Rundkursrennen",
		chooseCalendarRunTypeSimpleVirtual:
			"Einfaches virtuelles Rennen (Sportanwendung)",
		chooseCalendarRunTypeLapsVirtual:
			"Virtuelles Rundkursrennen (Sportanwendung)",
		chooseCalendarRunTypeTriathlon: "Triathlon",
		calendarRunDistance: "Wie weit ist das Rennen? (in Metern)",
		calendarRunLaps: "Wie viele Runden hat Ihr Rennen?",
		calendarRunDetectionPoints: "Ihre Detektionspunkte",
		calendarRunSegments: "Ihre Etappen",
		calendarRunDetectionAddPoint: "Fügen Sie einen Detektionspunkt hinzu",
		calendarRunAddSegment: "Fügen Sie eine Etappe hinzu",
		calendarRunDetectionConfigurePoint:
			"Konfigurieren Sie Ihren Detektionspunkt",
		calendarRunConfigureSegment: "Konfigurieren Sie Ihre Etappe",
		calendarRunDetectionType: "Welcher Typ ist Ihr Punkt?",
		calendarRunDetectionChooseType: "Wählen Sie einen Typ",
		finish: "Ziel",
		arrived: "Angekommen",
		advancedParams: "Erweiterte Einstellungen",
		interpassTime: "Zwischenpasszeit",
		maxNumberPass: "Maximale Anzahl der Durchgänge",
		minNumberPass: "Minimale Anzahl der Durchgänge",
		startNumberPass: "Startnummer Durchgang",
		endNumberPass: "Endnummer Durchgang",
		numberPass: "Durchgangsnummer",
		startDetectionPoint: "Eintritt",
		endDetectionPoint: "Austritt",
		latitude: "Breitengrad",
		longitude: "Längengrad",
		order: "Reihenfolge",
		radius: "Radius",
		altitude: "Höhe",
		runFormTypeError: "Sie müssen einen Rennenstyp wählen",
		runFormDistanceError: "Die Entfernung Ihres Rennens muss größer als 0 sein",
		runFormLapsError: "Die Anzahl der Runden muss größer als 0 sein",
		runFormPointNameError: "Sie müssen Ihrem Punkt einen Namen geben",
		runFormPointDistanceError:
			"Die Entfernung Ihres Punktes muss definiert werden",
		runFormPointLineError: "Eine Linie muss definiert werden",
		runFormPointTypeError: "Sie müssen einen Stichtyp wählen",
		runFormPointSameError: "Start- und Endpunkte sind gleich",
		runFormSegmentNotComplete: "Etappeninformation unvollständig",
		runFormSegmentErrorHappened:
			"Ein Fehler ist beim Speichern der Etappe aufgetreten",
		calendarRunDeleteSegment: "Etappe löschen",
		runFormDeleteSegmentConfirmation:
			"Sind Sie sicher, dass Sie diese Etappe löschen möchten?",
		runFormDeleteSegmentSuccess: "Etappe erfolgreich gelöscht",
		runFormUpdateSegmentSuccess: "Etappe erfolgreich aktualisiert",
		runFormDistanceType: "Wählen Sie den Entfernungstyp",
		whichRunConfigure: "Welches Rennen möchten Sie konfigurieren?",
		credentialDagLive: "Ihre DAG Live-Anmeldeinformationen",
		saveCredentialSuccess: "Ihre Anmeldeinformationen wurden gespeichert",
		saveCredentialError:
			"Ein Fehler ist beim Speichern Ihrer Anmeldeinformationen aufgetreten.",
		saveConfigRunSuccess: "Ihre Konfiguration wurde gespeichert.",
		saveConfigRunError:
			"Ein Fehler ist beim Aktualisieren Ihrer Konfiguration aufgetreten.",
		saveConfigPointSuccess: "Ihr Punkt wurde gespeichert.",
		saveConfigPointError:
			"Ein Fehler ist beim Speichern Ihres Punktes aufgetreten.",
		deleteConfigPointSuccess: "Ihre Konfiguration wurde gelöscht.",
		deleteConfigPointError:
			"Ein Fehler ist beim Löschen Ihres Punktes aufgetreten.",
		seeAllDetections: "Alle Detektionen anzeigen",
		rankingHeaderRank: "Rk",
		rankingHeaderName: "Name",
		rankingHeaderBib: "Startnummer",
		rankingHeaderLastPassage: "Letzter Durchgang",
		rankingHeaderDetections: "Detektionen",
		rankingHeaderTime: "Zeit",
		rankingHeaderCategoryRank: "Kat. Rk.",
		rankingHeaderSexRank: "Geschlechts-Rk.",
		rankingHeaderRun: "Rennen",
		rankingHeaderTeam: "Team",
		rankingHeaderActions: "Aktionen",
		editRunnerTooltip:
			"Bitte beachten Sie, dass das Aktualisieren des Läufers auch alle anderen Abonnements ändert",
		editRunnerRunTooltip:
			"Das Aktualisieren der Renndaten wirkt sich auf die Rangliste aus",
		selectRunPlaceholder: "Wählen Sie ein Rennen",
		chooseTeam: "Wählen Sie ein Team",
		createTeam: "Erstellen Sie ein Team",
		resetOnRunner: "Zurücksetzen beim Läufer",
		bibTime: "Startnummer-Zeit",
		manageDetections: "Detektionen verwalten",
		totalPriceOfRun: "Gesamtpreis des Rennens (ohne Gebühren)",
		invalidateSub: "Registrierung ungültig machen?",
		errorVerifyBibAlreadyUse:
			"Ein Fehler ist aufgetreten. Überprüfen Sie, ob die Startnummer bereits verwendet wird.",
		addDetection: "Eine Detektion hinzufügen",
		setRunnerSurrended: "Läufer als aufgegeben markieren",
		setRunnerDisqualified: "Läufer als disqualifiziert markieren",
		setRunnerStarting: "Läufer als startend markieren",
		setRunnerNotStarting: "Läufer als nicht startend markieren",
		updateRunnerStatusSurrended: "Läuferstatus auf aufgegeben aktualisieren?",
		updateRunnerStatusDisqualified:
			"Läuferstatus auf disqualifiziert aktualisieren?",
		updateRunnerStatusStarting: "Läuferstatus auf startend aktualisieren?",
		updateRunnerStatusNotStarting:
			"Läuferstatus auf nicht startend aktualisieren?",
		notStarting: "Nicht startend",
		patchNotes: "Update-Notizen",
		patchAddNotes: "Hinzufügen von Update-Notizen",
		patchAddJetcodes: "Verfügbarkeit von Jetcodes für ein Event",
		patchAddRoles: "Benutzerzugriffsrechte verwalten",
		patchCorrectDocuments: "Korrektur der Dokumentenanzeige",
		patchFixInvoiceErrorMessage: "Korrektur der Fehlermeldung zur Rechnung",
		patchChangeDashboardLinks: "Links für Event-Karten im Dashboard geändert",
		patchUpdateSendOneMail: "E-Mail an einen Läufer aktualisiert",
		patchMailHistory: "Korrektur der Anzeige der E-Mail-Historie eines Läufers",
		patchResponsive:
			"Korrektur der Responsivität zur Verbesserung der Benutzerfreundlichkeit auf mobilen Geräten",
		patchHoursPrices:
			"Korrektur der Zeiterstellung, wenn Sie einen Preis aktualisieren möchten",
		patchRedirectionToValidate:
			"Aktualisierung der Weiterleitung bei Klick auf X zur Validierung",
		patchTraduction: "Übersetzungen korrigiert",
		patchEventImages: "Event-Bilder korrigiert",
		patchCreateEdition: "Neue Ausgabe hinzufügen",
		patchPictoDocs: "Piktogramme für Dokumente hinzufügen",
		patchForgetPassword: "Passwort vergessen hinzufügen",
		patchAddPdfExport: "PDF-Export hinzufügen",
		patchAddFilterDocuments:
			"Filter für Abonnements mit ausstehenden Dokumenten hinzufügen",
		onlyWithDocStart: "Nur Abonnements mit",
		onlyWithDocStrong: " ausstehenden Dokumenten anzeigen",
		patchReasignBibsAndKeepThem:
			"Verhalten der Startnummern-Zuweisung aktualisiert",
		patchAddRegistration: "Verbesserung der Registrierung hinzufügen",
		patchGenderX: "Anzeige des Geschlechts X",
		patchRegistrations: "Verbesserung der Registrierungsverwaltung",
		patchSwitchSubscriptionStatus:
			"Abonnementstatus kann jetzt gewechselt werden",
		patchUpdateAdminStatistics:
			"Aktualisierung der Administrationsoberfläche bezüglich der Datentabellen",
		patchAddRibSelector:
			"Hinzufügen einer Funktion zum schnellen Hinzufügen von RIBs aus einer Liste von bereits verwendeten RIBs des Nutzers",
		patchEditOption: "Ermöglicht das Konfigurieren der Registrierungsoptionen",
		patchAddResults:
			"Ermöglicht das Hinzufügen von Ergebnissen als PDF-Dateien",
		patchDocumentPreview: "Verbesserung der Dokumentvorschau",
		patchUX: "Verbesserung der Benutzererfahrung",
		patchPDFExportRaces: "Hinzufügen der Auswahl von Rennen für den PDF-Export",

		deleteGpxFileConfirmation:
			"Sind Sie sicher, dass Sie diese Datei löschen möchten?",
		deletedFile: "Datei gelöscht!",
		selectRunMail: "Wählen Sie ein Rennen",
		detectionPointName: "Name des Detektionspunkts",
		detectionLigne: "Linie",
		verifyPrestation:
			"Überprüfen Sie, ob ein Live-Event läuft und Ihre DAG Live-Konfiguration korrekt ist",
		verifyCredential:
			"Ein Fehler ist aufgetreten. Überprüfen Sie Ihre DAG Live-Anmeldeinformationen.",
		noPrestationStarted:
			"Starten Sie das Live-Event zuerst, um das Ranking anzuzeigen.",
		ranked: "Rangiert",
		missing: "Fehlend",

		ffa_error_generic: "Lizenz nicht validiert",
		ffa_error_001: "Fehler im Geburtsdatumformat",
		ffa_error_002: "Unzureichende bereitgestellte Informationen",
		ffa_error_003: "Nummer nicht gefunden",
		ffa_error_004: "TP-Nummer nicht gefunden",
		ffa_error_005: "CF-Nummer nicht gefunden",
		ffa_error_006:
			"Die Nummer wird zum Zeitpunkt des Wettbewerbs nicht mehr gültig sein",
		ffa_error_007:
			"Die Nummer stimmt nicht mit Ihrem Namen/Vornamen/Geschlecht/Geburtsdatum überein",
		ffa_error_008:
			"Ihre Informationen (Name/Vorname/Geschlecht/Geburtsdatum) wurden nicht gefunden",
		ffa_error_009: "Homonymiefehler, bitte kontaktieren Sie die FFA direkt",
		ffa_error_010:
			"FFA-Service nicht verfügbar, kontaktieren Sie den Organisator (10)",
		ffa_error_011:
			"FFA-Service nicht verfügbar, kontaktieren Sie den Organisator (11)",
		ffa_error_012:
			"FFA-Service nicht verfügbar, kontaktieren Sie den Organisator (12)",
		ffa_error_013:
			"FFA-Service nicht verfügbar, kontaktieren Sie den Organisator (13)",
		ffa_error_014:
			"FFA-Service nicht verfügbar, kontaktieren Sie den Organisator (14)",
		ffa_error_015: "PPS-Nummer nicht gefunden",
		SUB: "SUB",
		ARR: "ARR",
		DNS: "DNS",
		DNF: "DNF",
		DQ: "DQ",
		sport_identity_error_name_taken: "Der Eventname ist bereits vergeben",
		add_licence: "Fügen Sie eine Lizenz hinzu",
		udc_licence_remaining: "Verbleibende UDC-Lizenzen",
		total_key_credit: "Gesamte Codierungs-Credits",
		donation_required: "Spende erforderlich",
		donation_minimum_amount: "Mindestspendenbetrag",
		additional_information: "Zusätzliche Informationen",
		shop_licence: "Lizenzen",
		shop_material: "Hardware",
		shop_chip: "Chips",
		global_search: "Suche",
		global_search_desc: "Suche nach Personen, Kunden, Befehlen...",
		quickstart: "Schnellstart",
		features: "Funktionen",
		shop: "Shop",
		reset: "Zurücksetzen",
		persons: "Personen",
		commands: "Befehle",
		search_results: "Suchergebnisse",
		account_type: "Kontotyp",
		registration: "Registrierung",
		ttc_price: "Preis inkl. MwSt.",
		command_date: "Bestelldatum",
		payer: "Zahler",
		donation_list: "Spendenliste",
		no_run_with_donation:
			"Es gibt kein Rennen mit einer Spende für eine Organisation",
		donation_value: "Spendenwert",
		no_donation_for_association: "Keine Spende für diese Organisation",
		donation_campain: "Spendenkampagne",
		campain_email: "E-MAIL DER ORGANISATION",
		campain_objective: "KAMPAGNENZIEL",
		campain_minimum: "MINDESBETRAG",
		campain_default: "STANDARDBETRAG",
		pps_number: "PPS-Nummer",
		all_runs: "Alle Läufe",
		all_runners: "Alle Läufer",
		all_valid_subsriptions: "Alle gültigen Abonnements",
		all_invalid_subsriptions: "Alle ungültigen Abonnements",
		all_incomplete_subsriptions: "Alle unvollständigen Abonnements",
		active_users: "Aktive Nutzer",
		tracking_url: "Tracking-URL",
		document_title: "Dokumenttitel",
		ranking: "Rangliste",
		subscription_history_by_run: "Abonnementshistorie nach Rennen",
		rights_add_subscription: "Registrierung",
		rights_add_subscription_infos:
			"Ermöglicht das Hinzufügen eines Vor-Ort-Teilnehmers",
		rights_import_subscriptions: "Registrierungen importieren",
		rights_import_subscriptions_infos: "Im Excel-Format",
		rights_export_subscriptions: "Registrierungen exportieren",
		rights_export_subscriptions_infos:
			"Im Excel-, CSV-, PDF- oder GmDAG-Format",
		rights_validate_documents: "Dokumentenvalidierung",
		rights_validate_documents_infos:
			"Validierung von ärztlichen Attesten und Elternbefreiungen",
		rights_send_mails: "Kommunikation mit Teilnehmern (E-Mails)",
		rights_subscription_live: "Registrierungsrechte und Live-Management",
		rights_run_config: "Rennkonfiguration",
		rights_rib_config: "Bearbeitung von Bankdaten (RIB)",
		rights_contact_config: "Bearbeitung der Kontaktdaten",
		rights_runner_management: "Läuferverwaltung",
		rights_runner_management_infos: "Zugang zur Liste, Passzeiten, etc.",
		rights_dossard_assign: "Nummernvergabe",
		rights_dossard_assign_infos:
			"Ermöglicht das Ändern der Startnummer eines Läufers",
		live_chrono: "Live / Zeitmessung",
		passage_points: "Durchgangspunkte",
		times: "Zeiten",
		detections: "Detektionen",
		general_ranking: "Gesamtwertung",
		sex_ranking: "Geschlechterwertung",
		cat_ranking: "Kategoriewertung",
		minAgeRequired: (minAge: number) =>
			`Das Mindestalter beträgt ${minAge} Jahre.`,
		maxAgeRequired: (maxAge: number) =>
			`Das Höchstalter beträgt ${maxAge} Jahre.`,
		yearBetweenRequired: (minAge: number, maxAge: number) =>
			`Das Geburtsjahr muss zwischen ${minAge} und ${maxAge} liegen.`,
		ageBetweenRequired: (minAge: number, maxAge: number) =>
			`Sie müssen zum Zeitpunkt der Anmeldung mindestens ${minAge} Jahre alt oder höchstens ${maxAge} Jahre alt sein.`,
		activities: "Aktivitäten",
		not_all_position_display:
			"Die Anzahl der Positionen wurde angepasst, es werden nur noch 500 Positionen angezeigt",
		success_time_saved: "Die Zeit wurde gespeichert",
		error_time_saved: "Beim Speichern der Zeit ist ein Fehler aufgetreten",
		display_gpx: "GPX-Tracks sichtbar machen ?",
		error_create_licence: "Fehler beim Erstellen der Lizenz",
		success_create_licence: "Lizenz erfolgreich erstellt",
		error_create_encode_key: "Fehler beim Erstellen des Encode-Schlüssels",
		success_create_encode_key: "Encode-Schlüssel erfolgreich erstellt",
		live_ranking: "Live-Ranking",
		add_ranking: "Add ranking",
		deselect: "Deselect",
		ranking_name: "Ranking name",
		available_filters: "Available filters",
		ranking_filters_explanation:
			"Diese Filter definieren die für die Klassifizierung verfügbaren Kombinationen",
		toast_error_save_ranking_config: "Fehler beim Speichern der Rangliste",
		toast_success_save_ranking_config: "Ranking gespeichert",
		toast_empty_ranking_config: "Rangname und Filter dürfen nicht leer sein",
		sure_delete_ranking_list: (name: string) =>
			"Wollen Sie diese Rangliste wirklich löschen?",
		success_delete_ranking_list: "Rangliste gelöscht",
		error_delete_ranking_list: "Fehler beim Löschen der Rangliste",
		custom_rankings: "Personalisiertes Ranking",
		care_choice_unavaible:
			"Bitte beachten Sie, dass diese Auswahlmöglichkeiten für den Ranking-Filter nicht mehr verfügbar sind.",
		list_name_required:
			"Der Listenname ist obligatorisch und muss eindeutig sein",
		ranking_title: "Rangliste",
		ranking_all_choices: "Alle",
		ranking_all_races: "Alle Rennen",
		ranking_export_experimental: "(experimentell)",
		ranking_export_info1:
			"Exportiert die Ergebnisse, wie sie auf Ihrem Bildschirm im Excel-Format erscheinen.",
		ranking_export_info2:
			"Die Spalten sind die gleichen wie auf der Läuferverwaltungsseite angezeigt.",
		ranking_export_pdf_newline: "Seitenumbruch bei Geschlechtswechsel:",
		ranking_export_pdf_unranked: "Unbewertete anzeigen:",
		ranking_export_pdf_all_races: "Alle Rennen",
		price_dates_archived: "Preisdaten archiviert"
	},

	es: {
		delete_old_detections:
			"¿Quieres conservar las detecciones de la última actuación?",
		select_race: "Por favor selecciona una carrera",
		select_club: "Selección de club",
		select_team: "Selección de equipo",
		select_noClub: "No hay club disponible",
		select_noTeam: "No hay equipo disponible",
		select_gender: "Por favor selecciona el género",
		select_bib: "Por favor selecciona un número de dorsal",
		select_country: "Por favor selecciona un país",
		select_countrySearch: "Buscar...",
		select_countryNoResult: "Sin resultados",
		published: "Publicado",
		unpublished: "No publicado",
		edit: "Editar",
		registered: "registrado",
		toValidate: "por validar",
		ofTurnover: "de facturación",
		publishEvent: "Publicar evento",
		unpublishEvent: "Despublicar evento",
		publishEventWarning:
			"Advertencia, estás a punto de publicar este evento, lo que significa que será visible para todos los usuarios de Chronometrage.com. ¿Estás seguro de que deseas continuar?",
		unpublishEventWarning:
			"Advertencia, estás a punto de despublicar este evento, lo que significa que ya no será visible para los usuarios de Chronometrage.com. ¿Estás seguro de que deseas continuar?",
		editEvent: "Editar evento",
		editRunner: "Editar corredor",
		handleDetection: "Gestionar detecciones",
		eventName: "Nombre del evento",
		category: "Categoría",
		date: "Fecha",
		description: "Descripción",
		poster: "Cartel",
		ffaFFTRI: "FFA / FFTRI",
		ffa: "FFA",
		fftri: "FFTRI",
		location: "Ubicación",
		rulesPDF: "Reglamento PDF",
		website: "Sitio web",
		file: "Archivo",
		rib: "RIB",
		addRunner: "Agregar corredor",
		addRegistration: "Agregar inscripción",
		sendEmailTitle: "Redactar el correo para los participantes registrados",
		sendOneEmailTitle: "Redactar el correo para el participante",
		insight: "Vista previa del correo",
		config_title: "Gestión",
		runs_title: "Carreras",
		update_presentation: "Presentación",
		update_runs: "Carreras",
		update_options: "Opciones",
		update_contacts: "Contactos",
		update_sponsors: "Patrocinadores",
		update_ribs: "RIBs",
		configure_steps: "Etapas",
		configure_live: "Seguimiento en vivo",
		configure_results: "Enviar resultados",
		sync_folomi_error: "Hay más inscripciones que corredores.",
		sync_folomi_action: "Hacer clic para sincronizar",
		sync_folomi_button: "Sincronizar corredores",
		sync_folomi_race_subscribed: "registrado pero solo",
		sync_folomi_race_detected: "corredores detectados",
		ask_validation: "Solicitar publicación",
		unpublish: "Despublicar evento",
		validation_asked: "Publicación solicitada",
		preview: "Vista previa",
		preview_invoice: "Vista previa de factura",
		edit_invoice: "Edición de factura",
		commissions: "Información de comisión",
		donations: "Donaciones",
		invoice_title: "Facturación",
		option: "Opción",
		configure_options: "Configurar opciones",
		configure_lists: "Campos personalizados",
		lists: "Configuración de campos",
		add_list: "Agregar un campo",
		edit_list: "Editar un campo",
		delete_list: "Eliminar una lista",
		sure_delete_list: "¿Estás seguro de que deseas eliminar",
		add_list_choice: "Agregar opciones",
		empty_list_text: "No puedes importar una lista vacía",
		list_choice_name_too_short: "El nombre de la opción es demasiado corto",
		add_global_option: "Agregar una opción global",
		lists_desc:
			"Estos campos estarán disponibles en el formulario de inscripción",
		lists_text_desc:
			"Puedes pegar un texto separado por punto y coma ( ; ) o saltos de línea",
		list_name_too_short: "El nombre del campo es demasiado corto",
		list_available_choices: "Opciones disponibles",
		import_list: "Importar esta lista",
		or: "o",
		add_price: "Agregar un precio",
		add_option: "Agregar una opción",
		add_product: "Agregar un producto",
		min_quantity: "Cantidad mínima",
		max_quantity: "Cantidad máxima",
		designation: "Designación",
		option_type: "TIPO DE CAMPO DESEADO",
		is_required: "¿QUIERES HACER OBLIGATORIA LA OPCIÓN?",
		product: "Producto",
		price: "Precio",
		type_dropdown_description:
			"Lista de diferentes productos (Ejemplo: Talla de camiseta, S, M, L)",
		type_check_description:
			"Casilla de verificación (Ejemplo: Autobús, Comida)",
		type_quantity_description:
			"Solicitud de cantidad para un producto (Ejemplo: Número de comidas para corredores)",
		delete_title: "Eliminación",
		delete_message: "¿Estás seguro de que deseas eliminar",
		price_start_date: "FECHA Y HORA DE INICIO DEL PRECIO",
		price_end_date: "FECHA Y HORA DE FIN DEL PRECIO",
		price_start_date_donation: "FECHA Y HORA DE INICIO DE LA DONACIÓN",
		price_end_date_donation: "FECHA Y HORA DE FIN DE LA DONACIÓN",
		warning_no_price:
			"Debe existir al menos un precio (incluso gratuito) para esta carrera para agregar una opción.",
		warning_delete: "(La operación tendrá efecto después de guardar)",
		error_qty_min_0: "Las cantidades deben ser mayores a 0",
		error_qty_min_max:
			"La cantidad mínima debe ser menor que la cantidad máxima",
		error_too_small: "(Demasiado pequeño)",
		default: "Por defecto",
		prestation: "Lanzar el servicio",
		loading: "Cargando",
		importResults: "Importar resultados",
		set: "Establecer",
		notSet: "No establecido",
		filesToBeChecked: "archivos por verificar",
		completedAndValidatedFiles: "archivos completados y validados",
		viaOptions: "vía opciones",
		confirm: "Confirmar",
		cancel: "Cancelar",
		interrupt: "Interrumpir",
		previous: "Anterior",
		next: "Siguiente",
		save: "Guardar",
		search: "Buscar",
		download: "Descargar",
		allM: "Todos",
		allF: "Todas",
		delete: "Eliminar",
		inTeam: " - En equipo",
		send: "Enviar",
		create_event: "Crear un evento",
		required: "Obligatorio",
		discard_changes: "¿Descartar cambios?",
		preview_off: "Cerrar vista previa",
		error_boundary_title: "Ocurrió un error.",
		error_boundary_support:
			"Si el problema persiste, contacta con soporte y proporciona la siguiente información:",
		back: "Atrás",
		sendMailAllSubscribers: "Enviar correo colectivo",
		sendMail: "Enviar correo",
		sendMailError: "Ocurrió un error al enviar el correo",
		resetExpired: "Restablecer expirado. Solicita un nuevo restablecimiento",
		eventSearch: "Buscar un evento",
		number_bib: "Número de dorsal",
		number_line: "Número de línea",
		time_detection: "Hora de paso",
		number_line_short: "Línea",
		whatIsJetcode: "¿Qué es un jetcode?",
		jetcodeExplanation:
			"Es una herramienta que te permite integrar el contenido de otra página (formularios de registro y pago en línea, contadores de dorsales restantes, cuenta atrás, logo de Chronometrage.com) directamente en tu sitio web.",
		howIntegrateJetcodeToMyWebsite:
			"¿Cómo integrar un jetcode en mi sitio web?",
		jetcodeIntegrationExplanation:
			"Simplemente copia los códigos proporcionados de los jetcodes deseados (incluir mis inscripciones y logo de Chronometrage.com) y pégalos en una página de tu sitio web.",
		integrateSubscriptionsForm: "Integrar el formulario de inscripción",
		integrateSubscirbersList: "Integrar la lista de inscritos",
		integrateOnlineSubscriptionLogo:
			"Integrar el logo de 'inscripción en línea'",
		engineCodeLabel:
			"Código de motor para copiar una vez en el encabezado de tu sitio",
		basketCodeLabel:
			"Código de cesta para copiar una vez en cada página de tu sitio",
		runCodeLabel: "Código específico de la carrera",
		french: "Francés",
		english: "Inglés",
		copy: "Copiar",
		copyDone: "¡Copiado!",
		insertionCodeLabel:
			"Código de inserción del carrito, este código debe insertarse una vez por página",
		language: "Idioma",
		runSelectLabel: "Carrera deseada para el jetcode",
		priceJetcodeCodeLabel:
			"Configuración de precios Jetcode para copiar en su sitio",
		theme: "Tema",
		setup: "Configuración",
		seeTimer: "Ver temporizador",
		seeSeconds: "Ver segundos",
		seeBibs: "Ver dorsales",
		seeBibsLeft: "Ver dorsales restantes",
		codeToCopyToYourWebsite: "Código para copiar en su sitio web",
		downloadProcessLabel:
			"Descargar el procedimiento en formato PDF para proporcionar a su webmaster",
		listSetup: "Configuración de la lista",
		selectARun: "Seleccionar una carrera",
		generalSettings: "Configuración general",
		dagLiveCredentials: "Credenciales DAG Live",
		sensorName: "Etiqueta",
		distance: "Distancia (m)",
		passageMin: "Pasos mínimos",
		passageMax: "Pasos máximos",
		sensor: "Sensor",
		type: "Tipo",
		start: "Inicio",
		stop: "Detener rendimiento",
		deleteDetections: "Eliminar detecciones definitivamente",
		deleteDetectionsSuccess: "Eliminación completada",
		deleteDetectionsError: "Error en la eliminación",
		prestationError: "ERROR",
		sensorSettings: "Configuración de sensores",
		addGpx: "Agregar GPX",
		chooseSensorType: "Elija el tipo de sensor",
		startLine: "Línea de salida",
		finishLine: "Línea de llegada",
		checkpoint: "Punto de control",
		addSensor: "Agregar un sensor",
		removeThisSensor: "Eliminar este sensor",
		handleRunners: "Corredores",
		handleDetections: "Detecciones",
		dashboardDetections: "Resumen",
		global_configuration: "Configuración",
		route_configuration: "Rutas",
		delete_step: "Eliminar este paso",
		edit_step: "Editar este paso",
		delete_parcours: "Eliminar esta ruta",
		edit_parcours: "Editar esta ruta",
		delete_segment: "Eliminar este segmento",
		edit_segment: "Editar este segmento",
		step: "Paso",
		parcours: "Ruta",
		segment: "Segmento",
		configure_routes: "Configurar rutas",
		configure_segments: "Configurar segmentos",
		intermediary: "Intermedio",
		start_line: "Inicio",
		end_line: "Llegada",
		typeLine1: "Inicio",
		typeLine2: "Intermedio",
		typeLine3: "Llegada",
		grid: "Diseño de cuadrícula",
		categoryReassignement: "Reasignación de categoría",
		handleResults: "Resultados",
		timezone: "¿Qué zona horaria desea mantener?",
		no_run_selected: "No se seleccionó ninguna carrera",
		placeholder_select_run: "Seleccionar una carrera",
		placeholder_select_segment: "Seleccionar un segmento",
		log_title: "Iniciar sesión en su herramienta de administración",
		forgotten_title: "¿Contraseña olvidada?",
		reset_password: "Restablecer contraseña",
		back_to_home: "Volver a la página principal",
		passwords_not_match: "Las contraseñas no coinciden",
		passsword_reset_success: "Su contraseña ha sido restablecida",
		password_too_short: "Su contraseña debe tener al menos 6 caracteres",
		send_forgotten_mail_success: "Se ha enviado un correo electrónico",
		email: "Dirección de correo electrónico",
		new_password: "Nueva contraseña",
		new_password_confirm: "Confirmar nueva contraseña",
		password: "Contraseña",
		confirmPassword: "Confirme su contraseña",
		submit: "Iniciar sesión",
		social_reason: "Razón social",
		adresse: "Dirección",
		postal_code: "Código postal",
		city: "Ciudad",
		country: "País",
		noAccount: "¿Aún no tiene una cuenta?",
		rememberMe: "Recuérdame",
		signup_button: "Crear una cuenta",
		signup: "Aún no tengo una cuenta",
		signup_title: "Regístrese en la herramienta de administración",
		already_account: "Ya tengo una cuenta",
		forgotten_password: "¿Contraseña olvidada?",
		lastname: "Apellido",
		firstname: "Nombre",
		birthdate: "Fecha de nacimiento",
		sex: "Género",
		acceptCGV: "Acepto las condiciones de uso de la plataforma",
		signup_error_firstname: "Su nombre debe tener más de una letra.",
		signup_error_lastname: "Su apellido debe tener más de una letra.",
		signup_error_birthdate:
			"Su fecha de nacimiento debe ser válida y debe tener entre 13 y 100 años.",
		signup_error_email: "Su correo electrónico no es válido.",
		signup_error_password: "Su contraseña debe tener al menos 6 caracteres.",
		signup_error_passwordConfirm: "Sus contraseñas no son idénticas.",
		signup_error_CGU: "Debe aceptar los Términos y Condiciones.",
		male: "Masculino",
		female: "Femenino",
		other: "Otro",
		legalEntity: "Entidad jurídica",
		address: "Dirección",
		team: "Equipo",
		race: "Carrera",
		bib: "Dorsal",
		bibAvailable: "Dorsal disponible",
		bibUnavailable: "Dorsal ya usado",
		invalid: "Inválido",
		bibInvalid: "Dorsal inválido",
		runner: "Corredor",
		zipCode: "Código postal",
		birthDate: "Fecha de nacimiento",
		genderH: "H",
		genderF: "M",
		genderX: "X",
		editGeneral: "General",
		editRace: "En carrera",
		nationality: "Nacionalidad",
		countryPlaceholder: "Seleccione un país",
		firstPhone: "Teléfono 1",
		secondPhone: "Teléfono 2",
		marketer: "Comercial",
		organizer: "Organizador",
		yes: "Sí",
		no: "No",
		bbanDelete: "Eliminar BBAN",
		bbanDeleteWarning:
			"Advertencia, está a punto de eliminar este BBAN, esta acción es irreversible. ¿Está seguro de que desea continuar?",
		addTeam: "Agregar un equipo",
		club: "Club",
		duplicateEmail: "Esta dirección de correo electrónico ya está en uso",
		alreadyInUse: "Ya en uso",
		editTimes: "Tiempos",
		object: "Asunto",
		mailBody: "Cuerpo del correo",
		swapBibs: "Intercambiar dorsales",
		swapFrom: "De",
		swapTo: "A",
		nbDetections: "Detecciones",
		stageDepartureHour: "Hora oficial de salida",
		handicap: "Handicap",
		miniTime: "Tiempo mínimo en la etapa",
		error404: "Error 404",
		pageDoesNotExist: "Esta página no existe",
		iThinkYouAreLost: "Creo que estás perdido",
		goBackHome: "Volver a la página principal",
		dashboard: "Tablero",
		registrations: "Inscripciones",
		event: "Evento",
		liveResults: "Cronometraje",
		menuResults: "Resultados",
		liveConfiguration: "Configuración del Live",
		statistics: "Estadísticas",
		admin: "Administración",
		jetcodes: "Jetcodes",
		toCome: "Próximos",
		today: "Hoy",
		past: "Pasados",
		myAccount: "Mi cuenta",
		logout: "Cerrar sesión",
		manageRunners: "Gestionar corredores",
		detections_dashboard: "Panel de detecciones",
		manage_dashboard: "Gestionar detecciones",
		registration_refund: "Reembolso de inscripción",
		registration_order: "Orden de inscripción",
		cgu: "Términos de uso",
		cgv: "Condiciones generales de venta",
		manage_results: "Gestionar resultados",
		startOn: "Empieza el",
		registeredContestants: "Corredores registrados",
		displayRegistrations: "Mostrar inscripciones",
		completeSubscriptionsList: "Lista completa de inscripciones",
		bibs: "Dorsales",
		bibsSearch: "Buscar un dorsal",
		firstnameSearch: "Buscar por nombre",
		lastnameSearch: "Buscar por apellido",
		statusSearch: "Buscar por estado",
		loadingRegisterForm: "Cargando formulario de inscripción",
		thanksToWait: "Gracias por esperar",
		noRace: "No hay carreras configuradas",
		teamOf: "EQUIPO de",
		invididual: "SOLO",
		importError: "Error durante la importación",
		preimportWarning: "ADVERTENCIA",
		importWarning: "ADVERTENCIA",
		preimportError: "ERROR: ESTA INSCRIPCIÓN NO SERÁ IMPORTADA...",
		preimportErrors: "ERROR: ESTAS INSCRIPCIONES NO SERÁN IMPORTADAS...",
		exportTitle: "Exportar inscripciones en",
		raceChoice: "Carreras",
		sexeChoice: "Género",
		statusChoice: "Estado",
		subscriptionType: "Tipo de suscripción",
		importTitle: "Importar inscripciones",
		toImport: "Para importar una lista de inscripciones, usa",
		configureFromAnotherRace: "Configurar desde otra carrera",
		maximumAge: "La edad máxima requerida es",
		useThisModel: "esta plantilla de archivo EXCEL",
		dragHere: "Arrastra el archivo EXCEL (formato .xlsx) aquí...",
		dragHereOr:
			"Arrastra el archivo EXCEL (formato .xlsx) aquí o haz clic para seleccionarlo",
		fileFormatNotSupported: "Formato de archivo no compatible",
		existingSubs: "registrantes previamente importados.",
		replace: "Eliminar todo y reemplazar",
		donotupdate: "Agregar solo nuevos dorsales",
		continue: "Continuar importación",
		entries: "adiciones en",
		import: "Importar",
		nbImportedRunners: "corredor(es) importado(s)",
		pre: "Pre-",
		importing: "Importando...",
		existing: "ya existente",
		adds: "adiciones en",
		add: "adiciones",
		close: "Cerrar",
		whattodo: "¿Qué quieres hacer?",
		line: "Línea",
		invalidRace: "Carrera inválida",
		bibUsed: "Dorsal ya utilizado",
		missingRace: "Carrera no proporcionada",
		missingBib: "Dorsal no proporcionado",
		yourEvents: "Tus eventos",
		yourRuns: "Tus carreras",
		bibsAllocation: "Asignación de dorsales",
		discountVouchers: "Cupones de descuento",
		form: "Formulario",
		refunds: "Reembolsos",
		accessRights: "Derechos de acceso",
		bankBBAN: "BBAN",
		manageBibsAllocation: "Gestionar asignación de dorsales",
		manageEventDiscountVouchers: "Gestionar cupones de descuento del evento",
		manageEventForm: "Gestionar formulario del evento",
		manageRefunds: "Gestionar reembolsos",
		manageAccessRights: "Gestionar derechos de acceso",
		manageYourBBAN: "Gestionar tu BBAN",
		manageFFAFFTRILicenses: "Gestionar tus licencias FFA/FFTRI",
		ribListLinkToThisEvent: "Lista de RIBs vinculados a este evento",
		addBBAN: "Agregar un BBAN",
		name: "Nombre",
		bank: "Banco",
		ibanBban: "IBAN / BBAN",
		thereAreCurrently: "Actualmente hay",
		registrantsForThe: "registrantes para la carrera",
		doYouReallyWantToReimburseThe: "¿Realmente deseas reembolsar a los",
		registrants: "registrantes",
		chargeAdditionalFeesPerRegistrations:
			"Tarifas adicionales por inscripción (en €)",
		invoicingOnRunnerOrOrganizer: "Facturación al corredor o al organizador",
		ifRegistrationFeeIncluded:
			"Si la tarifa de inscripción está incluida, cubierta por",
		reimbursementCostsCoveredBy: "Costos de reembolso cubiertos por",
		makeTheRefund: "Realizar el reembolso",
		reason: "Razón",
		choose: "Elegir...",
		bibPlan: "Plan de numeración de dorsales",
		bibPlanAffectation: "Forma de asignar dorsales",
		minBib: "Dorsal mínimo",
		maxBib: "Dorsal máximo",
		bibExclusion: "Dorsales excluidos",
		bibExlusionPlaceholder:
			"Ejemplo: \rLista = 1;2;8;10 (los dorsales 1, 2, 8 y 10 no se considerarán) \rRango = 5:10 (los dorsales del 5 al 10 inclusive (5, 6, 7, 8, 9 y 10) no se considerarán) \rCombinación 1 = 2:4;7:8 (los dorsales 2, 3, 4, 7 y 8 no se considerarán) \rCombinación 2 = 2;4:6;8 (los dorsales 2, 4, 5, 6 y 8 no se considerarán)",
		reaffectAllbibs:
			"Reasignar todos los dorsales para la carrera validando la selección",
		keepBibs: "Conservar dorsales ya asignados",
		applyToPending: "Aplicar a inscripciones pendientes",
		orderAlphabetic: "Reasignar dorsales en orden alfabético de corredores",
		list: "Por lista de dorsales",
		range: "Por rango de dorsales",
		filterByRun: "Filtrar por carrera",
		resolution: "Resolución",
		days: "Días",
		weeks: "Semanas",
		months: "Meses",
		years: "Años",
		runnerAge: "años",
		seeComments: "Ver comentarios",
		downloadInPdf: "Descargar en PDF",
		nbOfSubscribers: "Número de suscriptores",
		maxInfMin: "Por favor, elige un valor máximo mayor que el valor mínimo",
		errorBib: "Ocurrió un error al actualizar los dorsales",
		updatedBib: "Los números de dorsales han sido actualizados",
		loginSuccess: "Inicio de sesión exitoso",
		loginError: "Error al iniciar sesión",
		logoutSuccess: "Cierre de sesión exitoso",
		logoutError: "Error al cerrar sesión",
		updateError: "Se encontró un problema en la actualización",
		updateSuccess: "La actualización fue exitosa",
		updateCredentialSuccess: "La actualización de credenciales fue exitosa",
		updateCredentialError:
			"La actualización de credenciales encontró un problema",
		startPrestation: "El servicio ha comenzado",
		liveTimezone:
			"Hay una inconsistencia en la configuración de la zona horaria de tu evento.",
		stopPrestation: "El servicio se ha detenido",
		prestationStartError: "Error al iniciar el servicio",
		prestationStartErrorCredentiel:
			"Por favor, configura tus credenciales antes de iniciar el servicio",
		prestationStopError: "Error al detener el servicio",
		updateCheckpointSuccess: "El registro del detector fue exitoso",
		updateCheckpointError: "Error durante el registro del detector",
		deletionSuccess: "Eliminación exitosa",
		deletionError: "Ocurrió un error durante la eliminación",
		insertSuccess: "Creación exitosa",
		insertError: "Error durante la creación",
		insertErrorDetection:
			"No se pudo transmitir la detección. Verifica que tienes una carrera en curso.",
		ppsError:
			"Licencia o PPS inválida (no validada por la Federación Francesa)",
		requiredRace: "Por favor, elige una carrera",
		requiredBib: "Por favor, elige un número de dorsal",
		requiredBirthdate: "Por favor, proporciona la fecha de nacimiento",
		requiredGender: "Por favor, proporciona el género",
		requiredTeamName: "Por favor, proporciona el nombre del equipo",
		requiredTeam: "Por favor, elige un equipo de la lista o crea uno nuevo",
		requiredOneOrAllRunner: "Por favor, proporciona uno o todos los corredores",
		emailSendedSuccess: "Correos enviados con éxito.",
		emailSendedError: "Ocurrió un error al enviar los correos.",
		login_expired: "Tu sesión ha expirado",
		newRaceUpdateSuccess: "El cambio de carrera fue exitoso",
		newRaceUpdateError: "Ocurrió un error durante el cambio de carrera",
		bibChangeRaceChoice: "Por favor, toma una decisión sobre los dorsales",
		refundSuccess: "El reembolso fue exitoso",
		refundError: "El reembolso encontró un error",
		sport_identity_success: "Registro del evento exitoso",
		sport_runs_success: "Carreras configuradas con éxito",
		sport_options_success: "Opciones configuradas con éxito",
		sport_identity_error: "Ocurrió un error durante la creación del evento",
		sport_runs_error: "Ocurrió un error durante la configuración de carreras",
		sport_options_error:
			"No se pueden guardar las opciones. Verifique que no esté modificando una tarifa para una opción ya suscrita.",
		file_error: "Se produjo un error al leer el archivo.",
		file_exist: "Este archivo ya ha sido subido.",
		event_form_required: "No has completado todos los campos obligatorios.",
		event_invalid_dates:
			"Advertencia: La fecha de finalización es la misma que la fecha de inicio.",
		error_creation_segment: "Se produjo un error al guardar la etapa.",
		success_creation_segment: "Etapa guardada con éxito.",
		success_deletion_segment: "Etapa eliminada.",
		error_deletion_segment: "Se produjo un error al eliminar la etapa.",
		error_creation_parcours: "Se produjo un error al registrar el recorrido.",
		success_creation_parcours: "Recorrido registrado con éxito.",
		success_deletion_parcours: "Recorrido eliminado.",
		error_deletion_parcours: "Se produjo un error al eliminar el recorrido.",
		error_creation_etape: "Se produjo un error al registrar la etapa.",
		success_creation_etape: "Etapa registrada con éxito.",
		success_deletion_etape: "Etapa eliminada.",
		error_deletion_etape: "Se produjo un error al eliminar la etapa.",
		success_creating_price: "Precio registrado con éxito.",
		error_creating_price: "Se produjo un error al crear el precio.",
		success_deleting_run: "Carrera eliminada con éxito.",
		error_deleting_run_subs:
			"No se puede eliminar una carrera con suscripciones.",
		error_deleting_run: "Se produjo un error al eliminar la carrera.",
		success_deleting_price: "Precio eliminado con éxito.",
		error_deleting_price: "Se produjo un error al eliminar el precio.",
		success_updating_fields: "Formulario de inscripción actualizado.",
		error_updating_fields: "Se produjo un error al actualizar el formulario.",
		event_does_not_exist: "El evento que desea configurar no existe.",
		success_updating_contact: "Contacto registrado con éxito.",
		error_updating_contact: "Se produjo un error al registrar el contacto.",
		success_updating_rib: "Cuenta bancaria (RIB) registrada con éxito.",
		error_updating_rib:
			"Se produjo un error al registrar la cuenta bancaria (RIB). Es posible que el representante legal ya tenga una cuenta.",
		success_deleting_contact: "Contacto eliminado con éxito.",
		success_deleting_rib: "Cuenta bancaria (RIB) eliminada con éxito.",
		error_deleting_contact: "Se produjo un error al eliminar el contacto.",
		error_deleting_rib:
			"Se produjo un error al eliminar la cuenta bancaria (RIB).",
		error_signup:
			"Se produjo un error. Es posible que este correo ya esté en uso.",
		success_signup: "Su cuenta se ha creado con éxito.",
		success_change_orga:
			"Ahora eres organizador. Por favor, inicia sesión nuevamente.",
		error_change_orga: "Se produjo un error.",
		error_contact_empty:
			"Por favor, proporciona el nombre de la empresa, nombre y apellidos del contacto.",
		success_ask_validation: "Se ha enviado un correo al administrador.",
		error_ask_validation: "Se produjo un error al solicitar la validación.",
		import_error: "Se produjo un error durante la importación.",
		success_publish_event: "Evento publicado.",
		success_unpublish_event: "Evento despublicado.",
		error_unpublish_event: "Se produjo un error.",
		cant_delete_stage:
			"Por favor, elimina las detecciones asociadas a esta etapa antes de eliminarla.",
		reassignCategorySuccess: "Categorías reasignadas con éxito.",
		reassignCategoryError: "Se produjo un error al reasignar las categorías.",
		orgaSuccess: "Ahora eres organizador.",
		orgaError: "Se produjo un error.",
		toast_raceChoice: "Por favor, selecciona al menos una carrera de la lista.",
		genderChoice: "Por favor, selecciona al menos un género de la lista.",
		toast_statusChoice:
			"Por favor, selecciona al menos un estado de registro de la lista.",
		typeChoice:
			"Por favor, selecciona al menos un tipo de registro de la lista.",
		changeTimezoneError: "Se produjo un error al cambiar la zona horaria.",
		delete_detections_choice: "Por favor, realiza una elección.",
		no_delete: "No se han eliminado detecciones.",
		no_detections: "No hay detecciones.",
		no_times: "No hay tiempos.",
		export_error: "Se produjo un error durante la exportación.",
		accept_document_success: "El documento ha sido aceptado.",
		accept_document_error: "Se produjo un error.",
		refuse_document_success:
			"El documento ha sido rechazado y se ha enviado un correo al corredor.",
		refuse_document_error: "Se produjo un error.",
		category_or_team: "Categoría / Equipo",
		run: "Carrera",
		status: "Estado",
		options: "Opciones",
		runners: "Corredores",
		starter: "Principiante",
		surrendered: "Rendido",
		disqualified: "Descalificado",
		filter: "Filtro",
		gender: "Género",
		man: "Hombre",
		woman: "Mujer",
		speed: "Velocidad",
		gap: "Diferencia",
		passageTime: "HORA DE PASO",
		minimmAge: "La edad mínima requerida es",
		other_gender: "Otro",
		your_runners: "Tus corredores",
		refused_reason: "Razón del rechazo",
		accept_document: "Aceptar",
		refuse_document: "Rechazar",
		refuse_document_and_notice: "Rechazar y enviar correo",
		your_subscriptions: "Tus suscripciones",
		contact: "Contacto",
		edit_subscription: "Editar suscripción",
		add_subscription: "Agregar suscripción",
		message: "Enviar un mensaje",
		document: "Gestionar documentos",
		actions: "Acciones",
		waiting: "Esperando",
		valid: "Válido",
		reimbursed: "Reembolsado",
		documents_sent: "Documento(s) enviado(s)",
		close_documents: "Cerrar",
		manage_documents: "Gestión de documentos",
		personnal_informations: "Información personal:",
		confirmDeleteSub: "¿Está seguro de que desea eliminar la inscripción de",
		age: "Edad",
		phone: "Teléfono",
		emergency_phone: "Teléfono de emergencia",
		mail: "Correo electrónico",
		run_informations: "Información de la carrera",
		bib_info: "Número de dorsal",
		licence: "Licencia",
		validated: "validado",
		by: "por",
		la: "el",
		validated_by_fede: "Validado por",
		certificat: "Certificado médico",
		autorisation: "Autorización parental",
		validation_date: "Fecha de validación:",
		sub_team: "Equipo / Asociación / Empresa",
		sub_options: "Opciones del suscriptor:",
		reimburse: "Reembolsar",
		validate_subscription: "Validar suscripción",
		refuse_subscription: "Rechazar suscripción",
		refuse_reason: "¿Cuál es la razón del rechazo?",
		see_message_history: "Ver historial de correos",
		messages_history: "Historial de correos",
		close_history: "Cerrar historial",
		close_comments: "Cerrar comentarios",
		"inscription.refus.title": "Suscripción rechazada",
		no_messages: "No se han enviado mensajes a este suscriptor.",
		changeRace: "Cambiar carrera",
		changeRaceModalTitle: "Modificación de suscripción para:",
		currentlyRegistredFor: "Actualmente registrado para la carrera",
		newRace: "Nueva carrera",
		cannotChangeRace: "No es posible cambiar de carrera.",
		teammatesWith: "Con",
		bibMessageChangeRun: "1 - ¿Desea mantener los números de dorsal?",
		automaticBibChangeRun:
			"2 - ¿Desea asignar automáticamente nuevos números de dorsal según el plan de numeración de la nueva carrera?",
		bibAlreadyExistInNewRace:
			"ya tiene el número de dorsal en la carrera seleccionada. Se asignará automáticamente un nuevo número de dorsal al corredor si se ha definido un plan de numeración para la carrera elegida.",
		noBibInNewRace:
			"ATENCIÓN: El número de dorsal actual será liberado. Será necesaria la asignación manual del número de dorsal.",
		noBibAssignmentInNewRace:
			"ATENCIÓN: El número de dorsal actual será liberado ya que la nueva carrera no tiene un plan de numeración.",
		see_order: "Ver orden",
		no_document: "Sin documento",
		detectors: "Detectores",
		verification: "Verificación OK",
		percentage: "Porcentaje",
		finishers: "Finalistas",
		number_runners: "Corredores",
		non_starters: "No Iniciadores",
		start_the: "Empieza el:",
		abandon: "Abandonos",
		official_starters: "Iniciadores Oficiales",
		manage_detections_title: "Detecciones",
		hours: "Hora de Paso",
		restore_detection: "Restaurar",
		delete_detections: "Eliminar Detecciones",
		choose_detectors: "Elige una línea",
		add_detection: "Agregar Detección",
		edit_detection: "Editar Esta Detección",
		runFormDeleteDetectionConfirmation: "¿Desea eliminar esta detección?",
		date_format: "Formato de Fecha",
		chrono_format: "Formato de Crono",
		effective_start: "Inicio Efectivo",
		races: "Todas las Carreras",
		deleteAllDetections: "¿Desea eliminar todas las detecciones?",
		confirmStartSaveCredential:
			"¿Desea guardar sus credenciales antes de iniciar el en vivo?",
		deleteTitle: "Eliminación de Detecciones de Carreras",
		all: "Eliminar todas las detecciones del evento",
		upload_dag: "Importar Archivo DAG",
		insertion: "Inserción",
		estimation: "Estimación",
		current_time: "Duración",
		seconds: "segundos",
		detection_detail: "Detalles de la Detección",
		already_exist: "Ya Existe",
		inserted: "Insertado",
		runner_created: "Incluyendo Corredor Creado",
		checkpoint_missing: "Punto de Control Faltante",
		interpassage_ignored: "Ignorado por Tiempo de Interpaso",
		search_runner: "Buscar un Corredor",
		search_event: "Buscar un Evento",
		search_bib: "Buscar por Número de Dorsal",
		search_subscription: "Buscar por Suscripción",
		autorisationParentale: "Autorización Parental",
		licence_already_sent: "Licencia Ya Enviada",
		certificat_already_sent: "Certificado Ya Enviado",
		autorisation_already_sent: "Autorización Ya Enviada",
		send_documents: "Enviar Nuevos Documentos",
		see_document: "Ver Documento",
		download_document: "Descargar",
		product_name: "Nombre del Producto",
		refund_price: "Precio (IVA Incl.)",
		refund_amount: "Monto de Reembolso",
		subscription_fees: "Cuotas de Suscripción",
		refund_reason: "Razón de su Reembolso (Obligatorio)",
		refund: "Reembolsar",
		deleteSub: "Eliminar suscripción",
		orga_sub_fees: "¿Cuotas de suscripción a su cargo?",
		orga_refund_fees: "¿Cuotas de reembolso (1€ IVA Incl.) a su cargo?",
		contact_doubt:
			"Por favor, contacte al soporte si tiene dudas sobre sus acciones",
		refund_summary: "Resumen del Reembolso",
		refund_close_summary: "Cerrar Resumen",
		refund_summary_start_text: "Está a punto de reembolsar ",
		refund_summary_end_text: " a este corredor, ¿desea confirmar?",
		confirm_refund: "Confirmar Reembolso",
		refund_paid: "El corredor ha pagado",
		amount_to_refund: "Va a reembolsar",
		refund_fees: "Cuotas de Reembolso",
		refunded_amount: "Monto Total del Reembolso",
		already_refunded: (amount: string, date: string) =>
			`No puede reembolsar esta suscripción porque ya se reembolsaron ${amount} € el ${date}.`,
		refund_product: "Reembolsar",
		choose_type: "Quiero organizar un evento",
		select: "Seleccionar",
		type_details: "Detalles",
		sport_event_identity: "Presentación del Evento",
		sport_event_runs: "Configuración de Carreras",
		sport_event_options: "Configuración de Opciones",
		go_back: "Volver",
		save_and_go_run: "Guardar y Configurar Carreras",
		save_and_go_options: "Configurar Opciones",
		save_and_finish: "Finalizar Configuración",
		event_name_label: "Nombre del Evento",
		event_sport_label: "Deporte",
		event_sport_option: "Seleccione un deporte",
		event_start_date_label: "Fecha de Inicio",
		event_end_date_label: "Fecha de Finalización",
		event_poster_label: "Imagen",
		event_banner_label: "Banner",
		event_short_description_label: "Descripción Breve",
		event_long_description_label: "Descripción Larga",
		event_address_label: "Dirección",
		event_postal_code_label: "Código Postal",
		event_city_label: "Ciudad",
		event_country_label: "País",
		event_name_error: "El nombre de su evento debe tener más de 3 caracteres",
		event_sport_error: "Debe elegir un deporte",
		event_start_date_error:
			"La fecha no puede ser anterior a la fecha de hoy (o debe coincidir con la fecha ingresada en SIFFA si el código FFA)",
		event_ffa_siffa_error:
			"Falta la autorización FFA. ¿Ha vinculado su evento al Sistema DAG en CALORG?",
		event_end_date_error: "Debe elegir una fecha posterior a la de hoy",
		event_short_description_error:
			"La descripción breve debe tener entre 3 y 190 caracteres",
		event_address_error: "Su dirección debe tener más de 3 caracteres",
		event_postal_code_error: "Su código postal debe tener más de 3 caracteres",
		event_city_error: "Su ciudad debe tener más de 3 caracteres",
		event_country_error: "Debe elegir un país",
		event_country_option: "Seleccione un país",
		event_timezone_label: "Zona Horaria",
		event_places_label: "Plazas Disponibles",
		event_timezone_option: "Seleccione una zona horaria",
		event_rules_label: "Reglas del Evento",
		event_run_name_label: "Nombre de la Carrera",
		event_run_date_label: "Fecha de la Carrera",
		event_run_member_label: "Si es en equipo, número de compañeros",
		event_run_transfer_label: "¿Permite transferencias de inscripciones?",
		event_run_details_label: "Información Adicional",
		event_run_details: "Si desea proporcionar más información sobre la carrera",
		event_run_number_members_label: "Número de Compañeros",
		event_run_places_label: "Plazas Disponibles",
		event_run_start_date_label: "Fecha de Inicio",
		event_run_end_date_label: "Fecha de Finalización",
		event_run_same_bib_label: "Mismo dorsal para el equipo",
		event_run_fftri_code: "Código de Competición FFTRI",
		event_run_fftri_distance: "Tipo de Distancia",
		event_run_licence_accepted: "Licencias Aceptadas",
		event_run_licence_accepted_tooltip:
			"Tipo de licencia aceptada para esta carrera",
		event_run_licence_accepted_all: "Todas las licencias",
		event_run_licence_accepted_comp: "Licencias de competición",
		event_run_same_bib_no: "No",
		event_run_same_bib_yes: "Sí",
		event_run_make_it_visible:
			"Hacer visible la lista de corredores en chronometrage.com ?",
		event_run_make_it_visible_info:
			"Al marcar esta casilla, autoriza la publicación de la lista de corredores registrados en el sitio chronometrage.com",
		event_add_run: "Agregar Carrera",
		event_delete_run: "Eliminar Carrera",
		event_undo_delete_run: "No Eliminar Carrera",
		event_prices: "Precios",
		event_run: "Carrera",
		event_run_designation_label: "Denominación",
		edit_run_title: "Editar Carrera",
		edit_tarif_title: "Editar Precio",
		edit_contact_title: "Editar Contacto",
		edit_rib_title: "Editar Cuenta Bancaria",
		deleteConfigPointError_1451:
			"Por favor, elimine todos los segmentos que contengan este punto de detección primero.",
		event_run_age_control_label: "Tipo de Límite de Edad",
		event_run_age_control_option: "Sin Límite de Edad",
		event_run_age_min_label: "Edad Mínima",
		event_run_age_max_label: "Edad Máxima",
		event_run_add_price: "Agregar Precio",
		event_add_contact: "Agregar Contacto",
		event_add_rib: "Agregar Cuenta Bancaria",
		validate: "Validar",
		fees_inclued: "incluido",
		fees_exclued: "excluido",
		event_fees_inclued: "Cuotas Incluidas",
		event_fees_exclued: "Cuotas Excluidas",
		event_starting_date: "Inicio:",
		event_ending_date: "Fin:",
		event_age_disponibility: (min: number, max: number) =>
			`Disponible desde ${min} años hasta ${max} años`,
		event_price_fee_inclued: (fees: number) =>
			`incluyendo ${fees.toFixed(2)}€ de tasas`,
		event_price_fee_exclued: (fees: number) => `+ ${fees.toFixed(2)}€ de tasas`,
		event_run_price: "Precio",
		event_run_fees_type_label: "Tipo de Tasas",
		event_run_type_control_event: "Según la fecha del evento",
		event_run_type_control_subscription: "Según la fecha de inscripción",
		event_run_type_control_year_event: "Según el año del evento",
		event_run_free_run: "¿Carrera Gratuita?",
		event_no_free_run: "No",
		event_free_run: "Sí",
		event_free_run_text: "Carrera Gratuita",
		event_ffa_siffa_label: "Código de Competición FFA",
		event_configure_sub_form: "Configurar el formulario",
		event_edit_run: "Editar la carrera",
		event_run_form_title: "Configuración del Formulario de Inscripción",
		event_tooltip_form: "Un formulario está configurado para esta tarifa",
		event_field_nom_label: "Apellido",
		event_field_prenom_label: "Nombre",
		event_field_sexe_label: "Género",
		event_field_dateNaissance_label: "Fecha de Nacimiento",
		event_field_mail_label: "Correo electrónico",
		event_field_ville_label: "Ciudad",
		event_field_codePostal_label: "Código Postal",
		event_field_pays_label: "País",
		event_field_nationalite_label: "Nacionalidad",
		event_field_telephone_label: "Teléfono",
		event_field_telephoneUrgence_label: "Teléfono de emergencia",
		event_field_numLicence_label: "Número de licencia",
		event_field_licence_label: "Licencia",
		event_field_certificat_label: "Certificado médico",
		event_field_pps_label: "Número PPS (FFA)",
		event_field_adresse1_label: "Dirección",
		event_field_adresse2_label: "Línea de dirección 2",
		event_field_club_label: "Club",
		event_field_team_label: "Equipo",
		event_field_ffa_label: "FFA CNIL",
		event_field_autorisationParentale_label: "Autorización parental",
		event_field_tiers_label: "Tercero",
		event_field_dechargeResponsabilite_label: "Exención de responsabilidad",
		group_fields_nominative: "Campos nominativos",
		group_fields_FFA: "Campos FFA",
		group_fields_others: "Otros campos",
		infos_ffa_numlicence_optional_message:
			"El corredor puede ingresar su número de licencia FFA",
		infos_ffa_numlicence_required_message:
			"El corredor debe ingresar su número de licencia FFA",
		infos_ffa_ppsandlicence_optional_message:
			"El corredor puede ingresar su número PPS o de licencia FFA",
		infos_ffa_ppsandlicence_required_message:
			"El corredor debe ingresar su número PPS o de licencia FFA",
		enter_text: "Introduce tu descripción",
		enter_mail_content: "Escribe el contenido de tu correo electrónico...",
		enter_mail_object: "Escribe el asunto de tu correo electrónico...",
		sport_event_contacts: "Gestión de contactos",
		sport_event_ribs: "Gestión de cuentas bancarias",
		event_raisonSocial_label: "Razón social",
		event_nom_label: "Apellido",
		event_prenom_label: "Nombre",
		event_adresse1_label: "Dirección",
		event_adresse2_label: "Línea de dirección 2",
		event_ville_label: "Ciudad",
		event_codePostal_label: "Código Postal",
		event_pays_label: "País",
		country_home: "País",
		event_telephone1_label: "Teléfono",
		event_telephone2_label: "Teléfono secundario",
		event_mail_label: "Correo electrónico",
		event_siteWeb_label: "Sitio web",
		event_facebook_label: "Facebook",
		event_twitter_label: "Twitter",
		event_instagram_label: "Instagram",
		delete_tarif_title: "¿Realmente quieres eliminar la tarifa?",
		delete_run_title: "¿Realmente quieres eliminar la carrera?",
		restore_deletes: "Restaurar elementos eliminados",
		restore: "Deshacer cambios",
		delete_contact_title: "¿Realmente quieres eliminar el contacto?",
		create_step: "Añadir un paso",
		create_route: "Añadir un paso",
		step_name_label: "Nombre del paso",
		event_distance_label: "Distancia (en metros)",
		sport_event_steps: "Configuración de pasos",
		sport_event_parcours: "Configuración de rutas",
		sport_event_segments: "Configuración de etapas",
		parcours_name_label: "Nombre de la ruta",
		segment_name_label: "Nombre de la etapa",
		parcours_file_label: "Archivo GPX",
		create_parcours: "Añadir una ruta",
		update_parcours: "Editar una ruta",
		create_segment: "Añadir una etapa",
		update_segment: "Editar una etapa",
		delete_step_title: "¿Realmente quieres eliminar el paso?",
		delete_segment_title: "¿Realmente quieres eliminar la etapa?",
		delete_parcours_title: "¿Realmente quieres eliminar la ruta?",
		checkpoint_debut_label: "Punto de control inicial",
		checkpoint_fin_label: "Punto de control final",
		parcours_active_label: "Ruta activa",
		active: "activo",
		event_fees: "Tarifas",
		dag_fees: "Tarifa DAG (IVA incluido)",
		subscription_income: "Tus ingresos",
		subscription_fees_sup: "Tu parte con IVA incluido",
		subscription_fees_total: "Total de tarifas de registro (IVA incluido)",
		event_sport_price: "Precio de la carrera",
		event_fftri_default_distance: "Elige una distancia",
		designation_label: "Designación",
		unlimited: "Ilimitado",
		nomBanque_label: "Nombre del banco",
		iban_label: "IBAN",
		bic_label: "BIC",
		inCharge_label: "Soy el representante legal",
		lastname_label: "Apellido",
		firstname_label: "Nombre",
		birthdate_label: "Fecha de nacimiento",
		email_label: "Correo electrónico",
		address_label: "Dirección",
		city_label: "Ciudad",
		postalCode_label: "Código Postal",
		country_label: "País",
		phone_label: "Teléfono",
		sex_label: "Género",
		genderHfull: "Hombre",
		genderFfull: "Mujer",
		genderXfull: "Otro",
		select_type_rib: "Selecciona un rol",
		see_contract: "Ver contrato",
		add_general_option: "Añadir una opción global",
		delete_stage_cannot_start_live:
			"ADVERTENCIA: Si eliminas esta etapa sin crear otra, no podrás iniciar la transmisión en vivo.",
		event_configuration: "Quiero organizar un evento",
		event_identity: "Identidad del evento",
		event_runs: "Carreras",
		event_options: "Opciones",
		event_subscription: "Configuración de registro",
		event_contacts: "Contactos",
		event_rib: "Cuentas bancarias",
		subscriptions: "Inscripciones",
		live_results: "Resultados en vivo",
		live_positions: "Posición de los corredores",
		manage_detections: "Gestión de detecciones",
		detection_dashboard: "Resumen de detecciones",
		manage_runners: "Gestión de corredores",
		manage_live: "Gestión en vivo",
		manage_steps: "Configuración de pasos",
		manage_parcours: "Configuración de rutas",
		manage_segments: "Configuración de etapas",
		bib_plan_exemple_1:
			"Lista = 1;2;8;10 (los dorsales 1, 2, 8 y 10 no serán considerados)",
		bib_plan_exemple_2:
			"Rango = 5:10 (los dorsales del 5 al 10 inclusive (5, 6, 7, 8, 9 y 10) no serán considerados)",
		bib_plan_exemple_3:
			"Combinación 1 = 2:4;7:8 (los dorsales 2, 3, 4, 7 y 8 no serán considerados)",
		bib_plan_exemple_4:
			"Combinación 2 = 2;4:6;8 (los dorsales 2, 4, 5, 6 y 8 no serán considerados)",
		event_name: "Se utiliza para identificar la actividad durante el registro.",
		event_places:
			"Limita los registros a un número definido de plazas (deja vacío para plazas ilimitadas).",
		event_start_date: "Define la fecha de inicio de la actividad.",
		event_end_date: "Define la fecha de finalización de la actividad.",
		event_team_number:
			"Define cuántos inscritos forman un equipo (deja vacío si la actividad no es por equipos).",
		event_transfer:
			"Permite a los inscritos transferir su registro a otro usuario.",
		event_price_name:
			"Describe el precio de la carrera (por ejemplo, Early Bird, Niño, Adulto).",
		event_age: "Define un límite de edad para el registro.",
		event_min_age: "Establece la edad mínima requerida para el registro.",
		event_max_age: "Establece la edad máxima requerida para el registro.",
		event_price_start_date:
			"Define la fecha de inicio cuando la tarifa estará disponible. Las tarifas no serán visibles antes de esta fecha.",
		event_price_end_date:
			"Define la fecha de finalización cuando la tarifa ya no estará disponible. Las tarifas no serán visibles después de esta fecha.",
		event_price_free:
			"Indica si la tarifa es gratuita. Los participantes no pagarán tarifas, pero se te cobrarán a ti.",
		event_price_price:
			"Define el precio de la carrera (pueden aplicarse tarifas adicionales).",
		event_price_fees_type:
			"Indica si las tarifas son exclusivas (no incluidas en el precio de la carrera) o inclusivas (incluidas en el precio de la carrera).",
		event_price_fees:
			"Tarifas cobradas por la plataforma. Estas tarifas son al menos iguales a las tarifas adicionales agregadas por el comercializador.",
		event_price_fees_sup:
			"Tarifas adicionales aplicadas por el comercializador.",
		event_price_fees_calc:
			"Tarifas de la plataforma + tarifas adicionales del comercializador.",
		event_timezone:
			"Elija cuidadosamente para propósitos de clasificación en vivo.",
		event_defined_tz: "Zona horaria definida para el evento",
		default_race:
			"Clasifique un dorsal desconocido detectado durante la transmisión en vivo en una carrera predeterminada si no se define un plan de numeración.",
		finances: "Flujos financieros",
		finances_desc: "Ver los flujos financieros de un evento",
		finances_resume: "Gestión de transferencias",
		finances_resume_desc:
			"Ver el estado de las transferencias para eventos de la plataforma",
		placeholder_select: "Seleccione un evento",
		placeholder_search: "Buscar un evento",
		search_client: "Buscar un cliente",
		select_no_options: "No hay eventos",
		report: "Informe",
		time: "Tiempo",
		section: "Etapa",
		filters: "Filtros",
		placeholder_select_gender: "Seleccione un género",
		placeholder_select_category: "Seleccione una categoría",
		H: "Hombre",
		F: "Mujer",
		A: "Otro",
		export: "Exportar",
		export_format: "Formato de exportación",
		placeholder_select_format: "Seleccione un formato",
		optional: "(opcional)",
		warning_passage: (line: number, min: number, done: number) =>
			`Línea ${line}: ${min} min de paso, ${done} pasos completados`,
		inviteNewUser: "Invitar a un nuevo usuario",
		error403: "Error 403",
		contactOrga: "Contacte al organizador si necesita este acceso",
		event_form_donation_required: "La elección de la donación es obligatoria",
		restrictedPage: "Página restringida",
		permissions: "Permisos",
		roles: "Permisos",
		search_permissions: "Buscar un nombre",
		invoice: "Factura",
		dag_share_ht: "Participaciones DAG sin IVA",
		orga_share_ht: "Participaciones de la organización sin IVA",
		total_ht: "Total sin IVA",
		total_ttc: "Total con IVA",
		com_ht: "Comisión sin IVA",
		bill_com: "Estado de la factura",
		bill_orga: "Factura de la organización",
		title: "Título",
		quantity: "Cantidad",
		unit_prix_ht: "P.U sin IVA",
		unit_prix_ttc: "P.U con IVA",
		nbSubs: "Suscriptores",
		StartPeriode: "Inicio del período",
		EndPeriode: "Fin del período",
		paymentDone: "Pago realizado",
		validatePayment: "Validar el pago",
		toastPaymentSuccess: "Transferencia validada",
		toastPaymentError:
			"Ocurrió un error durante la validación de la transferencia",
		downloadContractAskValidation: "Descargar y solicitar validación",
		contract_explanation:
			"Para finalizar la publicación de su evento, es esencial establecer un contrato. Al confirmar su solicitud de publicación, le enviaremos este contrato por correo electrónico, el cual debe ser completado y devuelto a nuestro equipo: ",
		licences: "Licencias",
		licences_desc: "Gestionar licencias de Clientes DAG",
		clients: "Clientes",
		clients_desc: "Gestión de clientes DAG",
		toast_invoice_error:
			"Ha ocurrido un error. Verifique que el evento esté cerrado y que el RIB esté presente.",
		documents: "Documentos",
		total_subs: "Número total de suscripciones",
		total_subs_online: "Número total de suscripciones en línea",
		total_subs_manual: "Número total de suscripciones manuales",
		total_subs_imported: "Número de suscripciones importadas",
		total_subs_in_place: "Número de suscripciones en el lugar",
		docMissing: "Documentos faltantes",
		manualCancel: "Cancelar",
		orga_title: "Conviértase en organizador",
		signup_error_country: "El país no es válido",
		signup_error_city: "La ciudad no es válida",
		signup_error_postal_code: "El código postal no es válido",
		signup_error_adresse: "La dirección no es válida",
		sub_validation_message: "Falta el motivo",
		surrended: "Rendido",
		runEndDate: "Fecha de finalización",
		runMaxNbOfSlots: "Número máximo de lugares",
		runnerChart: "Carta de corredores",
		yourParentalAuthorizationModel: "Su modelo de autorización parental",
		addBban: "Agregar un RIB",
		editBban: "Editar un RIB",
		bbanType: "Tipo de RIB",
		bbanName: "Nombre del RIB",
		bankName: "Nombre del banco",
		iban: "IBAN",
		bicSwift: "BIC/SWIFT",
		areYouThePersonInCharge: "¿Es usted el tutor legal?",
		personInChargeInformations: "Información del tutor legal",
		subOptions: "Opciones",
		subEdit: "Editar suscripción",
		subscription: "Suscripción",
		runName: "Nombre de la carrera",
		teamName: "Nombre del equipo",
		runStartDate: "Fecha de salida",
		noClub: "Sin club",
		loginMissing: "Faltan credenciales",
		createNewEdition: "Crear una nueva edición",
		disableSubscriptions: "Desactivar suscripciones",
		activateSubscriptions: "Activar suscripciones",
		inputs_edition_not_complete: "Por favor, complete todos los campos",
		error_new_edition: "Ocurrió un error al intentar crear una nueva edición",
		mailUsed: "Correo ignorado. Ya en uso.",
		mailUsedTooltip:
			"Verifique el nombre y apellido. Tal vez estén mal escritos",
		succes_stopsubscription_event: "Suscripciones desactivadas con éxito",
		error_stopsubscription_event:
			"Ocurrió un error al desactivar suscripciones",
		results: "Resultado",
		displayOrder: "Orden de visualización",
		moveAbove: "Mover arriba",
		moveBelow: "Mover abajo",
		moveTop: "Mover al inicio",
		moveBottom: "Mover al final",
		cannotDeleteRunWithSubscription:
			"No puede eliminar una carrera con suscripciones",
		addGpxFile: "Agregar archivo GPX",
		gpxFile: "Archivo GPX",
		ShouldBeNotSameDate: "Las fechas no deben ser las mismas",
		toastSuccessAddingRib: "RIB agregado con éxito",
		toastErrorAddingRib: "Ocurrió un error al agregar el RIB",
		placeholder_select_rib: "Elija un RIB",
		processed: "Procesado",
		earnings: "Ganancias",
		chartTitleSubs: "Subs / Carrera",
		chartTitleGenders: "Géneros",
		chartTitleCases: "Casos",
		noOptions: "No hay opciones disponibles",
		noAdditionalInformation: "Sin información adicional",
		saveOk: "Guardado exitoso",
		saveError: "Ocurrió un error al guardar",
		shareLiveTrackingUrl: "Compartir la URL de seguimiento en vivo",
		cantPreview:
			"La vista previa no está disponible para este documento (demasiado grande). Por favor descárguelo.",
		liveTypeOfRun: "¿Qué tipo de carrera?",
		liveDateOfRun: "¿Cuál es la fecha de inicio de la carrera?",
		bibNotFound:
			"Este dorsal no forma parte de su evento. Haga clic para agregarlo a los suscriptores",
		between: "entre",
		and: "y",
		none: "Ninguno",
		bySubscriber: " /corredor",

		chooseCalendarRunType: "Elija un tipo de carrera",
		chooseCalendarRunTypeSimple: "Carrera simple (Inicio - Inter. - Fin)",
		chooseCalendarRunTypeLaps: "Carrera por vueltas",
		chooseCalendarRunTypeSimpleVirtual:
			"Carrera virtual simple (Aplicación deportiva)",
		chooseCalendarRunTypeLapsVirtual:
			"Carrera por vueltas virtual (Aplicación deportiva)",
		chooseCalendarRunTypeTriathlon: "Triatlón",

		calendarRunDistance: "¿Qué distancia tiene la carrera? (en metros)",
		calendarRunLaps: "¿Cuántas vueltas toma su carrera?",
		calendarRunDetectionPoints: "Sus puntos de detección",
		calendarRunSegments: "Sus etapas",
		calendarRunDetectionAddPoint: "Agregar un punto de detección",
		calendarRunAddSegment: "Añadir una etapa",
		calendarRunDetectionConfigurePoint: "Configurar tu punto de detección",
		calendarRunConfigureSegment: "Configurar tu etapa",
		calendarRunDetectionType: "¿Cuál es el tipo de tu punto?",
		calendarRunDetectionChooseType: "Elige un tipo",
		finish: "Finalizar",
		arrived: "Llegada",
		advancedParams: "Configuración avanzada",
		interpassTime: "Tiempo entre pasos",
		maxNumberPass: "Número máximo de pasos",
		minNumberPass: "Número mínimo de pasos",
		startNumberPass: "Número de paso inicial",
		endNumberPass: "Número de paso final",
		numberPass: "Número de pasos",
		startDetectionPoint: "Entrada",
		endDetectionPoint: "Salida",
		latitude: "Latitud",
		longitude: "Longitud",
		order: "Orden",
		radius: "Radio",
		altitude: "Altitud",
		runFormTypeError: "Debes elegir un tipo de carrera",
		runFormDistanceError: "La distancia de tu carrera debe ser mayor a 0",
		runFormLapsError: "El número de vueltas debe ser mayor a 0",
		runFormPointNameError: "Debes asignar un nombre a tu punto",
		runFormPointDistanceError: "Debe definirse la distancia de tu punto",
		runFormPointLineError: "Debe definirse una línea",
		runFormPointTypeError: "Debes elegir un tipo de punto",
		runFormPointSameError: "Los puntos de inicio y fin son iguales",
		runFormSegmentNotComplete: "Información de la etapa incompleta",
		runFormSegmentErrorHappened: "Ocurrió un error al guardar la etapa",
		calendarRunDeleteSegment: "Eliminar etapa",
		runFormDeleteSegmentConfirmation:
			"¿Estás seguro de que quieres eliminar esta etapa?",
		runFormDeleteSegmentSuccess: "Etapa eliminada con éxito",
		runFormUpdateSegmentSuccess: "Etapa actualizada con éxito",
		runFormDistanceType: "Elegir tipo de distancia",
		whichRunConfigure: "¿Qué carrera deseas configurar?",
		credentialDagLive: "Tu credencial de DAG Live",
		saveCredentialSuccess: "Tu credencial se ha guardado",
		saveCredentialError: "Ocurrió un error al guardar tu credencial.",
		saveConfigRunSuccess: "Tu configuración ha sido guardada.",
		saveConfigRunError: "Ocurrió un error al actualizar tu configuración.",
		saveConfigPointSuccess: "Tu punto ha sido guardado.",
		saveConfigPointError: "Ocurrió un error al guardar tu punto.",
		deleteConfigPointSuccess: "Tu configuración ha sido eliminada.",
		deleteConfigPointError: "Ocurrió un error al eliminar tu punto.",
		seeAllDetections: "Ver todas las detecciones",
		rankingHeaderRank: "Rk",
		rankingHeaderName: "Nombre",
		rankingHeaderBib: "Dorsal",
		rankingHeaderLastPassage: "Último paso",
		rankingHeaderDetections: "Detecciones",
		rankingHeaderTime: "Tiempo",
		rankingHeaderCategoryRank: "Cat. Rk.",
		rankingHeaderSexRank: "Sexo Rk.",
		rankingHeaderRun: "Carrera",
		rankingHeaderTeam: "Equipo",
		rankingHeaderActions: "Acciones",
		editRunnerTooltip:
			"Ten en cuenta que actualizar un corredor también cambiará todas las demás inscripciones",
		editRunnerRunTooltip:
			"Actualizar información de la carrera afectará las clasificaciones",
		selectRunPlaceholder: "Selecciona una carrera",
		chooseTeam: "Elige un equipo",
		createTeam: "Crear un equipo",
		resetOnRunner: "Reiniciar en corredor",
		bibTime: "Tiempo del dorsal",
		manageDetections: "Gestionar detecciones",
		totalPriceOfRun: "Precio total del viaje (sin tasas)",
		invalidateSub: "¿Hacer que la inscripción sea inválida?",
		errorVerifyBibAlreadyUse:
			"Ocurrió un error. Verifica que el dorsal no esté ya en uso.",
		addDetection: "Añadir una detección",
		setRunnerSurrended: "Cambiar corredor a rendido",
		setRunnerDisqualified: "Cambiar corredor a descalificado",
		setRunnerStarting: "Cambiar corredor a comenzando",
		setRunnerNotStarting: "Cambiar corredor a no comenzando",
		updateRunnerStatusSurrended:
			"¿Actualizar el estado del corredor a rendido?",
		updateRunnerStatusDisqualified:
			"¿Actualizar el estado del corredor a descalificado?",
		updateRunnerStatusStarting:
			"¿Actualizar el estado del corredor a comenzando?",
		updateRunnerStatusNotStarting:
			"¿Actualizar el estado del corredor a no comenzando?",
		notStarting: "No comenzando",

		// PatchNotes
		patchNotes: "Notas de actualización",
		patchAddNotes: "Añadiendo notas de actualización",
		patchAddJetcodes: "Disponibilidad de Jetcodes para un evento",
		patchAddRoles: "Gestión de derechos de acceso de usuarios",
		patchCorrectDocuments: "Corrección en la visualización de documentos",
		patchFixInvoiceErrorMessage: "Corrección del mensaje de error en facturas",
		patchChangeDashboardLinks:
			"Cambio de enlaces en las tarjetas del Dashboard",
		patchUpdateSendOneMail: "Actualización del envío de correos a un corredor",
		patchMailHistory:
			"Corrección de la visualización del historial de correos enviados a un corredor",
		patchResponsive:
			"Corrección de la responsividad para mejorar el uso en móviles",
		patchHoursPrices:
			"Corrección en la actualización de horarios al modificar un precio",
		patchRedirectionToValidate:
			"Actualización de la redirección al hacer clic en X para validar",
		patchTraduction: "Corrección de traducciones",
		patchEventImages: "Corrección de imágenes del evento",
		patchCreateEdition: "Añadir creación de nueva edición",
		patchPictoDocs: "Añadir pictograma para documentos",
		patchForgetPassword: "Añadir opción de olvidar contraseña",
		patchAddPdfExport: "Añadir exportación en PDF",
		patchAddFilterDocuments:
			"Añadir filtro para suscripciones con documentos pendientes",
		onlyWithDocStart: "Mostrar solo suscripciones con",
		onlyWithDocStrong: " documentos pendientes",
		patchReasignBibsAndKeepThem:
			"Actualización del comportamiento de reasignación de dorsales, con posibilidad de mantener dorsales asignados",
		patchAddRegistration: "Mejorar adición de inscripción",
		patchGenderX: "Mostrar género X",
		patchRegistrations: "Mejorar gestión de inscripciones",
		patchSwitchSubscriptionStatus:
			"Ahora puedes cambiar el estado de inscripción",
		patchUpdateAdminStatistics:
			"Actualización de la interfaz administrativa respecto a tablas de datos",
		patchAddRibSelector:
			"Añadir funcionalidad para agregar rápidamente RIBs de una lista ya usada",
		patchEditOption: "Permitir configurar opciones de inscripciones",
		patchAddResults: "Permitir añadir resultados como archivos PDF",
		patchDocumentPreview: "Mejorar previsualización de documentos",
		patchUX: "Mejorar experiencia de usuario",
		patchPDFExportRaces: "Añadir selección de carreras para exportación en PDF",
		deleteGpxFileConfirmation:
			"¿Estás seguro de que deseas eliminar este archivo?",
		deletedFile: "¡Archivo eliminado!",
		selectRunMail: "Selecciona una carrera",
		detectionPointName: "Nombre del punto de detección",
		detectionLigne: "Línea",
		verifyPrestation:
			"Verifica que tienes un live en progreso y tu configuración de DAG Live",
		verifyCredential: "Ocurrió un error. Verifica tu credencial de DAG Live.",
		noPrestationStarted:
			"Inicia el live por primera vez para mostrar la clasificación.",
		ranked: "Clasificado",
		missing: "Faltante",

		ffa_error_generic: "Licencia no validada",
		ffa_error_001: "Error con el formato de la fecha de nacimiento",
		ffa_error_002: "Información proporcionada insuficiente",
		ffa_error_003: "Número no encontrado",
		ffa_error_004: "Número TP no encontrado",
		ffa_error_005: "Número CF no encontrado",
		ffa_error_006: "El número no será válido al momento de la competición",
		ffa_error_007:
			"El número no coincide con tu nombre / apellido / género / fecha de nacimiento",
		ffa_error_008:
			"Tu información (nombre / apellido / género / fecha de nacimiento) no se encuentra",
		ffa_error_009: "Error de homonimia, contacta directamente con la FFA",
		ffa_error_010: "Servicio FFA no disponible, contacta al organizador (10)",
		ffa_error_011: "Servicio FFA no disponible, contacta al organizador (11)",
		ffa_error_012: "Servicio FFA no disponible, contacta al organizador (12)",
		ffa_error_013: "Servicio FFA no disponible, contacta al organizador (13)",
		ffa_error_014: "Servicio FFA no disponible, contacta al organizador (14)",
		ffa_error_015: "Número PPS no encontrado",
		SUB: "SUB",
		ARR: "ARR",
		DNS: "DNS",
		DNF: "DNF",
		DQ: "DQ",
		sport_identity_error_name_taken: "El nombre del evento ya está en uso",
		add_licence: "Añadir una licencia",
		udc_licence_remaining: "Licencias UDC restantes",
		total_key_credit: "Total de créditos de codificación",
		donation_required: "Donación requerida",
		donation_minimum_amount: "Cantidad mínima de donación",
		additional_information: "Información adicional",
		shop_licence: "Licencias",
		shop_material: "Material",
		shop_chip: "Chips",
		global_search: "Buscar",
		global_search_desc: "Busca personas, clientes, pedidos...",
		quickstart: "Inicio rápido",
		features: "Características",
		shop: "Tienda",
		reset: "Reiniciar",
		persons: "Personas",
		commands: "Comandos",
		search_results: "Resultados de búsqueda",
		account_type: "Tipo de cuenta",
		registration: "Inscripción",
		ttc_price: "Precio con impuesto",
		command_date: "Fecha del pedido",
		payer: "Pagador",
		donation_list: "Lista de donaciones",
		no_run_with_donation:
			"Ninguna carrera está configurada con una donación para una asociación",
		donation_value: "Valor de la donación",
		no_donation_for_association: "No hay donación para esta asociación",
		donation_campain: "Campaña de donación",
		campain_email: "CORREO ELECTRÓNICO DE LA ASOCIACIÓN",
		campain_objective: "OBJETIVO DE LA CAMPAÑA",
		campain_minimum: "CANTIDAD MÍNIMA",
		campain_default: "CANTIDAD POR DEFECTO",
		pps_number: "Número PPS",
		all_runs: "Todas las carreras",
		all_runners: "Todos los corredores",
		all_valid_subsriptions: "Todas las inscripciones válidas",
		all_invalid_subsriptions: "Todas las inscripciones inválidas",
		all_incomplete_subsriptions: "Todas las inscripciones incompletas",
		active_users: "Usuarios activos",
		tracking_url: "URL de seguimiento",
		document_title: "Título del documento",
		ranking: "Clasificación",
		subscription_history_by_run: "Historial de inscripciones por carrera",
		rights_add_subscription: "Registro",
		rights_add_subscription_infos: "Permite agregar un registrante en el sitio",
		rights_import_subscriptions: "Importar inscripciones",
		rights_import_subscriptions_infos: "En formato Excel",
		rights_export_subscriptions: "Exportar inscripciones",
		rights_export_subscriptions_infos: "En formato Excel, CSV, PDF o GmDAG",
		rights_validate_documents: "Validación de documentos",
		rights_validate_documents_infos:
			"Validación de certificados médicos y autorizaciones parentales",
		rights_send_mails:
			"Comunicación con los registrantes (correos electrónicos)",
		rights_subscription_live: "Derechos de registro y gestión en vivo",
		rights_run_config: "Configuración de la carrera",
		rights_rib_config: "Editar los datos bancarios (RIB)",
		rights_contact_config: "Editar los detalles de contacto",
		rights_runner_management: "Gestión de corredores",
		rights_runner_management_infos: "Acceso a la lista, tiempos de paso, etc.",
		rights_dossard_assign: "Asignación de dorsal",
		rights_dossard_assign_infos:
			"Permite cambiar el número de dorsal de un corredor",
		live_chrono: "Cronometraje en vivo",
		passage_points: "Puntos de paso",
		times: "Tiempos",
		detections: "Detecciones",
		general_ranking: "Rk Gen.",
		sex_ranking: "Rk Sexo.",
		cat_ranking: "Rk Cat.",
		minAgeRequired: (minAge: number) =>
			`La edad mínima requerida es ${minAge} años.`,
		maxAgeRequired: (maxAge: number) =>
			`La edad máxima requerida es ${maxAge} años.`,
		yearBetweenRequired: (minAge: number, maxAge: number) =>
			`El año de nacimiento debe estar entre ${minAge} y ${maxAge} incluidos.`,
		ageBetweenRequired: (minAge: number, maxAge: number) =>
			`Debes tener al menos ${minAge} años o como máximo ${maxAge} años en el momento de la inscripción.`,
		activities: "Actividades",
		not_all_position_display:
			"Se ha ajustado el número de posiciones, solo se mostrarán 500 posiciones",
		success_time_saved: "El tiempo ha sido guardado",
		error_time_saved: "Ocurrió un error al guardar el tiempo",
		display_gpx: "¿Hacer visibles las pistas GPX?",
		error_create_licence: "Error al crear licencia",
		success_create_licence: "Licencia creada correctamente",
		error_create_encode_key: "Error al crear clave de codificación",
		success_create_encode_key: "Clave de codificación creada correctamente",
		live_ranking: "Gestión de ranking",
		add_ranking: "Agregar ranking",
		deselect: "Deseleccionar",
		ranking_name: "Nombre del ranking",
		available_filters: "Filtros disponibles",
		ranking_filters_explanation:
			"Estos filtros definen las combinaciones disponibles para la clasificación.",
		toast_error_save_ranking_config: "Error al guardar clasificaciones",
		toast_success_save_ranking_config: "Clasificación guardada",
		toast_empty_ranking_config:
			"El nombre de la clasificación y los filtros no pueden estar vacíos",
		sure_delete_ranking_list: (name: string) =>
			`¿Estás seguro de que quieres eliminar "${name}"?`,
		success_delete_ranking_list: "Clasificación eliminada",
		error_delete_ranking_list: "Error al eliminar la clasificación",
		custom_rankings: "ranking personalizado",
		care_choice_unavaible:
			"Tenga en cuenta que estas opciones ya no estarán disponibles para el filtro de clasificación.",
		list_name_required:
			"El nombre de la lista es obligatorio y debe ser único.",
		ranking_title: "Clasificación",
		ranking_all_choices: "Todos",
		ranking_all_races: "Todas las carreras",
		ranking_export_experimental: "(experimental)",
		ranking_export_info1:
			"Exporta los resultados tal como aparecen en su pantalla en formato Excel.",
		ranking_export_info2:
			"Las columnas son las mismas que se muestran en la página de gestión de corredores.",
		ranking_export_pdf_newline: "Salto de página al cambiar de género:",
		ranking_export_pdf_unranked: "Mostrar no clasificados:",
		ranking_export_pdf_all_races: "Todas las carreras",
		price_dates_archived: "Fechas de precios archivadas"
	},

	nl: {
		delete_old_detections:
			"Wilt u de detecties van de laatste prestatie behouden?",
		select_race: "Kies een race",
		select_club: "Kies een club",
		select_team: "Kies een team",
		select_noClub: "Geen club beschikbaar",
		select_noTeam: "Geen team beschikbaar",
		select_gender: "Kies het geslacht",
		select_bib: "Kies een startnummer",
		select_country: "Kies een land",
		select_countrySearch: "Zoeken...",
		select_countryNoResult: "Geen resultaten",
		published: "Geplaatst",
		unpublished: "Niet geplaatst",
		edit: "Bewerken",
		registered: "Geregistreerd",
		toValidate: "Te valideren",
		ofTurnover: "van de omzet",
		publishEvent: "Publiceer evenement",
		unpublishEvent: "Verwijder evenement",
		publishEventWarning:
			"Waarschuwing, u staat op het punt dit evenement te publiceren, wat betekent dat het zichtbaar zal zijn voor alle Chronometrage.com gebruikers. Weet u zeker dat u verder wilt gaan?",
		unpublishEventWarning:
			"Waarschuwing, u staat op het punt dit evenement te verwijderen, wat betekent dat het niet meer zichtbaar zal zijn voor Chronometrage.com gebruikers. Weet u zeker dat u verder wilt gaan?",
		editEvent: "Bewerk evenement",
		editRunner: "Bewerk hardloper",
		handleDetection: "Beheer detecties",
		eventName: "Evenement naam",
		category: "Categorie",
		date: "Datum",
		description: "Beschrijving",
		poster: "Poster",
		ffaFFTRI: "FFA / FFTRI",
		ffa: "FFA",
		fftri: "FFTRI",
		location: "Locatie",
		rulesPDF: "Regels PDF",
		website: "Website",
		file: "Bestand",
		rib: "RIB",
		addRunner: "Voeg hardloper toe",
		addRegistration: "Voeg registratie toe",
		sendEmailTitle: "Stel de e-mail op voor geregistreerde deelnemers",
		sendOneEmailTitle: "Stel de e-mail op voor de deelnemer",
		insight: "E-mail voorbeeld",
		config_title: "Beheer",
		runs_title: "Races",
		update_presentation: "Presentatie",
		update_runs: "Races",
		update_options: "Opties",
		update_contacts: "Contacten",
		update_sponsors: "Sponsoren",
		update_ribs: "RIBs",
		configure_steps: "Stappen configureren",
		configure_live: "Live tracking",
		configure_results: "Verstuur resultaten",
		sync_folomi_error: "Er zijn meer registraties dan hardlopers.",
		sync_folomi_action: "Klik om te synchroniseren",
		sync_folomi_button: "Synchroniseer hardlopers",
		sync_folomi_race_subscribed: "geregistreerd maar slechts",
		sync_folomi_race_detected: "gedetecteerde hardlopers",
		ask_validation: "Vraag publicatie aan",
		unpublish: "Verwijder evenement",
		validation_asked: "Publicatie aangevraagd",
		preview: "Voorbeeld",
		preview_invoice: "Voorbeeld factuur",
		edit_invoice: "Factuur bewerken",
		commissions: "Commissie-informatie",
		donations: "Donaties",
		invoice_title: "Facturering",
		option: "Optie",
		configure_options: "Configureer opties",
		configure_lists: "Aangepaste velden",
		lists: "Configuratie velden",
		add_list: "Voeg een veld toe",
		edit_list: "Bewerk een veld",
		delete_list: "Verwijder een lijst",
		sure_delete_list: "Weet u zeker dat u wilt verwijderen",
		add_list_choice: "Voeg keuzes toe",
		empty_list_text: "U kunt geen lege lijst importeren",
		list_choice_name_too_short: "De keuzenaam is te kort",
		add_global_option: "Voeg een globale optie toe",
		lists_desc:
			"Deze velden zullen beschikbaar zijn in het registratieformulier",
		lists_text_desc:
			"U kunt tekst plakken gescheiden door puntkomma’s ( ; ) of nieuwe regels",
		list_name_too_short: "De veldnaam is te kort",
		list_available_choices: "Beschikbare keuzes",
		import_list: "Importeer deze lijst",
		or: "of",
		add_price: "Voeg een prijs toe",
		add_option: "Voeg een optie toe",
		add_product: "Voeg een product toe",
		min_quantity: "Minimale hoeveelheid",
		max_quantity: "Maximale hoeveelheid",
		designation: "Aanduiding",
		option_type: "GEWENSTE VELDTYPE",
		is_required: "WILT U DE OPTIE VERPLICHT MAKEN?",
		product: "Product",
		price: "Prijs",
		type_dropdown_description:
			"Lijst van verschillende producten (Voorbeeld: T-shirt maat, S, M, L)",
		type_check_description: "Checkbox (Voorbeeld: Bus, Maaltijd)",
		type_quantity_description:
			"Aantal opgeven voor een product (Voorbeeld: Aantal maaltijden voor hardlopers)",
		delete_title: "Verwijdering",
		delete_message: "Weet u zeker dat u wilt verwijderen",
		price_start_date: "STARTDATUM EN TIJD VAN PRIJS",
		price_end_date: "EINDDATUM EN TIJD VAN PRIJS",
		price_start_date_donation: "STARTDATUM EN TIJD VAN DONATIE",
		price_end_date_donation: "EINDDATUM EN TIJD VAN DONATIE",
		warning_no_price:
			"Ten minste één prijs (zelfs gratis) moet bestaan voor deze race om een optie toe te voegen.",
		warning_delete: "(De bewerking wordt pas na opslaan uitgevoerd)",
		error_qty_min_0: "Hoeveelheden moeten groter zijn dan 0",
		error_qty_min_max:
			"De minimale hoeveelheid moet kleiner zijn dan de maximale hoeveelheid",
		error_too_small: "(Te klein)",
		default: "Standaard",
		prestation: "Start de dienst",
		loading: "Laden",
		importResults: "Importeer resultaten",
		set: "Instellen",
		notSet: "Niet ingesteld",
		filesToBeChecked: "bestanden om te controleren",
		completedAndValidatedFiles: "voltooide en gevalideerde bestanden",
		viaOptions: "via opties",
		confirm: "Bevestigen",
		cancel: "Annuleren",
		interrupt: "Onderbreken",
		previous: "Vorige",
		next: "Volgende",
		save: "Opslaan",
		search: "Zoeken",
		download: "Downloaden",
		allM: "Alle mannen",
		allF: "Alle vrouwen",
		delete: "Verwijderen",
		inTeam: " - In team",
		send: "Verstuur",
		create_event: "Maak een evenement",
		required: "Vereist",
		discard_changes: "Wijzigingen verworpen?",
		preview_off: "Sluit voorbeeld",
		error_boundary_title: "Er is een fout opgetreden.",
		error_boundary_support:
			"Als het probleem aanhoudt, neem dan contact op met de ondersteuning en geef de volgende informatie door:",
		back: "Terug",
		sendMailAllSubscribers: "Verstuur collectieve e-mail",
		sendMail: "Stuur e-mail",
		sendMailError: "Er is een fout opgetreden bij het versturen van de e-mail",
		resetExpired: "Reset is verlopen. Vraag een nieuwe reset aan",
		eventSearch: "Zoek een evenement",
		number_bib: "Startnummer",
		number_line: "Lijnnummer",
		time_detection: "Tijd van passage",
		number_line_short: "Lijn",
		whatIsJetcode: "Wat is een jetcode?",
		jetcodeExplanation:
			"Het is een hulpmiddel waarmee je de inhoud van een andere pagina (registratieformulieren & online betalingen, resterende startnummers, aftelling, chronometrage.com logo) direct op je website kunt integreren.",
		howIntegrateJetcodeToMyWebsite:
			"Hoe integreer ik een jetcode op mijn website?",
		jetcodeIntegrationExplanation:
			"Kopieer eenvoudig de geleverde codes van de gewenste jetcodes (inclusief mijn registraties en chronometrage.com logo) en plak ze op een pagina van je website.",
		integrateSubscriptionsForm: "Integreer het registratieformulier",
		integrateSubscirbersList: "Integreer de lijst van inschrijvingen",
		integrateOnlineSubscriptionLogo: "Integreer het 'online registratie' logo",
		engineCodeLabel:
			"Motercode die eenmaal in de header van je site moet worden gekopieerd",
		basketCodeLabel:
			"Mandcode die eenmaal op elke pagina van je site moet worden gekopieerd",
		runCodeLabel: "Voer specifieke code uit",
		french: "Frans",
		english: "Engels",
		copy: "Kopiëren",
		copyDone: "Gekopieerd!",
		insertionCodeLabel:
			"Mand-invoercode, deze code moet eenmaal per pagina worden ingevoegd",
		language: "Taal",
		runSelectLabel: "Gewenste race voor de jetcode",
		priceJetcodeCodeLabel:
			"Jetcode-prijsinstellingen om in je site te kopiëren",
		theme: "Thema",
		setup: "Instellingen",
		seeTimer: "Bekijk timer",
		seeSeconds: "Bekijk seconden",
		seeBibs: "Bekijk startnummers",
		seeBibsLeft: "Bekijk resterende startnummers",
		codeToCopyToYourWebsite: "Code om naar je website te kopiëren",
		downloadProcessLabel:
			"Download de procedure in PDF-formaat om naar je webmaster te sturen",
		listSetup: "Lijstinstellingen",
		selectARun: "Selecteer een race",
		generalSettings: "Algemene instellingen",
		dagLiveCredentials: "DAG Live referenties",
		sensorName: "Label",
		distance: "Afstand (m)",
		passageMin: "Min. passages",
		passageMax: "Max. passages",
		sensor: "Sensor",
		type: "Type",
		start: "Start",
		stop: "Stop prestatie",
		deleteDetections: "Verwijder definitief detecties",
		deleteDetectionsSuccess: "Verwijdering voltooid",
		deleteDetectionsError: "Verwijdering mislukt",
		prestationError: "FOUT",
		sensorSettings: "Sensorinstellingen",
		addGpx: "Voeg GPX toe",
		chooseSensorType: "Kies sensor type",
		startLine: "Startlijn",
		finishLine: "Finishlijn",
		checkpoint: "Checkpoint",
		addSensor: "Voeg een sensor toe",
		removeThisSensor: "Verwijder deze sensor",
		handleRunners: "Hardlopers",
		handleDetections: "Detecties",
		dashboardDetections: "Samenvatting",
		global_configuration: "Configuratie",
		route_configuration: "Routes",
		delete_step: "Verwijder deze stap",
		edit_step: "Bewerk deze stap",
		delete_parcours: "Verwijder deze route",
		edit_parcours: "Bewerk deze route",
		delete_segment: "Verwijder dit segment",
		edit_segment: "Bewerk dit segment",
		step: "Stap",
		parcours: "Route",
		segment: "Segment",
		configure_routes: "Configureer routes",
		configure_segments: "Configureer segmenten",
		intermediary: "Tussenstation",
		start_line: "Start",
		end_line: "Finish",
		typeLine1: "Start",
		typeLine2: "Tussenstation",
		typeLine3: "Finish",
		grid: "Rasterindeling",
		categoryReassignement: "Categorieherverdeling",
		handleResults: "Resultaten",
		timezone: "Welke tijdzone wil je behouden?",
		no_run_selected: "Geen race geselecteerd",
		placeholder_select_run: "Selecteer een race",
		placeholder_select_segment: "Selecteer een segment",
		log_title: "Log in op je beheertool",
		forgotten_title: "Wachtwoord vergeten?",
		reset_password: "Reset wachtwoord",
		back_to_home: "Terug naar startpagina",
		passwords_not_match: "Wachtwoorden komen niet overeen",
		passsword_reset_success: "Je wachtwoord is gereset",
		password_too_short: "Je wachtwoord moet minimaal 6 tekens lang zijn",
		send_forgotten_mail_success: "Een e-mail is naar je verzonden",
		email: "E-mailadres",
		new_password: "Nieuw wachtwoord",
		new_password_confirm: "Bevestig nieuw wachtwoord",
		password: "Wachtwoord",
		confirmPassword: "Bevestig je wachtwoord",
		submit: "Inloggen",
		social_reason: "Sociale reden",
		adresse: "Adres",
		postal_code: "Postcode",
		city: "Stad",
		country: "Land",
		noAccount: "Heb je nog geen account?",
		rememberMe: "Onthoud mij",
		signup_button: "Maak een account aan",
		signup: "Ik heb nog geen account",
		signup_title: "Registreer voor de beheertool",
		already_account: "Ik heb al een account",
		forgotten_password: "Wachtwoord vergeten?",
		lastname: "Achternaam",
		firstname: "Voornaam",
		birthdate: "Geboortedatum",
		sex: "Geslacht",
		acceptCGV: "Ik accepteer de gebruiksvoorwaarden van het platform",
		signup_error_firstname: "Je voornaam moet meer dan 1 letter zijn.",
		signup_error_lastname: "Je achternaam moet meer dan 1 letter zijn.",
		signup_error_birthdate:
			"Je geboortedatum moet geldig zijn en je moet tussen de 13 en 100 jaar oud zijn.",
		signup_error_email: "Je e-mail is ongeldig.",
		signup_error_password: "Je wachtwoord moet minimaal 6 tekens lang zijn.",
		signup_error_passwordConfirm: "Je wachtwoorden komen niet overeen.",
		signup_error_CGU: "Je moet de algemene voorwaarden accepteren.",
		male: "Man",
		female: "Vrouw",
		other: "Anders",
		legalEntity: "Juridische entiteit",
		address: "Adres",
		team: "Team",
		race: "Race",
		bib: "Startnummer",
		bibAvailable: "Beschikbaar Startnummer",
		bibUnavailable: "Startnummer al gebruikt",
		invalid: "Ongeldig",
		bibInvalid: "Ongeldig Startnummer",
		runner: "Loper",
		zipCode: "Postcode",
		birthDate: "Geboortedatum",
		genderH: "M",
		genderF: "V",
		genderX: "X",
		editGeneral: "Algemeen",
		editRace: "In race",
		nationality: "Nationaliteit",
		countryPlaceholder: "Selecteer een land",
		firstPhone: "Telefoon 1",
		secondPhone: "Telefoon 2",
		marketer: "Marketeer",
		organizer: "Organisator",
		yes: "Ja",
		no: "Nee",
		bbanDelete: "Verwijder BBAN",
		bbanDeleteWarning:
			"Waarschuwing, u staat op het punt deze BBAN te verwijderen, deze actie is onomkeerbaar. Weet u zeker dat u wilt doorgaan?",
		addTeam: "Voeg een team toe",
		club: "Club",
		duplicateEmail: "Dit e-mailadres is al in gebruik",
		alreadyInUse: "Al in gebruik",
		editTimes: "Tijden",
		object: "Onderwerp",
		mailBody: "E-mailbody",
		swapBibs: "Wissel startnummers",
		swapFrom: "Van",
		swapTo: "Naar",
		nbDetections: "Detecties",
		stageDepartureHour: "Officiële vertrektijd",
		handicap: "Handicap",
		miniTime: "Minimale tijd op het parcours",
		error404: "Fout 404",
		pageDoesNotExist: "Deze pagina bestaat niet",
		iThinkYouAreLost: "Ik denk dat je verdwaald bent",
		goBackHome: "Ga terug naar de homepage",
		dashboard: "Dashboard",
		registrations: "Registraties",
		event: "Evenement",
		liveResults: "Tijdregistratie",
		menuResults: "Resultaten",
		liveConfiguration: "Live-configuratie",
		statistics: "Statistieken",
		admin: "Administratie",
		jetcodes: "Jetcodes",
		toCome: "Komt eraan",
		today: "Vandaag",
		past: "Verleden",
		myAccount: "Mijn account",
		logout: "Uitloggen",
		manageRunners: "Beheer hardlopers",
		detections_dashboard: "Detecties dashboard",
		manage_dashboard: "Beheer detecties",
		registration_refund: "Terugbetaling registratie",
		registration_order: "Registratie bestelling",
		cgu: "Gebruiksvoorwaarden",
		cgv: "Algemene verkoopvoorwaarden",
		manage_results: "Beheer resultaten",
		startOn: "Begint op",
		registeredContestants: "Geregistreerde hardlopers",
		displayRegistrations: "Toon registraties",
		completeSubscriptionsList: "Volledige lijst van registraties",
		bibs: "Startnummers",
		bibsSearch: "Zoek naar een startnummer",
		firstnameSearch: "Zoek naar een voornaam",
		lastnameSearch: "Zoek naar een achternaam",
		statusSearch: "Zoek naar een status",
		loadingRegisterForm: "Registratieformulier laden",
		thanksToWait: "Bedankt voor het wachten",
		noRace: "Geen race geconfigureerd",
		teamOf: "TEAM van",
		invididual: "SOLO",
		importError: "Fout tijdens importeren",
		preimportWarning: "WAARSCHUWING",
		importWarning: "WAARSCHUWING",
		preimportError: "FOUT: DEZE REGISTRATIE WORDT NIET GEPROCESSEERD...",
		preimportErrors: "FOUT: DEZE REGISTRATIES WORDEN NIET GEPROCESSEERD...",
		exportTitle: "Exporteer registraties in",
		raceChoice: "Races",
		sexeChoice: "Geslacht",
		statusChoice: "Status",
		subscriptionType: "Type inschrijving",
		importTitle: "Importeer registraties",
		toImport: "Om een lijst van registraties te importeren, gebruik",
		configureFromAnotherRace: "Configureer vanuit een andere race",
		maximumAge: "Maximale vereiste leeftijd is",
		useThisModel: "dit EXCEL-bestand sjabloon",
		dragHere: "Sleep het EXCEL-bestand (.xlsx formaat) hierheen...",
		dragHereOr:
			"Sleep het EXCEL-bestand (.xlsx formaat) hierheen, of klik om het te selecteren",
		fileFormatNotSupported: "Bestandstype wordt niet ondersteund",
		existingSubs: "Eerdere geïmporteerde deelnemers.",
		replace: "Verwijder alles en vervang",
		donotupdate: "Voeg alleen nieuwe startnummers toe",
		continue: "Ga door met importeren",
		entries: "toevoegingen op",
		import: "Importeren",
		nbImportedRunners: "geïmporteerde loper(s)",
		pre: "Pre-",
		importing: "Importeren...",
		existing: "al bestaand",
		adds: "toevoegingen op",
		add: "toevoegingen",
		close: "Sluiten",
		whattodo: "Wat wil je doen?",
		line: "Lijn",
		invalidRace: "Ongeldige race",
		bibUsed: "Startnummer al gebruikt",
		missingRace: "Race niet opgegeven",
		missingBib: "Startnummer niet opgegeven",
		yourEvents: "Jouw evenementen",
		yourRuns: "Jouw runs",
		bibsAllocation: "Toewijzing van startnummers",
		discountVouchers: "Kortingsbonnen",
		form: "Formulier",
		refunds: "Terugbetalingen",
		accessRights: "Toegangsrechten",
		bankBBAN: "BBAN",
		manageBibsAllocation: "Beheer toewijzing van startnummers",
		manageEventDiscountVouchers: "Beheer evenementen kortingsbonnen",
		manageEventForm: "Beheer evenementformulier",
		manageRefunds: "Beheer terugbetalingen",
		manageAccessRights: "Beheer toegangsrechten",
		manageYourBBAN: "Beheer je BBAN",
		manageFFAFFTRILicenses: "Beheer je FFA/FFTRI-licenties",
		ribListLinkToThisEvent: "Lijst van RIB's gekoppeld aan dit evenement",
		addBBAN: "Voeg een BBAN toe",
		name: "Naam",
		bank: "Bank",
		ibanBban: "IBAN / BBAN",
		thereAreCurrently: "Er zijn momenteel",
		registrantsForThe: "deelnemers voor de race",
		doYouReallyWantToReimburseThe: "Weet je zeker dat je de",
		registrants: "deelnemers wilt terugbetalen?",
		chargeAdditionalFeesPerRegistrations: "Extra kosten per registratie (in €)",
		invoicingOnRunnerOrOrganizer: "Facturering op de loper of organisator",
		ifRegistrationFeeIncluded:
			"Als het inschrijfgeld is inbegrepen, gedekt door",
		reimbursementCostsCoveredBy: "Terugbetalingskosten gedekt door",
		makeTheRefund: "Maak de terugbetaling",
		reason: "Reden",
		choose: "Kies...",
		bibPlan: "Startnummerplan",
		bibPlanAffectation: "Methode voor toewijzing van startnummers",
		minBib: "Minimaal startnummer",
		maxBib: "Maximaal startnummer",
		bibExclusion: "Uitsluiting van startnummers",
		bibExlusionPlaceholder:
			"Voorbeeld: \rLijst = 1;2;8;10 (startnummers 1, 2, 8, en 10 worden niet meegenomen) \rBereik = 5:10 (startnummers van 5 tot 10 inclusief (5, 6, 7, 8, 9, en 10) worden niet meegenomen) \rCombinatie 1 = 2:4;7:8 (startnummers 2, 3, 4, 7, en 8 worden niet meegenomen) \rCombinatie 2 = 2;4:6;8 (startnummers 2, 4, 5, 6, en 8 worden niet meegenomen)",
		reaffectAllbibs:
			"Herkoppel alle startnummers voor de race door de selectie te bevestigen",
		keepBibs: "Behoud reeds toegewezen startnummers",
		applyToPending: "Pas toe op wachtende registraties",
		orderAlphabetic:
			"Herkoppel startnummers in alfabetische volgorde van lopers",
		list: "Op basis van startnummerlijst",
		range: "Op basis van startnummerbereik",
		filterByRun: "Filteren op race",
		resolution: "Oplossing",
		days: "Dagen",
		weeks: "Weken",
		months: "Maanden",
		years: "Jaren",
		runnerAge: "jaar",
		seeComments: "Bekijk opmerkingen",
		downloadInPdf: "Download in PDF",
		nbOfSubscribers: "Aantal abonnees",
		maxInfMin: "Kies een maximale waarde die groter is dan de minimumwaarde",
		errorBib: "Er is een fout opgetreden bij het bijwerken van de startnummers",
		updatedBib: "Startnummers zijn bijgewerkt",
		loginSuccess: "Inloggen succesvol",
		loginError: "Inlogfout",
		logoutSuccess: "Uitloggen succesvol",
		logoutError: "Uitlogfout",
		updateError: "Er is een probleem opgetreden bij de update",
		updateSuccess: "De update was succesvol",
		updateCredentialSuccess: "De update van inloggegevens was succesvol",
		updateCredentialError:
			"Er is een probleem opgetreden bij de update van inloggegevens",
		startPrestation: "De service is gestart",
		liveTimezone:
			"Er is een inconsistentie in de tijdzoneconfiguratie van uw evenement.",
		stopPrestation: "De service is gestopt",
		prestationStartError: "Fout bij het starten van de service",
		prestationStartErrorCredentiel:
			"Configureer uw inloggegevens voordat u de service start",
		prestationStopError: "Fout bij het stoppen van de service",
		updateCheckpointSuccess: "De detectorregistratie was succesvol",
		updateCheckpointError: "Fout tijdens detectorregistratie",
		deletionSuccess: "Verwijdering succesvol",
		deletionError: "Er is een fout opgetreden bij het verwijderen",
		insertSuccess: "Creatie succesvol",
		insertError: "Fout bij het creëren",
		insertErrorDetection:
			"De detectie kon niet worden verzonden. Controleer of er een race gaande is.",
		ppsError:
			"Ongeldige Licentie of PPS (niet gevalideerd door de Franse Federatie)",
		requiredRace: "Kies een race",
		requiredBib: "Kies een startnummer",
		requiredBirthdate: "Geef de geboortedatum op",
		requiredGender: "Geef het geslacht op",
		requiredTeamName: "Geef de naam van het team op",
		requiredTeam: "Kies een team uit de lijst of maak een nieuw team aan",
		requiredOneOrAllRunner: "Geef een of alle lopers op",
		emailSendedSuccess: "E-mails succesvol verzonden.",
		emailSendedError:
			"Er is een fout opgetreden bij het versturen van e-mails.",
		login_expired: "Je sessie is verlopen",
		newRaceUpdateSuccess: "De wijziging van de race was succesvol",
		newRaceUpdateError:
			"Er is een fout opgetreden tijdens de wijziging van de race",
		bibChangeRaceChoice: "Maak een keuze met betrekking tot de startnummers",
		refundSuccess: "Terugbetaling was succesvol",
		refundError: "Er is een fout opgetreden tijdens de terugbetaling",
		sport_identity_success: "Evenement registratie succesvol",
		sport_runs_success: "Races succesvol geconfigureerd",
		sport_options_success: "Opties succesvol geconfigureerd",
		sport_identity_error:
			"Er is een fout opgetreden tijdens het aanmaken van het evenement",
		sport_runs_error:
			"Er is een fout opgetreden tijdens het configureren van de races",
		sport_options_error:
			"Het is niet mogelijk om de opties op te slaan. Controleer of je geen tarief voor een al ingeschreven optie aan het wijzigen bent",
		file_error: "Er is een fout opgetreden tijdens het lezen van het bestand",
		file_exist: "Dit bestand is al geüpload",
		event_form_required: "Je hebt niet alle verplichte velden ingevuld",
		event_invalid_dates:
			"Waarschuwing: De einddatum is hetzelfde als de startdatum",
		error_creation_segment:
			"Er is een fout opgetreden tijdens het opslaan van de etappe",
		success_creation_segment: "Etappe succesvol opgeslagen",
		success_deletion_segment: "Etappe verwijderd",
		error_deletion_segment:
			"Er is een fout opgetreden tijdens het verwijderen van de etappe",
		error_creation_parcours:
			"Er is een fout opgetreden tijdens het opnemen van het parcours",
		success_creation_parcours: "Parcours succesvol opgeslagen",
		success_deletion_parcours: "Parcours verwijderd",
		error_deletion_parcours:
			"Er is een fout opgetreden tijdens het verwijderen van het parcours",
		error_creation_etape:
			"Er is een fout opgetreden tijdens het opnemen van de etappe",
		success_creation_etape: "Etappe succesvol opgeslagen",
		success_deletion_etape: "Etappe verwijderd",
		error_deletion_etape:
			"Er is een fout opgetreden tijdens het verwijderen van de etappe",
		success_creating_price: "Prijs succesvol opgeslagen",
		error_creating_price:
			"Er is een fout opgetreden tijdens het aanmaken van de prijs",
		success_deleting_run: "Race succesvol verwijderd",
		error_deleting_run_subs:
			"Je kunt een race niet verwijderen met inschrijvingen",
		error_deleting_run:
			"Er is een fout opgetreden tijdens het verwijderen van de race",
		success_deleting_price: "Prijs succesvol verwijderd",
		error_deleting_price:
			"Er is een fout opgetreden tijdens het verwijderen van de prijs",
		success_updating_fields: "Registratieformulier bijgewerkt",
		error_updating_fields:
			"Er is een fout opgetreden tijdens het bijwerken van het formulier",
		event_does_not_exist: "Het evenement dat je wilt configureren bestaat niet",
		success_updating_contact: "Contact succesvol geregistreerd",
		error_updating_contact:
			"Er is een fout opgetreden tijdens het registreren van het contact",
		success_updating_rib: "Bankrekening (RIB) succesvol geregistreerd",
		error_updating_rib:
			"Er is een fout opgetreden tijdens het registreren van de bankrekening (RIB). De wettelijke vertegenwoordiger heeft mogelijk al een rekening.",
		success_deleting_contact: "Contact succesvol verwijderd",
		success_deleting_rib: "Bankrekening (RIB) succesvol verwijderd",
		error_deleting_contact:
			"Er is een fout opgetreden tijdens het verwijderen van het contact",
		error_deleting_rib:
			"Er is een fout opgetreden tijdens het verwijderen van de bankrekening (RIB)",
		error_signup:
			"Er is een fout opgetreden. Dit e-mailadres is mogelijk al in gebruik.",
		success_signup: "Je account is succesvol aangemaakt",
		success_change_orga: "Je bent nu een organisator, log opnieuw in",
		error_change_orga: "Er is een fout opgetreden.",
		error_contact_empty:
			"Vul de bedrijfsnaam, voornaam en achternaam van het contact in.",
		success_ask_validation: "Een e-mail is naar de beheerder gestuurd",
		error_ask_validation:
			"Er is een fout opgetreden tijdens het aanvragen van validatie",
		import_error: "Er is een fout opgetreden tijdens de import",
		success_publish_event: "Evenement gepubliceerd",
		success_unpublish_event: "Evenement niet gepubliceerd",
		error_unpublish_event: "Er is een fout opgetreden",
		cant_delete_stage:
			"Verwijder eerst de detecties die aan deze etappe zijn gekoppeld voordat je deze verwijdert",
		reassignCategorySuccess: "Categorieën succesvol toegewezen",
		reassignCategoryError:
			"Er is een fout opgetreden tijdens het hertoewijzen van categorieën",
		orgaSuccess: "Je bent nu organisator",
		orgaError: "Er is een fout opgetreden",
		toast_raceChoice: "Selecteer minimaal één race uit de lijst",
		genderChoice: "Selecteer minimaal één geslacht uit de lijst",
		toast_statusChoice:
			"Selecteer minimaal één inschrijvingsstatus uit de lijst",
		typeChoice: "Selecteer minimaal één inschrijvings type uit de lijst",
		changeTimezoneError:
			"Er is een fout opgetreden tijdens het wijzigen van de tijdzone",
		delete_detections_choice: "Maak een keuze",
		no_delete: "Geen detecties verwijderd",
		no_detections: "Geen detecties",
		no_times: "Geen tijden",
		export_error: "Er is een fout opgetreden tijdens de export",
		accept_document_success: "Het document is geaccepteerd",
		accept_document_error: "Er is een fout opgetreden",
		refuse_document_success:
			"Het document is geweigerd en een e-mail is naar de loper gestuurd",
		refuse_document_error: "Er is een fout opgetreden",
		category_or_team: "Categorie / Team",
		run: "Race",
		status: "Status",
		options: "Opties",
		runners: "Lopers",
		starter: "Starter",
		surrendered: "Overgegeven",
		disqualified: "Gediskwalificeerd",
		filter: "Filter",
		gender: "Geslacht",
		man: "Man",
		woman: "Vrouw",
		speed: "Snelheid",
		gap: "Kloof",
		passageTime: "DOORGAANSTIJD",
		minimmAge: "Minimale vereiste leeftijd is",
		other_gender: "Andere",
		your_runners: "Jouw lopers",
		refused_reason: "Reden voor weigering",
		accept_document: "Accepteren",
		refuse_document: "Weigeren",
		refuse_document_and_notice: "Weigeren en stuur e-mail",
		your_subscriptions: "Jouw inschrijvingen",
		contact: "Contact",
		edit_subscription: "Bewerk inschrijving",
		add_subscription: "Voeg inschrijving toe",
		message: "Stuur een bericht",
		document: "Beheer documenten",
		actions: "Acties",
		waiting: "Wachten",
		valid: "Geldig",
		reimbursed: "Vergoed",
		documents_sent: "Document(en) verzonden",
		close_documents: "Sluiten",
		manage_documents: "Documentbeheer",
		personnal_informations: "Persoonlijke informatie:",
		confirmDeleteSub:
			"Weet je zeker dat je de inschrijving van wilt verwijderen?",
		age: "Leeftijd",
		phone: "Telefoon",
		emergency_phone: "Noodtelefoon",
		mail: "E-mail",
		run_informations: "Race-informatie",
		bib_info: "Startnummer",
		licence: "Licentie",
		validated: "gevalideerd",
		by: "door",
		la: "de",
		validated_by_fede: "Gevalideerd door",
		certificat: "Medisch certificaat",
		autorisation: "Ouderlijke toestemming",
		validation_date: "Validatiedatum:",
		sub_team: "Team / Vereniging / Bedrijf",
		sub_options: "Abonnee-opties:",
		reimburse: "Vergoeden",
		validate_subscription: "Inschrijving valideren",
		refuse_subscription: "Inschrijving weigeren",
		refuse_reason: "Wat is de reden voor weigering?",
		see_message_history: "Bekijk e-mailgeschiedenis",
		messages_history: "E-mailgeschiedenis",
		close_history: "Sluit geschiedenis",
		close_comments: "Sluit opmerkingen",
		"inscription.refus.title": "Inschrijving geweigerd",
		no_messages: "Geen berichten naar deze abonnee verzonden",
		changeRace: "Race wijzigen",
		changeRaceModalTitle: "Wijzig inschrijving voor:",
		currentlyRegistredFor: "Momenteel ingeschreven voor de race",
		newRace: "Nieuwe race",
		cannotChangeRace: "Race wijzigen niet mogelijk",
		teammatesWith: "Met",
		bibMessageChangeRun: "1 - Wil je de startnummers behouden?",
		automaticBibChangeRun:
			"2 - Wil je automatisch nieuwe startnummers toewijzen op basis van het nummerplan van de nieuwe race?",
		bibAlreadyExistInNewRace:
			"heeft al het startnummer in de geselecteerde race. Een nieuw startnummer wordt automatisch toegewezen aan de loper als er een nummerplan is gedefinieerd voor de gekozen race.",
		noBibInNewRace:
			"LET OP: Het huidige startnummer wordt vrijgegeven. Handmatige toewijzing van het startnummer is nodig.",
		noBibAssignmentInNewRace:
			"LET OP: Het huidige startnummer wordt vrijgegeven omdat de nieuwe race geen nummerplan heeft.",
		see_order: "Bekijk bestelling",
		no_document: "Geen document",
		detectors: "Detecteurs",
		verification: "Verificatie OK",
		percentage: "Percentage",
		finishers: "Finishers",
		number_runners: "Deelnemers",
		non_starters: "Niet-gestarten",
		start_the: "Begint op:",
		abandon: "Afgebroken",
		official_starters: "Officiële Deelnemers",
		manage_detections_title: "Detecties",
		hours: "Tijd Doorbrengen",
		restore_detection: "Herstellen",
		delete_detections: "Verwijder Detecties",
		choose_detectors: "Kies een lijn",
		add_detection: "Voeg Detectie Toe",
		edit_detection: "Bewerk Deze Detectie",
		runFormDeleteDetectionConfirmation: "Wil je deze detectie verwijderen?",
		date_format: "Datumformaat",
		chrono_format: "Chrono Formaat",
		effective_start: "Effectieve Start",
		races: "Alle Races",
		deleteAllDetections: "Wil je alle detecties verwijderen?",
		confirmStartSaveCredential:
			"Wil je je gegevens opslaan voordat je de live start?",
		deleteTitle: "Verwijderen van Race Detecties",
		all: "Verwijder alle eventdetecties",
		upload_dag: "Importeer DAG Bestand",
		insertion: "Invoegen",
		estimation: "Schatting",
		current_time: "Duur",
		seconds: "seconden",
		detection_detail: "Detectie Details",
		already_exist: "Bestaat al",
		inserted: "Ingevoegd",
		runner_created: "Inbegrepen Deelnemer Gecreëerd",
		checkpoint_missing: "Ontbrekende Checkpoint",
		interpassage_ignored: "Genegeerd vanwege Interpassage Tijd",
		search_runner: "Zoek een Deelnemer",
		search_event: "Zoek een Event",
		search_bib: "Zoek een Startnummer",
		search_subscription: "Zoek een Abonnement",
		autorisationParentale: "Ouderlijke Toestemming",
		licence_already_sent: "Licentie Al Verzonden",
		certificat_already_sent: "Certificaat Al Verzonden",
		autorisation_already_sent: "Toestemming Al Verzonden",
		send_documents: "Stuur Nieuwe Documenten",
		see_document: "Bekijk Document",
		download_document: "Downloaden",
		product_name: "Productnaam",
		refund_price: "Prijs (inclusief BTW)",
		refund_amount: "Terugbetalingsbedrag",
		subscription_fees: "Abonnementskosten",
		refund_reason: "Reden voor Terugbetaling (Verplicht)",
		refund: "Terugbetaling",
		deleteSub: "Verwijder abonnement",
		orga_sub_fees: "Abonnementskosten voor Uw Rekening?",
		orga_refund_fees:
			"Terugbetalingskosten (1€ inclusief BTW) voor Uw Rekening?",
		contact_doubt:
			"Neem contact op met de ondersteuning als je twijfels hebt over je acties",
		refund_summary: "Terugbetalingssamenvatting",
		refund_close_summary: "Samenvatting Sluiten",
		refund_summary_start_text: "Je staat op het punt om ",
		refund_summary_end_text:
			" terug te betalen aan deze deelnemer, wil je dit bevestigen?",
		confirm_refund: "Bevestig Terugbetaling",
		refund_paid: "De deelnemer heeft betaald",
		amount_to_refund: "Je gaat terugbetalen",
		refund_fees: "Terugbetalingskosten",
		refunded_amount: "Totaal Terugbetaald Bedrag",
		already_refunded: (amount: string, date: string) =>
			`Je kunt dit abonnement niet meer terugbetalen omdat een terugbetaling van ${amount} € al heeft plaatsgevonden op ${date}.`,
		refund_product: "Terugbetaling",
		choose_type: "Ik wil een event organiseren",
		select: "Selecteer",
		type_details: "Details",
		sport_event_identity: "Event Presentatie",
		sport_event_runs: "Race Configuratie",
		sport_event_options: "Optie Configuratie",
		go_back: "Terug",
		save_and_go_run: "Opslaan en Configureer Races",
		save_and_go_options: "Configureer Opties",
		save_and_finish: "Voltooien Configuratie",
		event_name_label: "Eventnaam",
		event_sport_label: "Sport",
		event_sport_option: "Selecteer een sport",
		event_start_date_label: "Startdatum",
		event_end_date_label: "Einddatum",
		event_poster_label: "Afbeelding",
		event_banner_label: "Banner",
		event_short_description_label: "Korte Beschrijving",
		event_long_description_label: "Lange Beschrijving",
		event_address_label: "Adres",
		event_postal_code_label: "Postcode",
		event_city_label: "Stad",
		event_country_label: "Land",
		event_name_error: "De naam van je event moet langer zijn dan 3 tekens",
		event_sport_error: "Je moet een sport kiezen",
		event_start_date_error:
			"De datum mag niet vóór de huidige datum zijn (of moet overeenkomen met de datum ingevoerd in de SIFFA als FFA-code)",
		event_ffa_siffa_error:
			"Ontbrekende FFA-autoriteit. Heb je je event gekoppeld aan het DAG-systeem op CALORG?",
		event_end_date_error:
			"Je moet een datum kiezen die later is dan de huidige datum",
		event_short_description_error:
			"De korte beschrijving moet tussen de 3 en 190 tekens bevatten",
		event_address_error: "Je adres moet langer zijn dan 3 tekens",
		event_postal_code_error: "Je postcode moet langer zijn dan 3 tekens",
		event_city_error: "Je stad moet meer dan 3 tekens bevatten",
		event_country_error: "Je moet een land kiezen",
		event_country_option: "Selecteer een land",
		event_timezone_label: "Tijdzone",
		event_places_label: "Beschikbare Plaatsen",
		event_timezone_option: "Selecteer een tijdzone",
		event_rules_label: "Event Regels",
		event_run_name_label: "Racenaam",
		event_run_date_label: "Racedatum",
		event_run_member_label: "Indien per team, aantal teamleden",
		event_run_transfer_label: "Sta overdracht van inschrijvingen toe?",
		event_run_details_label: "Extra informatie",
		event_run_details: "Als je meer informatie over de race wilt geven",
		event_run_number_members_label: "Aantal Teamleden",
		event_run_places_label: "Beschikbare Plaatsen",
		event_run_start_date_label: "Startdatum",
		event_run_end_date_label: "Einddatum",
		event_run_same_bib_label: "Zelfde nummer voor het team",
		event_run_fftri_code: "FFTRI Competitiecode",
		event_run_fftri_distance: "Afstandstype",
		event_run_licence_accepted: "Geaccepteerde licenties",
		event_run_licence_accepted_tooltip:
			"Soort licentie geaccepteerd voor deze race",
		event_run_licence_accepted_all: "Alle licenties",
		event_run_licence_accepted_comp: "Wedstrijdlicenties",
		event_run_same_bib_no: "Nee",
		event_run_same_bib_yes: "Ja",
		event_run_make_it_visible:
			"Maak de lijst van lopers zichtbaar op chronometrage.com ?",
		event_run_make_it_visible_info:
			"Door dit vakje aan te vinken, machtigt u de publicatie van de lijst van geregistreerde lopers op de site chronometrage.com",
		event_add_run: "Voeg Race Toe",
		event_delete_run: "Verwijder Race",
		event_undo_delete_run: "Verwijder Race Niet",
		event_prices: "Prijzen",
		event_run: "Race",
		event_run_designation_label: "Benaming",
		edit_run_title: "Bewerk Race",
		edit_tarif_title: "Bewerk Prijs",
		edit_contact_title: "Bewerk Contact",
		edit_rib_title: "Bewerk Bankrekening",
		deleteConfigPointError_1451:
			"Verwijder eerst alle segmenten die dit detectiepunt bevatten.",
		event_run_age_control_label: "Leeftijdscontrole Type",
		event_run_age_control_option: "Geen Leeftijdsbeperkingen",
		event_run_age_min_label: "Minimale Leeftijd",
		event_run_age_max_label: "Maximale Leeftijd",
		event_run_add_price: "Voeg Prijs Toe",
		event_add_contact: "Voeg Contact Toe",
		event_add_rib: "Voeg Bankrekening Toe",
		validate: "Valideren",
		fees_inclued: "inclusief",
		fees_exclued: "exclusief",
		event_fees_inclued: "Inclusieve Kosten",
		event_fees_exclued: "Exclusieve Kosten",
		event_starting_date: "Start:",
		event_ending_date: "Einde:",
		event_age_disponibility: (min: number, max: number) =>
			`Beschikbaar van ${min} jaar oud tot ${max} jaar oud`,
		event_price_fee_inclued: (fees: number) =>
			`inclusief ${fees.toFixed(2)}€ kosten`,
		event_price_fee_exclued: (fees: number) => `+ ${fees.toFixed(2)}€ kosten`,
		event_run_price: "Prijs",
		event_run_fees_type_label: "Soort vergoeding",
		event_run_type_control_event: "Volgens de evenementdatum",
		event_run_type_control_subscription: "Volgens de inschrijvingsdatum",
		event_run_type_control_year_event: "Volgens het evenementjaar",
		event_run_free_run: "Gratis race?",
		event_no_free_run: "Nee",
		event_free_run: "Ja",
		event_free_run_text: "Gratis race",
		event_ffa_siffa_label: "FFA Competiticode",
		event_configure_sub_form: "Configureer het formulier",
		event_edit_run: "Bewerk de race",
		event_run_form_title: "Inschrijvingsformulier configuratie",
		event_tooltip_form: "Een formulier is geconfigureerd voor dit tarief",
		event_field_nom_label: "Achternaam",
		event_field_prenom_label: "Voornaam",
		event_field_sexe_label: "Geslacht",
		event_field_dateNaissance_label: "Geboortedatum",
		event_field_mail_label: "E-mail",
		event_field_ville_label: "Stad",
		event_field_codePostal_label: "Postcode",
		event_field_pays_label: "Land",
		event_field_nationalite_label: "Nationaliteit",
		event_field_telephone_label: "Telefoon",
		event_field_telephoneUrgence_label: "Noodtelefoon",
		event_field_numLicence_label: "Licentienummer",
		event_field_licence_label: "Licentie",
		event_field_certificat_label: "Medisch certificaat",
		event_field_pps_label: "PPS Nummer (FFA)",
		event_field_adresse1_label: "Adres",
		event_field_adresse2_label: "Adresregel 2",
		event_field_club_label: "Club",
		event_field_team_label: "Team",
		event_field_ffa_label: "FFA CNIL",
		event_field_autorisationParentale_label: "Ouderlijke toestemming",
		event_field_tiers_label: "Derde partij",
		event_field_dechargeResponsabilite_label: "Disclaimer",
		group_fields_nominative: "Nominatieve velden",
		group_fields_FFA: "FFA velden",
		group_fields_others: "Andere velden",
		infos_ffa_numlicence_optional_message:
			"De hardloper kan zijn FFA-licentienummer invoeren",
		infos_ffa_numlicence_required_message:
			"De hardloper moet zijn FFA-licentienummer invoeren",
		infos_ffa_ppsandlicence_optional_message:
			"De hardloper kan zijn PPS of FFA-licentienummer invoeren",
		infos_ffa_ppsandlicence_required_message:
			"De hardloper moet zijn PPS of FFA-licentienummer invoeren",
		enter_text: "Voer uw beschrijving in",
		enter_mail_content: "Schrijf de inhoud van uw e-mail...",
		enter_mail_object: "Schrijf het onderwerp van uw e-mail...",
		sport_event_contacts: "Contactbeheer",
		sport_event_ribs: "Bankrekeningbeheer",
		event_raisonSocial_label: "Juridische naam",
		event_nom_label: "Achternaam",
		event_prenom_label: "Voornaam",
		event_adresse1_label: "Adres",
		event_adresse2_label: "Adresregel 2",
		event_ville_label: "Stad",
		event_codePostal_label: "Postcode",
		event_pays_label: "Land",
		country_home: "Land",
		event_telephone1_label: "Telefoon",
		event_telephone2_label: "Secundaire telefoon",
		event_mail_label: "E-mail",
		event_siteWeb_label: "Website",
		event_facebook_label: "Facebook",
		event_twitter_label: "Twitter",
		event_instagram_label: "Instagram",
		delete_tarif_title: "Wilt u het tarief echt verwijderen",
		delete_run_title: "Wilt u de race echt verwijderen",
		restore_deletes: "Herstel verwijderde items",
		restore: "Ongedaan maken",
		delete_contact_title: "Wilt u het contact echt verwijderen",
		create_step: "Voeg een stap toe",
		create_route: "Voeg een route toe",
		step_name_label: "Stapnaam",
		event_distance_label: "Afstand (in meters)",
		sport_event_steps: "Stapconfiguratie",
		sport_event_parcours: "Routeconfiguratie",
		sport_event_segments: "Faseconfiguratie",
		parcours_name_label: "Route naam",
		segment_name_label: "Fase naam",
		parcours_file_label: "GPX bestand",
		create_parcours: "Voeg een route toe",
		update_parcours: "Bewerk een route",
		create_segment: "Voeg een fase toe",
		update_segment: "Bewerk een fase",
		delete_step_title: "Wilt u de stap echt verwijderen",
		delete_segment_title: "Wilt u de fase echt verwijderen",
		delete_parcours_title: "Wilt u de route echt verwijderen",
		checkpoint_debut_label: "Start checkpoint",
		checkpoint_fin_label: "Eind checkpoint",
		parcours_active_label: "Actieve route",
		active: "actief",
		event_fees: "Vergoedingen",
		dag_fees: "DAG vergoeding (inclusief BTW)",
		subscription_income: "Uw inkomen",
		subscription_fees_sup: "Uw aandeel inclusief BTW",
		subscription_fees_total: "Totale inschrijvingskosten (inclusief BTW)",
		event_sport_price: "Race prijs",
		event_fftri_default_distance: "Kies een afstand",
		designation_label: "Benaming",
		unlimited: "Onbeperkt",
		nomBanque_label: "Banknaam",
		iban_label: "IBAN",
		bic_label: "BIC",
		inCharge_label: "Ik ben de wettelijke vertegenwoordiger",
		lastname_label: "Achternaam",
		firstname_label: "Voornaam",
		birthdate_label: "Geboortedatum",
		email_label: "E-mail",
		address_label: "Adres",
		city_label: "Stad",
		postalCode_label: "Postcode",
		country_label: "Land",
		phone_label: "Telefoon",
		sex_label: "Geslacht",
		genderHfull: "Man",
		genderFfull: "Vrouw",
		genderXfull: "Overig",
		select_type_rib: "Selecteer een rol",
		see_contract: "Bekijk contract",
		add_general_option: "Voeg een globale optie toe",
		delete_stage_cannot_start_live:
			"WAARSCHUWING: Als je deze fase verwijdert zonder een andere te creëren, kun je niet live gaan.",
		event_configuration: "Ik wil een evenement organiseren",
		event_identity: "Evenement identiteit",
		event_runs: "Races",
		event_options: "Opties",
		event_subscription: "Inschrijving configuratie",
		event_contacts: "Contacten",
		event_rib: "Bankrekeningen",
		subscriptions: "Inschrijvingen",
		live_results: "Live / Resultaten",
		live_positions: "Positie van de lopers",
		manage_detections: "Detectiebeheer",
		detection_dashboard: "Detectie overzicht",
		manage_runners: "Loperbeheer",
		manage_live: "Live beheer",
		manage_steps: "Stapconfiguratie",
		manage_parcours: "Routeconfiguratie",
		manage_segments: "Faseconfiguratie",
		bib_plan_exemple_1:
			"Lijst = 1;2;8;10 (startnummers 1, 2, 8 en 10 worden niet in aanmerking genomen)",
		bib_plan_exemple_2:
			"Bereik = 5:10 (bib-nummers van 5 tot 10 inclusief (5, 6, 7, 8, 9, en 10) worden niet in aanmerking genomen)",
		bib_plan_exemple_3:
			"Combinatie 1 = 2:4;7:8 (bib-nummers 2, 3, 4, 7, en 8 worden niet in aanmerking genomen)",
		bib_plan_exemple_4:
			"Combinatie 2 = 2;4:6;8 (bib-nummers 2, 4, 5, 6, en 8 worden niet in aanmerking genomen)",
		event_name:
			"Wordt gebruikt om de activiteit te identificeren tijdens de registratie.",
		event_places:
			"Beperk registraties tot een gedefinieerd aantal plaatsen (laat leeg voor onbeperkte plaatsen).",
		event_start_date: "Bepaalt de startdatum van de activiteit.",
		event_end_date: "Bepaalt de einddatum van de activiteit.",
		event_team_number:
			"Bepaalt hoeveel registranten een team vormen (laat leeg als de activiteit niet op teams is gebaseerd).",
		event_transfer:
			"Stelt registranten in staat hun registratie naar een andere gebruiker over te dragen.",
		event_price_name:
			"Beschrijft de prijs van de race (bijv. Early Bird, Kind, Volwassene).",
		event_age: "Bepaalt een leeftijdsgrens voor registratie.",
		event_min_age: "Stelt de minimumleeftijd in voor registratie.",
		event_max_age: "Stelt de maximumleeftijd in voor registratie.",
		event_price_start_date:
			"Bepaalt de startdatum wanneer het tarief beschikbaar wordt. Tarieven zijn pas zichtbaar na deze datum.",
		event_price_end_date:
			"Bepaalt de einddatum wanneer het tarief niet meer beschikbaar is. Tarieven zijn niet zichtbaar na deze datum.",
		event_price_free:
			"Geeft aan of het tarief gratis is. Deelnemers betalen geen kosten, maar ze worden aan jou in rekening gebracht.",
		event_price_price:
			"Bepaalt de prijs van de race (aanvullende kosten kunnen van toepassing zijn).",
		event_price_fees_type:
			"Geeft aan of de kosten exclusief (niet inbegrepen in de prijs van de race) of inclusief (inbegrepen in de prijs van de race) zijn.",
		event_price_fees:
			"Kosten in rekening gebracht door het platform. Deze kosten zijn ten minste gelijk aan de extra kosten die door de marketeer zijn toegevoegd.",
		event_price_fees_sup: "Aanvullende kosten toegepast door de marketeer.",
		event_price_fees_calc:
			"Platformkosten + aanvullende kosten van de marketeer.",
		event_timezone: "Kies zorgvuldig voor live ranking doeleinden.",
		event_defined_tz: "Tijdzone gedefinieerd voor het evenement",
		default_race:
			"Classificeer een onbekend bib gedetecteerd tijdens live in een standaard race als er geen nummeringsplan is gedefinieerd",
		finances: "Financiële Stroom",
		finances_desc: "Bekijk de financiële stromen van een evenement",
		finances_resume: "Overzicht van Overdrachten",
		finances_resume_desc:
			"Bekijk de status van overboekingen voor platformevenementen",
		placeholder_select: "Selecteer een evenement",
		placeholder_search: "Zoek naar een evenement",
		search_client: "Zoek naar een klant",
		select_no_options: "Geen evenementen",
		report: "Rapport",
		time: "Tijd",
		section: "Fase",
		filters: "Filters",
		placeholder_select_gender: "Selecteer een geslacht",
		placeholder_select_category: "Selecteer een categorie",
		H: "Man",
		F: "Vrouw",
		A: "Anders",
		export: "Exporteren",
		export_format: "Exportformaat",
		placeholder_select_format: "Selecteer een formaat",
		optional: "(optioneel)",
		warning_passage: (line: number, min: number, done: number) =>
			`Line ${line}: ${min} min passage, ${done} passages completed`,
		inviteNewUser: "Nodig een nieuwe gebruiker uit",
		error403: "Fout 403",
		contactOrga:
			"Neem contact op met de organisator als je deze toegang nodig hebt",
		event_form_donation_required: "De keuze voor een donatie is vereist",
		restrictedPage: "Beperktere pagina",
		permissions: "Machtigingen",
		roles: "Machtigingen",
		search_permissions: "Zoek naar een naam",
		invoice: "Factuur",
		dag_share_ht: "DAG-aandelen excl. belasting",
		orga_share_ht: "Orga-aandelen excl. belasting",
		total_ht: "Totaal excl. belasting",
		total_ttc: "Totaal incl. belasting",
		com_ht: "Commissie excl. belasting",
		bill_com: "Factuurstaat",
		bill_orga: "Factuur Orga",
		title: "Titel",
		quantity: "Hoeveelheid",
		unit_prix_ht: "P.U excl. belasting",
		unit_prix_ttc: "P.U incl. belasting",
		nbSubs: "Abonnees",
		StartPeriode: "Periode start",
		EndPeriode: "Periode einde",
		paymentDone: "Betaling voltooid",
		validatePayment: "Valideer betaling",
		toastPaymentSuccess: "Overdracht gevalideerd",
		toastPaymentError:
			"Er is een fout opgetreden bij het valideren van de overdracht",
		downloadContractAskValidation: "Download en vraag validatie",
		contract_explanation:
			"Om de publicatie van je evenement te voltooien, is het essentieel een contract op te stellen. Nadat je je verzoek tot publicatie hebt bevestigd, sturen we je dit contract per e-mail, dat vervolgens ingevuld en teruggestuurd moet worden naar ons team: ",
		licences: "Licenties",
		licences_desc: "Beheer de DAG-klantenlicenties",
		clients: "Klanten",
		clients_desc: "DAG-klantenbeheer",
		toast_invoice_error:
			"Er is een fout opgetreden. Controleer of het evenement gesloten is en of de rib aanwezig is.",
		documents: "Documenten",
		total_subs: "Totaal aantal abonnementen",
		total_subs_online: "Totaal aantal online abonnementen",
		total_subs_manual: "Aantal handmatige abonnementen",
		total_subs_imported: "Aantal geïmporteerde abonnementen",
		total_subs_in_place: "Aantal abonnementen ter plaatse",
		docMissing: "Documenten missen",
		manualCancel: "Annuleren",
		orga_title: "Word een organisator",
		signup_error_country: "Land is niet geldig",
		signup_error_city: "Stad is ongeldig",
		signup_error_postal_code: "Postcode is ongeldig",
		signup_error_adresse: "Adres is ongeldig",
		sub_validation_message: "Reden ontbreekt",
		surrended: "Opgegeven",
		runEndDate: "Einddatum",
		runMaxNbOfSlots: "Maximaal aantal plaatsen",
		runnerChart: "Loperscharter",
		yourParentalAuthorizationModel: "Uw ouderlijke autorisatiemodel",
		addBban: "Voeg een RIB toe",
		editBban: "Bewerk een RIB",
		bbanType: "RIB-type",
		bbanName: "RIB-naam",
		bankName: "Naam van de bank",
		iban: "IBAN",
		bicSwift: "BIC/SWIFT",
		areYouThePersonInCharge: "Bent u de wettelijke voogd?",
		personInChargeInformations: "Informatie van de wettelijke voogd",
		subOptions: "Opties",
		subEdit: "Abonnement bewerken",
		subscription: "Abonnement",
		runName: "Naam van de race",
		teamName: "Teamnaam",
		runStartDate: "Startdatum",
		noClub: "Geen club",
		loginMissing: "Ontbrekende inloggegevens",
		createNewEdition: "Maak een nieuwe editie aan",
		disableSubscriptions: "Schakel abonnementen uit",
		activateSubscriptions: "Activeer abonnementen",
		inputs_edition_not_complete: "Vul alstublieft alle velden in",
		error_new_edition:
			"Er is een fout opgetreden bij het proberen een nieuwe editie te maken",
		mailUsed: "E-mail genegeerd. Al in gebruik.",
		mailUsedTooltip:
			"Controleer voornaam en achternaam. Ze zijn mogelijk verkeerd gespeld",
		succes_stopsubscription_event: "Abonnementen succesvol uitgeschakeld",
		error_stopsubscription_event:
			"Er is een fout opgetreden bij het uitschakelen van abonnementen",
		results: "Resultaten",
		displayOrder: "Weergave volgorde",
		moveAbove: "Verplaats naar boven",
		moveBelow: "Verplaats naar beneden",
		moveTop: "Verplaats naar bovenaan",
		moveBottom: "Verplaats naar onderaan",
		cannotDeleteRunWithSubscription:
			"U kunt een race met abonnementen niet verwijderen",
		addGpxFile: "Voeg een GPX-bestand toe",
		gpxFile: "GPX-bestand",
		ShouldBeNotSameDate: "Data mogen niet hetzelfde zijn",
		toastSuccessAddingRib: "Rib succesvol toegevoegd",
		toastErrorAddingRib: "Er is een fout opgetreden bij het toevoegen van rib",
		placeholder_select_rib: "Kies een rib",
		processed: "Verwerkt",
		earnings: "Verdiensten",
		chartTitleSubs: "Abonnementen / Race",
		chartTitleGenders: "Geslachten",
		chartTitleCases: "Gevallen",
		noOptions: "Geen optie beschikbaar",
		noAdditionalInformation: "Geen aanvullende informatie",
		saveOk: "Opslaan succesvol",
		saveError: "Er is een fout opgetreden bij het opslaan",
		shareLiveTrackingUrl: "De live tracking URL delen",
		cantPreview:
			"De preview is niet beschikbaar voor dit document (te groot). Download het alstublieft.",
		liveTypeOfRun: "Wat voor type race?",
		liveDateOfRun: "Wanneer is de startdatum van de race?",
		bibNotFound:
			"Dit startnummer maakt geen deel uit van uw evenement. Klik om toe te voegen aan abonnees",
		between: "tussen",
		and: "en",
		none: "Geen",
		bySubscriber: " /loper",
		chooseCalendarRunType: "Kies een type race",
		chooseCalendarRunTypeSimple: "Eén enkele race (Start - Inter. - Finish)",
		chooseCalendarRunTypeLaps: "Ronde race",
		chooseCalendarRunTypeSimpleVirtual:
			"Eenvoudige virtuele race (Sportapplicatie)",
		chooseCalendarRunTypeLapsVirtual: "Virtuele ronde race (Sportapplicatie)",
		chooseCalendarRunTypeTriathlon: "Triatlon",
		calendarRunDistance: "Hoe ver is de race? (in meter)",
		calendarRunLaps: "Hoeveel ronden heeft uw race?",
		calendarRunDetectionPoints: "Uw detectiepunten",
		calendarRunSegments: "Uw segmenten",
		calendarRunDetectionAddPoint: "Voeg een detectiepunt toe",
		calendarRunAddSegment: "Voeg een segment toe",
		calendarRunDetectionConfigurePoint: "Configureer uw detectiepunt",
		calendarRunConfigureSegment: "Configureer uw segment",
		calendarRunDetectionType: "Wat is het type van uw punt?",
		calendarRunDetectionChooseType: "Kies een type",
		finish: "Finish",
		arrived: "Aangekomen",
		advancedParams: "Geavanceerde instellingen",
		interpassTime: "Tijd tussen passages",
		maxNumberPass: "Maximaal aantal passages",
		minNumberPass: "Minimaal aantal passages",
		startNumberPass: "Startnummer passage",
		endNumberPass: "Eindnummer passage",
		numberPass: "Nummer passage",
		startDetectionPoint: "Ingang",
		endDetectionPoint: "Uitgang",
		latitude: "Breedtegraad",
		longitude: "Lengtegraad",
		order: "Volgorde",
		radius: "Straal",
		altitude: "Hoogte",
		runFormTypeError: "U moet een type race kiezen",
		runFormDistanceError: "De afstand van uw race moet groter zijn dan 0",
		runFormLapsError: "Het aantal ronden moet groter zijn dan 0",
		runFormPointNameError: "U moet een naam geven aan uw punt",
		runFormPointDistanceError:
			"De afstand van uw punt moet worden gedefinieerd",
		runFormPointLineError: "Een lijn moet worden gedefinieerd",
		runFormPointTypeError: "U moet een stiktype kiezen",
		runFormPointSameError: "Start- en eindpunten zijn hetzelfde",
		runFormSegmentNotComplete: "Segmentinformatie incompleet",
		runFormSegmentErrorHappened:
			"Er is een fout opgetreden bij het opslaan van het segment",
		calendarRunDeleteSegment: "Verwijder segment",
		runFormDeleteSegmentConfirmation:
			"Weet u zeker dat u dit segment wilt verwijderen?",
		runFormDeleteSegmentSuccess: "Segment succesvol verwijderd",
		runFormUpdateSegmentSuccess: "Segment succesvol bijgewerkt",
		runFormDistanceType: "Kies afstandstype",
		whichRunConfigure: "Welke race wilt u configureren?",
		credentialDagLive: "Uw DAG Live-inloggegevens",
		saveCredentialSuccess: "Uw inloggegevens zijn opgeslagen",
		saveCredentialError:
			"Er is een fout opgetreden bij het opslaan van uw inloggegevens.",
		saveConfigRunSuccess: "Uw configuratie is opgeslagen.",
		saveConfigRunError:
			"Er is een fout opgetreden bij het bijwerken van uw configuratie.",
		saveConfigPointSuccess: "Uw punt is opgeslagen.",
		saveConfigPointError:
			"Er is een fout opgetreden bij het opslaan van uw punt.",
		deleteConfigPointSuccess: "Uw configuratie is verwijderd.",
		deleteConfigPointError:
			"Er is een fout opgetreden bij het verwijderen van uw punt.",
		seeAllDetections: "Bekijk alle detecties",
		rankingHeaderRank: "Rk",
		rankingHeaderName: "Naam",
		rankingHeaderBib: "Startnummer",
		rankingHeaderLastPassage: "Laatste passage",
		rankingHeaderDetections: "Detecties",
		rankingHeaderTime: "Tijd",
		rankingHeaderCategoryRank: "Cat. Rk.",
		rankingHeaderSexRank: "Geslachts-Rk.",
		rankingHeaderRun: "Race",
		rankingHeaderTeam: "Team",
		rankingHeaderActions: "Acties",
		editRunnerTooltip:
			"Let op, het bijwerken van een loper verandert ook alle andere abonnementen",
		editRunnerRunTooltip:
			"Het bijwerken van race-informatie heeft invloed op de ranglijsten",
		selectRunPlaceholder: "Selecteer een race",
		chooseTeam: "Kies een team",
		createTeam: "Maak een team",
		resetOnRunner: "Reset op loper",
		bibTime: "Startnummer tijd",
		manageDetections: "Beheer detecties",
		totalPriceOfRun: "Totale prijs van de race (exclusief kosten)",
		invalidateSub: "Registratie ongeldig maken?",
		errorVerifyBibAlreadyUse:
			"Er is een fout opgetreden. Controleer of het startnummer niet al in gebruik is.",
		addDetection: "Voeg een detectie toe",
		setRunnerSurrended: "Markeer loper als overgegeven",
		setRunnerDisqualified: "Markeer loper als gediskwalificeerd",
		setRunnerStarting: "Markeer loper als startend",
		setRunnerNotStarting: "Markeer loper als niet-startend",
		updateRunnerStatusSurrended: "Status loper bijwerken naar overgegeven?",
		updateRunnerStatusDisqualified:
			"Status loper bijwerken naar gediskwalificeerd?",
		updateRunnerStatusStarting: "Status loper bijwerken naar startend?",
		updateRunnerStatusNotStarting: "Status loper bijwerken naar niet-startend?",
		notStarting: "Niet starten",
		patchNotes: "Update notities",
		patchAddNotes: "Update notities toevoegen",
		patchAddJetcodes: "Beschikbaarheid van Jetcodes voor een evenement",
		patchAddRoles: "Beheer van gebruikersrechten",
		patchCorrectDocuments: "Correctie van documentweergave",
		patchFixInvoiceErrorMessage: "Correctie van foutmelding op factuur",
		patchChangeDashboardLinks:
			"Wijzigde links voor evenementkaarten in het Dashboard",
		patchUpdateSendOneMail: "Bijgewerkte e-mail naar een deelnemer verzenden",
		patchMailHistory:
			"Correctie van de weergave van de geschiedenis van e-mails naar een deelnemer",
		patchResponsive:
			"Correctie van de responsiviteit voor betere bruikbaarheid op mobiel",
		patchHoursPrices: "Correctie van het bijwerken van de tijd bij prijsupdate",
		patchRedirectionToValidate:
			"Update van de redirectie bij klikken op X om te valideren",
		patchTraduction: "Vertalingen corrigeren",
		patchEventImages: "Fix evenementafbeeldingen",
		patchCreateEdition: "Nieuwe editie toevoegen",
		patchPictoDocs: "Picto voor documenten toevoegen",
		patchForgetPassword: "Wachtwoord vergeten toevoegen",
		patchAddPdfExport: "PDF-export toevoegen",
		patchAddFilterDocuments:
			"Filter toevoegen voor abonnementen met hangende documenten",
		onlyWithDocStart: "Toon alleen abonnementen met",
		onlyWithDocStrong: " hangende documenten",
		patchReasignBibsAndKeepThem:
			"Bijgewerkt bib-herassignatiegedrag met de mogelijkheid om al toegewezen bibs te behouden",
		patchAddRegistration: "Verbeteren van registratie toevoegen",
		patchGenderX: "Weergave van X geslacht",
		patchRegistrations: "Verbeteringen in het beheer van registraties",
		patchSwitchSubscriptionStatus:
			"Je kunt nu de status van een abonnement wijzigen",
		patchUpdateAdminStatistics:
			"Update van de administratie-interface voor gegevens tabellen",
		patchAddRibSelector:
			"Functionaliteit toegevoegd om RIB's snel toe te voegen van een lijst van reeds gebruikte RIB's door de gebruiker",
		patchEditOption:
			"Maakt het mogelijk om registratiemogelijkheden te configureren",
		patchAddResults:
			"Maakt het mogelijk om resultaten toe te voegen als PDF-bestanden",
		patchDocumentPreview: "Verbeter documentvoorbeeld",
		patchUX: "Verbeter gebruikerservaring",
		patchPDFExportRaces: "Voeg race selectie toe voor PDF-export",
		deleteGpxFileConfirmation:
			"Weet je zeker dat je dit bestand wilt verwijderen?",
		deletedFile: "Bestand verwijderd!",
		selectRunMail: "Selecteer een race",
		detectionPointName: "Naam detectiepunt",
		detectionLigne: "Lijn",
		verifyPrestation:
			"Controleer of je een live in uitvoering hebt en je DAG Live-configuratie",
		verifyCredential:
			"Er is een fout opgetreden. Controleer je DAG Live-inloggegevens.",
		noPrestationStarted:
			"Start de live voor de eerste keer om de ranglijst te tonen.",
		ranked: "Gerangschikt",
		missing: "Ontbrekend",
		ffa_error_generic: "Licentie niet gevalideerd",
		ffa_error_001: "Fout met het geboortedatumformaat",
		ffa_error_002: "Onvoldoende verstrekte informatie",
		ffa_error_003: "Nummer niet gevonden",
		ffa_error_004: "TP-nummer niet gevonden",
		ffa_error_005: "CF-nummer niet gevonden",
		ffa_error_006:
			"Het nummer is niet langer geldig op het moment van de competitie",
		ffa_error_007:
			"Het nummer komt niet overeen met je naam / voornaam / geslacht / geboortedatum",
		ffa_error_008:
			"Je informatie (naam / voornaam / geslacht / geboortedatum) is niet gevonden",
		ffa_error_009: "Homonieme fout, neem rechtstreeks contact op met de FFA",
		ffa_error_010:
			"FFA-service niet beschikbaar, neem contact op met de organisator (10)",
		ffa_error_011:
			"FFA-service niet beschikbaar, neem contact op met de organisator (11)",
		ffa_error_012:
			"FFA-service niet beschikbaar, neem contact op met de organisator (12)",
		ffa_error_013:
			"FFA-service niet beschikbaar, neem contact op met de organisator (13)",
		ffa_error_014:
			"FFA-service niet beschikbaar, neem contact op met de organisator (14)",
		ffa_error_015: "PPS-nummer niet gevonden",
		SUB: "SUB",
		ARR: "ARR",
		DNS: "DNS",
		DNF: "DNF",
		DQ: "DQ",
		sport_identity_error_name_taken:
			"De naam van het evenement is al in gebruik",
		add_licence: "Voeg een licentie toe",
		udc_licence_remaining: "Overgebleven UDC-licentie",
		total_key_credit: "Totaal aantal codering credits",
		donation_required: "Donatie vereist",
		donation_minimum_amount: "Minimumbedrag voor donatie",
		additional_information: "Aanvullende informatie",
		shop_licence: "Licenties",
		shop_material: "Hardware",
		shop_chip: "Chips",
		global_search: "Zoeken",
		global_search_desc: "Zoek naar mensen, klanten, bestellingen...",
		quickstart: "Snelle start",
		features: "Functies",
		shop: "Winkel",
		reset: "Reset",
		persons: "Personen",
		commands: "Bestellingen",
		search_results: "Zoekresultaten",
		account_type: "Accounttype",
		registration: "Registratie",
		ttc_price: "Prijs inclusief btw",
		command_date: "Bestel datum",
		payer: "Betaler",
		donation_list: "Donatielijst",
		no_run_with_donation:
			"Er is geen race geconfigureerd met een donatie voor een vereniging",
		donation_value: "Donatiewaarde",
		no_donation_for_association: "Geen donatie voor deze vereniging",
		donation_campain: "Donatiecampagne",
		campain_email: "E-MAIL VAN DE VERENIGING",
		campain_objective: "DOEL VAN DE CAMPAGNE",
		campain_minimum: "MINIMUMBEDRAG",
		campain_default: "STANDAARDBEDRAG",
		pps_number: "PPS-nummer",
		all_runs: "Alle runs",
		all_runners: "Alle deelnemers",
		all_valid_subsriptions: "Alle geldige abonnementen",
		all_invalid_subsriptions: "Alle ongeldige abonnementen",
		all_incomplete_subsriptions: "Alle onvolledige abonnementen",
		active_users: "Actieve gebruikers",
		tracking_url: "Tracking URL",
		document_title: "Documenttitel",
		ranking: "Ranglijst",
		subscription_history_by_run: "Abonnements geschiedenis per run",
		rights_add_subscription: "Registratie",
		rights_add_subscription_infos:
			"Staat toe om een on-site deelnemer toe te voegen",
		rights_import_subscriptions: "Importeer registraties",
		rights_import_subscriptions_infos: "In Excel-formaat",
		rights_export_subscriptions: "Exporteer registraties",
		rights_export_subscriptions_infos: "In Excel, CSV, PDF, of GmDAG-formaat",
		rights_validate_documents: "Documentvalidatie",
		rights_validate_documents_infos:
			"Validatie van medische certificaten en ouderlijke toestemming",
		rights_send_mails: "Communicatie met deelnemers (e-mails)",
		rights_subscription_live: "Registratierechten en livebeheer",
		rights_run_config: "Raceconfiguratie",
		rights_rib_config: "Bewerk bankgegevens (RIB)",
		rights_contact_config: "Bewerk contactgegevens",
		rights_runner_management: "Deelnemersbeheer",
		rights_runner_management_infos:
			"Toegang tot de lijst, passage tijden, enz.",
		rights_dossard_assign: "Bib toewijzen",
		rights_dossard_assign_infos:
			"Staat toe om het bib-nummer van een deelnemer te wijzigen",
		live_chrono: "Live/Tijdregistratie",
		passage_points: "Passagepunten",
		times: "Tijden",
		detections: "Detecties",
		general_ranking: "Rk Gen.",
		sex_ranking: "Rk Geslacht",
		cat_ranking: "Rk Cat.",
		minAgeRequired: (minAge: number) =>
			`De minimale vereiste leeftijd is ${minAge} jaar.`,
		maxAgeRequired: (maxAge: number) =>
			`De maximale vereiste leeftijd is ${maxAge} jaar.`,
		yearBetweenRequired: (minAge: number, maxAge: number) =>
			`Het geboortejaar moet tussen ${minAge} en ${maxAge} liggen.`,
		ageBetweenRequired: (minAge: number, maxAge: number) =>
			`Je moet minimaal ${minAge} jaar of maximaal ${maxAge} jaar oud zijn op het moment van registratie.`,
		activities: "Activiteiten",
		not_all_position_display:
			"Het aantal posities is aangepast, er worden slechts 500 posities weergegeven",
		success_time_saved: "De tijd is opgeslagen",
		error_time_saved: "Er is een fout opgetreden bij het opslaan van de tijd",
		display_gpx: "GPX-tracks zichtbaar maken ?",
		error_create_licence: "Fout bij het aanmaken van licentie",
		success_create_licence: "Licentie succesvol aangemaakt",
		error_create_encode_key: "Fout bij het aanmaken van coderingssleutel",
		success_create_encode_key: "Coderingssleutel succesvol aangemaakt",
		live_ranking: "Rangschikking beheer",
		add_ranking: "Rangschikking toevoegen",
		deselect: "Deselecteren",
		ranking_name: "Rangschikkingsnaam",
		available_filters: "Beschikbare filters",
		ranking_filters_explanation:
			"Deze filters definiëren de combinaties die beschikbaar zijn voor classificatie",
		toast_error_save_ranking_config: "Fout bij opslaan van ranglijsten",
		toast_success_save_ranking_config: "Positie opgeslagen",
		toast_empty_ranking_config: "Rankingnaam en filters mogen niet leeg zijn",
		sure_delete_ranking_list: (name: string) =>
			"Weet je zeker dat je deze ranking wilt verwijderen?",
		success_delete_ranking_list: "Ranking verwijderd",
		error_delete_ranking_list: "Fout bij verwijderen van ranking",
		custom_rankings: "gepersonaliseerde ranking",
		care_choice_unavaible:
			"Houd er rekening mee dat deze keuzes niet langer beschikbaar zijn voor het rangschikkingsfilter.",
		list_name_required: "De lijstnaam is verplicht en moet uniek zijn",
		ranking_title: "Ranglijst",
		ranking_all_choices: "Alle",
		ranking_all_races: "Alle races",
		ranking_export_experimental: "(experimenteel)",
		ranking_export_info1:
			"Exporteer de resultaten zoals ze op uw scherm verschijnen in Excel-formaat.",
		ranking_export_info2:
			"De kolommen zijn dezelfde als die op de pagina voor het beheer van lopers worden weergegeven.",
		ranking_export_pdf_newline: "Pagina-einde bij geslachtsverandering:",
		ranking_export_pdf_unranked: "Niet-geklasseerden weergeven:",
		ranking_export_pdf_all_races: "Alle races",
		price_dates_archived: "Prijzen en datums zijn gearchiveerd"
	},

	it: {
		delete_old_detections:
			"Vuoi mantenere le rilevazioni dall'ultima performance?",
		select_race: "Seleziona una gara",
		select_club: "Selezione club",
		select_team: "Selezione squadra",
		select_noClub: "Nessun club disponibile",
		select_noTeam: "Nessuna squadra disponibile",
		select_gender: "Scegli il sesso",
		select_bib: "Scegli un numero di pettorale",
		select_country: "Scegli un paese",
		select_countrySearch: "Cerca...",
		select_countryNoResult: "Nessun risultato",
		published: "Pubblicato",
		unpublished: "Non pubblicato",
		edit: "Modifica",
		registered: "registrato",
		toValidate: "da convalidare",
		ofTurnover: "del fatturato",
		publishEvent: "Pubblica Evento",
		unpublishEvent: "Annulla pubblicazione Evento",
		publishEventWarning:
			"Attenzione, stai per pubblicare questo evento, il che significa che sarà visibile a tutti gli utenti di Chronometrage.com. Sei sicuro di voler continuare?",
		unpublishEventWarning:
			"Attenzione, stai per annullare la pubblicazione di questo evento, il che significa che non sarà più visibile agli utenti di Chronometrage.com. Sei sicuro di voler continuare?",
		editEvent: "Modifica Evento",
		editRunner: "Modifica Corridore",
		handleDetection: "Gestisci rilevazioni",
		eventName: "Nome Evento",
		category: "Categoria",
		date: "Data",
		description: "Descrizione",
		poster: "Locandina",
		ffaFFTRI: "FFA / FFTRI",
		ffa: "FFA",
		fftri: "FFTRI",
		location: "Luogo",
		rulesPDF: "Regole PDF",
		website: "Sito web",
		file: "File",
		rib: "RIB",
		addRunner: "Aggiungi Corridore",
		addRegistration: "Aggiungi Iscrizione",
		sendEmailTitle: "Componi l'email per i partecipanti iscritti",
		sendOneEmailTitle: "Componi l'email per il partecipante",
		insight: "Anteprima email",
		config_title: "Gestione",
		runs_title: "Gare",
		update_presentation: "Presentazione",
		update_runs: "Gare",
		update_options: "Opzioni",
		update_contacts: "Contatti",
		update_sponsors: "Sponsor",
		update_ribs: "RIB",
		configure_steps: "Fasi",
		configure_live: "Monitoraggio in tempo reale",
		configure_results: "Invia risultati",
		sync_folomi_error: "Ci sono più iscrizioni che corridori.",
		sync_folomi_action: "Clicca per sincronizzare",
		sync_folomi_button: "Sincronizza corridori",
		sync_folomi_race_subscribed: "iscritti ma solo",
		sync_folomi_race_detected: "corridori rilevati",
		ask_validation: "Richiedi pubblicazione",
		unpublish: "Annulla pubblicazione evento",
		validation_asked: "Pubblicazione richiesta",
		preview: "Anteprima",
		preview_invoice: "Anteprima fattura",
		edit_invoice: "Modifica fattura",
		commissions: "Informazioni sulle commissioni",
		donations: "Donazioni",
		invoice_title: "Fatturazione",
		option: "Opzione",
		configure_options: "Configura opzioni",
		configure_lists: "Campi personalizzati",
		lists: "Configurazione campi",
		add_list: "Aggiungi un campo",
		edit_list: "Modifica un campo",
		delete_list: "Elimina una lista",
		sure_delete_list: "Sei sicuro di voler eliminare",
		add_list_choice: "Aggiungi scelte",
		empty_list_text: "Non puoi importare una lista vuota",
		list_choice_name_too_short: "Il nome della scelta è troppo corto",
		add_global_option: "Aggiungi un'opzione globale",
		lists_desc: "Questi campi saranno disponibili nel modulo di iscrizione",
		lists_text_desc:
			"Puoi incollare un testo separato da punti e virgola ( ; ) o interruzioni di linea",
		list_name_too_short: "Il nome del campo è troppo corto",
		list_available_choices: "Scelte disponibili",
		import_list: "Importa questa lista",
		or: "o",
		add_price: "Aggiungi un prezzo",
		add_option: "Aggiungi un'opzione",
		add_product: "Aggiungi un prodotto",
		min_quantity: "Quantità minima",
		max_quantity: "Quantità massima",
		designation: "Designazione",
		option_type: "TIPO DI CAMPO DESIDERATO",
		is_required: "VUOI RENDERE L'OPZIONE OBBLIGATORIA?",
		product: "Prodotto",
		price: "Prezzo",
		type_dropdown_description:
			"Lista di diversi prodotti (Esempio: Taglia t-shirt, S, M, L)",
		type_check_description: "Checkbox (Esempio: Bus, Pasti)",
		type_quantity_description:
			"Richiesta di quantità per un prodotto (Esempio: Numero pasti corridori)",
		delete_title: "Eliminazione",
		delete_message: "Sei sicuro di voler eliminare",
		price_start_date: "DATA E ORA DI INIZIO PREZZO",
		price_end_date: "DATA E ORA FINE PREZZO",
		price_start_date_donation: "DATA E ORA INIZIO DONAZIONE",
		price_end_date_donation: "DATA E ORA FINE DONAZIONE",
		warning_no_price:
			"Deve esistere almeno un prezzo (anche gratuito) per aggiungere un'opzione a questa gara.",
		warning_delete: "(L'operazione avrà effetto dopo il salvataggio)",
		error_qty_min_0: "Le quantità devono essere maggiori di 0",
		error_qty_min_max:
			"La quantità minima deve essere inferiore alla quantità massima",
		error_too_small: "(Troppo piccolo)",
		default: "Predefinito",
		prestation: "Avvia il servizio",
		loading: "Caricamento",
		importResults: "Importa risultati",
		set: "Imposta",
		notSet: "Non impostato",
		filesToBeChecked: "file da controllare",
		completedAndValidatedFiles: "file completati e validati",
		viaOptions: "via opzioni",
		confirm: "Conferma",
		cancel: "Annulla",
		interrupt: "Interrompi",
		previous: "Precedente",
		next: "Successivo",
		save: "Salva",
		search: "Cerca",
		download: "Scarica",
		allM: "Tutti",
		allF: "Tutte",
		delete: "Elimina",
		inTeam: " - In squadra",
		send: "Invia",
		create_event: "Crea un evento",
		required: "Obbligatorio",
		discard_changes: "Scartare le modifiche?",
		preview_off: "Chiudi anteprima",
		error_boundary_title: "Si è verificato un errore.",
		error_boundary_support:
			"Se il problema persiste, contatta il supporto e fornisci le seguenti informazioni:",
		back: "Indietro",
		sendMailAllSubscribers: "Invia email collettiva",
		sendMail: "Invia email",
		sendMailError: "Si è verificato un errore durante l'invio dell'email",
		resetExpired: "Reset scaduto. Richiedi un nuovo reset",
		eventSearch: "Cerca un evento",
		number_bib: "Numero pettorale",
		number_line: "Numero linea",
		time_detection: "Tempo di passaggio",
		number_line_short: "Linea",
		whatIsJetcode: "Cos'è un jetcode?",
		jetcodeExplanation:
			"È uno strumento che ti permette di integrare il contenuto di un'altra pagina (moduli di iscrizione & pagamento online, contatori di pettorali rimanenti, cronometrage.com logo) direttamente nel tuo sito web.",
		howIntegrateJetcodeToMyWebsite:
			"Come integrare un jetcode nel mio sito web?",
		jetcodeIntegrationExplanation:
			"Basta copiare i codici forniti dei jetcode desiderati (includi le mie iscrizioni e il logo di chronometrage.com) e incollarli in una pagina del tuo sito.",
		integrateSubscriptionsForm: "Integra il modulo di iscrizione",
		integrateSubscirbersList: "Integra la lista degli iscritti",
		integrateOnlineSubscriptionLogo: "Integra il logo 'iscrizione online'",
		engineCodeLabel:
			"Codice motore da copiare una sola volta nell'intestazione del tuo sito",
		basketCodeLabel:
			"Codice carrello da copiare una sola volta su ogni pagina del tuo sito",
		runCodeLabel: "Codice gara specifico",
		french: "Francese",
		english: "Inglese",
		copy: "Copia",
		copyDone: "Copiato!",
		insertionCodeLabel:
			"Codice di inserimento del carrello, questo codice deve essere inserito una volta per pagina",
		language: "Lingua",
		runSelectLabel: "Gara desiderata per il jetcode",
		priceJetcodeCodeLabel:
			"Configurazione prezzi jetcode da copiare nel tuo sito",
		theme: "Tema",
		setup: "Impostazioni",
		seeTimer: "Vedi timer",
		seeSeconds: "Vedi secondi",
		seeBibs: "Vedi pettorali",
		seeBibsLeft: "Vedi pettorali rimanenti",
		codeToCopyToYourWebsite: "Codice da copiare nel tuo sito",
		downloadProcessLabel:
			"Scarica la procedura in formato PDF da fornire al tuo webmaster",
		listSetup: "Impostazioni lista",
		selectARun: "Seleziona una gara",
		generalSettings: "Impostazioni generali",
		dagLiveCredentials: "Credenziali DAG Live",
		sensorName: "Etichetta",
		distance: "Distanza (m)",
		passageMin: "Passaggi minimi",
		passageMax: "Passaggi massimi",
		sensor: "Sensore",
		type: "Tipo",
		start: "Inizio",
		stop: "Ferma performance",
		deleteDetections: "Elimina definitivamente rilevazioni",
		deleteDetectionsSuccess: "Eliminazione completata",
		deleteDetectionsError: "Eliminazione fallita",
		prestationError: "ERRORE",
		sensorSettings: "Impostazioni sensore",
		addGpx: "Aggiungi GPX",
		chooseSensorType: "Scegli il tipo di sensore",
		startLine: "Linea di partenza",
		finishLine: "Linea di arrivo",
		checkpoint: "Checkpoint",
		addSensor: "Aggiungi un sensore",
		removeThisSensor: "Rimuovi questo sensore",
		handleRunners: "Gestisci corridori",
		handleDetections: "Gestisci rilevazioni",
		dashboardDetections: "Riepilogo",
		global_configuration: "Configurazione",
		route_configuration: "Percorsi",
		delete_step: "Elimina questa fase",
		edit_step: "Modifica questa fase",
		delete_parcours: "Elimina questo percorso",
		edit_parcours: "Modifica questo percorso",
		delete_segment: "Elimina questo segmento",
		edit_segment: "Modifica questo segmento",
		step: "Passo",
		parcours: "Percorso",
		segment: "Segmento",
		configure_routes: "Configura percorsi",
		configure_segments: "Configura segmenti",
		intermediary: "Intermedio",
		start_line: "Partenza",
		end_line: "Arrivo",
		typeLine1: "Partenza",
		typeLine2: "Intermedio",
		typeLine3: "Arrivo",
		grid: "Layout a griglia",
		categoryReassignement: "Riassegnazione categoria",
		handleResults: "Risultati",
		timezone: "Quale fuso orario vuoi mantenere?",
		no_run_selected: "Nessuna gara selezionata",
		placeholder_select_run: "Seleziona una gara",
		placeholder_select_segment: "Seleziona un segmento",
		log_title: "Accedi al tuo strumento di amministrazione",
		forgotten_title: "Password dimenticata?",
		reset_password: "Reimposta password",
		back_to_home: "Torna alla homepage",
		passwords_not_match: "Le password non corrispondono",
		passsword_reset_success: "La tua password è stata reimpostata",
		password_too_short: "La tua password deve contenere almeno 6 caratteri",
		send_forgotten_mail_success: "Ti è stata inviata un'email",
		email: "Indirizzo email",
		new_password: "Nuova password",
		new_password_confirm: "Conferma nuova password",
		password: "Password",
		confirmPassword: "Conferma la tua password",
		submit: "Accedi",
		social_reason: "Ragione sociale",
		adresse: "Indirizzo",
		postal_code: "CAP",
		city: "Città",
		country: "Paese",
		noAccount: "Non hai ancora un account?",
		rememberMe: "Ricordami",
		signup_button: "Crea un account",
		signup: "Non ho ancora un account",
		signup_title: "Iscriviti allo strumento di amministrazione",
		already_account: "Ho già un account",
		forgotten_password: "Password dimenticata?",
		lastname: "Cognome",
		firstname: "Nome",
		birthdate: "Data di nascita",
		sex: "Sesso",
		acceptCGV: "Accetto le condizioni d'uso della piattaforma",
		signup_error_firstname: "Il tuo nome deve contenere più di 1 lettera.",
		signup_error_lastname: "Il tuo cognome deve contenere più di 1 lettera.",
		signup_error_birthdate:
			"La tua data di nascita deve essere valida e devi avere tra i 13 e i 100 anni.",
		signup_error_email: "La tua email non è valida.",
		signup_error_password: "La tua password deve contenere almeno 6 caratteri.",
		signup_error_passwordConfirm: "Le tue password non sono identiche.",
		signup_error_CGU: "Devi accettare i T&C.",
		male: "Maschio",
		female: "Femmina",
		other: "Altro",
		legalEntity: "Entità legale",
		address: "Indirizzo",
		team: "Squadra",
		race: "Gara",
		bib: "Pettorale",
		bibAvailable: "Pettorale disponibile",
		bibUnavailable: "Pettorale già utilizzato",
		invalid: "Non valido",
		bibInvalid: "Pettorale non valido",
		runner: "Corridore",
		zipCode: "CAP",
		birthDate: "Data di nascita",
		genderH: "M",
		genderF: "F",
		genderX: "X",
		editGeneral: "Generale",
		editRace: "In gara",
		nationality: "Nazionalità",
		countryPlaceholder: "Seleziona un paese",
		firstPhone: "Telefono 1",
		secondPhone: "Telefono 2",
		marketer: "Marketer",
		organizer: "Organizzatore",
		yes: "Sì",
		no: "No",
		bbanDelete: "Elimina BBAN",
		bbanDeleteWarning:
			"Attenzione, stai per eliminare questo BBAN, questa azione è irreversibile. Sei sicuro di voler continuare?",
		addTeam: "Aggiungi una squadra",
		club: "Club",
		duplicateEmail: "Questo indirizzo email è già in uso",
		alreadyInUse: "Già in uso",
		editTimes: "Tempi",
		object: "Oggetto",
		mailBody: "Corpo della mail",
		swapBibs: "Scambia pettorali",
		swapFrom: "Da",
		swapTo: "A",
		nbDetections: "Rilevamenti",
		stageDepartureHour: "Orario di partenza ufficiale",
		handicap: "Handicap",
		miniTime: "Tempo minimo per il segmento",
		error404: "Errore 404",
		pageDoesNotExist: "Questa pagina non esiste",
		iThinkYouAreLost: "Penso che tu sia perso",
		goBackHome: "Torna alla homepage",
		dashboard: "Cruscotto",
		registrations: "Registrazioni",
		event: "Evento",
		liveResults: "Tempi",
		menuResults: "Risultati",
		liveConfiguration: "Configurazione live",
		statistics: "Statistiche",
		admin: "Amministrazione",
		jetcodes: "Jetcodes",
		toCome: "Da venire",
		today: "Oggi",
		past: "Passato",
		myAccount: "Il mio account",
		logout: "Esci",
		manageRunners: "Gestisci corridori",
		detections_dashboard: "Cruscotto rilevamenti",
		manage_dashboard: "Gestisci rilevamenti",
		registration_refund: "Rimborso iscrizione",
		registration_order: "Ordine iscrizione",
		cgu: "Termini di utilizzo",
		cgv: "Condizioni generali di vendita",
		manage_results: "Gestisci risultati",
		startOn: "Inizia il",
		registeredContestants: "Corridori registrati",
		displayRegistrations: "Visualizza registrazioni",
		completeSubscriptionsList: "Elenco completo delle iscrizioni",
		bibs: "Pettorali",
		bibsSearch: "Cerca un pettorale",
		firstnameSearch: "Cerca un nome",
		lastnameSearch: "Cerca un cognome",
		statusSearch: "Cerca uno stato",
		loadingRegisterForm: "Caricamento modulo di registrazione",
		thanksToWait: "Grazie per aver aspettato",
		noRace: "Nessuna gara configurata",
		teamOf: "SQUADRA di",
		invididual: "SOLO",
		importError: "Errore durante l'importazione",
		preimportWarning: "ATTENZIONE",
		importWarning: "ATTENZIONE",
		preimportError: "ERRORE: QUESTA ISCRIZIONE NON SARÀ IMPORTATA...",
		preimportErrors: "ERRORE: QUESTE ISCRIZIONI NON SARANNO IMPORTATE...",
		exportTitle: "Esporta iscrizioni in",
		raceChoice: "Gare",
		sexeChoice: "Sesso",
		statusChoice: "Stato",
		subscriptionType: "Tipo di iscrizione",
		importTitle: "Importa iscrizioni",
		toImport: "Per importare un elenco di iscrizioni, usa",
		configureFromAnotherRace: "Configura da un'altra gara",
		maximumAge: "L'età massima richiesta è",
		useThisModel: "questo modello di file EXCEL",
		dragHere: "Trascina il file EXCEL (.xlsx) qui...",
		dragHereOr:
			"Trascina il file EXCEL (.xlsx) qui, oppure clicca per selezionarlo",
		fileFormatNotSupported: "Formato file non supportato",
		existingSubs: "Iscritti precedentemente importati.",
		replace: "Rimuovi tutto e sostituisci",
		donotupdate: "Aggiungi solo i nuovi pettorali",
		continue: "Continua importazione",
		entries: "aggiunte il",
		import: "Importa",
		nbImportedRunners: "corridori importati",
		pre: "Pre-",
		importing: "Importando...",
		existing: "già esistente",
		adds: "aggiunte il",
		add: "aggiunte",
		close: "Chiudi",
		whattodo: "Cosa vuoi fare?",
		line: "Linea",
		invalidRace: "Gara non valida",
		bibUsed: "Pettorale già usato",
		missingRace: "Gara non fornita",
		missingBib: "Pettorale non fornito",
		yourEvents: "I tuoi eventi",
		yourRuns: "Le tue gare",
		bibsAllocation: "Assegnazione pettorali",
		discountVouchers: "Buoni sconto",
		form: "Modulo",
		refunds: "Rimborsi",
		accessRights: "Diritti di accesso",
		bankBBAN: "BBAN",
		manageBibsAllocation: "Gestisci assegnazione pettorali",
		manageEventDiscountVouchers: "Gestisci buoni sconto eventi",
		manageEventForm: "Gestisci modulo evento",
		manageRefunds: "Gestisci rimborsi",
		manageAccessRights: "Gestisci diritti di accesso",
		manageYourBBAN: "Gestisci il tuo BBAN",
		manageFFAFFTRILicenses: "Gestisci le tue licenze FFA/FFTRI",
		ribListLinkToThisEvent: "Elenco RIB collegati a questo evento",
		addBBAN: "Aggiungi un BBAN",
		name: "Nome",
		bank: "Banca",
		ibanBban: "IBAN / BBAN",
		thereAreCurrently: "Attualmente ci sono",
		registrantsForThe: "iscritti per la gara",
		doYouReallyWantToReimburseThe: "Vuoi veramente rimborsare gli",
		registrants: "iscritti",
		chargeAdditionalFeesPerRegistrations:
			"Costi aggiuntivi per iscrizione (in €)",
		invoicingOnRunnerOrOrganizer:
			"Fatturazione sul corridore o sull'organizzatore",
		ifRegistrationFeeIncluded:
			"Se la tassa di iscrizione è inclusa, coperta da",
		reimbursementCostsCoveredBy: "I costi di rimborso sono coperti da",
		makeTheRefund: "Effettuare il rimborso",
		reason: "Motivo",
		choose: "Scegli...",
		bibPlan: "Piano di numerazione pettorali",
		bibPlanAffectation: "Modo per assegnare i pettorali",
		minBib: "Pettorale minimo",
		maxBib: "Pettorale massimo",
		bibExclusion: "Pettorale escluso",
		bibExlusionPlaceholder:
			"Esempio: \rLista = 1;2;8;10 (i pettorali 1, 2, 8, e 10 non saranno considerati) \rIntervallo = 5:10 (i pettorali da 5 a 10 inclusi (5, 6, 7, 8, 9, e 10) non saranno considerati) \rCombinazione 1 = 2:4;7:8 (i pettorali 2, 3, 4, 7, e 8 non saranno considerati) \rCombinazione 2 = 2;4:6;8 (i pettorali 2, 4, 5, 6, e 8 non saranno considerati)",
		reaffectAllbibs:
			"Riassegnare tutti i pettorali per la gara validando la selezione",
		keepBibs: "Mantieni i pettorali già assegnati",
		applyToPending: "Applica alle iscrizioni in sospeso",
		orderAlphabetic:
			"Riassegnare i pettorali in ordine alfabetico dei corridori",
		list: "Per lista di pettorali",
		range: "Per intervallo di pettorali",
		filterByRun: "Filtra per gara",
		resolution: "Risoluzione",
		days: "Giorni",
		weeks: "Settimane",
		months: "Mesi",
		years: "Anni",
		runnerAge: "anni",
		seeComments: "Vedi commenti",
		downloadInPdf: "Scarica in PDF",
		nbOfSubscribers: "Numero di iscritti",
		maxInfMin:
			"Per favore, scegli un valore massimo maggiore del valore minimo",
		errorBib: "Si è verificato un errore durante l'aggiornamento dei pettorali",
		updatedBib: "I numeri dei pettorali sono stati aggiornati",
		loginSuccess: "Accesso riuscito",
		loginError: "Errore di accesso",
		logoutSuccess: "Disconnessione riuscita",
		logoutError: "Errore di disconnessione",
		updateError: "Si è verificato un problema durante l'aggiornamento",
		updateSuccess: "L'aggiornamento è stato completato con successo",
		updateCredentialSuccess: "Aggiornamento delle credenziali riuscito",
		updateCredentialError:
			"Si è verificato un problema durante l'aggiornamento delle credenziali",
		startPrestation: "Il servizio è stato avviato",
		liveTimezone:
			"C'è una discrepanza nella configurazione del fuso orario del tuo evento.",
		stopPrestation: "Il servizio è stato fermato",
		prestationStartError: "Errore durante l'avvio del servizio",
		prestationStartErrorCredentiel:
			"Per favore configura le tue credenziali prima di avviare il servizio",
		prestationStopError: "Errore durante l'arresto del servizio",
		updateCheckpointSuccess: "La registrazione del rilevatore è riuscita",
		updateCheckpointError: "Errore durante la registrazione del rilevatore",
		deletionSuccess: "Cancellazione riuscita",
		deletionError: "Si è verificato un errore durante la cancellazione",
		insertSuccess: "Creazione riuscita",
		insertError: "Errore durante la creazione",
		insertErrorDetection:
			"La rilevazione non è stata trasmessa. Verifica che ci sia una gara in corso.",
		ppsError:
			"Licenza o PPS non valido (non convalidato dalla Federazione Francese)",
		requiredRace: "Per favore scegli una gara",
		requiredBib: "Per favore scegli un numero di pettorale",
		requiredBirthdate: "Per favore fornisci la data di nascita",
		requiredGender: "Per favore fornisci il sesso",
		requiredTeamName: "Per favore fornisci il nome della squadra",
		requiredTeam:
			"Per favore scegli una squadra dalla lista o creane una nuova",
		requiredOneOrAllRunner: "Per favore fornisci uno o tutti i corridori",
		emailSendedSuccess: "Email inviate con successo.",
		emailSendedError: "Si è verificato un errore durante l'invio delle email.",
		login_expired: "La tua sessione è scaduta",
		newRaceUpdateSuccess: "La modifica della gara è riuscita",
		newRaceUpdateError:
			"Si è verificato un errore durante la modifica della gara",
		bibChangeRaceChoice: "Per favore fai una scelta riguardo i pettorali",
		refundSuccess: "Rimborso riuscito",
		refundError: "Si è verificato un errore durante il rimborso",
		sport_identity_success: "Iscrizione all'evento riuscita",
		sport_runs_success: "Gare configurate con successo",
		sport_options_success: "Opzioni configurate con successo",
		sport_identity_error:
			"Si è verificato un errore durante la creazione dell'evento",
		sport_runs_error:
			"Si è verificato un errore durante la configurazione delle gare",
		sport_options_error:
			"Impossibile salvare le opzioni. Verifica di non star modificando una tariffa per un'opzione già sottoscritta",
		file_error: "Si è verificato un errore durante la lettura del file",
		file_exist: "Questo file è già stato caricato",
		event_form_required: "Non hai compilato tutti i campi obbligatori",
		event_invalid_dates:
			"Attenzione: la data di fine è la stessa della data di inizio",
		error_creation_segment:
			"Si è verificato un errore durante il salvataggio della tappa",
		success_creation_segment: "Tappa salvata con successo",
		success_deletion_segment: "Tappa eliminata",
		error_deletion_segment:
			"Si è verificato un errore durante l'eliminazione della tappa",
		error_creation_parcours:
			"Si è verificato un errore durante la registrazione del percorso",
		success_creation_parcours: "Percorso registrato con successo",
		success_deletion_parcours: "Percorso eliminato",
		error_deletion_parcours:
			"Si è verificato un errore durante l'eliminazione del percorso",
		error_creation_etape:
			"Si è verificato un errore durante la registrazione dell'etapa",
		success_creation_etape: "Etapa registrata con successo",
		success_deletion_etape: "Etapa eliminata",
		error_deletion_etape:
			"Si è verificato un errore durante l'eliminazione dell'etapa",
		success_creating_price: "Prezzo registrato con successo",
		error_creating_price:
			"Si è verificato un errore durante la creazione del prezzo",
		success_deleting_run: "Gara eliminata con successo",
		error_deleting_run_subs: "Non puoi eliminare una gara con iscrizioni",
		error_deleting_run:
			"Si è verificato un errore durante l'eliminazione della gara",
		success_deleting_price: "Prezzo eliminato con successo",
		error_deleting_price:
			"Si è verificato un errore durante l'eliminazione del prezzo",
		success_updating_fields: "Modulo di iscrizione aggiornato",
		error_updating_fields:
			"Si è verificato un errore durante l'aggiornamento del modulo",
		event_does_not_exist: "L'evento che desideri configurare non esiste",
		success_updating_contact: "Contatto registrato",
		error_updating_contact:
			"Si è verificato un errore durante la registrazione del contatto",
		success_updating_rib: "Conto bancario (RIB) registrato",
		error_updating_rib:
			"Si è verificato un errore durante la registrazione del conto bancario (RIB). Il rappresentante legale potrebbe già avere un conto.",
		success_deleting_contact: "Contatto eliminato",
		success_deleting_rib: "Conto bancario (RIB) eliminato",
		error_deleting_contact:
			"Si è verificato un errore durante l'eliminazione del contatto",
		error_deleting_rib:
			"Si è verificato un errore durante l'eliminazione del conto bancario (RIB)",
		error_signup:
			"Si è verificato un errore. Questa email potrebbe essere già in uso.",
		success_signup: "Il tuo account è stato creato con successo",
		success_change_orga:
			"Sei ora un organizzatore, effettua nuovamente il login",
		error_change_orga: "Si è verificato un errore.",
		error_contact_empty:
			"Per favore fornisci il nome dell'azienda, il nome e cognome del contatto.",
		success_ask_validation: "Una email è stata inviata all'amministratore",
		error_ask_validation:
			"Si è verificato un errore durante la richiesta di convalida",
		import_error: "Si è verificato un errore durante l'importazione",
		success_publish_event: "Evento pubblicato",
		success_unpublish_event: "Evento non pubblicato",
		error_unpublish_event: "Si è verificato un errore",
		cant_delete_stage:
			"Per favore elimina le rilevazioni associate a questa tappa prima di eliminarla",
		reassignCategorySuccess: "Categorie riassegnate con successo",
		reassignCategoryError:
			"Si è verificato un errore durante la riassegnazione delle categorie",
		orgaSuccess: "Sei ora un organizzatore",
		orgaError: "Si è verificato un errore",
		toast_raceChoice: "Per favore seleziona almeno una gara dalla lista",
		genderChoice: "Per favore seleziona almeno un genere dalla lista",
		toast_statusChoice:
			"Per favore seleziona almeno uno stato di iscrizione dalla lista",
		typeChoice: "Per favore seleziona almeno un tipo di iscrizione dalla lista",
		changeTimezoneError:
			"Si è verificato un errore durante il cambiamento del fuso orario",
		delete_detections_choice: "Per favore, fai una scelta",
		no_delete: "Nessuna rilevazione è stata eliminata",
		no_detections: "Nessuna rilevazione",
		no_times: "Nessun tempo",
		export_error: "Si è verificato un errore durante l'esportazione",
		accept_document_success: "Il documento è stato accettato",
		accept_document_error: "Si è verificato un errore",
		refuse_document_success:
			"Il documento è stato rifiutato e una mail è stata inviata al partecipante",
		refuse_document_error: "Si è verificato un errore",
		category_or_team: "Categoria / Team",
		run: "Gara",
		status: "Stato",
		options: "Opzioni",
		runners: "Partecipanti",
		starter: "Partente",
		surrendered: "Surrendered",
		disqualified: "Squalificato",
		filter: "Filtro",
		gender: "Genere",
		man: "Maschio",
		woman: "Femmina",
		speed: "Velocità",
		gap: "Differenza",
		passageTime: "TEMPO DI PASSAGGIO",
		minimmAge: "L'età minima richiesta è",
		other_gender: "Altro",
		your_runners: "I tuoi partecipanti",
		refused_reason: "Motivo del rifiuto",
		accept_document: "Accetta",
		refuse_document: "Rifiuta",
		refuse_document_and_notice: "Rifiuta e invia mail",
		your_subscriptions: "Le tue iscrizioni",
		contact: "Contatto",
		edit_subscription: "Modifica iscrizione",
		add_subscription: "Aggiungi iscrizione",
		message: "Invia un messaggio",
		document: "Gestisci documenti",
		actions: "Azione",
		waiting: "In attesa",
		valid: "Valido",
		reimbursed: "Rimborsato",
		documents_sent: "Documento(i) inviato(i)",
		close_documents: "Chiudi",
		manage_documents: "Gestione documenti",
		personnal_informations: "Informazioni personali:",
		confirmDeleteSub: "Sei sicuro di voler eliminare l'iscrizione da",
		age: "Età",
		phone: "Telefono",
		emergency_phone: "Telefono di emergenza",
		mail: "Email",
		run_informations: "Informazioni sulla gara",
		bib_info: "Numero di pettorale",
		licence: "Licenza",
		validated: "validato",
		by: "da",
		la: "il",
		validated_by_fede: "Validato da ",
		certificat: "Certificato medico",
		autorisation: "Autorizzazione parentale",
		validation_date: "Data di validazione:",
		sub_team: "Team / Associazione / Azienda",
		sub_options: "Opzioni dell'iscritto:",
		reimburse: "Rimborso",
		validate_subscription: "Valida iscrizione",
		refuse_subscription: "Rifiuta iscrizione",
		refuse_reason: "Qual è il motivo del rifiuto?",
		see_message_history: "Vedi la cronologia dei messaggi",
		messages_history: "Cronologia messaggi",
		close_history: "Chiudi la cronologia",
		close_comments: "Chiudi i commenti",
		"inscription.refus.title": "Iscrizione rifiutata",
		no_messages: "Nessun messaggio inviato a questo iscritto",
		changeRace: "Cambia gara",
		changeRaceModalTitle: "Modifica iscrizione per:",
		currentlyRegistredFor: "Attualmente registrato per la gara",
		newRace: "Nuova gara",
		cannotChangeRace: "Impossibile cambiare gara",
		teammatesWith: "Con",
		bibMessageChangeRun: "1 - Vuoi mantenere i numeri di pettorale?",
		automaticBibChangeRun:
			"2 - Vuoi assegnare automaticamente nuovi numeri di pettorale in base al piano di numerazione della nuova gara?",
		bibAlreadyExistInNewRace:
			"ha già il numero di pettorale nella gara selezionata. Un nuovo numero di pettorale verrà assegnato automaticamente al partecipante se è stato definito un piano di numerazione per la gara scelta.",
		noBibInNewRace:
			"ATTENZIONE: Il numero di pettorale attuale verrà rilasciato. Sarà necessaria l'assegnazione manuale del numero di pettorale.",
		noBibAssignmentInNewRace:
			"ATTENZIONE: Il numero di pettorale attuale verrà rilasciato poiché la nuova gara non ha un piano di numerazione.",
		see_order: "Visualizza ordine",
		no_document: "Nessun documento",
		detectors: "Rivelatori",
		verification: "Verifica OK",
		percentage: "Percentuale",
		finishers: "Finisher",
		number_runners: "Partecipanti",
		non_starters: "Non partenti",
		start_the: "Inizia il:",
		abandon: "Abbandoni",
		official_starters: "Partenti ufficiali",
		manage_detections_title: "Rilevazioni",
		hours: "Orario di passaggio",
		restore_detection: "Ripristina",
		delete_detections: "Elimina rilevazioni",
		choose_detectors: "Scegli una linea",
		add_detection: "Aggiungi rilevazione",
		edit_detection: "Modifica questa rilevazione",
		runFormDeleteDetectionConfirmation: "Vuoi eliminare questa rilevazione?",
		date_format: "Formato data",
		chrono_format: "Formato cronometro",
		effective_start: "Inizio effettivo",
		races: "Tutte le gare",
		deleteAllDetections: "Vuoi eliminare tutte le rilevazioni?",
		confirmStartSaveCredential:
			"Vuoi salvare la tua credenziale prima di avviare la diretta?",
		deleteTitle: "Eliminazione rilevazioni della gara",
		all: "Elimina tutte le rilevazioni dell'evento",
		upload_dag: "Importa file DAG",
		insertion: "Inserimento",
		estimation: "Stima",
		current_time: "Durata",
		seconds: "secondi",
		detection_detail: "Dettagli rilevazione",
		already_exist: "Esiste già",
		inserted: "Inserito",
		runner_created: "Partecipante creato",
		checkpoint_missing: "Punto di controllo mancante",
		interpassage_ignored: "Ignorato a causa del tempo di interpassaggio",
		search_runner: "Cerca un partecipante",
		search_event: "Cerca un evento",
		search_bib: "Cerca un numero di pettorale",
		search_subscription: "Cerca un'iscrizione",
		autorisationParentale: "Autorizzazione parentale",
		licence_already_sent: "Licenza già inviata",
		certificat_already_sent: "Certificato già inviato",
		autorisation_already_sent: "Autorizzazione già inviata",
		send_documents: "Invia nuovi documenti",
		see_document: "Visualizza documento",
		download_document: "Scarica",
		product_name: "Nome del prodotto",
		refund_price: "Prezzo (TTC)",
		refund_amount: "Importo del rimborso",
		subscription_fees: "Tasse di iscrizione",
		refund_reason: "Motivo del rimborso (Obbligatorio)",
		refund: "Rimborso",
		deleteSub: "Elimina iscrizione",
		orga_sub_fees: "Tasse di iscrizione a tuo carico?",
		orga_refund_fees: "Tasse di rimborso (1€ TTC) a tuo carico?",
		contact_doubt: "Contatta il supporto se hai dubbi sulle tue azioni",
		refund_summary: "Riepilogo del rimborso",
		refund_close_summary: "Chiudi riepilogo",
		refund_summary_start_text: "Stai per rimborsare ",
		refund_summary_end_text: " a questo partecipante, desideri confermare?",
		confirm_refund: "Conferma rimborso",
		refund_paid: "Il partecipante ha pagato",
		amount_to_refund: "Stai per rimborsare",
		refund_fees: "Tasse di rimborso",
		refunded_amount: "Importo totale del rimborso",
		already_refunded: (amount: string, date: string) =>
			`Non puoi più rimborsare questa iscrizione perché un rimborso di ${amount} € è già stato effettuato il ${date}.`,
		refund_product: "Rimborso",
		choose_type: "Voglio organizzare un evento",
		select: "Seleziona",
		type_details: "Dettagli",
		sport_event_identity: "Presentazione dell'evento",
		sport_event_runs: "Configurazione delle gare",
		sport_event_options: "Configurazione delle opzioni",
		go_back: "Indietro",
		save_and_go_run: "Salva e configura le gare",
		save_and_go_options: "Configura le opzioni",
		save_and_finish: "Termina configurazione",
		event_name_label: "Nome evento",
		event_sport_label: "Sport",
		event_sport_option: "Seleziona uno sport",
		event_start_date_label: "Data inizio",
		event_end_date_label: "Data fine",
		event_poster_label: "Immagine",
		event_banner_label: "Banner",
		event_short_description_label: "Descrizione breve",
		event_long_description_label: "Descrizione lunga",
		event_address_label: "Indirizzo",
		event_postal_code_label: "CAP",
		event_city_label: "Città",
		event_country_label: "Paese",
		event_name_error:
			"Il nome dell'evento deve essere più lungo di 3 caratteri",
		event_sport_error: "Devi scegliere uno sport",
		event_start_date_error:
			"La data non può essere anteriore alla data odierna (o deve corrispondere alla data inserita nel SIFFA se codice FFA)",
		event_ffa_siffa_error:
			"Mancata autorizzazione FFA. Hai collegato il tuo evento al sistema DAG su CALORG?",
		event_end_date_error: "Devi scegliere una data successiva a quella odierna",
		event_short_description_error:
			"La descrizione breve deve essere tra 3 e 190 caratteri",
		event_address_error:
			"Il tuo indirizzo deve essere più lungo di 3 caratteri",
		event_postal_code_error: "Il tuo CAP deve essere più lungo di 3 caratteri",
		event_city_error: "La tua città deve essere lunga più di 3 caratteri",
		event_country_error: "Devi scegliere un paese",
		event_country_option: "Seleziona un paese",
		event_timezone_label: "Fuso orario",
		event_places_label: "Posti disponibili",
		event_timezone_option: "Seleziona un fuso orario",
		event_rules_label: "Regole dell'evento",
		event_run_name_label: "Nome gara",
		event_run_date_label: "Data della gara",
		event_run_member_label: "Se in squadra, numero di compagni di squadra",
		event_run_transfer_label: "Consente il trasferimento delle iscrizioni?",
		event_run_details_label: "Informazioni aggiuntive",
		event_run_details: "Se vuoi fornire ulteriori informazioni sulla gara",
		event_run_number_members_label: "Numero di compagni di squadra",
		event_run_places_label: "Posti disponibili",
		event_run_start_date_label: "Data di inizio",
		event_run_end_date_label: "Data di fine",
		event_run_same_bib_label: "Stesso pettorale per la squadra",
		event_run_fftri_code: "Codice competizione FFTRI",
		event_run_fftri_distance: "Tipo di distanza",
		event_run_licence_accepted: "Licenze accettate",
		event_run_licence_accepted_tooltip:
			"Tipo di licenza accettata per questa gara",
		event_run_licence_accepted_all: "Tutte le licenze",
		event_run_licence_accepted_comp: "Licenze competitive",
		event_run_same_bib_no: "No",
		event_run_same_bib_yes: "Sì",
		event_run_make_it_visible: "Rendi visibile la lista dei partecipanti ?",
		event_run_make_it_visible_info:
			"Se attivato, la lista dei partecipanti sarà visibile a tutti.",
		event_add_run: "Aggiungi gara",
		event_delete_run: "Elimina gara",
		event_undo_delete_run: "Non eliminare gara",
		event_prices: "Prezzi",
		event_run: "Gara",
		event_run_designation_label: "Designazione",
		edit_run_title: "Modifica gara",
		edit_tarif_title: "Modifica prezzo",
		edit_contact_title: "Modifica contatto",
		edit_rib_title: "Modifica conto bancario",
		deleteConfigPointError_1451:
			"Per favore elimina prima tutti i segmenti che contengono questo punto di rilevamento.",
		event_run_age_control_label: "Tipo di limite di età",
		event_run_age_control_option: "Nessun limite di età",
		event_run_age_min_label: "Età minima",
		event_run_age_max_label: "Età massima",
		event_run_add_price: "Aggiungi prezzo",
		event_add_contact: "Aggiungi contatto",
		event_add_rib: "Aggiungi conto bancario",
		validate: "Convalida",
		fees_inclued: "inclusivo",
		fees_exclued: "esclusivo",
		event_fees_inclued: "Tariffe inclusive",
		event_fees_exclued: "Tariffe esclusive",
		event_starting_date: "Inizio:",
		event_ending_date: "Fine:",
		event_age_disponibility: (min: number, max: number) =>
			`Disponibile dai ${min} anni ai ${max} anni`,
		event_price_fee_inclued: (fees: number) =>
			`incluso ${fees.toFixed(2)}€ di tasse`,
		event_price_fee_exclued: (fees: number) => `+ ${fees.toFixed(2)}€ di tasse`,
		event_run_price: "Prezzo",
		event_run_fees_type_label: "Tipo di tassa",
		event_run_type_control_event: "Secondo la data dell'evento",
		event_run_type_control_subscription: "Secondo la data di iscrizione",
		event_run_type_control_year_event: "Secondo l'anno dell'evento",
		event_run_free_run: "Gara gratuita?",
		event_no_free_run: "No",
		event_free_run: "Sì",
		event_free_run_text: "Gara gratuita",
		event_ffa_siffa_label: "Codice competizione FFA",
		event_configure_sub_form: "Configura il modulo",
		event_edit_run: "Modifica la gara",
		event_run_form_title: "Configurazione del modulo di iscrizione",
		event_tooltip_form: "Un modulo è configurato per questa tariffa",
		event_field_nom_label: "Cognome",
		event_field_prenom_label: "Nome",
		event_field_sexe_label: "Sesso",
		event_field_dateNaissance_label: "Data di nascita",
		event_field_mail_label: "Email",
		event_field_ville_label: "Città",
		event_field_codePostal_label: "Codice postale",
		event_field_pays_label: "Paese",
		event_field_nationalite_label: "Nazionalità",
		event_field_telephone_label: "Telefono",
		event_field_telephoneUrgence_label: "Telefono di emergenza",
		event_field_numLicence_label: "Numero di licenza",
		event_field_licence_label: "Licenza",
		event_field_certificat_label: "Certificato medico",
		event_field_pps_label: "Numero PPS (FFA)",
		event_field_adresse1_label: "Indirizzo",
		event_field_adresse2_label: "Linea di indirizzo 2",
		event_field_club_label: "Club",
		event_field_team_label: "Squadra",
		event_field_ffa_label: "FFA CNIL",
		event_field_autorisationParentale_label: "Autorizzazione parentale",
		event_field_tiers_label: "Terza parte",
		event_field_dechargeResponsabilite_label: "Esenzione di responsabilità",
		group_fields_nominative: "Campi nominativi",
		group_fields_FFA: "Campi FFA",
		group_fields_others: "Altri campi",
		infos_ffa_numlicence_optional_message:
			"Il corridore può inserire il suo numero di licenza FFA",
		infos_ffa_numlicence_required_message:
			"Il corridore deve inserire il suo numero di licenza FFA",
		infos_ffa_ppsandlicence_optional_message:
			"Il corridore può inserire il suo numero PPS o di licenza FFA",
		infos_ffa_ppsandlicence_required_message:
			"Il corridore deve inserire il suo numero PPS o di licenza FFA",
		enter_text: "Inserisci la tua descrizione",
		enter_mail_content: "Scrivi il contenuto della tua email...",
		enter_mail_object: "Scrivi l'oggetto della tua email...",
		sport_event_contacts: "Gestione contatti",
		sport_event_ribs: "Gestione conti bancari",
		event_raisonSocial_label: "Nome legale",
		event_nom_label: "Cognome",
		event_prenom_label: "Nome",
		event_adresse1_label: "Indirizzo",
		event_adresse2_label: "Linea indirizzo 2",
		event_ville_label: "Città",
		event_codePostal_label: "Codice postale",
		event_pays_label: "Paese",
		country_home: "Paese",
		event_telephone1_label: "Telefono",
		event_telephone2_label: "Telefono secondario",
		event_mail_label: "Email",
		event_siteWeb_label: "Sito web",
		event_facebook_label: "Facebook",
		event_twitter_label: "Twitter",
		event_instagram_label: "Instagram",
		delete_tarif_title: "Vuoi davvero eliminare la tariffa",
		delete_run_title: "Vuoi davvero eliminare la gara",
		restore_deletes: "Ripristina gli oggetti eliminati",
		restore: "Annulla modifiche",
		delete_contact_title: "Vuoi davvero eliminare il contatto",
		create_step: "Aggiungi una tappa",
		create_route: "Aggiungi un percorso",
		step_name_label: "Nome tappa",
		event_distance_label: "Distanza (in metri)",
		sport_event_steps: "Configurazione delle tappe",
		sport_event_parcours: "Configurazione del percorso",
		sport_event_segments: "Configurazione delle fasi",
		parcours_name_label: "Nome del percorso",
		segment_name_label: "Nome della fase",
		parcours_file_label: "File GPX",
		create_parcours: "Aggiungi un percorso",
		update_parcours: "Modifica un percorso",
		create_segment: "Aggiungi una fase",
		update_segment: "Modifica una fase",
		delete_step_title: "Vuoi davvero eliminare la tappa",
		delete_segment_title: "Vuoi davvero eliminare la fase",
		delete_parcours_title: "Vuoi davvero eliminare il percorso",
		checkpoint_debut_label: "Checkpoint di inizio",
		checkpoint_fin_label: "Checkpoint di fine",
		parcours_active_label: "Percorso attivo",
		active: "attivo",
		event_fees: "Tariffe",
		dag_fees: "Tassa DAG (IVA inclusa)",
		subscription_income: "Il tuo reddito",
		subscription_fees_sup: "La tua quota IVA inclusa",
		subscription_fees_total: "Tariffe di registrazione totali (IVA inclusa)",
		event_sport_price: "Prezzo della gara",
		event_fftri_default_distance: "Scegli una distanza",
		designation_label: "Designazione",
		unlimited: "Illimitato",
		nomBanque_label: "Nome della banca",
		iban_label: "IBAN",
		bic_label: "BIC",
		inCharge_label: "Sono il rappresentante legale",
		lastname_label: "Cognome",
		firstname_label: "Nome",
		birthdate_label: "Data di nascita",
		email_label: "Email",
		address_label: "Indirizzo",
		city_label: "Città",
		postalCode_label: "Codice postale",
		country_label: "Paese",
		phone_label: "Telefono",
		sex_label: "Genere",
		genderHfull: "Maschile",
		genderFfull: "Femminile",
		genderXfull: "Altro",
		select_type_rib: "Seleziona un ruolo",
		see_contract: "Vedi contratto",
		add_general_option: "Aggiungi un'opzione globale",
		delete_stage_cannot_start_live:
			"ATTENZIONE: Se elimini questa fase senza crearne un'altra, non sarai in grado di andare in diretta.",
		event_configuration: "Voglio organizzare un evento",
		event_identity: "Identità dell'evento",
		event_runs: "Gare",
		event_options: "Opzioni",
		event_subscription: "Configurazione della registrazione",
		event_contacts: "Contatti",
		event_rib: "Conti bancari",
		subscriptions: "Iscrizioni",
		live_results: "Diretta / Risultati",
		live_positions: "Posizioni dei corridori",
		manage_detections: "Gestione delle rilevazioni",
		detection_dashboard: "Riepilogo delle rilevazioni",
		manage_runners: "Gestione dei corridori",
		manage_live: "Gestione della diretta",
		manage_steps: "Configurazione dei passi",
		manage_parcours: "Configurazione del percorso",
		manage_segments: "Configurazione delle fasi",
		bib_plan_exemple_1:
			"Lista = 1;2;8;10 (i numeri di pettorale 1, 2, 8 e 10 non saranno considerati)",
		bib_plan_exemple_2:
			"Intervallo = 5:10 (i numeri di pettorale da 5 a 10 inclusi (5, 6, 7, 8, 9, e 10) non saranno considerati)",
		bib_plan_exemple_3:
			"Combinazione 1 = 2:4;7:8 (i numeri di pettorale 2, 3, 4, 7, e 8 non saranno considerati)",
		bib_plan_exemple_4:
			"Combinazione 2 = 2;4:6;8 (i numeri di pettorale 2, 4, 5, 6, e 8 non saranno considerati)",
		event_name: "Usato per identificare l'attività durante la registrazione.",
		event_places:
			"Limita le iscrizioni a un numero definito di posti (lascia vuoto per posti illimitati).",
		event_start_date: "Definisce la data di inizio dell'attività.",
		event_end_date: "Definisce la data di fine dell'attività.",
		event_team_number:
			"Definisce il numero di registranti che compongono una squadra (lascia vuoto se l'attività non è basata su squadre).",
		event_transfer:
			"Permette ai registranti di trasferire la loro iscrizione a un altro utente.",
		event_price_name:
			"Descrive il prezzo della gara (es. Early Bird, Bambino, Adulto).",
		event_age: "Definisce un limite di età per la registrazione.",
		event_min_age: "Imposta l'età minima richiesta per la registrazione.",
		event_max_age: "Imposta l'età massima richiesta per la registrazione.",
		event_price_start_date:
			"Definisce la data di inizio quando la tariffa sarà disponibile. Le tariffe non saranno visibili prima di questa data.",
		event_price_end_date:
			"Definisce la data di fine quando la tariffa non sarà più disponibile. Le tariffe non saranno visibili dopo questa data.",
		event_price_free:
			"Indica se la tariffa è gratuita. I partecipanti non pagheranno le tariffe, ma saranno addebitate a te.",
		event_price_price:
			"Definisce il prezzo della gara (possono essere applicate tariffe aggiuntive).",
		event_price_fees_type:
			"Indica se le tariffe sono esclusive (non incluse nel prezzo della gara) o inclusive (incluse nel prezzo della gara).",
		event_price_fees:
			"Tariffe addebitate dalla piattaforma. Queste tariffe sono almeno uguali alle tariffe aggiuntive applicate dal marketer.",
		event_price_fees_sup: "Tariffe aggiuntive applicate dal marketer.",
		event_price_fees_calc:
			"Tariffe della piattaforma + Tariffe aggiuntive del marketer.",
		event_timezone:
			"Scegli con attenzione per scopi di classifica in tempo reale.",
		event_defined_tz: "Fuso orario definito per l'evento",
		default_race:
			"Classifica un pettorale sconosciuto rilevato durante la diretta in una gara predefinita se non è stato definito un piano di numerazione",
		finances: "Flussi finanziari",
		finances_desc: "Visualizza i flussi finanziari di un evento",
		finances_resume: "Gestione dei trasferimenti",
		finances_resume_desc:
			"Visualizza lo stato dei trasferimenti per gli eventi della piattaforma",
		placeholder_select: "Seleziona un evento",
		placeholder_search: "Cerca un evento",
		search_client: "Cerca un cliente",
		select_no_options: "Nessun evento",
		report: "Rapporto",
		time: "Tempo",
		section: "Fase",
		filters: "Filtri",
		placeholder_select_gender: "Seleziona un genere",
		placeholder_select_category: "Seleziona una categoria",
		H: "Maschile",
		F: "Femminile",
		A: "Altro",
		export: "Esporta",
		export_format: "Formato di esportazione",
		placeholder_select_format: "Seleziona un formato",
		optional: "(opzionale)",
		warning_passage: (line: number, min: number, done: number) =>
			`Linea ${line}: passaggio ${min} min, ${done} passaggi completati`,
		inviteNewUser: "Invita un nuovo utente",
		error403: "Errore 403",
		contactOrga: "Contatta l'organizzatore se hai bisogno di questo accesso",
		event_form_donation_required: "La scelta della donazione è obbligatoria",
		restrictedPage: "Pagina riservata",
		permissions: "Permessi",
		roles: "Permessi",
		search_permissions: "Cerca un nome",
		invoice: "Fattura",
		dag_share_ht: "Quote DAG al netto delle tasse",
		orga_share_ht: "Quote Orga al netto delle tasse",
		total_ht: "Totale al netto delle tasse",
		total_ttc: "Totale IVA inclusa",
		com_ht: "Commissione al netto delle tasse",
		bill_com: "Stato della fattura",
		bill_orga: "Fattura Orga",
		title: "Titolo",
		quantity: "Quantità",
		unit_prix_ht: "P.U al netto delle tasse",
		unit_prix_ttc: "P.U IVA inclusa",
		nbSubs: "Iscritti",
		StartPeriode: "Inizio periodo",
		EndPeriode: "Fine periodo",
		paymentDone: "Pagamento effettuato",
		validatePayment: "Convalida pagamento",
		toastPaymentSuccess: "Trasferimento convalidato",
		toastPaymentError:
			"Si è verificato un errore durante la convalida del trasferimento",
		downloadContractAskValidation: "Scarica e chiedi la convalida",
		contract_explanation:
			"Per finalizzare la pubblicazione del tuo evento, è essenziale stabilire un contratto. Dopo aver confermato la tua richiesta di pubblicazione, ti invieremo questo contratto via email, che dovrai poi completare e restituire al nostro team: ",
		licences: "Licenze",
		licences_desc: "Gestisci le licenze dei clienti DAG",
		clients: "Clienti",
		clients_desc: "Gestione dei clienti DAG",
		toast_invoice_error:
			"Si è verificato un errore. Controlla che l'evento sia chiuso e che il rib sia presente.",
		documents: "Documenti",
		total_subs: "Numero totale di iscrizioni",
		total_subs_online: "Numero totale di iscrizioni online",
		total_subs_manual: "Numero totale di iscrizioni manuali",
		total_subs_imported: "Numero di iscrizioni importate",
		total_subs_in_place: "Numero di iscrizioni in loco",
		docMissing: "Documenti mancanti",
		manualCancel: "Annulla",
		orga_title: "Diventa un organizzatore",
		signup_error_country: "Il paese non è valido",
		signup_error_city: "La città non è valida",
		signup_error_postal_code: "Il codice postale non è valido",
		signup_error_adresse: "L'indirizzo non è valido",
		sub_validation_message: "Il motivo è mancante",
		surrended: "Consegnato",
		runEndDate: "Data di fine",
		runMaxNbOfSlots: "Numero massimo di posti",
		runnerChart: "Carta dei corridori",
		yourParentalAuthorizationModel:
			"Il tuo modello di autorizzazione genitoriale",
		addBban: "Aggiungi un RIB",
		editBban: "Modifica un RIB",
		bbanType: "Tipo di RIB",
		bbanName: "Nome del RIB",
		bankName: "Nome della banca",
		iban: "IBAN",
		bicSwift: "BIC/SWIFT",
		areYouThePersonInCharge: "Sei il tutore legale?",
		personInChargeInformations: "Informazioni del tutore legale",
		subOptions: "Opzioni",
		subEdit: "Modifica iscrizione",
		subscription: "Iscrizione",
		runName: "Nome della corsa",
		teamName: "Nome della squadra",
		runStartDate: "Data di partenza",
		noClub: "Nessun club",
		loginMissing: "Credenziali mancanti",
		createNewEdition: "Crea una nuova edizione",
		disableSubscriptions: "Disabilita le iscrizioni",
		activateSubscriptions: "Attiva le iscrizioni",
		inputs_edition_not_complete: "Per favore, compila tutti i campi",
		error_new_edition:
			"Si è verificato un errore durante la creazione della nuova edizione",
		mailUsed: "Email ignorata. Già utilizzata.",
		mailUsedTooltip:
			"Controlla il nome e il cognome. Potrebbero essere scritti in modo errato",
		succes_stopsubscription_event: "Iscrizioni disabilitate con successo",
		error_stopsubscription_event:
			"Si è verificato un errore durante la disabilitazione delle iscrizioni",
		results: "risultato",
		displayOrder: "Ordine di visualizzazione",
		moveAbove: "Sposta in alto",
		moveBelow: "Sposta in basso",
		moveTop: "Sposta in cima",
		moveBottom: "Sposta in fondo",
		cannotDeleteRunWithSubscription:
			"Non puoi eliminare una corsa con iscrizioni",
		addGpxFile: "Aggiungi file GPX",
		gpxFile: "File GPX",
		ShouldBeNotSameDate: "Le date non devono essere le stesse",
		toastSuccessAddingRib: "RIB aggiunto con successo",
		toastErrorAddingRib: "Si è verificato un errore durante l'aggiunta del RIB",
		placeholder_select_rib: "Scegli un RIB",
		processed: "Elaborato",
		earnings: "Guadagni",
		chartTitleSubs: "Iscrizioni / Corsa",
		chartTitleGenders: "Generi",
		chartTitleCases: "Casi",
		noOptions: "Nessuna opzione disponibile",
		noAdditionalInformation: "Nessuna informazione aggiuntiva",
		saveOk: "Salvataggio riuscito",
		saveError: "Si è verificato un errore durante il salvataggio",
		shareLiveTrackingUrl: "Condividi l'URL di tracciamento in tempo reale",
		cantPreview:
			"L'anteprima non è disponibile per questo documento (troppo grande). Per favore, scaricalo.",
		liveTypeOfRun: "Che tipo di corsa?",
		liveDateOfRun: "Quando è la data di partenza della corsa?",
		bibNotFound:
			"Questo pettorale non fa parte del tuo evento. Clicca per aggiungerlo agli iscritti",
		between: "tra",
		and: "e",
		none: "Nessuno",
		bySubscriber: " /corridore",
		chooseCalendarRunType: "Scegli un tipo di corsa",
		chooseCalendarRunTypeSimple: "Corsa singola (Partenza - Inter. - Arrivo)",
		chooseCalendarRunTypeLaps: "Corsa a giri",
		chooseCalendarRunTypeSimpleVirtual:
			"Corsa virtuale semplice (Applicazione sportiva)",
		chooseCalendarRunTypeLapsVirtual:
			"Corsa a giri virtuale (Applicazione sportiva)",
		chooseCalendarRunTypeTriathlon: "Triathlon",
		calendarRunDistance: "Quanto è lunga la corsa? (in metri)",
		calendarRunLaps: "Quanti giri ha la tua corsa?",
		calendarRunDetectionPoints: "I tuoi punti di rilevamento",
		calendarRunSegments: "Le tue tappe",
		calendarRunDetectionAddPoint: "Aggiungi un punto di rilevamento",
		calendarRunAddSegment: "Aggiungi una tappa",
		calendarRunDetectionConfigurePoint: "Configura il tuo punto di rilevamento",
		calendarRunConfigureSegment: "Configura la tua tappa",
		calendarRunDetectionType: "Qual è il tipo del tuo punto?",
		calendarRunDetectionChooseType: "Scegli un tipo",
		finish: "Fine",
		arrived: "Arrivato",
		advancedParams: "Impostazioni avanzate",
		interpassTime: "Tempo interpassaggio",
		maxNumberPass: "Numero massimo di passaggi",
		minNumberPass: "Numero minimo di passaggi",
		startNumberPass: "Numero di partenza",
		endNumberPass: "Numero di arrivo",
		numberPass: "Numero passaggio",
		startDetectionPoint: "Ingresso",
		endDetectionPoint: "Uscita",
		latitude: "Latitudine",
		longitude: "Longitudine",
		order: "Ordine",
		radius: "Raggio",
		altitude: "Altitudine",
		runFormTypeError: "Devi scegliere un tipo di corsa",
		runFormDistanceError:
			"La distanza della tua corsa deve essere maggiore di 0",
		runFormLapsError: "Il numero di giri deve essere maggiore di 0",
		runFormPointNameError: "Devi dare un nome al tuo punto",
		runFormPointDistanceError: "La distanza del tuo punto deve essere definita",
		runFormPointLineError: "Una linea deve essere definita",
		runFormPointTypeError: "Devi scegliere un tipo di cucitura",
		runFormPointSameError: "I punti di inizio e fine sono gli stessi",
		runFormSegmentNotComplete: "Informazioni sulla tappa incomplete",
		runFormSegmentErrorHappened:
			"Si è verificato un errore durante il salvataggio della tappa",
		calendarRunDeleteSegment: "Elimina tappa",
		runFormDeleteSegmentConfirmation:
			"Sei sicuro di voler eliminare questa tappa?",
		runFormDeleteSegmentSuccess: "Tappa eliminata con successo",
		runFormUpdateSegmentSuccess: "Tappa aggiornata con successo",
		runFormDistanceType: "Scegli il tipo di distanza",
		whichRunConfigure: "Quale corsa vuoi configurare?",
		credentialDagLive: "Le tue credenziali DAG Live",
		saveCredentialSuccess: "La tua credenziale è stata salvata",
		saveCredentialError:
			"Si è verificato un errore durante il salvataggio della tua credenziale.",
		saveConfigRunSuccess: "La tua configurazione è stata salvata.",
		saveConfigRunError:
			"Si è verificato un errore durante l'aggiornamento della tua configurazione.",
		saveConfigPointSuccess: "Il tuo punto è stato salvato.",
		saveConfigPointError:
			"Si è verificato un errore durante il salvataggio del tuo punto.",
		deleteConfigPointSuccess: "La tua configurazione è stata eliminata.",
		deleteConfigPointError:
			"Si è verificato un errore durante l'eliminazione del tuo punto.",
		seeAllDetections: "Vedi tutte le rilevazioni",
		rankingHeaderRank: "Pos.",
		rankingHeaderName: "Nome",
		rankingHeaderBib: "Pettorale",
		rankingHeaderLastPassage: "Ultimo passaggio",
		rankingHeaderDetections: "Rilevazioni",
		rankingHeaderTime: "Tempo",
		rankingHeaderCategoryRank: "Pos. Cat.",
		rankingHeaderSexRank: "Pos. Sesso",
		rankingHeaderRun: "Corsa",
		rankingHeaderTeam: "Squadra",
		rankingHeaderActions: "Azioni",
		editRunnerTooltip:
			"Attenzione, l'aggiornamento del corridore cambierà anche tutte le altre iscrizioni",
		editRunnerRunTooltip:
			"L'aggiornamento delle informazioni sulla corsa influenzerà la classifica",
		selectRunPlaceholder: "Seleziona una corsa",
		chooseTeam: "Scegli una squadra",
		createTeam: "Crea una squadra",
		resetOnRunner: "Resetta sul corridore",
		bibTime: "Tempo del pettorale",
		manageDetections: "Gestisci le rilevazioni",
		totalPriceOfRun: "Prezzo totale della corsa (escluse le tasse)",
		invalidateSub: "Annullare l'iscrizione?",
		errorVerifyBibAlreadyUse:
			"Si è verificato un errore. Verifica che il pettorale non sia già utilizzato.",
		addDetection: "Aggiungi una rilevazione",
		setRunnerSurrended: "Metti il corridore come abbandonato",
		setRunnerDisqualified: "Metti il corridore come squalificato",
		setRunnerStarting: "Metti il corridore come in partenza",
		setRunnerNotStarting: "Metti il corridore come non partente",
		updateRunnerStatusSurrended:
			"Aggiornare lo stato del corridore a abbandonato?",
		updateRunnerStatusDisqualified:
			"Aggiornare lo stato del corridore a squalificato?",
		updateRunnerStatusStarting:
			"Aggiornare lo stato del corridore a in partenza?",
		updateRunnerStatusNotStarting:
			"Aggiornare lo stato del corridore a non partente?",
		notStarting: "Non partente",

		// PatchNotes
		patchNotes: "Note di aggiornamento",
		patchAddNotes: "Aggiunta di note di aggiornamento",
		patchAddJetcodes: "Disponibilità di Jetcodes per un evento",
		patchAddRoles: "Gestione dei diritti di accesso degli utenti",
		patchCorrectDocuments: "Correzione della visualizzazione dei documenti",
		patchFixInvoiceErrorMessage:
			"Correzione del messaggio di errore della fattura",
		patchChangeDashboardLinks:
			"Modifica dei link per le schede evento nel Dashboard",
		patchUpdateSendOneMail: "Aggiornato l'invio di una mail a un corridore",
		patchMailHistory:
			"Correzione della visualizzazione della cronologia delle email inviate a un corridore",
		patchResponsive:
			"Correzione della reattività per migliorare l'usabilità su mobile",
		patchHoursPrices:
			"Correzione dell'aggiornamento dell'orario quando si vuole aggiornare un prezzo",
		patchRedirectionToValidate:
			"Aggiornamento della redirezione quando si clicca su X per validare",
		patchTraduction: "Correzione delle traduzioni",
		patchEventImages: "Correzione delle immagini evento",
		patchCreateEdition: "Aggiungi la creazione di una nuova edizione",
		patchPictoDocs: "Aggiungi un'icona per i documenti",
		patchForgetPassword: "Aggiungi la funzionalità di recupero password",
		patchAddPdfExport: "Aggiungi l'esportazione in PDF",
		patchAddFilterDocuments:
			"Aggiungi un filtro per le iscrizioni con documenti in sospeso",
		onlyWithDocStart: "Visualizza solo iscrizioni con",
		onlyWithDocStrong: " documenti in sospeso",
		patchReasignBibsAndKeepThem:
			"Comportamento aggiornato della riassegnazione dei pettorali con la possibilità di mantenere i pettorali già assegnati",
		patchAddRegistration: "Migliora l'aggiunta di un'iscrizione",
		patchGenderX: "Visualizza genere X",
		patchRegistrations: "Migliora la gestione delle iscrizioni",
		patchSwitchSubscriptionStatus: "Ora puoi cambiare lo stato dell'iscrizione",
		patchUpdateAdminStatistics:
			"Aggiornamento dell'interfaccia di amministrazione riguardo alle tabelle dei dati",
		patchAddRibSelector:
			"Aggiunta di una funzionalità per aggiungere rapidamente RIB da un elenco di RIB già utilizzati dall'utente",
		patchEditOption: "Consente di configurare le opzioni delle iscrizioni",
		patchAddResults: "Consente di aggiungere risultati come file PDF",
		patchDocumentPreview: "Migliora l'anteprima dei documenti",
		patchUX: "Migliora l'esperienza utente",
		patchPDFExportRaces: "Aggiungi selezione corsa per l'esportazione in PDF",

		deleteGpxFileConfirmation: "Sei sicuro di voler eliminare questo file?",
		deletedFile: "File eliminato!",
		selectRunMail: "Seleziona una corsa",
		detectionPointName: "Nome del punto di rilevazione",
		detectionLigne: "Linea",
		verifyPrestation:
			"Verifica che tu abbia una live in corso e la tua configurazione DAG Live",
		verifyCredential:
			"Si è verificato un errore. Verifica la tua credenziale DAG Live.",
		noPrestationStarted:
			"Avvia la live per la prima volta per visualizzare la classifica.",
		ranked: "Classificato",
		missing: "Mancante",

		ffa_error_generic: "Licenza non validata",
		ffa_error_001: "Errore con il formato della data di nascita",
		ffa_error_002: "Informazioni fornite insufficienti",
		ffa_error_003: "Numero non trovato",
		ffa_error_004: "Numero TP non trovato",
		ffa_error_005: "Numero CF non trovato",
		ffa_error_006:
			"Il numero non sarà più valido al momento della competizione",
		ffa_error_007:
			"Il numero non corrisponde a nome / cognome / sesso / data di nascita",
		ffa_error_008:
			"Le tue informazioni (nome / cognome / sesso / data di nascita) non sono state trovate",
		ffa_error_009: "Errore di omonimia, contatta direttamente la FFA",
		ffa_error_010:
			"Servizio FFA non disponibile, contatta l'organizzatore (10)",
		ffa_error_011:
			"Servizio FFA non disponibile, contatta l'organizzatore (11)",
		ffa_error_012:
			"Servizio FFA non disponibile, contatta l'organizzatore (12)",
		ffa_error_013:
			"Servizio FFA non disponibile, contatta l'organizzatore (13)",
		ffa_error_014:
			"Servizio FFA non disponibile, contatta l'organizzatore (14)",
		ffa_error_015: "Numero PPS non trovato",
		SUB: "SUB",
		ARR: "ARR",
		DNS: "DNS",
		DNF: "DNF",
		DQ: "DQ",
		sport_identity_error_name_taken: "Il nome dell'evento è già in uso",
		add_licence: "Aggiungi una licenza",
		udc_licence_remaining: "Licenza UDC rimanente",
		total_key_credit: "Crediti totali per la codifica",
		donation_required: "Donazione richiesta",
		donation_minimum_amount: "Importo minimo della donazione",
		additional_information: "Informazioni aggiuntive",
		shop_licence: "Licenze",
		shop_material: "Materiale",
		shop_chip: "Chip",
		global_search: "Cerca",
		global_search_desc: "Cerca persone, clienti, ordini...",
		quickstart: "Inizio rapido",
		features: "Caratteristiche",
		shop: "Negozio",
		reset: "Ripristina",
		persons: "Persone",
		commands: "Comandi",
		search_results: "Risultati della ricerca",
		account_type: "Tipo di account",
		registration: "Registrazione",
		ttc_price: "Prezzo con tasse",
		command_date: "Data dell'ordine",
		payer: "Payer",
		donation_list: "Elenco delle donazioni",
		no_run_with_donation:
			"Nessuna corsa configurata con una donazione per un'associazione",
		donation_value: "Valore della donazione",
		no_donation_for_association: "Nessuna donazione per questa associazione",
		donation_campain: "Campagna di donazione",
		campain_email: "EMAIL DELL'ASSOCIAZIONE",
		campain_objective: "OBIETTIVO DELLA CAMPAGNA",
		campain_minimum: "IMPORTO MINIMO",
		campain_default: "IMPORTO PREDEFINITO",
		pps_number: "Numero PPS",
		all_runs: "Tutte le corse",
		all_runners: "Tutti i corridori",
		all_valid_subsriptions: "Tutte le iscrizioni valide",
		all_invalid_subsriptions: "Tutte le iscrizioni invalide",
		all_incomplete_subsriptions: "Tutte le iscrizioni incomplete",
		active_users: "Utenti attivi",
		tracking_url: "URL di tracciamento",
		document_title: "Titolo del documento",
		ranking: "Classifica",
		subscription_history_by_run: "Storico iscrizioni per corsa",
		rights_add_subscription: "Registrazione",
		rights_add_subscription_infos: "Permette di aggiungere un iscritto in loco",
		rights_import_subscriptions: "Importa iscrizioni",
		rights_import_subscriptions_infos: "In formato Excel",
		rights_export_subscriptions: "Esporta iscrizioni",
		rights_export_subscriptions_infos: "In formato Excel, CSV, PDF o GmDAG",
		rights_validate_documents: "Validazione dei documenti",
		rights_validate_documents_infos:
			"Validazione dei certificati medici e delle autorizzazioni parentali",
		rights_send_mails: "Comunicazione con gli iscritti (email)",
		rights_subscription_live: "Gestione delle iscrizioni e live",
		rights_run_config: "Configurazione corsa",
		rights_rib_config: "Modifica dati bancari (RIB)",
		rights_contact_config: "Modifica dettagli di contatto",
		rights_runner_management: "Gestione dei corridori",
		rights_runner_management_infos:
			"Accesso alla lista, tempi di passaggio, ecc.",
		rights_dossard_assign: "Assegnazione del pettorale",
		rights_dossard_assign_infos:
			"Permette di cambiare il numero del pettorale di un corridore",
		live_chrono: "Live/Tempi",
		passage_points: "Punti di passaggio",
		times: "Tempi",
		detections: "Rilevamenti",
		general_ranking: "Rk Gen.",
		sex_ranking: "Rk Sex.",
		cat_ranking: "Rk Cat.",
		minAgeRequired: (minAge: number) =>
			`L'età minima richiesta è ${minAge} anni.`,
		maxAgeRequired: (maxAge: number) =>
			`L'età massima richiesta è ${maxAge} anni.`,
		yearBetweenRequired: (minAge: number, maxAge: number) =>
			`L'anno di nascita deve essere compreso tra ${minAge} e ${maxAge} incluso.`,
		ageBetweenRequired: (minAge: number, maxAge: number) =>
			`Devi avere almeno ${minAge} anni o al massimo ${maxAge} anni al momento dell'iscrizione.`,
		activities: "Attività",
		not_all_position_display:
			"Il numero di posizioni è stato modificato, verranno visualizzate solo 500 posizioni",
		success_time_saved: "Il tempo è stato salvato",
		error_time_saved:
			"Si è verificato un errore durante il salvataggio del tempo",
		display_gpx: "Rendere visibili le tracce GPX?",
		error_create_licence: "Errore nella creazione della licenza",
		success_create_licence: "Licenza creata con successo",
		error_create_encode_key: "Errore nella creazione della chiave di codifica",
		success_create_encode_key: "Chiave di codifica creata con successo",
		live_ranking: "Gestione della classifica",
		add_ranking: "Aggiungi classifica",
		deselect: "Deseleziona",
		ranking_name: "Nome classifica",
		available_filters: "Filtri disponibili",
		ranking_filters_explanation:
			"Questi filtri definiscono le combinazioni disponibili per la classificazione",
		toast_error_save_ranking_config:
			"Errore durante il salvataggio delle classifiche",
		toast_success_save_ranking_config: "Classifica salvata",
		toast_empty_ranking_config:
			"Il nome e i filtri della classifica non possono essere vuoti",
		sure_delete_ranking_list: (name: string) =>
			`Sei sicuro di voler eliminare "${name}"?`,
		success_delete_ranking_list: "Classifica eliminata",
		error_delete_ranking_list: "Errore durante l'eliminazione della classifica",
		custom_rankings: "classifica personalizzata",
		care_choice_unavaible:
			"Tieni presente che queste scelte non saranno più disponibili per il filtro di classificazione.",
		list_name_required:
			"Il nome dell'elenco è obbligatorio e deve essere univoco",
		ranking_title: "Classifica",
		ranking_all_choices: "Tutti",
		ranking_all_races: "Tutte le gare",
		ranking_export_experimental: "(sperimentale)",
		ranking_export_info1:
			"Esporta i risultati come appaiono sullo schermo in formato Excel.",
		ranking_export_info2:
			"Le colonne sono le stesse visualizzate nella pagina di gestione dei corridori.",
		ranking_export_pdf_newline: "Interruzione di pagina al cambio di sesso:",
		ranking_export_pdf_unranked: "Mostra non classificati:",
		ranking_export_pdf_all_races: "Tutte le gare",
		price_dates_archived: "Tariffe archiviate"
	}
};

export default trad;
